import { IconCheck, IconSearch } from "@/assets/svg";
import { useTheme } from "@/styles";
import type { ListRenderItem } from "@shopify/flash-list";
import { FlashList } from "@shopify/flash-list";
import { matchSorter } from "match-sorter";
import type { FC } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import type {
  AccessibilityProps,
  DimensionValue,
  StyleProp,
  ViewStyle,
} from "react-native";
import { Platform, StyleSheet, TouchableHighlight, View } from "react-native";
import type { DialogProps } from "../Dialog";
import { Dialog } from "../Dialog";
import { Text } from "../Typography";
import type { CoreSelectProps, SelectOption } from "./Select";
import { TextInput } from "./TextInput";

interface SelectPopoverProps<TValue> extends CoreSelectProps<TValue> {
  id?: string;
  isOpen: boolean;
  close: () => void;
  style?: StyleProp<ViewStyle>;
  dialogVariant?: DialogProps["variant"];
}

const ITEM_HEIGHT = 48;

const SelectItem: FC<{
  option: SelectOption<any>;
  selected: boolean;
  onPress: () => void;
}> = ({ option, selected, onPress }) => {
  const theme = useTheme();

  return (
    <TouchableHighlight
      onPress={onPress}
      underlayColor={theme.colors.layerMuted}
      role="option"
      accessibilityState={{
        selected,
      }}
      style={{
        backgroundColor: theme.colors.layerDefault,
      }}
    >
      <View style={styles.option}>
        {option.Icon && (
          <option.Icon
            width={16}
            height={16}
            color={theme.colors.textSecondary}
          />
        )}
        {option.left}
        <Text
          variant="body2Regular"
          color="textSecondary"
          style={styles.optionLabel}
        >
          {option.label}
        </Text>
        {selected ? (
          <IconCheck
            width={16}
            height={16}
            color={theme.colors.textSecondary}
          />
        ) : (
          <View style={{ width: 16 }} />
        )}
      </View>
    </TouchableHighlight>
  );
};

function SelectPopoverContent<TValue>({
  options,
  value,
  onValueChange,
  close,
  showSearch,
  freeSolo,
}: CoreSelectProps<TValue> & {
  close: () => void;
}) {
  const height = options.length * ITEM_HEIGHT;

  const renderItems = useCallback<ListRenderItem<SelectOption<TValue>>>(
    ({ item: option }) => {
      return (
        <SelectItem
          option={option}
          selected={option.value === value}
          onPress={() => {
            onValueChange(option.value, option);
            close();
          }}
        />
      );
    },
    [close, onValueChange, value],
  );

  const [search, setSearch] = useState("");

  const filterOptions = useMemo<SelectOption<TValue>[]>(() => {
    const trimmedSearch = search.trim();

    const nonEmptyOptions = options.filter((option) => !!option.value);

    const result = trimmedSearch
      ? matchSorter(nonEmptyOptions, trimmedSearch, {
          keys: ["value", "label", "alt"],
        })
      : options;

    if (
      freeSolo &&
      !result.find(
        (option) =>
          option.label === trimmedSearch ||
          option.value === trimmedSearch ||
          option.alt?.includes(trimmedSearch),
      )
    ) {
      // no matching option, add a free solo option
      return [
        ...result,
        {
          label: `"${trimmedSearch}"`,
          value: trimmedSearch as TValue,
        },
      ];
    }

    return result;
  }, [freeSolo, options, search]);

  const listRef = useRef<FlashList<SelectOption<TValue>>>(null);

  const [initialScrollIndex] = useState(() => {
    if (value) {
      const currentOptionIndex = options.findIndex(
        (option) => option.value === value,
      );
      if (currentOptionIndex !== -1) {
        return currentOptionIndex;
      }
      return 0;
    }
  });

  const stickyHeaderIndices = useMemo(() => {
    const indices: number[] = [];
    filterOptions.forEach((option, index) => {
      if (option.sticky) {
        indices.push(index);
      }
    });
    return indices;
  }, [filterOptions]);

  return (
    <>
      {showSearch && (
        <View style={styles.search}>
          <TextInput
            placeholder="Search"
            value={search}
            onValueChange={setSearch}
            Icon={IconSearch}
            variant="filled"
          />
        </View>
      )}
      <View style={[styles.list, { height }]}>
        <FlashList
          initialScrollIndex={initialScrollIndex}
          ref={listRef}
          data={filterOptions}
          estimatedItemSize={ITEM_HEIGHT}
          renderItem={renderItems}
          // @ts-ignore: find a role for listbox
          role="listbox"
          persistentScrollbar
          stickyHeaderIndices={stickyHeaderIndices}
          keyboardShouldPersistTaps="handled"
        />
      </View>
    </>
  );
}

export function SelectPopover<TValue>({
  isOpen,
  close,
  style,
  options,
  value,
  onValueChange,
  id,
  showSearch,
  freeSolo,
  dialogVariant = "basic",
  ...props
}: SelectPopoverProps<TValue> & AccessibilityProps) {
  return (
    <Dialog.Root
      isOpen={isOpen}
      close={close}
      variant={dialogVariant}
      style={[styles.dialog, style]}
      id={id}
      {...props}
    >
      <SelectPopoverContent
        options={options}
        value={value}
        onValueChange={onValueChange}
        close={close}
        showSearch={showSearch}
        freeSolo={freeSolo}
      />
    </Dialog.Root>
  );
}

const styles = StyleSheet.create({
  dialog: {
    overflow: "hidden",
  },
  list: {
    maxHeight: Platform.select({
      web: "80vh" as DimensionValue,
      default: "80%",
    }),
  },
  option: {
    paddingHorizontal: 20,
    alignItems: "center",
    gap: 8,
    flexDirection: "row",
    minHeight: ITEM_HEIGHT,
    paddingVertical: 8,
  },
  optionLabel: {
    flex: 1,
  },
  search: {
    padding: 8,
    paddingTop: 12,
    paddingBottom: 0,
  },
});
