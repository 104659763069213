import type { AuthTokens } from "./types";

const EXPIRATION_GRACE_PERIOD = 60 * 1000; // 1 minute

export function isTokenExpired(tokens: AuthTokens) {
  const expiresAt = tokens.expiresAt;
  // this also causes the previous version of storage,
  // which does not have expires info to refresh
  if (!expiresAt) {
    return true;
  }
  if (expiresAt * 1000 - Date.now() < EXPIRATION_GRACE_PERIOD) {
    return true;
  }
  return false;
}
