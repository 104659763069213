import { useAuth } from "@/features/auth";
import { useGetTeamQuery } from "@/graphql";
import { useGetUserGroupsQuery } from "@firefliesai/payments-ff.graphql-client";

export const useTeam = () => {
  const { user } = useAuth();

  const { data, loading } = useGetTeamQuery({
    skip: !user,
  });
  const team = data?.team;

  const userId = user?.notebookId || user?.id;

  const isAdmin = Boolean(
    userId &&
      team &&
      (team.admin === userId ||
        team.admins?.includes(userId) ||
        team.teammates?.find((teammate) => teammate?.id === userId)?.isAdmin),
  );

  const { data: userGroupsData, loading: userGroupsLoading } =
    useGetUserGroupsQuery({
      skip: !user,
    });

  const userGroups = userGroupsData?.getUserGroups;

  return {
    team,
    loading: loading || userGroupsLoading,
    isAdmin,
    userGroups,
  };
};
