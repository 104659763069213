import {
  IconCheckSquare,
  IconSoundbite,
  IconStar,
  IconThumbsDown,
  IconThumbsUp,
} from "@/assets/svg";
import { BottomSheetMenuModal } from "@/components/BottomSheet";
import { IconButton } from "@/components/Button";
import {
  BOOKMARK_ACTION_TYPES,
  BOOKMARK_PIN_TYPES,
  BOOKMARK_TYPES,
  CREATE_BOOKMARK_SOURCES,
} from "@/constants";
import type { CreateMarkMutationVariables, Meeting } from "@/graphql";
import { useBooleanState } from "@/utils/states";
import { useState, type FC } from "react";
import { StyleSheet, View } from "react-native";
import type { SvgProps } from "react-native-svg";
import { useLiveScreenContext } from "../Context/LiveScreenContext";
import { getElapsedDuration } from "../utils";

type LiveScreenActionVariable = Pick<
  CreateMarkMutationVariables,
  "markPinType" | "actionType" | "pastOffsetSeconds" | "futureOffsetSeconds"
>;

const LiveScreenSoundbiteAction: FC<{
  meeting: Meeting;
}> = ({ meeting }) => {
  const [loading, setLoading] = useState(false);
  const { createMark } = useLiveScreenContext();

  const [isOpenMenu, openMenu, closeMenu] = useBooleanState();

  const createSoundbite = (
    pastOffsetSeconds: number,
    futureOffsetSeconds: number,
  ) => {
    setLoading(true);
    createMark({
      meetingId: meeting.id,
      markType: BOOKMARK_TYPES.PIN,
      actionType: BOOKMARK_ACTION_TYPES.SOUND_BITE,
      markPinType: BOOKMARK_PIN_TYPES.SOUND_BITE,
      pastOffsetSeconds,
      futureOffsetSeconds,
      time: getElapsedDuration(
        meeting.fredJoinedMeetingAt || meeting.startTime,
      ),
      source: CREATE_BOOKMARK_SOURCES.LIVE,
    }).finally(() => setLoading(false));
  };

  return (
    <>
      <IconButton
        variant="neutral"
        size="sm"
        Icon={IconSoundbite}
        label="Create Soundbite"
        onPress={openMenu}
        disabled={loading}
      />
      <BottomSheetMenuModal
        isOpen={isOpenMenu}
        close={closeMenu}
        items={[
          {
            label: "Past 20 seconds",
            onPress() {
              createSoundbite(20, 0);
            },
          },
          {
            label: "Past 40 seconds",
            onPress() {
              createSoundbite(40, 0);
            },
          },
          {
            label: "Past 1 minute",
            onPress() {
              createSoundbite(60, 0);
            },
          },
        ]}
      />
    </>
  );
};

const LiveScreenPinAction: FC<{
  Icon: FC<SvgProps>;
  label: string;
  variables: LiveScreenActionVariable;
  meeting: Meeting;
}> = ({ Icon, label, variables, meeting }) => {
  const [loading, setLoading] = useState(false);
  const { createMark, meetingId } = useLiveScreenContext();

  const onPress = () => {
    setLoading(true);
    createMark({
      ...variables,
      meetingId,
      markType: BOOKMARK_TYPES.PIN,
      time: getElapsedDuration(
        meeting.fredJoinedMeetingAt || meeting.startTime,
      ),
      source: CREATE_BOOKMARK_SOURCES.LIVE,
    }).finally(() => setLoading(false));
  };

  return (
    <IconButton
      variant="neutral"
      size="sm"
      Icon={Icon}
      label={label}
      onPress={onPress}
      disabled={loading}
    />
  );
};

export const LiveScreenActions: FC<{
  meeting: Meeting;
}> = ({ meeting }) => {
  return (
    <View style={styles.root}>
      <LiveScreenPinAction
        Icon={IconStar}
        label="Bookmark Important"
        variables={{
          actionType: BOOKMARK_ACTION_TYPES.BOOKMARK,
          markPinType: BOOKMARK_PIN_TYPES.IMPORTANT,
        }}
        meeting={meeting}
      />
      <LiveScreenPinAction
        Icon={IconCheckSquare}
        label="Bookmark Action Item"
        variables={{
          actionType: BOOKMARK_ACTION_TYPES.BOOKMARK,
          markPinType: BOOKMARK_PIN_TYPES.ACTION,
        }}
        meeting={meeting}
      />
      <LiveScreenPinAction
        Icon={IconThumbsUp}
        label="Bookmark Positive"
        variables={{
          actionType: BOOKMARK_ACTION_TYPES.BOOKMARK,
          markPinType: BOOKMARK_PIN_TYPES.POSITIVE,
        }}
        meeting={meeting}
      />
      <LiveScreenPinAction
        Icon={IconThumbsDown}
        label="Bookmark Concern"
        variables={{
          actionType: BOOKMARK_ACTION_TYPES.BOOKMARK,
          markPinType: BOOKMARK_PIN_TYPES.CONCERN,
        }}
        meeting={meeting}
      />
      <LiveScreenSoundbiteAction meeting={meeting} />
    </View>
  );
};

export const LoadingLiveScreenActions: FC = () => {
  return (
    <View style={styles.root}>
      <IconButton
        variant="neutral"
        size="sm"
        Icon={IconStar}
        label=""
        disabled
      />
      <IconButton
        variant="neutral"
        size="sm"
        Icon={IconCheckSquare}
        label=""
        disabled
      />
      <IconButton
        variant="neutral"
        size="sm"
        Icon={IconThumbsUp}
        label=""
        disabled
      />
      <IconButton
        variant="neutral"
        size="sm"
        Icon={IconThumbsDown}
        label=""
        disabled
      />
      <IconButton
        variant="neutral"
        size="sm"
        Icon={IconSoundbite}
        label=""
        disabled
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
    paddingHorizontal: 32,
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
});
