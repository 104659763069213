import { useAuth } from "@/features/auth";
import { useTeam } from "@/features/team";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";

export const useCanManageMeeting = (meeting: Meeting) => {
  const { isAdmin, team } = useTeam();
  const { user } = useAuth();
  const isOwner = Boolean(
    user &&
      (user.id === meeting.owner || user.email === meeting.organizerEmail),
  );
  const isMeetingOwnerTeamAdmin = Boolean(
    team &&
      team.teammates?.some(
        (teammate) => teammate?.email === meeting.organizerEmail,
      ) &&
      isAdmin,
  );
  return isMeetingOwnerTeamAdmin || isOwner;
};
