import { Avatar } from "@/components/Avatar";
import { LinkView } from "@/components/Link";
import { RouteNames } from "@/constants";
import { useAuth } from "@/features/auth";
import type { FC } from "react";
import { StyleSheet } from "react-native";

export const YouHeaderButton: FC = () => {
  const { user } = useAuth();
  if (!user) return null;
  return (
    <LinkView
      style={styles.button}
      aria-label={`User: ${user?.name}`}
      href={RouteNames.You}
      animated="highlight"
    >
      <Avatar
        size={24}
        name={user.name}
        picture={user.photoUrl}
        shape="square"
      />
    </LinkView>
  );
};

const styles = StyleSheet.create({
  button: {
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
});
