import { createStyles, useTheme } from "@/styles";
import { useEffect, type FC } from "react";
import type { AccessibilityProps } from "react-native";
import { ActivityIndicator, Pressable } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

interface SwitchProps {
  value: boolean;
  onValueChange: (value: boolean) => void;
  loading?: boolean;
  disabled?: boolean;
}

const AnimatedActivityIndicator =
  Animated.createAnimatedComponent(ActivityIndicator);

export const Switch: FC<SwitchProps & AccessibilityProps> = ({
  value,
  onValueChange,
  disabled,
  loading,
  ...props
}) => {
  const offset = useSharedValue(value ? 1 : 0);
  useEffect(() => {
    offset.value = value ? 1 : 0;
  }, [value, offset]);

  const onPress = () => {
    if (disabled || loading) return;
    offset.value = value ? 0 : 1;
    onValueChange(!value);
  };

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: withTiming(offset.value * 14) }],
    };
  }, []);

  const theme = useTheme();

  return (
    // TODO: make this accessible
    <>
      <Pressable
        style={[
          styles.root(theme),
          value && styles.rootActive(theme),
          disabled && styles.rootDisabled,
        ]}
        onPress={onPress}
        disabled={disabled}
        role="switch"
        aria-checked={value}
        {...props}
      >
        {loading ? (
          <AnimatedActivityIndicator
            style={[styles.indicator, animatedStyle]}
            color="rgba(255, 255, 255, 0.5)"
          />
        ) : (
          <Animated.View style={[styles.ball, animatedStyle]} />
        )}
      </Pressable>
    </>
  );
};

const styles = createStyles({
  root: (theme) => ({
    width: 38,
    height: 24,
    borderRadius: 24,
    backgroundColor: theme.colors.interactiveNeutral3Default,
    position: "relative",
    padding: 4,
  }),
  rootActive: (theme) => ({
    backgroundColor: theme.colors.interactivePrimaryDefault,
  }),
  indicator: {
    width: 16,
    height: 16,
  },
  ball: {
    width: 16,
    height: 16,
    backgroundColor: "#FFFFFF",
    borderRadius: 9999,
  },
  rootDisabled: {
    opacity: 0.75,
  },
});
