import { toast } from "@/components/Toast";
import { Logger } from "@/logger";
import type { ErrorEvent } from "@/modules/audio-record";
import { scheduleNotificationAsync } from "@/utils/notifications";
import { useBooleanState } from "@/utils/states";
import { useCallback, useEffect, useRef, useState } from "react";
import { recorderApi, useRecorder } from "../record.store";
import { SilentRecordingDialog } from "./SilentRecordingDialog";

const TIMEOUT_MS = 10 * 1000;

const logger = new Logger("live-record-interrupt-listener");

// check if the recording is silent for 30 seconds
// straight and show actionable message to user
export const useIsRecordingSilent = ({
  onDetectedSilence,
}: {
  onDetectedSilence: () => void;
}) => {
  const { status } = useRecorder();
  const prevStatusRef = useRef(status.state);

  const onDetectedSilenceRef = useRef(onDetectedSilence);
  useEffect(() => {
    onDetectedSilenceRef.current = onDetectedSilence;
  }, [onDetectedSilence]);

  const hasShownSilenceMessage = useRef(false);

  useEffect(() => {
    if (prevStatusRef.current === "recording" && status.state === "inactive") {
      // new recording started
      hasShownSilenceMessage.current = false;
    }
    prevStatusRef.current = status.state;
  }, [status.state]);

  const silenceTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );

  const [isSilent, setIsSilent] = useState(false);

  useEffect(() => {
    if (isSilent) {
      const timeout = setTimeout(() => {
        if (hasShownSilenceMessage.current) return;
        logger.info(`silence detected after ${TIMEOUT_MS}ms`);
        hasShownSilenceMessage.current = true;
        onDetectedSilenceRef.current();
      }, TIMEOUT_MS);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isSilent]);

  useEffect(() => {
    if (status.state !== "recording") return setIsSilent(false);
    const subscription = recorderApi.addEventListener("status", (event) => {
      // event.metering is a value between -160 and 0
      // show message if this keeps happening for x seconds
      if (!event.metering) return;
      setIsSilent(event.metering <= -160);
    });
    return subscription.remove;
  }, [status.state]);

  useEffect(() => {
    return () => {
      clearTimeout(silenceTimeoutRef.current);
    };
  }, []);

  return isSilent;
};

const showNotificationOnSilence = () => {
  scheduleNotificationAsync({
    content: {
      title: "Your recording has no sound",
      body: "Please check that your microphone is working and no other apps are using it.",
    },
    trigger: null,
    identifier: "live-record/recording",
  });
};

const showNotificationOnInterrupt = () => {
  scheduleNotificationAsync({
    content: {
      title: "Your recording was interrupted",
      body: "You may need to resume your recording.",
    },
    trigger: null,
    identifier: "live-record/recording",
  });
};

const toastError = (error: ErrorEvent) => {
  toast({
    title: "An internal error occurred while recording",
    message: error.message,
    type: "error",
  });
};

export const LiveRecordInterruptListener = () => {
  const [
    shouldShowSilentRecordingModal,
    openSilentRecordingModal,
    closeSilentRecordingModal,
  ] = useBooleanState();

  const onDetectedSilence = useCallback(() => {
    openSilentRecordingModal();
    showNotificationOnSilence();
  }, [openSilentRecordingModal]);

  useIsRecordingSilent({
    onDetectedSilence,
  });

  const { status, addEventListener } = useRecorder();
  useEffect(() => {
    if (status.state === "inactive") return;
    const interruptSub = addEventListener("interrupt", () => {
      showNotificationOnInterrupt();
    });
    const errorSub = addEventListener("error", toastError);
    return () => {
      interruptSub.remove();
      errorSub.remove();
    };
  }, [status.state, addEventListener]);

  return (
    <>
      <SilentRecordingDialog
        isOpen={shouldShowSilentRecordingModal}
        close={closeSilentRecordingModal}
        title="No sound"
      />
    </>
  );
};
