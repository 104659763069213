import type { StackRouteConfig } from "../types";
import { RecordScreen } from "./RecordScreen";

const RecordRouteConfig: StackRouteConfig<"Record"> = {
  name: "Record",
  component: RecordScreen,
  options: {
    title: "Record",
  },
};

export default RecordRouteConfig;
