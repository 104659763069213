import { useTheme } from "@/styles";
import type { ReactNode } from "react";
import { useMemo, type FC } from "react";
import { StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import type { SvgProps } from "react-native-svg";
import { Text } from "../Typography";
import { BottomSheetFF } from "./BottomSheet";

interface MenuItem {
  Icon?: FC<SvgProps>;
  label: string;
  right?: ReactNode;
  onPress: () => void;
}

const ITEM_HEIGHT = 56;

export const BottomSheetMenuModal: FC<{
  isOpen: boolean;
  close: () => void;
  items: MenuItem[];
}> = ({ isOpen, close, items }) => {
  const theme = useTheme();

  const safeAreaInsest = useSafeAreaInsets();

  const snapPoints = useMemo(
    () => [ITEM_HEIGHT * items.length + 24 + safeAreaInsest.bottom],
    [items.length, safeAreaInsest.bottom],
  );

  return (
    <BottomSheetFF.Root isOpen={isOpen} close={close} snapPoints={snapPoints}>
      <BottomSheetFF.View
        style={[styles.menu, { paddingBottom: safeAreaInsest.bottom }]}
      >
        {items.map(({ Icon, right, label, onPress }) => (
          <BottomSheetFF.TouchableHighlight
            key={label}
            underlayColor={theme.colors.layerMuted}
            activeOpacity={1}
            onPress={() => {
              close();
              onPress();
            }}
          >
            <View style={styles.item}>
              {Icon && (
                <Icon
                  color={theme.colors.textSecondary}
                  width={24}
                  height={24}
                />
              )}
              <Text
                variant="body1Regular"
                color="textPrimary"
                numberOfLines={1}
                style={styles.itemText}
              >
                {label}
              </Text>
              {right}
            </View>
          </BottomSheetFF.TouchableHighlight>
        ))}
      </BottomSheetFF.View>
    </BottomSheetFF.Root>
  );
};

const styles = StyleSheet.create({
  menu: {
    flexDirection: "column",
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    paddingHorizontal: 20,
    height: ITEM_HEIGHT,
  },
  itemText: {
    flex: 1,
  },
});
