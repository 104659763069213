import type * as AudioRecord from "@/modules/audio-record";
import * as FileSystem from "expo-file-system";

export const PCM_RECORDING_OPTIONS = {
  bitDepth: 16,
  channels: 1,
  sampleRate: 16000,
  bitRate: 128000,
  container: "wav",
  meetingId: "",
  socketUrl: "",
  socketAuthToken: "",
} satisfies Omit<AudioRecord.RecordingOptions, "fileUri">;

export const MP3_RECORDING_OPTIONS = {
  bitDepth: 16,
  channels: 1,
  sampleRate: 44100,
  bitRate: 128000,
  container: "mp3",
  meetingId: "",
  socketUrl: "",
  socketAuthToken: "",
} satisfies Omit<AudioRecord.RecordingOptions, "fileUri">;

export const MIME_TYPE_RECORDING_OPTIONS_MAP: Record<
  string,
  Omit<AudioRecord.RecordingOptions, "fileUri"> | undefined
> = {
  "audio/mpeg": MP3_RECORDING_OPTIONS,
  "audio/wav": PCM_RECORDING_OPTIONS,
};

export const RECORDING_DIRECTORY = `${FileSystem.documentDirectory}recordings/`;

export type RecordingFileExtensionInfo =
  | {
      isRaw: true;
      containedExtension: string;
      extension: string;
    }
  | {
      isRaw: false;
      extension: string;
    };

export const RECORDING_FILE_EXTENSIONS: RecordingFileExtensionInfo[] = [
  {
    extension: ".m4a",
    isRaw: false,
  },
  {
    extension: ".mp3",
    isRaw: false,
  },
  {
    extension: ".m4a.pcm",
    containedExtension: ".m4a",
    isRaw: true,
  },
  {
    extension: ".m4a.caf",
    containedExtension: ".m4a",
    isRaw: true,
  },
  {
    extension: ".wav",
    isRaw: false,
  },
  {
    extension: ".wav.tmp",
    containedExtension: ".wav",
    isRaw: true,
  },
  {
    extension: ".wav.pcm",
    containedExtension: ".wav",
    isRaw: true,
  },
  {
    extension: ".wav.caf",
    containedExtension: ".wav",
    isRaw: true,
  },
  {
    extension: ".unknown",
    isRaw: false,
  },
];

export const RECORD_HQ_AUDIO_STORAGE_KEY: string = "recordHQAudio";
export const RECORD_USE_STREAMING_STORAGE_KEY: string = "recordUseStreaming";
