import { LoadingScreen } from "@/components/Loading";
import { PersonGroupListItem, PersonListItem } from "@/components/Person";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { Config } from "@/constants";
import { useTeam } from "@/features/team";
import type {
  FindUsersMeetingIsSharedWithResponse,
  SharedUser,
} from "@/graphql";
import {
  FindUsersMeetingIsSharedWithDocument,
  MeetingNotesPrivacy,
  useCancelSharedMeetingAccessMutation,
  type Meeting,
} from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import { useCallback, type FC } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { MEETING_PRIVACY_ICON_MAP } from "../constants/privacy";
import { useCanManageMeeting } from "../utils/permissions";
import { sharedStyles } from "./styles";

const ShareMeetingPersonListItem: FC<{
  sharedUser: SharedUser;
  meeting: Meeting;
}> = ({ sharedUser, meeting }) => {
  const [cancelSharedMeetingAccess, { loading }] =
    useCancelSharedMeetingAccessMutation({
      refetchQueries: [FindUsersMeetingIsSharedWithDocument],
      onError(err) {
        toast({
          title: "Could not remove access",
          message: err.message,
          type: "error",
        });
      },
    });

  const removeAccess = useCallback(
    (sharedUser: SharedUser) => {
      if (!sharedUser.sharedMeetingId) return;
      cancelSharedMeetingAccess({
        variables: {
          meetingId: meeting.id,
          sharedMeetingId: sharedUser.sharedMeetingId,
          reason: Config.NAME,
        },
        onCompleted() {
          toast({
            message: `${sharedUser.email} removed from access`,
          });
        },
      });
    },
    [meeting.id, cancelSharedMeetingAccess],
  );

  const canManage = useCanManageMeeting(meeting);

  return (
    <PersonListItem
      {...sharedUser}
      remove={
        canManage && sharedUser.sharedMeetingId
          ? {
              label: `Remove access`,
              onPress: () => removeAccess(sharedUser),
              loading,
            }
          : undefined
      }
    />
  );
};

export const ShareMeetingDialogEmail: FC<{
  meeting: Meeting;
  setIsOpenAdd: (isOpen: boolean) => void;
  findUsersMeetingIsSharedWith:
    | FindUsersMeetingIsSharedWithResponse
    | undefined
    | null;
  loading?: boolean;
}> = ({ setIsOpenAdd, meeting, findUsersMeetingIsSharedWith, loading }) => {
  const theme = useTheme();

  const teammates = findUsersMeetingIsSharedWith?.teammates;
  const nonTeammates = findUsersMeetingIsSharedWith?.nonTeammates;

  const isTeammatesPrivacy = [
    MeetingNotesPrivacy.Teammates,
    MeetingNotesPrivacy.Teammatesandparticipants,
  ].includes(meeting.notesPrivacy);
  const isParticipantsPrivacy = [
    MeetingNotesPrivacy.Participants,
    MeetingNotesPrivacy.Teammatesandparticipants,
  ].includes(meeting.notesPrivacy);

  const { team } = useTeam();
  const isOrganizerFromSameTeam = !!team?.teammates?.some(
    (teammate) => teammate?.email === meeting.organizerEmail,
  );

  const canManage = useCanManageMeeting(meeting);

  return (
    <View style={styles.root}>
      {loading && !findUsersMeetingIsSharedWith ? (
        <LoadingScreen />
      ) : (
        <>
          {canManage && (
            <View style={styles.content}>
              <Pressable
                style={styles.fakeInput(theme)}
                onPress={() => setIsOpenAdd(true)}
              >
                <Text color="textMuted" variant="body2Regular">
                  Add emails or people
                </Text>
              </Pressable>
            </View>
          )}
          <ScrollView contentContainerStyle={sharedStyles.tabContent}>
            {(!!teammates?.length ||
              isTeammatesPrivacy ||
              isParticipantsPrivacy) && (
              <View style={styles.section}>
                <Text
                  color="textMuted"
                  variant="body2Regular"
                  style={styles.sectionTitle}
                >
                  Teammates with access
                </Text>
                {isTeammatesPrivacy && (
                  <PersonGroupListItem
                    Icon={
                      MEETING_PRIVACY_ICON_MAP[MeetingNotesPrivacy.Teammates]
                    }
                    title={
                      isOrganizerFromSameTeam
                        ? "Your team"
                        : `${meeting.organizer?.name || "Organizer"}'s Team`
                    }
                    description={
                      isOrganizerFromSameTeam
                        ? `${team?.teammates?.length || 0} members`
                        : undefined
                    }
                  />
                )}
                {isParticipantsPrivacy && (
                  <PersonGroupListItem
                    Icon={
                      MEETING_PRIVACY_ICON_MAP[MeetingNotesPrivacy.Participants]
                    }
                    title="Participants"
                    description={`${meeting.allEmails?.length || 0} people`}
                  />
                )}
                {teammates?.map(
                  (teammate) =>
                    !!teammate && (
                      <ShareMeetingPersonListItem
                        sharedUser={teammate}
                        meeting={meeting}
                        key={teammate.email}
                      />
                    ),
                )}
              </View>
            )}
            {!!nonTeammates?.length && (
              <View style={styles.section}>
                <Text
                  color="textMuted"
                  variant="body2Regular"
                  style={styles.sectionTitle}
                >
                  Other people with access
                </Text>
                {nonTeammates.map(
                  (nonTeammate) =>
                    !!nonTeammate && (
                      <ShareMeetingPersonListItem
                        sharedUser={nonTeammate}
                        meeting={meeting}
                        key={nonTeammate.email}
                      />
                    ),
                )}
              </View>
            )}
          </ScrollView>
        </>
      )}
    </View>
  );
};

const styles = createStyles({
  root: {
    paddingVertical: 16,
    flex: 1,
  },
  content: {
    paddingHorizontal: 16,
  },
  fakeInput: (theme) => ({
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: theme.colors.layerMuted,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.colors.borderStaticDefault,
  }),
  section: {
    marginBottom: 12,
  },
  sectionTitle: {
    marginBottom: 4,
  },
});
