import uniqolor from "uniqolor";

const UNIQUE_COLOR_CACHE: { [key: string]: string } = {};

export const getUniqueColor = (str: string): string => {
  if (!UNIQUE_COLOR_CACHE[str]) {
    UNIQUE_COLOR_CACHE[str] = uniqolor(str || "?").color;
  }
  return UNIQUE_COLOR_CACHE[str];
};
