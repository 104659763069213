import { IconArrowLeft, IconSearch } from "@/assets/svg";
import { TextInput } from "@/components/Input";
import { HeaderIconButton } from "@/components/Layout";
import { useNavigation } from "@react-navigation/native";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";

const PLACEHOLDERS = {
  bite: "Search your soundbites",
  playlist: "Search your playlists",
  meeting: "Search your meetings",
};

export const SearchBar: FC<{
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  entity: "bite" | "playlist" | "meeting";
}> = ({ setSearchQuery, entity }) => {
  const navigation = useNavigation();

  return (
    <View style={styles.root}>
      <HeaderIconButton
        Icon={IconArrowLeft}
        onPress={navigation.goBack}
        label="Back"
      />
      <TextInput
        onSubmitEditing={(e) => setSearchQuery(e.nativeEvent.text.trim())}
        Icon={IconSearch}
        placeholder={PLACEHOLDERS[entity]}
        returnKeyType="search"
        style={styles.input}
        autoFocus
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: 56,
    alignItems: "center",
    paddingHorizontal: 16,
    gap: 8,
    flexDirection: "row",
  },
  input: {
    flex: 1,
  },
});
