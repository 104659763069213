import { useTheme } from "@/styles";
import { BlurView } from "expo-blur";
import type { FC, ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import Animated, { FadeIn } from "react-native-reanimated";
import { PaywallContent } from "./PaywallContent";

export const PaywallBlurOverlay: FC<{
  isOpen: boolean;
  title: string;
  description: string;
  header?: ReactNode;
  pwl?: string;
}> = ({ isOpen, title, description, header, pwl }) => {
  const theme = useTheme();

  if (!isOpen) return null;

  return (
    <Animated.View style={styles.root} entering={FadeIn}>
      <BlurView
        style={styles.wrapper}
        tint={theme.name === "light" ? "light" : "dark"}
        intensity={20}
        experimentalBlurMethod="dimezisBlurView"
      >
        <View style={styles.container}>
          <PaywallContent
            title={title}
            description={description}
            header={header}
            pwl={pwl}
          />
        </View>
      </BlurView>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
  },
  wrapper: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  container: {
    alignSelf: "center",
    borderRadius: 8,
    width: "85%",
    maxWidth: 500,
  },
});
