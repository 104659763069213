import { Breakpoint, createStyles, useTheme } from "@/styles";
import type { BottomSheetBackdropProps } from "@gorhom/bottom-sheet";
import {
  BottomSheetBackdrop,
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetScrollView,
  BottomSheetView,
  TouchableHighlight,
} from "@gorhom/bottom-sheet";
import { useIsFocused } from "@react-navigation/native";
import type { PropsWithChildren } from "react";
import { useEffect, useRef, useState, type FC } from "react";
import { Dimensions, Platform, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { responsiveSizes } from "src/styles/breakpoint";
import { Text } from "../Typography";

interface BottomSheetProps {
  snapPoints: (number | `${number}%`)[];
  isOpen: boolean;
  close?: () => void;
}

const renderBackdrop = (props: BottomSheetBackdropProps) => (
  <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />
);

const MAX_WIDTH = responsiveSizes.medium;

const BottomSheetRoot: FC<PropsWithChildren<BottomSheetProps>> = ({
  children,
  isOpen,
  snapPoints,
  close,
}) => {
  const bottomSheetRef = useRef<BottomSheetModal>(null);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isOpen && isFocused) bottomSheetRef.current?.present();
    else bottomSheetRef.current?.dismiss();
  }, [isOpen, isFocused]);

  const safeAreaInsest = useSafeAreaInsets();

  const theme = useTheme();

  const [windowWidth, setWindowWidth] = useState(
    () => Dimensions.get("window").width,
  );
  useEffect(() => {
    // TODO: find a better way to center the sheet on native
    if (Platform.OS === "web" || theme.breakpoint < Breakpoint.Medium) return;
    const subscription = Dimensions.addEventListener("change", ({ window }) =>
      setWindowWidth(window.width),
    );
    return () => subscription.remove();
  }, [theme.breakpoint]);

  return (
    <BottomSheetModal
      ref={bottomSheetRef}
      snapPoints={snapPoints}
      backdropComponent={renderBackdrop}
      enablePanDownToClose
      onDismiss={close}
      handleIndicatorStyle={styles.handleIndicator}
      handleStyle={styles.handleStyle}
      topInset={safeAreaInsest.top}
      detached={true}
      {...(theme.breakpoint >= Breakpoint.Medium && {
        style: [
          styles.detachedSheetContainer,
          Platform.select({
            web: { marginHorizontal: "auto" },
            default: {
              marginHorizontal: (windowWidth - MAX_WIDTH) / 2,
            },
          }),
        ],
        bottomInset: safeAreaInsest.bottom + 24,
      })}
    >
      {children}
    </BottomSheetModal>
  );
};

const BottomSheetHeader: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  const isStringChildren = Array.isArray(children)
    ? !children.some((child) => typeof child === "object")
    : typeof children !== "object";

  return (
    <View style={styles.header(theme)}>
      {isStringChildren ? (
        <Text variant="body1Weight">{children}</Text>
      ) : (
        children
      )}
    </View>
  );
};

const styles = createStyles({
  header: (theme) => ({
    padding: 20,
    borderBottomColor: theme.colors.borderStaticDefault,
    borderBottomWidth: 1,
    flexShrink: 0,
  }),
  handleIndicator: {
    backgroundColor: "#98A2B3",
    opacity: 0.4,
  },
  handleStyle: {
    height: 24,
  },
  detachedSheetContainer: {
    maxWidth: MAX_WIDTH,
    width: "100%",
  },
});

export const BottomSheetFF = {
  Root: BottomSheetRoot,
  Header: BottomSheetHeader,
  View: BottomSheetView,
  ScrollView: BottomSheetScrollView,
  TouchableHighlight,
  ModalProvider: BottomSheetModalProvider,
};
