import type {
  CreateUserFileMutation,
  CreateUserFileMutationVariables,
} from "@/graphql";
import { CreateUserFileDocument, apolloClient } from "@/graphql";
import { getExtensionFromMimeType } from "@/utils/mime";
import { isPaidUser } from "@firefliesai/payments-ff.utils";
import { useAuth } from "../auth";
import { mobileApi } from "../mobile-api";
import {
  audioFileSizeLimit,
  freeVideoFileSizeLimit,
  paidVideoFileSizeLimit,
  supportedMimeType,
} from "./constants";

export const validateFile = async (file: {
  mimeType: string;
  size: number;
}) => {
  const currentUser = useAuth.getState().user;
  const isUserPaid = !!currentUser && isPaidUser(currentUser.paidUser);

  const { mimeType, size } = file;

  const isSupported = supportedMimeType.some((supportedType) =>
    new RegExp(supportedType.replace("*", ".*")).test(mimeType),
  );
  if (!isSupported) {
    throw new Error("Unsupported file type");
  }

  const isVideo = mimeType.startsWith("video");
  const sizeLimit = isVideo
    ? isUserPaid
      ? paidVideoFileSizeLimit
      : freeVideoFileSizeLimit
    : audioFileSizeLimit;
  if (size > sizeLimit) {
    throw new Error("File size limit exceeded");
  }

  return {
    mimeType,
    isVideo,
    size,
  };
};

export const getUploadAndMediaUrl = async ({
  size,
  mimeType,
  meetingId,
  durationMillis,
}: {
  size: number;
  mimeType: string;
  meetingId: string;
  durationMillis?: number;
}) => {
  const { uploadUrl, mediaUrl } = await mobileApi.getUpload(meetingId, {
    size,
    contentType: mimeType,
    durationMillis: durationMillis,
  });

  return {
    uploadUrl,
    mediaUrl,
  };
};

export interface UploadCompletePayload {
  mediaUrl: string;
  title: string;
  fileSize: number;
  mimeType: string;
  meetingId: string;
}

export const createUserFile = async ({
  mediaUrl,
  title,
  fileSize,
  mimeType,
  meetingId,
}: UploadCompletePayload) => {
  let fileName = title;
  // dashboard-ff relies on the file name to determine the extension
  // so we need to ensure the file name has an extension
  const extensionFromTitle = title.split(".").pop();
  const actualExtension = getExtensionFromMimeType(mimeType);
  if (!extensionFromTitle || actualExtension !== extensionFromTitle) {
    fileName = `${title}.${actualExtension}`;
  }

  const res = await apolloClient.mutate<
    CreateUserFileMutation,
    CreateUserFileMutationVariables
  >({
    mutation: CreateUserFileDocument,
    variables: {
      audioUrl: mediaUrl,
      fileName,
      fileSize,
      fileType: mimeType,
      meetingId,
    },
  });
  if (res.errors?.length) {
    const message = res.errors[0].message;
    if (message.includes("Duplicate")) {
      // ignore
      return;
    }
    throw new Error(message);
  }
};

export const completeUpload = async ({
  mimeType,
  meetingId,
}: Pick<UploadCompletePayload, "mimeType" | "meetingId">) => {
  await mobileApi.completeUpload(meetingId, {
    contentType: mimeType,
  });
};
