import * as Sentry from "@sentry/browser";
import type { FC } from "react";
import type { AuthUser } from "../features/auth";

export const init = Sentry.init;

export const captureException = Sentry.captureException;

export const setUser = (user: AuthUser | null) => {
  Sentry.setUser(user);
};

export const wrapSentry = (Component: FC) => {
  return Component;
};

export const setContext = (name: string, context: Record<string, any>) => {
  Sentry.setContext(name, context);
};

export const setTag = Sentry.setTag;

export const addBreadcrumb = Sentry.addBreadcrumb;
