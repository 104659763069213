import { createNavigationContainerRef } from "@react-navigation/native";
import type { ParamList } from "./types";

export const navigationRef = createNavigationContainerRef();

export function navigate<T extends keyof ParamList>(
  name: T,
  params?: ParamList[T],
) {
  if (navigationRef.isReady()) {
    // @ts-ignore
    navigationRef.navigate(name, params);
  }
}

export const RootNavigation = {
  navigate,
};
