import { IconFred } from "@/assets/svg";
import type { FC } from "react";
import { FabButton } from "../components/FabButton";

export const AskFredButton: FC<{
  onPress: () => void;
}> = ({ onPress }) => {
  return (
    <FabButton
      Icon={IconFred}
      iconSize={40}
      onPress={onPress}
      aria-label="Ask Fred"
    />
  );
};
