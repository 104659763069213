import { Platform, Share } from "react-native";

export const shareUrl = (url: string, title?: string) => {
  Share.share(
    Platform.select({
      ios: { url },
      android: {
        title,
        message: url,
      },
      default: {
        message: url,
      },
    }),
  ).catch(() => undefined);
};
