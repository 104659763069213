import { Dialog } from "@/components/Dialog";
import { PaywallContent } from "@/components/Paywall";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { FC } from "react";
import { globalModalsApi } from "../global-modals.store";

export const SMART_SEARCH_PAYWALL_MODAL_NAME = "smart-search-paywall-modal";

export const SmartSearchPaywallModal: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  return (
    <Dialog.Root isOpen={isOpen} close={close}>
      <PaywallContent
        title="Smart Search is only available on Pro plan or above"
        description="Upgrade your account to filter action items, tasks, questions, and other key metrics from your meetings."
        pwl="mobile-smart-search"
      />
    </Dialog.Root>
  );
};

export const openSmartSearchPaywallModal = (fromRef: string) => {
  globalModalsApi.openModal(SMART_SEARCH_PAYWALL_MODAL_NAME);

  tracker.track(TRACKING_EVENTS.PAYWALL_SHOWN, {
    pwl: "smart-search",
    from: fromRef,
  });
};
