import { createStyles } from "@/styles";
import { type FC } from "react";
import { View } from "react-native";
import { RecordAudioView } from "./components/RecordAudioView";
import { RecordControls } from "./components/RecordControls";
import { RecordingWaveform } from "./components/RecordingWaveform";

export const RecordView: FC = () => {
  return (
    <View style={styles.root}>
      <RecordingWaveform />
      <RecordAudioView />
      <RecordControls />
    </View>
  );
};

const styles = createStyles({
  root: {
    flex: 1,
  },
});
