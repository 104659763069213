import { toast } from "@/components/Toast";
import type {
  GetLiveMeetingInsightsQuery,
  LiveActionItem,
  LiveNote,
  Meeting,
} from "@/graphql";
import {
  GetLiveMeetingInsightsDocument,
  useGetLiveMeetingInsightsQuery,
} from "@/graphql";
import { partialUpsertDataArray } from "@/utils/array";
import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

export const useLiveInsights = ({
  meeting,
  realtimeToken,
}: {
  meeting: Meeting;
  realtimeToken: string | null;
}) => {
  const apolloClient = useApolloClient();

  const {
    data: liveMeetingInsightsData,
    loading: loadingLiveInsights,
    refetch: refetchLiveInsights,
  } = useGetLiveMeetingInsightsQuery({
    skip: !realtimeToken,
    variables: {
      meetingId: meeting.id,
      realtimeToken: realtimeToken as string,
    },
    onError(error) {
      toast({
        title: "Failed to get live meeting insights",
        message: error.message,
        type: "error",
      });
    },
    fetchPolicy: "cache-and-network",
  });

  const liveInsights = liveMeetingInsightsData?.getLiveMeetingInsights || null;

  const upsertLiveInsightsCache = useCallback(
    ({
      actionItems: updatingActionItems,
      notes: updatingNotes,
    }: {
      actionItems?: LiveActionItem[];
      notes?: LiveNote[];
    }) => {
      apolloClient.cache.updateQuery<GetLiveMeetingInsightsQuery>(
        {
          query: GetLiveMeetingInsightsDocument,
          variables: {
            meetingId: meeting.id,
          },
        },
        (data) => {
          const existingLiveInsights = data?.getLiveMeetingInsights;
          let actionItems =
            existingLiveInsights?.actionItems?.filter((item) => !!item) || [];
          let notes =
            existingLiveInsights?.notes?.filter((note) => !!note) || [];

          if (updatingActionItems?.length) {
            for (const updatingActionItem of updatingActionItems) {
              actionItems = partialUpsertDataArray(
                actionItems,
                {
                  ...updatingActionItem,
                  __typename: "LiveActionItem",
                },
                "id",
              );
            }
          }
          if (updatingNotes?.length) {
            for (const updatingNote of updatingNotes) {
              notes = partialUpsertDataArray(
                notes,
                {
                  ...updatingNote,
                  __typename: "LiveNote",
                },
                "id",
              );
            }
          }

          return {
            getLiveMeetingInsights: {
              __typename: "LiveInsights",
              actionItems,
              notes,
              chapterizedNotes: existingLiveInsights?.chapterizedNotes,
            },
          };
        },
      );
    },
    [meeting.id, apolloClient],
  );

  return {
    liveInsights,
    loadingLiveInsights,
    upsertLiveInsightsCache,
    refetchLiveInsights,
  };
};
