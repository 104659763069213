import type { AuthTokens } from "./types";

export function createAuthHeaders(tokens: AuthTokens): Record<string, string> {
  return {
    ...(tokens.accessToken && {
      authorization: `Bearer ${tokens.accessToken}`,
    }),
    ...(tokens.refreshToken && {
      "x-refresh-token": tokens.refreshToken,
    }),
  };
}
