import { NumberBadge } from "@/components/NumberBadge";
import type { TabsRef } from "@/components/Tabs";
import { Tabs } from "@/components/Tabs";
import { AnnouncementModals } from "@/features/announcements";
import { useUpcomingCalendarEvents } from "@/features/calendar";
import { Breakpoint, createStyles, useTheme } from "@/styles";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { LinearGradient } from "expo-linear-gradient";
import { useEffect, useRef, type FC } from "react";
import { View } from "react-native";
import type { ParamList } from "../types";
import {
  PaywallBanners,
  useShowPaywallBanners,
} from "./Banners/PaywallBanners";
import { UpdateBanner, useShowUpdateBanner } from "./Banners/UpdateBanner";
import { Feed } from "./Feed/Feed";
import { SideView } from "./Side/Side";
import { Tasks } from "./Tasks/Tasks";
import { FloatingAddButton, SolidAddButton } from "./components/AddButton";
import { RecapBanner } from "@/features/yearly-recap";

enum TabNames {
  Feed = "feed",
  Tasks = "tasks",
  Calendar = "calendar",
}

const largeScreenBreakpoint = Breakpoint.Large;

export const HomeScreen: FC<NativeStackScreenProps<ParamList, "Home">> = () => {
  const calendarEventProps = useUpcomingCalendarEvents();

  const theme = useTheme();

  const isLargeScreen = theme.breakpoint === largeScreenBreakpoint;

  const tabsRef = useRef<TabsRef>(null);
  useEffect(() => {
    if (isLargeScreen) {
      // first 2 tabs are shown on large screen
      tabsRef.current?.setTab(Math.min(tabsRef.current?.index ?? 0, 1));
    }
  }, [isLargeScreen]);

  const shouldShowPaywallBanner = useShowPaywallBanners();
  const shouldShowUpdateBanner = useShowUpdateBanner();

  const ListHeaderComponent =
    shouldShowPaywallBanner || shouldShowUpdateBanner ? (
      <View>
        {shouldShowUpdateBanner && <UpdateBanner />}
        {shouldShowPaywallBanner && <PaywallBanners />}
      </View>
    ) : undefined;

  return (
    <View style={styles.root}>
      <LinearGradient
        colors={["#EDDFFF", "rgba(255, 255, 255, 0.00)"]}
        style={styles.background}
      />
      <View style={[styles.main, isLargeScreen && styles.mainLarge]}>
        <Tabs.Root
          ref={tabsRef}
          tabBarStyle={[styles.tabList, isLargeScreen && styles.tabListLarge]}
        >
          <Tabs.Panel
            name={TabNames.Feed}
            title="My Feed"
            style={!isLargeScreen ? styles.contentSmall : undefined}
          >
            <View style={styles.card(theme)}>
              <RecapBanner screen="Home" />
              <Feed ListHeaderComponent={ListHeaderComponent} />
            </View>
          </Tabs.Panel>
          <Tabs.Panel
            name={TabNames.Tasks}
            title="Tasks"
            style={!isLargeScreen ? styles.contentSmall : undefined}
          >
            <View style={styles.card(theme)}>
              <Tasks />
            </View>
          </Tabs.Panel>
          {/**
           * On large screen, technically the feed is still in this Tabs,
           * but it's is a 1-tab list, so it is pretty much the same as not.
           * The reason we keep it this way instead of doing a full {isLargeScreen ? <Tabs /> : <><Feeds/><Side /></>}
           * is to avoid losing states when moving between large and small screens.
           */}
          {!isLargeScreen && (
            <Tabs.Panel
              name={TabNames.Calendar}
              title="Calendar"
              tabTrailing={
                calendarEventProps.liveMeetings.length ? (
                  <NumberBadge color="red">LIVE</NumberBadge>
                ) : calendarEventProps.events?.length ? (
                  <NumberBadge color="green">
                    {calendarEventProps.events.length}
                  </NumberBadge>
                ) : undefined
              }
              style={!isLargeScreen ? styles.contentSmall : undefined}
            >
              <SideView {...calendarEventProps} />
            </Tabs.Panel>
          )}
        </Tabs.Root>
      </View>
      {isLargeScreen && (
        <View style={styles.side}>
          <SideView
            {...calendarEventProps}
            ListHeaderComponent={<SolidAddButton />}
          />
        </View>
      )}
      {!isLargeScreen && <FloatingAddButton />}
      <AnnouncementModals />
    </View>
  );
};

const styles = createStyles({
  root: {
    flexDirection: "row",
    flex: 1,
  },
  main: {
    flex: 1,
  },
  mainLarge: {
    paddingTop: 24,
    paddingHorizontal: 32,
  },
  tabList: {
    minHeight: 40,
  },
  tabListLarge: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  card: (theme) => ({
    backgroundColor: theme.colors.layerDefault,
    ...(theme.breakpoint !== largeScreenBreakpoint && {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    }),
    flex: 1,
  }),
  contentSmall: {
    paddingHorizontal: 16,
    paddingTop: 12,
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  side: {
    paddingTop: 24,
    paddingRight: 24,
  },
});
