export function requestPermissionAndGetTokens() {
  /** noop */
}

export function setupNotifications() {
  /** noop */
}

export function scheduleNotificationAsync() {
  /** noop */
}

export function getLastNotificationResponseAsync() {
  return undefined;
}

export function addNotificationResponseReceivedListener() {
  /** noop */
}

export function removeNotificationSubscription() {
  /** noop */
}
