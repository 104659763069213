import { PaywallBlurOverlay } from "@/components/Paywall";
import { Tabs } from "@/components/Tabs";
import { Text } from "@/components/Typography";
import { createStyles, useTheme } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { endOfDay, startOfDay, sub } from "date-fns";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import type { ParamList } from "../types";
import { AnalyticsTab } from "./Analytics/Analytics";
import { TopicInsightsTab } from "./TopicInsights/TopicInsights";
import { DatePickerButton } from "./components/DatePicker";
import { useCanAccessAnalytics } from "./utils";

export const AnalyticsScreen: FC<
  NativeStackScreenProps<ParamList, "Analytics">
> = () => {
  const [dateRange, setDateRange] = useState(() => {
    const now = new Date();
    return [startOfDay(sub(now, { days: 7 })), endOfDay(now)] as [Date, Date];
  });

  const canAccessAnalytics = useCanAccessAnalytics();

  useEffect(() => {
    if (!canAccessAnalytics) {
      tracker.track(TRACKING_EVENTS.PAYWALL_SHOWN, {
        pwl: "analytics",
      });
    }
  }, [canAccessAnalytics]);

  const theme = useTheme();

  return (
    <>
      <View style={styles.root}>
        <Tabs.Root>
          <Tabs.Panel name="analytics" title="Analytics">
            <AnalyticsTab dateRange={dateRange} />
          </Tabs.Panel>
          <Tabs.Panel name="topic-insights" title="Topic Insights">
            <TopicInsightsTab dateRange={dateRange} />
          </Tabs.Panel>
        </Tabs.Root>
        <View>
          <ScrollView
            style={styles.footer(theme)}
            contentContainerStyle={styles.footerContent}
            horizontal
          >
            <Text variant="label1Weight" color="textSecondary">
              Filters
            </Text>
            <DatePickerButton
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </ScrollView>
        </View>
      </View>
      <PaywallBlurOverlay
        isOpen={!canAccessAnalytics}
        title="Upgrade your account to get access to Analytics"
        description="Analytics is a premium add-on available only on Business plan."
        pwl="mobile-analytics"
      />
    </>
  );
};

const styles = createStyles({
  root: {
    flex: 1,
    overflow: "hidden",
  },
  footer: (theme) => ({
    borderTopWidth: 1,
    borderColor: theme.colors.borderStaticSubtle,
  }),
  footerContent: {
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    gap: 8,
  },
});
