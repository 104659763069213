import type { StackRouteConfig } from "../types";
import { OnboardingScreen } from "./OnboardingScreen";

const OnboardingRouteConfig: StackRouteConfig<"Onboarding"> = {
  name: "Onboarding",
  component: OnboardingScreen,
  options: {
    headerShown: false,
  },
};

export { OnboardingRedirectComponent } from "./OnboardingRedirectComponent";

export default OnboardingRouteConfig;
