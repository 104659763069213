import { Config } from "@/constants";
import { Logger } from "@/logger";
import type * as Sentry from "@sentry/react-native";

const logger = new Logger("sentry");

export const sentryConfig = {
  enabled: !__DEV__,
  dsn: Config.SENTRY_DSN,
  normalizeDepth: 5,
  environment: Config.APP_ENV,
  beforeSend(event, hint) {
    if (hint?.originalException) {
      const error = hint.originalException;
      logger.error(error as Error, event.contexts);
    }
    return event;
  },
} satisfies Sentry.ReactNativeOptions;
