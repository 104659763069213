import { imageNoMeetingVideo } from "@/assets/images";
import { captureException, getErrorMessage } from "@/errors";
import { useIsLoginPopupVisible } from "@/features/auth";
import { createStyles, useTheme } from "@/styles";
import type {
  AVPlaybackStatus,
  AVPlaybackStatusSuccess,
  VideoFullscreenUpdateEvent,
} from "expo-av";
import { Audio, ResizeMode, Video, VideoFullscreenUpdate } from "expo-av";
import { Image } from "expo-image";
import type { Emitter } from "mitt";
import mitt from "mitt";
import type { FC, PropsWithChildren } from "react";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { View } from "react-native";
import { useRecorder } from "../live-record";
import { PlayerComponentElements } from "./PlayerComponentElements";
import { PlayerContext } from "./PlayerContext";
import type { PlayerApi, PlayerApiEventName } from "./types";
import { useMusicControl } from "./useMusicControl";

export const PlayerComponentContainer: FC<
  PropsWithChildren<{
    videoHidden?: boolean;
  }>
> = ({ children, videoHidden }) => {
  const theme = useTheme();
  const { options } = useContext(PlayerContext);
  const isAudio = options?.sources?.[0]?.type?.startsWith("audio/");
  return (
    <View style={styles.videoContainer(theme)}>
      <View
        style={[
          !videoHidden && styles.videoAspectRatio,
          !videoHidden && isAudio && styles.videoAspectRatioAudio,
          videoHidden && styles.videoHiddenContainer,
        ]}
      >
        {children}
      </View>
    </View>
  );
};

export const PlayerComponent: FC<{
  videoHidden?: boolean;
  setVideoHidden?: (hidden: boolean) => void;
}> = ({ videoHidden, setVideoHidden }) => {
  const emitterRef = useRef(
    null as unknown as Emitter<Record<PlayerApiEventName, void>>,
  );
  emitterRef.current = emitterRef.current || mitt();
  const { setPlayer, options, player } = useContext(PlayerContext);

  const isLoginPopupVisible = useIsLoginPopupVisible();

  const video = useRef<Video>(null);

  useEffect(() => {
    if (useRecorder.getState().status.state === "inactive") {
      Audio.setAudioModeAsync({
        staysActiveInBackground: true,
        playsInSilentModeIOS: true,
      }).catch(() => {});
    }
  }, []);

  useEffect(() => () => emitterRef.current.all.clear(), []);

  useEffect(() => {
    if (player && isLoginPopupVisible) {
      player.pause();
    }
  }, [player, isLoginPopupVisible]);

  const statusRef = useRef({
    isPlaying: false,
    didJustFinish: false,
    positionMillis: 0,
    durationMillis: 0,
    uri: "",
    rate: 1,
    isFullscreen: false,
  } as AVPlaybackStatus & {
    isFullscreen: boolean;
  });

  useEffect(() => {
    const videoApi = video.current;

    if (!videoApi) return;

    let wasSeeking = false;

    const playerApi: PlayerApi = {
      play: () => {
        if (!statusRef.current.isLoaded) return;
        videoApi.playAsync().catch((err) => {
          captureException(
            new Error(getErrorMessage(err, "videoApi.playAsync")),
            {
              contexts: {
                status: statusRef.current,
              },
              tags: {
                section: "player",
              },
            },
          );
        });
      },
      pause: () => {
        if (!statusRef.current.isLoaded) return;
        videoApi.pauseAsync().catch((err) => {
          captureException(
            new Error(getErrorMessage(err, "videoApi.pauseAsync")),
            {
              contexts: {
                status: statusRef.current,
              },
              tags: {
                section: "player",
              },
            },
          );
        });
      },
      get paused() {
        if (!statusRef.current.isLoaded) return true;
        return !statusRef.current.isPlaying;
      },
      get ended() {
        if (!statusRef.current.isLoaded) return false;
        return statusRef.current.didJustFinish;
      },
      get currentTime() {
        if (!statusRef.current.isLoaded) return 0;
        return statusRef.current.positionMillis / 1000;
      },
      set currentTime(time) {
        if (!statusRef.current.isLoaded || wasSeeking) return;
        wasSeeking = true;
        emitterRef.current.emit("seeking");
        videoApi
          .setPositionAsync(time * 1000, {
            toleranceMillisAfter: 0,
            toleranceMillisBefore: 0,
          })
          .catch((err) => {
            captureException(
              new Error(getErrorMessage(err, "videoApi.setPositionAsync")),
              {
                contexts: {
                  status: statusRef.current,
                },
                tags: {
                  section: "player",
                },
              },
            );
          })
          .finally(() => {
            wasSeeking = false;
            emitterRef.current.emit("seeked");
          });
      },
      get duration() {
        if (!statusRef.current.isLoaded) return 0;
        // FIXME: sometimes not available on iOS
        return (statusRef.current.durationMillis || 0) / 1000;
      },
      get currentSrc() {
        if (!statusRef.current.isLoaded) return "";
        return statusRef.current.uri;
      },
      get playbackRate() {
        if (!statusRef.current.isLoaded) return 1;
        // rate
        return statusRef.current.rate;
      },
      set playbackRate(rate) {
        if (!statusRef.current.isLoaded) return;
        videoApi.setRateAsync(rate, true).catch((err) => {
          captureException(
            new Error(getErrorMessage(err, "videoApi.setRateAsync")),
            {
              contexts: {
                status: statusRef.current,
              },
              tags: {
                section: "player",
              },
            },
          );
        });
      },
      get volume() {
        if (!statusRef.current.isLoaded) return 1;
        return statusRef.current.volume;
      },
      set volume(volume) {
        if (!statusRef.current.isLoaded) return;
        videoApi.setStatusAsync({ volume }).catch((err) => {
          captureException(
            new Error(getErrorMessage(err, "videoApi.setStatusAsync")),
            {
              contexts: {
                status: statusRef.current,
              },
              tags: {
                section: "player",
              },
            },
          );
        });
      },
      requestFullscreen() {
        if (!statusRef.current.isLoaded) return;
        videoApi.presentFullscreenPlayer().catch((err) => {
          captureException(
            new Error(getErrorMessage(err, "videoApi.presentFullscreenPlayer")),
            {
              contexts: {
                status: statusRef.current,
              },
              tags: {
                section: "player",
              },
            },
          );
        });
      },
      exitFullscreen() {
        if (!statusRef.current.isLoaded) return;
        videoApi.dismissFullscreenPlayer().catch((err) =>
          captureException(
            new Error(getErrorMessage(err, "videoApi.dismissFullscreenPlayer")),
            {
              contexts: {
                status: statusRef.current,
              },
              tags: {
                section: "player",
              },
            },
          ),
        );
      },
      isFullscreen() {
        return statusRef.current.isFullscreen;
      },
      addEventListener(event, callback) {
        emitterRef.current.on(event, callback);
      },
      removeEventListener(event, callback) {
        emitterRef.current.off(event, callback);
      },
    };

    setPlayer(playerApi);

    return () => {
      setPlayer(null);
    };
  }, [setPlayer]);

  const onPlaybackStatusUpdate = useCallback((status: AVPlaybackStatus) => {
    if (!status.isLoaded) return;
    const prevStatus = {
      ...statusRef.current,
    } as AVPlaybackStatusSuccess;
    statusRef.current = {
      ...statusRef.current,
      ...status,
    };
    if (status.isPlaying && !prevStatus.isPlaying) {
      emitterRef.current.emit("play");
    }
    if (!status.isPlaying && prevStatus.isPlaying) {
      if (!status.isBuffering) {
        emitterRef.current.emit("pause");
      } else {
        emitterRef.current.emit("waiting");
      }
    }
    if (status.durationMillis !== prevStatus.durationMillis) {
      emitterRef.current.emit("durationchange");
    }
    if (status.positionMillis !== prevStatus.positionMillis) {
      emitterRef.current.emit("timeupdate");
    }
    if (status.didJustFinish && !prevStatus.didJustFinish) {
      emitterRef.current.emit("ended");
    }
    if (status.rate !== prevStatus.rate) {
      emitterRef.current.emit("ratechange");
    }
    if (status.volume !== prevStatus.volume) {
      emitterRef.current.emit("volumechange");
    }
  }, []);

  const onFullscreenUpdate = useCallback(
    (event: VideoFullscreenUpdateEvent) => {
      statusRef.current.isFullscreen =
        event.fullscreenUpdate === VideoFullscreenUpdate.PLAYER_DID_PRESENT ||
        event.fullscreenUpdate === VideoFullscreenUpdate.PLAYER_WILL_PRESENT;
      emitterRef.current.emit("fullscreenchange");
    },
    [],
  );

  const source = useMemo(() => {
    if (!options?.sources?.[0]) return null;
    const mediaSource = options.sources[0];
    return {
      uri: mediaSource.src,
      type: mediaSource.type,
    };
  }, [options?.sources]);

  const isAudioSource = source?.type?.startsWith("audio/");

  useMusicControl(options);

  const hasLoadedOnceRef = useRef(false);
  // TODO: we want to confirm if onLoad always gets called only once
  // after the video has been loaded with a new source
  const onLoad = useCallback(() => {
    if (hasLoadedOnceRef.current) return;
    hasLoadedOnceRef.current = true;
    if (typeof options?.initialTime === "number") {
      video.current?.setPositionAsync(options.initialTime * 1000, {
        toleranceMillisAfter: 0,
        toleranceMillisBefore: 0,
      });
    }
  }, [options?.initialTime]);

  useEffect(() => {
    hasLoadedOnceRef.current = false;
  }, [source]);

  const onError = useCallback(
    (message: string) => {
      captureException(new Error(message), {
        contexts: {
          status: statusRef.current,
          ...(source && { source }),
        },
        tags: {
          section: "player",
        },
      });
    },
    [source],
  );

  return (
    <View style={styles.root}>
      {isAudioSource && (
        <Image
          source={imageNoMeetingVideo}
          aria-label="No Meeting Video"
          style={styles.noMeetingVideo}
          transition={300}
          cachePolicy="none"
        />
      )}
      <Video
        ref={video}
        style={[styles.video, videoHidden && { opacity: 0 }]}
        useNativeControls={false}
        resizeMode={ResizeMode.CONTAIN}
        source={source || undefined}
        onPlaybackStatusUpdate={onPlaybackStatusUpdate}
        onFullscreenUpdate={onFullscreenUpdate}
        shouldPlay
        videoStyle={styles.innerVideo}
        onError={onError}
        onLoad={onLoad}
      />
      <PlayerComponentElements
        videoHidden={videoHidden}
        setVideoHidden={setVideoHidden}
        isAudioSource={isAudioSource}
      />
    </View>
  );
};

const styles = createStyles({
  root: {
    backgroundColor: "#000000",
    width: "100%",
    height: "100%",
  },
  noMeetingVideo: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  video: {
    width: "100%",
    height: "100%",
    zIndex: 1,
  },
  innerVideo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  videoContainer: (theme) => ({
    width: "100%",
    backgroundColor: theme.colors.layerBrandLight2,
    flexDirection: "row",
    justifyContent: "center",
  }),
  videoAspectRatio: {
    aspectRatio: 16 / 9,
    width: "100%",
    maxWidth: 640,
    marginHorizontal: "auto",
  },
  videoAspectRatioAudio: {
    aspectRatio: 24 / 9,
  },
  videoHiddenContainer: {
    height: 100,
    width: "100%",
    overflow: "hidden",
  },
});
