import { Button } from "@/components/Button";
import { Dialog } from "@/components/Dialog";
import { Config } from "@/constants";
import { useAuth } from "@/features/auth";
import type { FindUsersMeetingIsSharedWithResponse } from "@/graphql";
import { useFindUsersMeetingIsSharedWithQuery, type Meeting } from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import { shareUrl } from "@/utils/share";
import { useState, type FC } from "react";
import { View } from "react-native";
import { ShareMeetingDialogEmail } from "./ShareMeetingDialogEmail";
import { ShareMeetingDialogEmailAddingContent } from "./ShareMeetingDialogEmailAdding";
import { ShareMeetingDialogPrivacy } from "./ShareMeetingDialogPrivacy";

const ShareMeetingDialogContent: FC<{
  meeting: Meeting;
  close: () => void;
  setIsOpenAdd: (isOpen: boolean) => void;
  findUsersMeetingIsSharedWith:
    | FindUsersMeetingIsSharedWithResponse
    | undefined
    | null;
  loading?: boolean;
}> = ({ meeting, setIsOpenAdd, findUsersMeetingIsSharedWith, loading }) => {
  const theme = useTheme();

  const url = `${Config.DASHBOARD_URL}/view/${meeting.id}`;
  const title = meeting.title;

  const { user } = useAuth();

  return (
    <>
      <Dialog.Header>Share meeting</Dialog.Header>
      {!!user && (
        <ShareMeetingDialogEmail
          meeting={meeting}
          setIsOpenAdd={setIsOpenAdd}
          findUsersMeetingIsSharedWith={findUsersMeetingIsSharedWith}
          loading={loading}
        />
      )}
      <View style={styles.footer(theme)}>
        <ShareMeetingDialogPrivacy meeting={meeting} />
        <Button
          onPress={() => {
            shareUrl(url, title);
            tracker.track(TRACKING_EVENTS.SHARE_LINK_BUTTON_CLICKED, {
              url: url,
              privacy: meeting.notesPrivacy,
              type: "meeting",
            });
          }}
        >
          Share page link
        </Button>
      </View>
    </>
  );
};

export const ShareMeetingDialog: FC<{
  meeting: Meeting;
  isOpen: boolean;
  close: () => void;
}> = ({ meeting, isOpen, close }) => {
  const [isOpenAdd, setIsOpenAdd] = useState(false);

  const { user } = useAuth();

  const { data, loading } = useFindUsersMeetingIsSharedWithQuery({
    variables: { meetingId: meeting.id },
    skip: !isOpen || !user,
  });

  return (
    <>
      <Dialog.Root
        isOpen={isOpen && !isOpenAdd}
        close={close}
        variant="fullscreen"
      >
        <ShareMeetingDialogContent
          meeting={meeting}
          close={close}
          setIsOpenAdd={setIsOpenAdd}
          findUsersMeetingIsSharedWith={data?.findUsersMeetingIsSharedWith}
          loading={loading}
        />
      </Dialog.Root>
      <Dialog.Root
        isOpen={isOpenAdd}
        close={() => setIsOpenAdd(false)}
        portalHostname="ShareDialogPortalHost"
        variant="fullscreen"
      >
        <ShareMeetingDialogEmailAddingContent
          meeting={meeting}
          close={() => setIsOpenAdd(false)}
          findUsersMeetingIsSharedWith={data?.findUsersMeetingIsSharedWith}
        />
      </Dialog.Root>
    </>
  );
};

const styles = createStyles({
  footer: (theme) => ({
    padding: 24,
    backgroundColor: theme.colors.layerSubtle,
    gap: 20,
  }),
});
