export enum TRACKING_EVENTS {
  APP_LOGGED_IN = "App Logged In",
  APP_LOGGED_OUT = "App Logged Out",
  APP_SCREEN_VIEWED = "App Screen Viewed",
  TRANSCRIBE_MENU_OPENED = "Transcribe Menu Opened",
  PAYWALL_SHOWN = "Paywall Shown",
  ADD_TO_LIVE_MEETING_MODAL_OPENED = "Add to Live Meeting Modal Opened",
  ADD_TOLIVE_MEETING_SUBMITTED = "Add to Live Meeting Submitted",
  SCHEDULE_MEETING_MODAL_OPENED = "Schedule Meeting Modal Opened",
  SCHEDULE_MEETING_LINK_CLICKED = "Schedule Meeting Link Clicked",
  SHARE_DIALOG_OPENED = "Share Dialog Opened",
  SHARE_LINK_BUTTON_CLICKED = "Meeting Share Link Button Clicked",
  MEETING_PRIVACY_UPDATED = "Meeting Privacy Updated",
  PERSON_VIEWING_TRANSCRIPT = "Person Viewing Transcript", // shared with dashboard
  MEETING_COMMENTS_VIEWED = "Meeting Comments Viewed",
  MEETING_COMMENT_ADDED = "Meeting Comment Added",
  MEETING_COMMENT_DELETED = "Meeting Comment Deleted",
  MEETING_COMMENT_EDITED = "Meeting Comment Edited",
  MEETING_BITES_VIEWED = "Meeting Soundbites Viewed",
  MEETING_ASK_FRED_MODAL_OPENED = "Meeting Ask Fred Modal Opened",
  MEETING_ASK_FRED_SUBMITTED = "Meeting Ask Fred Submitted",
  /**
   * @deprecated: use PAYWALL_SHOWN with pwl = "ask-fred"
   */
  ASK_FRED_PAYWALL_SHOWN = "Ask Fred Paywall Shown",
  GLOBAL_SEARCH_RESULT_CLICKED = "Global search result clicked", // shared with dashboard
  PLAYLIST_CREATE_MODAL_OPENED = "Playlist Create Modal Opened",
  PLAYLIST_PRIVACY_UPDATED = "Playlist Privacy Updated",
  PLAYLIST_SEARCHED = "Playlist Searched",
  BITE_PRIVACY_UPDATED = "Soundbite Privacy Updated",
  BITE_SEARCHED = "Soundbite Searched",
  SMART_SEARCH_MODAL_OPENED = "Smart Search Modal Opened",
  SMART_SEARCH_APPLIED = "Smart Search Applied",
  NOTEBOOK_CHANNEL_SELECTED = "Notebook Channel Selected",
  UPCOMING_EVENT_TOGGLED = "Upcoming Event Toggled",
  NOTEPAD_TRANSCRIPT_SEARCHED = "Notepad Transcript Searched",
  AUDIO_RECORD_STARTED = "Audio Record Started",
  AUDIO_RECORD_RESUMED = "Audio Record Resumed",
  AUDIO_RECORD_PAUSED = "Audio Record Paused",
  AUDIO_RECORD_STOPPED = "Audio Record Stopped",
  AUDIO_RECORD_UPLOAD_STARTED = "Audio Record Upload Started",
  AUDIO_RECORD_UPLOAD_COMPLETED = "Audio Record Upload Completed",
  AUDIO_RECORD_STREAM_COMPLETED = "Audio Record Stream Completed",
  AUDIO_RECORD_TITLE_EDITED = "Audio Record Title Edited",
  CALL_RECORD_TITLE_EDITED = "Call Record Title Edited",
  AUDIO_RECORD_PRIVACY_CHANGED = "Audio Record Privacy Changed",
  CALL_RECORD_PRIVACY_CHANGED = "Call Record Privacy Changed",
  DOCUMENT_PICKER_OPENED = "Document Picker Opened",
  FILE_UPLOADED = "File Uploaded",
  DOWNLOAD_MODAL_OPENED = "Download Modal Opened",
  USER_DOWNLOADED_TRANSCRIPT = "User Downloaded Transcript", // shared with dashboard
  USER_DOWNLOADED_RECORDING = "User Downloaded Recording", // shared with dashboard
  BITE_DOWNLOADED = "Soundbite Downloaded",
  FILE_SHARED_TO_FIREFLIES = "File Shared to Fireflies",
  MOBILE_RECAP_BANNER_CLICKED = "Mobile Recap Banner Clicked",
  AUTOJOIN_TOGGLE = "Auto join toggled",
  ASK_FRED_RESPONSE_COPIED = "Ask Fred Response Copied",
  LOGIN_POPUP_OPENED = "Login Popup Opened",
  CALL_RECORD_UPLOAD_STARTED = "Call Record Upload Started",
  CALL_RECORD_UPLOAD_COMPLETED = "Call Record Upload Completed",
  REALTIME_USER_ACTION = "Realtime User Action", // shared with shared-libs
  PUSH_NOTIFICATION_OPENED = "Push Notification Opened",
  REQUEST_APP_REVIEW_PROMPT = "Request App Review Prompt",
}
