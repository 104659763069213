import { Text } from "@/components/Typography";
import { useTheme } from "@/styles";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";

export const SectionHeader: FC<{
  title: string;
  tinted?: boolean;
}> = ({ title, tinted }) => {
  const theme = useTheme();

  return (
    <View
      style={[
        styles.root,
        tinted && {
          backgroundColor: theme.colors.layerMuted,
        },
      ]}
    >
      <Text variant="body1Weight">{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 24,
    paddingVertical: 8,
  },
});
