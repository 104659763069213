import { PortalProvider } from "@gorhom/portal";
import { StatusBar } from "expo-status-bar";
import type { FC } from "react";
import { memo } from "react";
import { gestureHandlerRootHOC } from "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import "./App.css";
import { BottomSheet } from "./components/BottomSheet";
import { Toaster } from "./components/Toast";
import { wrapSentry } from "./errors/sentry";
import { FeatureFlagProvider } from "./features/feature-flags";
import { GraphQLProvider } from "./graphql";
import { Navigator } from "./screens/Navigator";
import { ThemeProvider, useTheme } from "./styles";
import { setupNotifications } from "./utils/notifications";

if (__DEV__) {
  require("@expo/metro-runtime");
}

setupNotifications();

const Main = gestureHandlerRootHOC(
  memo(
    function Main() {
      const theme = useTheme();
      return (
        <>
          <StatusBar
            translucent={false}
            backgroundColor={theme.colors.pageBackground}
            style={theme.name === "light" ? "dark" : "light"}
          />
          <SafeAreaProvider>
            <PortalProvider>
              <BottomSheet.ModalProvider>
                <Navigator />
              </BottomSheet.ModalProvider>
              <Toaster />
            </PortalProvider>
          </SafeAreaProvider>
        </>
      );
    },
    () => true,
  ),
);

const BaseApp: FC = () => {
  return (
    <GraphQLProvider>
      <FeatureFlagProvider>
        <ThemeProvider>
          <Main />
        </ThemeProvider>
      </FeatureFlagProvider>
    </GraphQLProvider>
  );
};

export const App = wrapSentry(BaseApp);
