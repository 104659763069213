import { IconCheckCircle } from "@/assets/svg";
import { MessageView } from "@/components/Message";
import { RefreshControl } from "@/components/RefreshControl";
import { Timestamp } from "@/components/Timestamp";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import { useEffect, useMemo, useRef, type FC } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useLiveScreenContext } from "../Context/LiveScreenContext";
import { ActivityListItem } from "./ActivityListItem";
import { groupByTimeRange } from "./utils";

export const LiveTranscriptBookmarks: FC<{
  meeting: Meeting;
}> = () => {
  const { liveActivities, refetchLiveActivities } = useLiveScreenContext();
  const groups = useMemo(
    () => groupByTimeRange(liveActivities),
    [liveActivities],
  );

  const scrollRef = useRef<ScrollView>(null);

  const prevLiveActivitiesLengthRef = useRef(liveActivities.length);
  useEffect(() => {
    if (prevLiveActivitiesLengthRef.current < liveActivities.length) {
      const timeout = setTimeout(() => {
        // wait a bit for the list to rerender
        scrollRef.current?.scrollToEnd();
      }, 1000);
      prevLiveActivitiesLengthRef.current = liveActivities.length;
      return () => clearTimeout(timeout);
    }
    prevLiveActivitiesLengthRef.current = liveActivities.length;
  }, [liveActivities.length]);

  return (
    <ScrollView
      ref={scrollRef}
      contentContainerStyle={styles.container}
      refreshControl={<RefreshControl onRefresh={refetchLiveActivities} />}
    >
      {groups.length ? (
        groups.map((group) => (
          <View key={group.time} style={styles.group}>
            <Timestamp time={group.time} endTime={group.endTime} />
            {group.activities.map((activity) => (
              <ActivityListItem
                key={("_id" in activity && activity._id) || undefined}
                activity={activity}
              />
            ))}
          </View>
        ))
      ) : (
        <MessageView
          Icon={IconCheckCircle}
          title="No meeting activities yet"
          description="Real-time meeting actions like bookmarks, comments, creating soundbite appears here"
        />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    gap: 20,
  },
  group: {
    alignItems: "center",
    gap: 8,
  },
});
