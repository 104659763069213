import { Platform, type TextStyle } from "react-native";

export const Fonts = {
  dmSans:
    Platform.OS === "web"
      ? {
          "400": {
            regular: "'DM Sans', sans-serif",
            italic: "'DM Sans', sans-serif",
          },
          "500": {
            regular: "'DM Sans', sans-serif",
            italic: "'DM Sans', sans-serif",
          },
          "700": {
            regular: "'DM Sans', sans-serif",
            italic: "'DM Sans', sans-serif",
          },
        }
      : {
          "400": {
            regular: "DMSans-Regular",
            italic: "DMSans-Italic",
          },
          "500": {
            regular: "DMSans-Medium",
            italic: "DMSans-MediumItalic",
          },
          "700": {
            regular: "DMSans-Bold",
            italic: "DMSans-BoldItalic",
          },
        },
  inter:
    Platform.OS === "web"
      ? {
          "400": {
            regular: "'Inter', sans-serif",
            italic: "'Inter', sans-serif",
          },
          "500": {
            regular: "'Inter', sans-serif",
            italic: "'Inter', sans-serif",
          },
          "700": {
            regular: "'Inter', sans-serif",
            italic: "'Inter', sans-serif",
          },
        }
      : {
          "400": {
            regular: "Inter-Regular",
            italic: "Inter-Regular",
          },
          "500": {
            regular: "Inter-Medium",
            italic: "Inter-Medium",
          },
          "700": {
            regular: "Inter-Bold",
            italic: "Inter-Bold",
          },
        },
};

export const FontStyles = {
  heading1: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 64,
    lineHeight: 76,
    letterSpacing: -1.25,
    fontWeight: "400",
  },
  heading1Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 64,
    lineHeight: 76,
    letterSpacing: -1.25,
    fontWeight: "500",
  },
  heading2: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 48,
    lineHeight: 56,
    letterSpacing: -0.8,
    fontWeight: "400",
  },
  heading2Weight: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 48,
    lineHeight: 56,
    letterSpacing: -0.8,
    fontWeight: "400",
  },
  heading3: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 36,
    lineHeight: 44,
    letterSpacing: -0.6,
    fontWeight: "400",
  },
  heading3Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 36,
    lineHeight: 44,
    letterSpacing: -0.6,
    fontWeight: "500",
  },
  heading4: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 24,
    lineHeight: 32,
    letterSpacing: -0.2,
    fontWeight: "400",
  },
  heading4Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 24,
    lineHeight: 32,
    letterSpacing: -0.2,
    fontWeight: "500",
  },
  heading5: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 20,
    lineHeight: 28,
    letterSpacing: -0.1,
    fontWeight: "400",
  },
  heading5Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 20,
    lineHeight: 28,
    letterSpacing: -0.1,
    fontWeight: "500",
  },
  title1Regular: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 18,
    lineHeight: 28,
    letterSpacing: -0.32,
    fontWeight: "400",
  },
  title1Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 18,
    lineHeight: 28,
    letterSpacing: -0.32,
    fontWeight: "500",
  },
  title2Regular: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.32,
    fontWeight: "400",
  },
  title2Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.32,
    fontWeight: "500",
  },
  title3Regular: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.16,
    fontWeight: "400",
  },
  title3Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.16,
    fontWeight: "500",
  },
  body1Regular: {
    fontFamily: Fonts.inter["400"].regular,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.18,
    fontWeight: "400",
  },
  body1Weight: {
    fontFamily: Fonts.inter["500"].regular,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.18,
    fontWeight: "500",
  },
  body1LongRegular: {
    fontFamily: Fonts.inter["400"].regular,
    fontSize: 16,
    lineHeight: 28,
    letterSpacing: -0.18,
    fontWeight: "400",
  },
  body1LongWeight: {
    fontFamily: Fonts.inter["500"].regular,
    fontSize: 16,
    lineHeight: 28,
    letterSpacing: -0.18,
    fontWeight: "500",
  },
  body2Regular: {
    fontFamily: Fonts.inter["400"].regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.16,
    fontWeight: "400",
  },
  body2Weight: {
    fontFamily: Fonts.inter["500"].regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.16,
    fontWeight: "500",
  },
  body2LongRegular: {
    fontFamily: Fonts.inter["400"].regular,
    fontSize: 14,
    lineHeight: 24,
    letterSpacing: -0.16,
    fontWeight: "400",
  },
  body2LongWeight: {
    fontFamily: Fonts.inter["500"].regular,
    fontSize: 14,
    lineHeight: 24,
    letterSpacing: -0.16,
    fontWeight: "500",
  },
  body3Regular: {
    fontFamily: Fonts.inter["400"].regular,
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: -0.1,
    fontWeight: "400",
  },
  body3Weight: {
    fontFamily: Fonts.inter["500"].regular,
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: -0.1,
    fontWeight: "500",
  },
  label1Regular: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.16,
    fontWeight: "400",
  },
  label1Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.16,
    fontWeight: "500",
  },
  label2Regular: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.16,
    fontWeight: "400",
  },
  label2Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.16,
    fontWeight: "500",
  },
  label3Regular: {
    fontFamily: Fonts.dmSans["400"].regular,
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: -0.04,
    fontWeight: "400",
  },
  label3Weight: {
    fontFamily: Fonts.dmSans["500"].regular,
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: -0.04,
    fontWeight: "500",
  },
  link1Regular: {
    fontFamily: Fonts.inter["400"].regular,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.18,
    fontWeight: "400",
    textDecorationLine: "underline",
  },
  link1Weight: {
    fontFamily: Fonts.inter["500"].regular,
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: -0.18,
    fontWeight: "500",
    textDecorationLine: "underline",
  },
  link2Regular: {
    fontFamily: Fonts.inter["400"].regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.12,
    fontWeight: "400",
    textDecorationLine: "underline",
  },
  link2Weight: {
    fontFamily: Fonts.inter["500"].regular,
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: -0.12,
    fontWeight: "500",
    textDecorationLine: "underline",
  },
} satisfies Record<string, TextStyle>;
