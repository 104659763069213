import { createStyles, vars } from "@/styles";
import type { TextStyle } from "react-native";
import { Platform } from "react-native";

export const PADDING_VERTICAL = 10 - 2;

export const getNumberOfLinesStyle = (
  numberOfLines: number | undefined,
): TextStyle | undefined => {
  if (!numberOfLines || numberOfLines <= 1) {
    return undefined;
  }
  return {
    maxHeight: numberOfLines * 20 + PADDING_VERTICAL * 2,
  };
};

export const styles = createStyles({
  root: {
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    paddingHorizontal: 4,
  },
  input: (theme) => ({
    color: theme.colors.textSecondary,
    flex: 1,
    ...(Platform.OS === "web" && {
      outline: "none",
    }),
    maxHeight: 20 + PADDING_VERTICAL * 2,
    paddingVertical: PADDING_VERTICAL,
    paddingHorizontal: 8,
    ...vars.typography.body2Regular,
  }),
  leading: {
    paddingLeft: 4,
  },
  trailing: {
    gap: 4,
    flexDirection: "row",
    alignItems: "center",
  },
  outlined: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutral1Default,
    borderColor: theme.colors.borderStaticDefault,
  }),
  outlinedHover: (theme) => ({
    borderColor: theme.colors.borderInteractiveHover,
  }),
  outlinedFocused: (theme) => ({
    borderColor: theme.colors.borderStaticBrand,
  }),
  filled: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutral2Default,
    borderColor: "transparent",
  }),
  filledHover: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutral2Hover,
  }),
  filledFocused: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutral1Default,
    borderColor: theme.colors.borderInteractiveActive,
  }),
  disabled: (theme) => ({
    borderColor: theme.colors.borderInteractiveDisabled,
  }),
  inputDisabled: (theme) => ({
    color: theme.colors.textDisabled,
  }),
  clear: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // size
  sm: {
    minHeight: 36,
    paddingHorizontal: 4,
  },
  md: {
    minHeight: 40,
    paddingHorizontal: 4,
  },
});
