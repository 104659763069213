// @ts-nocheck
import { default as Dot } from "./Dot.svg";
export const IconDot = Dot;
import { default as GoogleCalendar } from "./Google-Calendar.svg";
export const IconGoogleCalendar = GoogleCalendar;
import { default as GoogleGColor } from "./Google-G-Color.svg";
export const IconGoogleGColor = GoogleGColor;
import { default as LogoTextOnly } from "./LogoTextOnly.svg";
export const IconLogoTextOnly = LogoTextOnly;
import { default as MicrosoftColor } from "./MicrosoftColor.svg";
export const IconMicrosoftColor = MicrosoftColor;
import { default as Outlook } from "./Outlook.svg";
export const IconOutlook = Outlook;
import { default as Sparkles } from "./Sparkles.svg";
export const IconSparkles = Sparkles;
import { default as Activity } from "./activity.svg";
export const IconActivity = Activity;
import { default as AlertCircle } from "./alert-circle.svg";
export const IconAlertCircle = AlertCircle;
import { default as AlignLeft } from "./align-left.svg";
export const IconAlignLeft = AlignLeft;
import { default as ArrowDown } from "./arrow-down.svg";
export const IconArrowDown = ArrowDown;
import { default as ArrowLeft } from "./arrow-left.svg";
export const IconArrowLeft = ArrowLeft;
import { default as ArrowRight } from "./arrow-right.svg";
export const IconArrowRight = ArrowRight;
import { default as ArrowUpRight } from "./arrow-up-right.svg";
export const IconArrowUpRight = ArrowUpRight;
import { default as ArrowUp } from "./arrow-up.svg";
export const IconArrowUp = ArrowUp;
import { default as Backspace } from "./backspace.svg";
export const IconBackspace = Backspace;
import { default as BarChart2 } from "./bar-chart-2.svg";
export const IconBarChart2 = BarChart2;
import { default as BoltPink } from "./bolt-pink.svg";
export const IconBoltPink = BoltPink;
import { default as BookOpen } from "./book-open.svg";
export const IconBookOpen = BookOpen;
import { default as Bookmark } from "./bookmark.svg";
export const IconBookmark = Bookmark;
import { default as Calendar } from "./calendar.svg";
export const IconCalendar = Calendar;
import { default as CallEnd } from "./call-end.svg";
export const IconCallEnd = CallEnd;
import { default as CheckCircle } from "./check-circle.svg";
export const IconCheckCircle = CheckCircle;
import { default as CheckSquare } from "./check-square.svg";
export const IconCheckSquare = CheckSquare;
import { default as Check } from "./check.svg";
export const IconCheck = Check;
import { default as ChevronDown } from "./chevron-down.svg";
export const IconChevronDown = ChevronDown;
import { default as ChevronLeft } from "./chevron-left.svg";
export const IconChevronLeft = ChevronLeft;
import { default as ChevronUp } from "./chevron-up.svg";
export const IconChevronUp = ChevronUp;
import { default as Chrome } from "./chrome.svg";
export const IconChrome = Chrome;
import { default as Circle } from "./circle.svg";
export const IconCircle = Circle;
import { default as Clock } from "./clock.svg";
export const IconClock = Clock;
import { default as Close } from "./close.svg";
export const IconClose = Close;
import { default as CloudOff } from "./cloud-off.svg";
export const IconCloudOff = CloudOff;
import { default as Cloud } from "./cloud.svg";
export const IconCloud = Cloud;
import { default as Copy } from "./copy.svg";
export const IconCopy = Copy;
import { default as DottedCircle } from "./dotted-circle.svg";
export const IconDottedCircle = DottedCircle;
import { default as Download } from "./download.svg";
export const IconDownload = Download;
import { default as Edit } from "./edit.svg";
export const IconEdit = Edit;
import { default as FileText } from "./file-text.svg";
export const IconFileText = FileText;
import { default as Globe } from "./globe.svg";
export const IconGlobe = Globe;
import { default as Grid } from "./grid.svg";
export const IconGrid = Grid;
import { default as Hash } from "./hash.svg";
export const IconHash = Hash;
import { default as HelpCircle } from "./help-circle.svg";
export const IconHelpCircle = HelpCircle;
import { default as Home } from "./home.svg";
export const IconHome = Home;
import { default as Info } from "./info.svg";
export const IconInfo = Info;
import { default as Layers } from "./layers.svg";
export const IconLayers = Layers;
import { default as Link2 } from "./link-2.svg";
export const IconLink2 = Link2;
import { default as Link } from "./link.svg";
export const IconLink = Link;
import { default as List } from "./list.svg";
export const IconList = List;
import { default as Lock } from "./lock.svg";
export const IconLock = Lock;
import { default as LogOut } from "./log-out.svg";
export const IconLogOut = LogOut;
import { default as Mail } from "./mail.svg";
export const IconMail = Mail;
import { default as Maximize } from "./maximize.svg";
export const IconMaximize = Maximize;
import { default as MediaLibrary } from "./media-library.svg";
export const IconMediaLibrary = MediaLibrary;
import { default as MeetingNoaudio } from "./meeting-noaudio.svg";
export const IconMeetingNoaudio = MeetingNoaudio;
import { default as MessageCircle } from "./message-circle.svg";
export const IconMessageCircle = MessageCircle;
import { default as MicOff } from "./mic-off.svg";
export const IconMicOff = MicOff;
import { default as MicOn } from "./mic-on.svg";
export const IconMicOn = MicOn;
import { default as Mic } from "./mic.svg";
export const IconMic = Mic;
import { default as Minimize } from "./minimize.svg";
export const IconMinimize = Minimize;
import { default as MoreVertical } from "./more-vertical.svg";
export const IconMoreVertical = MoreVertical;
import { default as Pause } from "./pause.svg";
export const IconPause = Pause;
import { default as PhoneCall } from "./phone-call.svg";
export const IconPhoneCall = PhoneCall;
import { default as Phone } from "./phone.svg";
export const IconPhone = Phone;
import { default as Play } from "./play.svg";
export const IconPlay = Play;
import { default as Playlist } from "./playlist.svg";
export const IconPlaylist = Playlist;
import { default as Plus } from "./plus.svg";
export const IconPlus = Plus;
import { default as RefreshCcw } from "./refresh-ccw.svg";
export const IconRefreshCcw = RefreshCcw;
import { default as Rewind } from "./rewind.svg";
export const IconRewind = Rewind;
import { default as Search } from "./search.svg";
export const IconSearch = Search;
import { default as SeekBackward } from "./seek-backward.svg";
export const IconSeekBackward = SeekBackward;
import { default as SeekForward } from "./seek-forward.svg";
export const IconSeekForward = SeekForward;
import { default as Send } from "./send.svg";
export const IconSend = Send;
import { default as Server } from "./server.svg";
export const IconServer = Server;
import { default as Settings } from "./settings.svg";
export const IconSettings = Settings;
import { default as Share } from "./share.svg";
export const IconShare = Share;
import { default as SmartSearch } from "./smart-search.svg";
export const IconSmartSearch = SmartSearch;
import { default as Smartphone } from "./smartphone.svg";
export const IconSmartphone = Smartphone;
import { default as Soundbite } from "./soundbite.svg";
export const IconSoundbite = Soundbite;
import { default as SparkleGradient } from "./sparkle-gradient.svg";
export const IconSparkleGradient = SparkleGradient;
import { default as Speedometer1 } from "./speedometer-1.svg";
export const IconSpeedometer1 = Speedometer1;
import { default as Speedometer2 } from "./speedometer-2.svg";
export const IconSpeedometer2 = Speedometer2;
import { default as Speedometer3 } from "./speedometer-3.svg";
export const IconSpeedometer3 = Speedometer3;
import { default as Speedometer4 } from "./speedometer-4.svg";
export const IconSpeedometer4 = Speedometer4;
import { default as Speedometer5 } from "./speedometer-5.svg";
export const IconSpeedometer5 = Speedometer5;
import { default as Square } from "./square.svg";
export const IconSquare = Square;
import { default as StarPurple } from "./star-purple.svg";
export const IconStarPurple = StarPurple;
import { default as Star } from "./star.svg";
export const IconStar = Star;
import { default as StopCircle } from "./stop-circle.svg";
export const IconStopCircle = StopCircle;
import { default as Teammates_participants } from "./teammates_participants.svg";
export const IconTeammates_participants = Teammates_participants;
import { default as ThumbsDown } from "./thumbs-down.svg";
export const IconThumbsDown = ThumbsDown;
import { default as ThumbsUp } from "./thumbs-up.svg";
export const IconThumbsUp = ThumbsUp;
import { default as Trash } from "./trash.svg";
export const IconTrash = Trash;
import { default as UploadCloud } from "./upload-cloud.svg";
export const IconUploadCloud = UploadCloud;
import { default as Upload } from "./upload.svg";
export const IconUpload = Upload;
import { default as UserGroup } from "./user-group.svg";
export const IconUserGroup = UserGroup;
import { default as UserPlus } from "./user-plus.svg";
export const IconUserPlus = UserPlus;
import { default as User } from "./user.svg";
export const IconUser = User;
import { default as Users } from "./users.svg";
export const IconUsers = Users;
import { default as VideoOff } from "./video-off.svg";
export const IconVideoOff = VideoOff;
import { default as Video } from "./video.svg";
export const IconVideo = Video;
import { default as VolumeUp } from "./volume-up.svg";
export const IconVolumeUp = VolumeUp;
import { default as Warning } from "./warning.svg";
export const IconWarning = Warning;
import { default as X } from "./x.svg";
export const IconX = X;
import { default as Zap } from "./zap.svg";
export const IconZap = Zap;
