export const partialUpsertDataArray = <T>(
  data: T[],
  newData: T,
  field: keyof T,
): T[] => {
  const index = data.findIndex((d) => d[field] === newData[field]);
  if (index === -1) {
    return [...data, newData];
  }
  return data.map((d, i) =>
    i === index
      ? {
          ...d,
          ...newData,
        }
      : d,
  );
};
