import type {
  ChangeEventMap,
  RecordingContext,
  RecordingOptions,
  RecordingStatus,
} from "../types";
import type { AudioFileBuffer } from "./audio-file-buffer";
import { AudioRecorder } from "./audio-recorder";
import { AudioStreamer } from "./audio-streamer";
import { AudioUploader } from "./audio-uploader";
import { RecordingState, StreamingState } from "./constants";
import { Mp3FileBuffer } from "./mp3-file-buffer";

export class AudioRecordModule {
  private state = RecordingState.INACTIVE;
  private streamingState = StreamingState.INACTIVE;
  private streamingError: string | null = null;
  private durationMillis = 0;
  private metering = 0;
  private isRecording = false;

  private audioRecorder?: AudioRecorder;
  private audioBuffer?: AudioFileBuffer;
  private audioStreamer?: AudioStreamer;
  private audioUploader?: AudioUploader;

  private itv?: ReturnType<typeof setInterval>;
  private context: RecordingContext = { fileUri: "", mimeType: "" };

  private emitter?: {
    emit: <E extends keyof ChangeEventMap>(
      type: E,
      event: ChangeEventMap[E],
    ) => void;
  };

  async startRecording(options: RecordingOptions): Promise<void> {
    if (this.isRecording) {
      throw new Error("Recording is already in progress.");
    }
    if (!options.sampleRate) {
      throw new Error("Sample rate is required.");
    }
    let audioBuffer: AudioFileBuffer;
    if (options.container === "mp3") {
      audioBuffer = new Mp3FileBuffer({
        bitrate: options.bitRate || 128,
        readChunkSize: 65536, // 64KB
        samplingRate: options.sampleRate,
        channels: options.channels || 1,
      });
    } else {
      throw new Error("Unsupported container format.");
    }
    this.audioBuffer = audioBuffer;
    this.audioRecorder = new AudioRecorder((data) => {
      audioBuffer.writeData(data);
    }, options.sampleRate);
    await this.audioRecorder.startRecording();
    this.isRecording = true;
    this.updateStatus({ state: RecordingState.RECORDING, streamingError: "" });
    this.startTimer();

    if (!options.useUploader) {
      if (!options.socketAuthToken) {
        throw new Error("Auth token is required.");
      }
      if (!options.socketUrl) {
        throw new Error("Socket url is required.");
      }
      this.audioStreamer = new AudioStreamer(
        options.socketUrl,
        options.socketAuthToken,
        audioBuffer,
        {
          log: (message) => {
            this.emitter?.emit("log", { message });
          },
          onStreamStatusUpdate: (status) => {
            this.updateStatus({
              streamingStatus: status,
            });
          },
          onStreamCompleted: () => {
            this.invalidateTimer();
            this.emitter?.emit("streamCompleted", {
              meetingId: options.meetingId,
            });
            this.release();
          },
          onStreamFailed: (error) => {
            this.emitter?.emit("streamFailed", {
              meetingId: options.meetingId,
              type: "streamer",
              code: "E_INTERNAL",
              message: error,
            });
          },
          onStreamError: (error) => {
            this.emitter?.emit("error", {
              code: "E_INTERNAL",
              message: error,
            });
          },
        },
      );
    }
  }

  async stopRecording(): Promise<{ uri: string }> {
    if (!this.isRecording) {
      throw new Error("Recording is not in progress.");
    }
    await this.audioRecorder?.stopRecording();
    this.updateStatus({ state: RecordingState.INACTIVE, durationMillis: 0 });
    // Mark the audio buffer so that it no longer accepts new data.
    this.audioBuffer?.endWrite();
    this.isRecording = false;
    return { uri: this.context.fileUri };
  }

  pauseRecording() {
    if (!this.isRecording) {
      throw new Error("Recording is not in progress.");
    }
    this.audioRecorder?.pauseRecording();
    this.updateStatus({ state: RecordingState.PAUSED });
  }

  resumeRecording() {
    if (!this.isRecording) {
      throw new Error("Recording is not in progress.");
    }
    this.audioRecorder?.resumeRecording();
    this.updateStatus({ state: RecordingState.RECORDING });
  }

  startUpload(uploadUrl: string) {
    const meetingId = this.context.meetingId;
    if (!meetingId) {
      throw new Error("Meeting ID is required.");
    }
    if (this.audioBuffer) {
      this.updateStatus({
        streamingError: "",
        streamingStatus: StreamingState.CONNECTING,
      });
      this.audioUploader = new AudioUploader(this.audioBuffer, {
        log: (message) => {
          this.emitter?.emit("log", { message });
        },
        onStreamStatusUpdate: (status) => {
          this.updateStatus({ streamingStatus: status });
        },
        onStreamCompleted: () => {
          this.emitter?.emit("streamCompleted", { meetingId });
          this.release();
        },
        onStreamFailed: (error) => {
          this.emitter?.emit("streamFailed", {
            meetingId,
            type: "uploader",
            code: "E_INTERNAL",
            message: error,
          });
        },
        onStreamError: (error) => {
          this.emitter?.emit("error", {
            code: "E_INTERNAL",
            message: error,
          });
        },
      });
      this.audioUploader.startUpload(uploadUrl);
    } else {
      this.emitter?.emit("streamFailed", {
        meetingId,
        type: "uploader",
        code: "E_INTERNAL",
        message: "audioBuffer is not available",
      });
      this.release();
    }
  }

  release() {
    this.updateStatus({
      state: RecordingState.INACTIVE,
      streamingStatus: StreamingState.INACTIVE,
      streamingError: "",
      durationMillis: 0,
      metering: 0,
    });
    this.invalidateTimer();
    this.audioRecorder = undefined;
    this.audioBuffer = undefined;
    this.audioStreamer = undefined;
    this.audioUploader = undefined;
    this.isRecording = false;
  }

  getStatus(): RecordingStatus {
    return {
      state: this.state,
      durationMillis: this.durationMillis,
      metering: this.metering,
      streamingState: this.streamingState,
      fileSize: this.audioBuffer?.fileSize || 0,
      fileReadOffset: this.audioBuffer?.readOffset || 0,
      streamingError: this.streamingError,
    };
  }

  getContext(): RecordingContext {
    return this.context;
  }

  setContext(context: RecordingContext) {
    this.context = context;
  }

  setStreamingError(error: string) {
    this.updateStatus({ streamingError: error });
  }

  private updateStatus({
    state,
    durationMillis,
    metering,
    streamingStatus,
    streamingError,
  }: {
    state?: RecordingState;
    durationMillis?: number;
    metering?: number;
    streamingStatus?: StreamingState;
    streamingError?: string;
  }) {
    if (typeof state === "string") {
      this.state = state;
    }
    if (typeof durationMillis === "number") {
      this.durationMillis = durationMillis;
    }
    if (typeof metering === "number") {
      this.metering = metering;
    }
    if (typeof streamingStatus === "string") {
      this.streamingState = streamingStatus;
    }
    if (typeof streamingError === "string") {
      this.streamingError = streamingError || null;
    }
    this.emitter?.emit("status", this.getStatus());
  }

  /**
   * Periodically updates status based on recorder data.
   */
  private onPeriodicNotification() {
    if (this.state !== RecordingState.RECORDING) {
      this.updateStatus({});
      return;
    }
    this.updateStatus({
      durationMillis: (this.audioRecorder?.getCurrentDuration() || 0) * 1000,
      metering: this.audioRecorder?.getCurrentMeter(),
    });
  }

  private startTimer() {
    if (this.itv) {
      return;
    }
    this.itv = setInterval(() => {
      this.onPeriodicNotification();
    }, 500);
  }

  private invalidateTimer() {
    if (this.itv) {
      clearInterval(this.itv);
      this.itv = undefined;
    }
  }
}
