import { getErrorMessage } from "@/errors";
import { Logger } from "@/logger";
import * as NativeCode from "@/modules/native-code";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { Platform } from "react-native";
import { getMimeType } from "./mime";

const logger = new Logger("file");

export function uploadFile(
  url: string,
  fileUrl: string,
  options: {
    method: "GET" | "POST" | "PUT" | "DELETE";
    headers?: Record<string, string>;
    onProgress?: (progress: { totalBytesSent: number }) => void;
    onComplete?: () => void;
    onError?: (error: Error) => void;
  },
) {
  logger.info("uploading file", {
    url,
    fileUrl,
  });

  if (Platform.OS === "web") {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (e) => {
      options.onProgress?.({
        totalBytesSent: e.loaded,
      });
    });
    xhr.addEventListener("load", () => {
      options.onComplete?.();
    });
    xhr.addEventListener("error", () => {
      options.onError?.(new Error(`Upload failed with status ${xhr.status}`));
    });
    fetch(fileUrl)
      .then((r) => r.blob())
      .then((blob) => {
        xhr.open(options.method, url, true);
        if (options.headers) {
          for (const [key, value] of Object.entries(options.headers)) {
            xhr.setRequestHeader(key, value);
          }
        }
        xhr.send(blob);
      });

    return {
      cancel: async () => xhr.abort(),
    };
  } else {
    const uploadTask = FileSystem.createUploadTask(
      url,
      fileUrl,
      {
        httpMethod: "PUT",
        headers: options.headers,
        uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
        sessionType: FileSystem.FileSystemSessionType.BACKGROUND,
      },
      (progressData) => {
        options.onProgress?.({
          totalBytesSent: progressData.totalBytesSent,
        });
      },
    );
    uploadTask
      .uploadAsync()
      .then((result) => {
        if (!result) return;
        if (!(result.status >= 200 && result.status < 300)) {
          throw new Error(
            `Upload failed with status ${result.status}: ${result.body}`,
          );
        }
        options?.onComplete?.();
      })
      .catch((err) => {
        options?.onError?.(
          new Error(getErrorMessage(err, "FileSystem.uploadAsync")),
        );
      });
    return {
      cancel: async () =>
        uploadTask
          .cancelAsync()
          .catch((err) =>
            Promise.reject(
              new Error(getErrorMessage(err, "uploadTask.cancelAsync")),
            ),
          ),
    };
  }
}

export async function copyFile(fileUrl: string, destinationFileUrl: string) {
  if (Platform.OS === "web") {
    throw new Error("Not implemented");
  } else {
    logger.info(`copying file`, {
      from: fileUrl,
      to: destinationFileUrl,
    });

    return FileSystem.copyAsync({
      from: fileUrl,
      to: destinationFileUrl,
    }).catch((err) => {
      throw new Error(getErrorMessage(err, "FileSystem.copyAsync"));
    });
  }
}

export async function deleteFile(fileUrl: string) {
  if (Platform.OS === "web") {
    // on the web, we use blob urls, so we just need to revoke them
    // there is no real file to delete
    URL.revokeObjectURL(fileUrl);
  } else {
    logger.info(`deleting file`, {
      fileUrl,
    });

    return FileSystem.deleteAsync(fileUrl).catch((err) => {
      throw new Error(getErrorMessage(err, "FileSystem.deleteAsync"));
    });
  }
}

export async function getFileInfo(
  uri: string,
  // the second argument is used as a hint
  // to resolve the correct file and mime type
  // this is because sometimes the uri is a content:// url
  // that does not represent the actual file name
  {
    name,
    mimeType: defaultMimeType,
  }: {
    name?: string;
    mimeType?: string;
  } = {},
) {
  let mimeType: string;
  let size: number;
  if (Platform.OS === "web") {
    const res = await fetch(uri);
    if (!res.ok) throw new Error(`Request failed with status ${res.status}`);
    const blob = await res.blob();
    mimeType = blob.type;
    size = blob.size;
  } else {
    const info = await FileSystem.getInfoAsync(uri).catch((err) => {
      throw new Error(getErrorMessage(err, "FileSystem.getInfoAsync"));
    });
    if (!info.exists) throw new Error("File does not exist");
    if (info.isDirectory) throw new Error("File is a directory");
    mimeType =
      defaultMimeType ||
      (name && getMimeType(name)) ||
      getMimeType(info.uri) ||
      "";
    size = info.size;
  }
  return {
    mimeType,
    size,
  };
}

export async function getFileUrisInDirectory(
  directory: string,
): Promise<string[]> {
  const fileUris = await FileSystem.readDirectoryAsync(directory)
    .then((files) => files.map((file) => `${directory}${file}`))
    .catch(() => []);
  return fileUris;
}

export async function fileExists(uri: string) {
  if (Platform.OS === "web") {
    throw new Error("Not implemented");
  } else {
    const info = await FileSystem.getInfoAsync(uri).catch((err) => {
      throw new Error(getErrorMessage(err, "FileSystem.getInfoAsync"));
    });
    return info.exists && !info.isDirectory;
  }
}

export async function createDirectoryIfNotExist(uri: string) {
  if (Platform.OS === "web") {
    throw new Error("Not implemented");
  } else {
    const dirInfo = await FileSystem.getInfoAsync(uri).catch((err) => {
      throw new Error(getErrorMessage(err, "FileSystem.getInfoAsync"));
    });
    if (dirInfo.exists) return;
    return FileSystem.makeDirectoryAsync(uri, {
      intermediates: true,
    }).catch((err) => {
      throw new Error(getErrorMessage(err, "FileSystem.makeDirectoryAsync"));
    });
  }
}

export function triggerDownloadFile(url: string) {
  NativeCode.openInBrowser(url);
}

export async function shareFile(url: string) {
  try {
    await Sharing.shareAsync(url);
  } catch (err) {
    throw new Error(getErrorMessage(err, "Sharing.shareAsync"));
  }
}
