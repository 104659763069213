import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { storage } from "../storage";

export interface LocalUserFlags {
  "record.language-select-prompt.shown": boolean;
}

export interface LocalUserFlagsStoreValue {
  flags: Partial<LocalUserFlags>;
  setFlag: <K extends keyof LocalUserFlags>(
    key: K,
    value: LocalUserFlags[K],
  ) => void;
}

export const userLocalUserFlagsStore = create(
  persist<LocalUserFlagsStoreValue>(
    (set) => ({
      flags: {},
      setFlag: (key: string, value: boolean) =>
        set((state) => ({
          flags: { ...state.flags, [key]: value },
        })),
    }),
    {
      name: "localUserFlags",
      storage: createJSONStorage(() => storage),
    },
  ),
);

export function setLocalUserFlag<K extends keyof LocalUserFlags>(
  key: K,
  value: LocalUserFlags[K],
) {
  userLocalUserFlagsStore.getState().setFlag(key, value);
}

export function getLocalUserFlag<K extends keyof LocalUserFlags>(
  key: K,
): LocalUserFlags[K] | undefined {
  return userLocalUserFlagsStore.getState().flags[key];
}
