import { Dialog } from "@/components/Dialog";
import { PaywallContent } from "@/components/Paywall";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { FC } from "react";
import { globalModalsApi } from "../global-modals.store";

export const DOWNLOAD_PAYWALL_MODAL_NAME = "download-paywall-modal";

export const DownloadPaywallModal: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  return (
    <Dialog.Root isOpen={isOpen} close={close}>
      <PaywallContent
        title="Download is only available on Pro plan or above"
        description="Upgrade your account to download your meeting recordings, transcripts, and more."
        pwl="mobile-download"
      />
    </Dialog.Root>
  );
};

export const openDownloadPaywallModal = (fromRef?: string) => {
  globalModalsApi.openModal(DOWNLOAD_PAYWALL_MODAL_NAME);

  tracker.track(TRACKING_EVENTS.PAYWALL_SHOWN, {
    pwl: "download",
    from: fromRef,
  });
};
