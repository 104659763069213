import { useEffect } from "react";
import { Pressable, TouchableHighlight, TouchableOpacity } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
} from "react-native-reanimated";

export const AnimatedTouchableOpacity =
  Animated.createAnimatedComponent(TouchableOpacity);
export const AnimatedPressable = Animated.createAnimatedComponent(Pressable);
export const AnimatedTouchableHighlight =
  Animated.createAnimatedComponent(TouchableHighlight);
export { RecordIndicator } from "./RecordIndicator";

export const usePulseAnimatedStyle = (duration = 3000) => {
  const animValue = useSharedValue(1);
  useEffect(() => {
    animValue.value = withRepeat(
      withSequence(
        withDelay(duration / 3, withTiming(0, { duration: duration / 6 })),
        withDelay(duration / 3, withTiming(1, { duration: duration / 6 })),
      ),
      -1,
      false,
      undefined,
    );
  }, [animValue, duration]);

  const animStyle = useAnimatedStyle(() => {
    return {
      opacity: animValue.value,
    };
  });

  return animStyle;
};
