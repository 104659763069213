import { Text } from "@/components/Typography";
import type { FC } from "react";
import { View } from "react-native";

export const InitErrorBanner: FC<{
  error: Error;
}> = ({ error }) => {
  return (
    <View>
      <Text>{error.message}</Text>
    </View>
  );
};
