import { StyleSheet } from "react-native";

export const sharedStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  tabPanel: {
    flex: 1,
  },
  tabPanelContent: {
    paddingBottom: 76,
  },
});
