import { Button } from "@/components/Button";
import { LoadingScreen } from "@/components/Loading";
import { MessageView } from "@/components/Message";
import { Toaster } from "@/components/Toast";
import { openLoginPopup, useAuth } from "@/features/auth";
import type { FredResponse } from "@/graphql";
import { useMeetingFredQaQuery } from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import { useQuery } from "@apollo/client";
import {
  GetMeetingSummaryQueryDocument,
  type Meeting,
} from "@firefliesai/mobile-ff.graphql-client";
import { type FC } from "react";
import { KeyboardAvoidingView, Modal, Platform, View } from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { AskFredPaywall } from "./AskFredPaywall";
import { AskFredHeader } from "./components/AskFredHeader";
import { AskFredInput } from "./components/AskFredInput";
import { AskFredMessages } from "./components/AskFredMessages";

export const NotepadAskFred: FC<{
  meeting: Meeting;
  aiModel: string;
}> = ({ meeting, aiModel }) => {
  const { user } = useAuth();

  const { data, loading } = useMeetingFredQaQuery({
    variables: {
      meetingId: meeting.id,
    },
    skip: !user,
  });

  // get prompt suggestions from meeting summary
  const { data: dataSummary } = useQuery(GetMeetingSummaryQueryDocument, {
    variables: { meetingId: meeting.id },
  });

  const promptSuggestions =
    dataSummary?.meetingSummary.summary?.promptSuggestions;

  if (!user) {
    return (
      <MessageView
        title="Ask Fred: ChatGPT for Meetings"
        description="Ask Fred allows you to extract insights and answer questions from your meetings"
        action={
          <Button onPress={() => openLoginPopup("notepad/ask-fred")}>
            Get started
          </Button>
        }
      />
    );
  }

  return (
    <View style={styles.content}>
      <View style={styles.main}>
        {loading && !data?.meetingFredQa ? (
          <LoadingScreen />
        ) : (
          <AskFredMessages
            meeting={meeting}
            meetingFredQa={(data?.meetingFredQa as FredResponse[]) || []}
            aiModel={aiModel}
            promptSuggestions={promptSuggestions || undefined}
          />
        )}
        <AskFredPaywall />
      </View>
      <AskFredInput meeting={meeting} aiModel={aiModel} />
    </View>
  );
};

export const AskFredChatModal: FC<{
  meeting: Meeting;
  isOpen: boolean;
  close: () => void;
  aiModel: string;
  setAiModel: (model: string) => void;
}> = ({ meeting, isOpen, close, aiModel, setAiModel }) => {
  const theme = useTheme();

  return (
    <Modal
      visible={isOpen}
      onRequestClose={close}
      transparent
      animationType="fade"
    >
      <SafeAreaProvider>
        <SafeAreaView style={styles.overlay(theme)}>
          <KeyboardAvoidingView
            style={styles.root(theme)}
            behavior={Platform.OS === "ios" ? "padding" : undefined}
          >
            <AskFredHeader
              close={close}
              aiModel={aiModel}
              setAiModel={setAiModel}
            />
            <NotepadAskFred meeting={meeting} aiModel={aiModel} />
          </KeyboardAvoidingView>
        </SafeAreaView>
      </SafeAreaProvider>
      <Toaster />
    </Modal>
  );
};

const styles = createStyles({
  overlay: (theme) => ({
    flex: 1,
    backgroundColor: theme.colors.miscellaneousOverlay,
    paddingVertical: 24,
    paddingHorizontal: 12,
  }),
  root: (theme) => ({
    flex: 1,
    backgroundColor: theme.colors.layerDefault,
    borderWidth: 1,
    borderColor: theme.colors.borderStaticSubtle,
    borderRadius: 12,
  }),
  content: {
    flex: 1,
  },
  main: {
    flex: 1,
  },
});
