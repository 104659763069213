import { Dialog } from "@/components/Dialog";
import { Switch } from "@/components/Switch";
import { Text } from "@/components/Typography";
import { createStyles } from "@/styles";
import { type FC } from "react";
import { Platform, View } from "react-native";
import { useExperimentalRecordConfig } from "../use-experimental-record-config";

const RecordSettingsModalContent: FC = () => {
  const {
    recordHQAudio,
    setRecordHQAudio,
    recordUseStreaming,
    setRecordUseStreaming,
  } = useExperimentalRecordConfig();

  return (
    <>
      <View style={styles.root}>
        <View style={styles.settingRowContainer}>
          <View style={styles.settingTextContainer}>
            <Text variant="label2Weight" id="setting-label-record-hq-audio">
              Record HQ audio
            </Text>
          </View>
          <Switch
            value={recordHQAudio ?? false}
            onValueChange={setRecordHQAudio}
            disabled={Platform.OS === "web"}
            aria-label="setting-label-record-hq-audio"
          />
        </View>
        {__DEV__ && (
          <View style={styles.settingRowContainer}>
            <View style={styles.settingTextContainer}>
              <Text
                variant="label2Weight"
                id="setting-label-record-use-streaming"
              >
                Use streaming
              </Text>
            </View>
            <Switch
              value={recordUseStreaming ?? false}
              onValueChange={setRecordUseStreaming}
              aria-label="setting-label-record-use-streaming"
            />
          </View>
        )}
      </View>
    </>
  );
};

export const RecordSettingsModal: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  return (
    <Dialog.Root isOpen={isOpen} close={close} variant="bottomSheet">
      <Dialog.Header>Record Settings</Dialog.Header>
      <RecordSettingsModalContent />
    </Dialog.Root>
  );
};

const styles = createStyles({
  root: {
    padding: 16,
    gap: 16,
  },
  settingRowContainer: {
    flexDirection: "row",
    gap: 16,
  },
  settingTextContainer: { flex: 1 },
});
