import { Text } from "@/components/Typography";
import { useBilling } from "@/features/billing";
import { createStyles, useTheme } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import { format } from "date-fns";
import { LinearGradient } from "expo-linear-gradient";
import { useEffect } from "react";
import { View } from "react-native";
import { PaywallContent } from "src/components/Paywall/PaywallContent";
import SvgCounter from "./assets/Counter.svg";

export const AskFredPaywall = () => {
  const {
    aiExtensionsSubscription,
    isAllowedToUsePrompts,
    teamPromptsConsumed,
    isFreeUser,
    loading: loadingBilling,
  } = useBilling();

  const theme = useTheme();

  const shouldShowPaywall = !isAllowedToUsePrompts && !loadingBilling;

  useEffect(() => {
    if (shouldShowPaywall) {
      // deprecated
      tracker.track(TRACKING_EVENTS.ASK_FRED_PAYWALL_SHOWN, {
        from: "meeting-ask-fred",
      });
      tracker.track(TRACKING_EVENTS.PAYWALL_SHOWN, {
        pwl: "ask-fred",
        from: "meeting-ask-fred",
      });
    }
  }, [shouldShowPaywall]);

  if (!shouldShowPaywall) return null;

  return (
    <LinearGradient
      colors={[theme.colors.layerDefault, "transparent"]}
      start={[0.5, 1]}
      end={[0.5, 0.25]}
      style={styles.wrapper}
    >
      {isFreeUser ? (
        <PaywallContent
          title="Upgrade your account to get access to AskFred"
          description="AskFred is a premium add-on available only on Pro plan and above."
          pwl="mobile-ask-fred"
        />
      ) : (
        <PaywallContent
          header={
            <View style={styles.header}>
              <SvgCounter />
              {aiExtensionsSubscription && teamPromptsConsumed && (
                <Text
                  variant="label1Regular"
                  color="informationStaticYellowText"
                >
                  {teamPromptsConsumed.promptsLeft} /{" "}
                  {teamPromptsConsumed.monthlyPromptsLimit} AI Credits left
                </Text>
              )}
            </View>
          }
          title={
            aiExtensionsSubscription
              ? "To continue using AskFred, please upgrade your AI credits package"
              : "Try AskFred: ChatGPT for meetings."
          }
          description={
            aiExtensionsSubscription
              ? `Current package will auto-renew on\n${
                  aiExtensionsSubscription.nextPaymentAt &&
                  format(
                    new Date(aiExtensionsSubscription.nextPaymentAt),
                    "MMM d, yyyy",
                  )
                }`
              : "Do more with your meetings using AskFred."
          }
          pwl="mobile-ask-fred"
          forAskFred
        />
      )}
    </LinearGradient>
  );
};

const styles = createStyles({
  wrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: 16,
    zIndex: 20,
    justifyContent: "flex-end",
  },
  header: {
    alignItems: "center",
    gap: 16,
  },
});
