import { imageMeetingMock } from "@/assets/images";
import { MEETING_LIST_ITEM_HEIGHT } from "@/components/Meeting";
import { MessageView } from "@/components/Message";
import { QueryListView } from "@/components/QueryListView";
import { toast } from "@/components/Toast";
import {
  MeetingBottomSheetMenu,
  meetingSourceToMeeting,
} from "@/features/meeting";
import type { Source } from "@/graphql";
import { useFetchChannelMeetingsQuery, useFetchMore } from "@/graphql";
import type { WeekGrouped } from "@/utils/date";
import { groupByWeeks } from "@/utils/date";
import { useBooleanState } from "@/utils/states";
import { useScrollToTop } from "@react-navigation/native";
import type { FlashList, ListRenderItem } from "@shopify/flash-list";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { InteractionManager, StyleSheet } from "react-native";
import {
  ChannelMeetingListSectionHeader,
  ChannelMeetingListSectionItem,
  LoadingMeetingNode,
} from "./ChannelMeetingListItem";

const LIMIT = 20;

type ListItem = WeekGrouped<Source> | Source;

const isWeakGrouped = (item: ListItem): item is WeekGrouped<Source> =>
  "items" in item;

const getItemType = (item: ListItem) => {
  if (isWeakGrouped(item)) return "section";
  return "meeting";
};

export const ChannelMeetingList = memo<{ channelId: string }>(
  function ChannelMeetingList({ channelId }) {
    const listRef = useRef<FlashList<ListItem>>(null);

    const { data, fetchMore, loading, error, refetch } =
      useFetchChannelMeetingsQuery({
        variables: {
          channelId,
          from: 0,
          size: LIMIT,
        },
        onError(error) {
          toast({
            title: "Could not load meetings",
            message: error.message,
            type: "error",
          });
        },
      });

    const meetings = data?.getChannelMeetings?.meetings;

    const [onLoadMore, fetchMoreState, resetFetchMore] = useFetchMore({
      fetchMore,
      data,
      limit: LIMIT,
      path: "getChannelMeetings.meetings",
      skipVariable: "from",
    });

    useEffect(() => {
      const im = InteractionManager.runAfterInteractions(() => {
        resetFetchMore();
        listRef.current?.scrollToOffset({ offset: 0, animated: false });
      });
      return () => im.cancel();
    }, [channelId, resetFetchMore]);

    const sourceForMenuRef = useRef<Source | null>(null);
    const [isOpenMenu, openMenu, closeMenu] = useBooleanState(false);

    const setSourceForMenu = useCallback(
      (source: Source) => {
        sourceForMenuRef.current = source;
        openMenu();
      },
      [openMenu],
    );

    const renderItem = useCallback<ListRenderItem<ListItem>>(
      ({ item }) => {
        if (isWeakGrouped(item)) {
          return <ChannelMeetingListSectionHeader group={item} />;
        }
        return (
          <ChannelMeetingListSectionItem
            item={item}
            setSourceForMenu={setSourceForMenu}
          />
        );
      },
      [setSourceForMenu],
    );

    const { data: listData, stickyHeaderIndices } = useMemo(() => {
      if (!meetings) {
        return {
          data: [],
          stickyHeaderIndices: [],
        };
      }
      const groupedMeetings = groupByWeeks(meetings);
      const data: ListItem[] = [];
      const stickyHeaderIndices: number[] = [];
      for (let i = 0; i < groupedMeetings.length; i++) {
        const group = groupedMeetings[i];
        data.push(group);
        stickyHeaderIndices.push(data.length - 1);
        data.push(...group.items);
      }

      return {
        data,
        stickyHeaderIndices,
      };
    }, [meetings]);

    useScrollToTop(
      useRef({
        scrollToTop: () =>
          listRef.current?.scrollToOffset({ offset: 0, animated: true }),
      }),
    );

    return (
      <>
        <QueryListView
          listRef={listRef}
          data={listData}
          renderItem={renderItem}
          getItemType={getItemType}
          contentContainerStyle={styles.listContent}
          estimatedItemSize={MEETING_LIST_ITEM_HEIGHT}
          stickyHeaderIndices={stickyHeaderIndices}
          refetch={refetch}
          onLoadMore={onLoadMore}
          fetchMoreState={fetchMoreState}
          LoadingItemComponent={LoadingMeetingNode}
          loadingItemCount={2}
          emptyState={
            <MessageView
              headerImage={imageMeetingMock}
              title="You have no meetings in the channel"
              description="Channels helps you keep your relevant meetings organized and easily sharable across team."
            />
          }
          errorStateTitle="Could not load meetings"
          loading={loading}
          error={error}
        />
        {sourceForMenuRef.current && (
          <MeetingBottomSheetMenu
            meeting={meetingSourceToMeeting(sourceForMenuRef.current)}
            isOpen={isOpenMenu}
            close={closeMenu}
            fromRef="notebook"
          />
        )}
      </>
    );
  },
);

const styles = StyleSheet.create({
  listContent: {
    paddingBottom: 24,
  },
});
