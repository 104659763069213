import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import type { FC } from "react";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import type { ParamList } from "../types";
import { BiteSearch } from "./bite/BiteSearch";
import { SearchBar } from "./components/SearchBar";
import { MeetingSearch } from "./meeting/MeetingSearch";
import { PlaylistSearch } from "./playlist/PlaylistSearch";

export const SearchScreen: FC<NativeStackScreenProps<ParamList, "Search">> = ({
  navigation,
  route,
}) => {
  const searchQuery = route.params?.q ?? "";
  const setSearchQuery = useCallback(
    (newSearchQuery: string) => {
      navigation.setParams({ q: newSearchQuery });
    },
    [navigation],
  );

  return (
    <SafeAreaView style={styles.root}>
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        entity={route.params?.entity || "meeting"}
      />
      {route.params?.entity === "playlist" ? (
        <PlaylistSearch searchQuery={searchQuery} mine={route.params.mine} />
      ) : route.params?.entity === "bite" ? (
        <BiteSearch searchQuery={searchQuery} mine={route.params.mine} />
      ) : (
        <MeetingSearch
          searchQuery={searchQuery}
          channelId={route.params?.channel}
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
