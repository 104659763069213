import { useFeatureValue } from "@growthbook/growthbook-react";
import { useCallback, useMemo, useState } from "react";
import { Platform } from "react-native";
import { FEATURE_FLAGS } from "../feature-flags";
import { storage } from "../storage";
import {
  RECORD_HQ_AUDIO_STORAGE_KEY,
  RECORD_USE_STREAMING_STORAGE_KEY,
} from "./constants";

// These configs are not meant to be exposed to the users, but rather for internal use only.
// We currently do expose some of these configs to the users (e.g. MP3 toggle), but we will be removing
// them in the future.
export const useExperimentalRecordConfig = () => {
  const defaultRecordConfig = useFeatureValue(
    FEATURE_FLAGS.MOBILE_RECORD_CONFIG,
    {
      defaultMimeType: "audio/wav",
      enableStreaming: false,
    },
  );

  const [recordHQAudio, _setRecordHQAudio] = useState<undefined | boolean>(
    () => {
      const recordHQAudio = storage.getItem(RECORD_HQ_AUDIO_STORAGE_KEY);
      if (typeof recordHQAudio === "string") {
        return recordHQAudio === "true";
      }
      return undefined;
    },
  );

  const setRecordHQAudio = useCallback((value: boolean) => {
    _setRecordHQAudio(value);
    storage.setItem(RECORD_HQ_AUDIO_STORAGE_KEY, value ? "true" : "false");
  }, []);

  const mimeType = useMemo(() => {
    // We only support mp3 on web
    if (Platform.OS === "web") {
      return "audio/mpeg";
    }
    if (typeof recordHQAudio === "boolean") {
      return recordHQAudio ? "audio/mpeg" : defaultRecordConfig.defaultMimeType;
    }
    return defaultRecordConfig.defaultMimeType;
  }, [defaultRecordConfig.defaultMimeType, recordHQAudio]);

  const [recordUseStreaming, _setRecordUseStreaming] = useState<
    undefined | boolean
  >(() => {
    const recordUseStreaming = storage.getItem(
      RECORD_USE_STREAMING_STORAGE_KEY,
    );
    if (typeof recordUseStreaming === "string") {
      return recordUseStreaming === "true";
    }
    return undefined;
  });

  const setRecordUseStreaming = useCallback((value: boolean) => {
    _setRecordUseStreaming(value);
    storage.setItem(RECORD_USE_STREAMING_STORAGE_KEY, value ? "true" : "false");
  }, []);

  const enableStreaming = useMemo(() => {
    if (typeof recordUseStreaming === "boolean") {
      return recordUseStreaming;
    }

    return defaultRecordConfig.enableStreaming;
  }, [defaultRecordConfig.enableStreaming, recordUseStreaming]);

  return useMemo(
    () => ({
      recordHQAudio,
      setRecordHQAudio,
      recordUseStreaming,
      setRecordUseStreaming,
      mimeType,
      enableStreaming,
    }),
    [
      recordHQAudio,
      mimeType,
      enableStreaming,
      setRecordHQAudio,
      recordUseStreaming,
      setRecordUseStreaming,
    ],
  );
};
