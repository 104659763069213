import type { User } from "@/graphql";
import { useCallback } from "react";
import { useSaveOptions } from "./useSaveOptions";

export const useUpdateOptionsLanguage = ({ user }: { user: User }) => {
  const language = user.preferredLanguage || "en";

  const { updateOptions, loadingUpdateOptions } = useSaveOptions({
    errorMessage: "Could not save language option",
    successMessage: "Meeting language saved successfully",
    user,
  });

  const updateLanguage = useCallback(
    (newLanguage: string) => {
      updateOptions({
        variables: {
          preferredLanguage: newLanguage,
        },
      });
    },
    [updateOptions],
  );

  return {
    language,
    updateLanguage,
    loadingUpdateOptions,
  };
};
