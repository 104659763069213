import { matchesGenericDomain } from "@firefliesai/shared-libs.utils/dist/emails/emails";

const emailMatcher =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export function isEmail(string: string) {
  return emailMatcher.test(string);
}

export function isGenericDomain(domain: string) {
  return matchesGenericDomain(domain);
}
