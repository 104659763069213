import { type ConfirmationDialogProps } from "@/components/Dialog";
import { globalModalsApi } from "../global-modals.store";

export const CONFIRMATION_DIALOG_MODAL_NAME = "confirmation-dialog-modal";

export const promptConfirmation = (
  props: Omit<ConfirmationDialogProps, "isOpen" | "close">,
) => {
  globalModalsApi.openModal(CONFIRMATION_DIALOG_MODAL_NAME, props);
};
