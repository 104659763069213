import { IconCalendar, IconSparkleGradient } from "@/assets/svg";
import { Text } from "@/components/Typography";
import { useTheme } from "@/styles";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";

export const FeedSectionHeader: FC<{
  label: string;
  subLabel?: string;
  isRecent?: boolean;
}> = ({ isRecent, label, subLabel }) => {
  const theme = useTheme();

  return (
    <View style={styles.sectionHeader}>
      {isRecent ? (
        <IconSparkleGradient width={16} height={16} />
      ) : (
        <IconCalendar
          width={16}
          height={16}
          color={theme.colors.textSecondary}
        />
      )}
      <View style={styles.sectionHeaderInfo}>
        <Text variant="body2Regular" color="textMuted">
          {isRecent ? "Recent" : label}
        </Text>
        {subLabel && (
          <>
            <Text color="textHint" variant="body2Regular">
              •
            </Text>
            <Text color="textHint" variant="body2Regular">
              {subLabel}
            </Text>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  sectionHeaderInfo: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
});
