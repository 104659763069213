import { imageMeetingMock } from "@/assets/images";
import { LoadingSpinner } from "@/components/Loading";
import { MessageView } from "@/components/Message";
import { RefreshControl } from "@/components/RefreshControl";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { useAuth } from "@/features/auth";
import { useUpload } from "@/features/upload";
import type { MeetingStatusData } from "@/graphql";
import { useGetUserMeetingsForStatusQuery } from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { endOfDay, startOfDay, sub } from "date-fns";
import { useCallback, useEffect, useRef, useState, type FC } from "react";
import type { ListRenderItem } from "react-native";
import { FlatList, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { DatePickerButton } from "../Analytics/components/DatePicker";
import { UploadingFileList } from "../Upload/components/UploadingFileList";
import type { ParamList } from "../types";
import { MeetingStatusListItem } from "./components/MeetingStatusListItem";

const renderItem: ListRenderItem<MeetingStatusData> = ({ item }) => (
  <MeetingStatusListItem key={item.objectId} meeting={item} />
);

const LIMIT = 20;

export const MeetingStatusScreen: FC<
  NativeStackScreenProps<ParamList, "MeetingStatus">
> = () => {
  const { user } = useAuth();

  const [dateRange, setDateRange] = useState(() => {
    const now = new Date();
    return [startOfDay(sub(now, { days: 30 })), endOfDay(now)] as [Date, Date];
  });

  const batchRef = useRef(1);

  const { data, loading, refetch, fetchMore } =
    useGetUserMeetingsForStatusQuery({
      variables: {
        limit: LIMIT,
        start: dateRange[0].toISOString(),
        end: dateRange[1].toISOString(),
      },
      fetchPolicy: "cache-and-network",
      onError(err) {
        toast({
          title: "Could not load meeting statuses",
          message: err.message,
          type: "error",
        });
      },
      pollInterval: 15 * 1000,
      skip: !user,
    });

  const [fetchMoreState, setFetchMoreState] = useState({
    loading: false,
    ended: false,
  });

  useEffect(() => {
    batchRef.current = 1;
    setFetchMoreState({
      loading: false,
      ended: false,
    });
  }, [dateRange]);

  const meetings = data?.getUserMeetingsForStatus?.meetings;

  const onLoadMore = useCallback(async () => {
    if (!meetings || fetchMoreState.loading || fetchMoreState.ended) return;
    setFetchMoreState({ loading: true, ended: false });
    batchRef.current += 1;
    const res = await fetchMore({
      variables: {
        batch: batchRef.current,
      },
    });
    if (
      res.data?.getUserMeetingsForStatus?.meetings &&
      res.data.getUserMeetingsForStatus.meetings.length < LIMIT
    ) {
      setFetchMoreState({ loading: false, ended: true });
    } else {
      setFetchMoreState({ loading: false, ended: false });
    }
  }, [fetchMore, fetchMoreState, meetings]);

  const theme = useTheme();

  const { uploads } = useUpload();

  useEffect(() => {
    // every time an upload is completed, we should reload
    refetch();
  }, [uploads.length, refetch]);

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text color="textHint">
          If a meeting takes too long or fails to process, please contact
          support.
        </Text>
      </View>
      {uploads.length > 0 && (
        <View style={styles.uploadContent(theme)}>
          <Text
            variant="label2Weight"
            color="textMuted"
            style={styles.uploadContentTitle}
          >
            Uploading meetings
          </Text>
          <UploadingFileList uploadTasks={uploads} />
        </View>
      )}
      <FlatList
        contentContainerStyle={styles.content}
        renderItem={renderItem}
        data={meetings as MeetingStatusData[]}
        onEndReached={onLoadMore}
        onEndReachedThreshold={0.2}
        ListFooterComponent={!fetchMoreState.ended ? <LoadingSpinner /> : null}
        ListEmptyComponent={
          !loading ? (
            <MessageView
              headerImage={imageMeetingMock}
              title="You have no meetings to be processed"
              description="Upload a recording or invite Fireflies to your meeting to get started"
            />
          ) : null
        }
        refreshControl={
          <RefreshControl
            refreshing={!!meetings && loading}
            onRefresh={refetch}
          />
        }
      />
      <SafeAreaView style={styles.footer(theme)} edges={["bottom"]}>
        <Text variant="label1Weight" color="textSecondary">
          Filters
        </Text>
        <DatePickerButton dateRange={dateRange} setDateRange={setDateRange} />
      </SafeAreaView>
    </View>
  );
};

const styles = createStyles({
  root: {
    flex: 1,
  },
  content: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  header: {
    gap: 4,
    paddingHorizontal: 24,
  },
  footer: (theme) => ({
    borderTopWidth: 1,
    borderColor: theme.colors.borderStaticSubtle,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    gap: 8,
  }),
  uploadContent: (theme) => ({
    paddingVertical: 16,
    paddingHorizontal: 16,
    gap: 8,
    backgroundColor: theme.colors.layerSubtle,
    marginTop: 16,
  }),
  uploadContentTitle: {
    textAlign: "center",
  },
});
