export const responsiveSizes = {
  medium: 840,
  large: 1336,
};

export enum Breakpoint {
  Small = 0,
  Medium = 1,
  Large = 2,
}
