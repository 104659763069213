import { createStyles, useTheme } from "@/styles";
import { useEffect, type FC } from "react";
import { View } from "react-native";
import Animated, {
  ReduceMotion,
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
} from "react-native-reanimated";

type RecordIndicatorProps = {
  invisible: boolean;
};

export const RecordIndicator: FC<RecordIndicatorProps> = ({ invisible }) => {
  const theme = useTheme();

  const animIndicator = useSharedValue(0);
  useEffect(() => {
    if (invisible) return;
    animIndicator.value = withRepeat(
      withSequence(
        withDelay(1000, withTiming(1, { duration: 500 })),
        withDelay(1000, withTiming(0, { duration: 500 })),
      ),
      -1,
      false,
      undefined,
      ReduceMotion.Never,
    );
    return () => {
      animIndicator.value = 0;
    };
  }, [invisible, animIndicator]);
  const animIndicatorStyle = useAnimatedStyle(() => {
    const size = interpolate(animIndicator.value, [0, 1], [10, 14]);
    return {
      // background toggle between transparent and red
      backgroundColor: interpolateColor(
        animIndicator.value,
        [0, 1],
        ["transparent", theme.colors.informationStaticRedSolid],
      ),
      width: size,
      height: size,
    };
  }, [theme.colors.informationStaticRedSolid]);

  return (
    <View style={styles.indicatorWrapper}>
      <Animated.View
        style={[
          styles.indicator(theme),
          animIndicatorStyle,
          invisible && styles.invisible,
        ]}
      />
    </View>
  );
};

const styles = createStyles({
  indicator: (theme) => ({
    borderRadius: 9999,
    borderColor: theme.colors.informationStaticRedSolid,
    borderWidth: 1,
    overflow: "hidden",
  }),
  indicatorWrapper: {
    width: 14,
    height: 14,
    alignItems: "center",
    justifyContent: "center",
  },
  invisible: {
    opacity: 0,
  },
});
