import { Button } from "@/components/Button";
import { LinkView } from "@/components/Link";
import { MessageView } from "@/components/Message";
import { RefreshControl } from "@/components/RefreshControl";
import { RouteNames } from "@/constants";
import { useAuth } from "@/features/auth";
import { getRemoteConstants } from "@/features/remote-constants";
import { useFetchMore, useGetFeedMeetingsQuery } from "@/graphql";
import { createStyles } from "@/styles";
import { pluralize } from "@/utils/pluralize";
import { useScrollToTop } from "@react-navigation/native";
import { useMemo, useRef, type FC } from "react";
import type { ListRenderItem } from "react-native";
import { FlatList, View } from "react-native";
import { FeedEmptyState } from "./components/FeedEmptyState";
import {
  FeedMeetingCard,
  LoadingFeedMeetingCard,
} from "./components/FeedMeetingCard";
import { FeedSectionHeader } from "./components/FeedSectionHeader";
import type { WeekSection } from "./utils";
import { getSectionLabel, groupMeetingsByWeek } from "./utils";
import { useAppReviewPrompt } from "@/features/app-review";

const LIMIT = 20;

const FeedSection: FC<{ section: WeekSection }> = ({ section }) => {
  return (
    <View style={styles.section}>
      <FeedSectionHeader
        label={getSectionLabel(section)}
        subLabel={`${section.meetings.length} ${pluralize(
          "meeting",
          section.meetings.length,
        )}`}
        isRecent={section.isRecent}
      />
      <View style={styles.sectionList}>
        {section.meetings.map((meeting) => (
          <LinkView
            key={meeting._id}
            href={{
              pathname: RouteNames.Notepad,
              params: { id: meeting._id, ref: "feed" },
            }}
            animated
          >
            <FeedMeetingCard meeting={meeting} />
          </LinkView>
        ))}
      </View>
    </View>
  );
};

const renderItems: ListRenderItem<WeekSection> = ({ item }) => (
  <FeedSection section={item} key={item.startTime.toISOString()} />
);

const loadingFeedMeetingCards = (
  <>
    {Array.from({ length: 3 }).map((_, index) => (
      <LoadingFeedMeetingCard key={index} />
    ))}
  </>
);

export const Feed: FC<{
  ListHeaderComponent?: React.ReactElement;
}> = ({ ListHeaderComponent }) => {
  const { user } = useAuth();

  const { data, fetchMore, loading, refetch, error } = useGetFeedMeetingsQuery({
    skip: !user,
    notifyOnNetworkStatusChange: true,
  });

  const [onLoadMore, fetchMoreState] = useFetchMore({
    data,
    fetchMore,
    limit: LIMIT,
    path: "getFeedMeetings.meetings",
  });

  const meetings = data?.getFeedMeetings.meetings;

  const sections = useMemo(() => {
    // filter out meetings with no summary
    return groupMeetingsByWeek(
      (meetings || []).filter((meeting) => meeting.summary.length > 0),
    );
  }, [meetings]);

  useAppReviewPrompt(!loading && !error && !!meetings?.length);

  const listRef = useRef<FlatList>(null);

  useScrollToTop(listRef);

  const isDemoMeeting =
    meetings?.[0]?._id === getRemoteConstants().DEMO_MEETING_ID;

  const shouldShowLoadingState = Boolean(
    loading && !data?.getFeedMeetings?.meetings?.length,
  );
  const shouldShowErrorState = Boolean(
    !loading && error && !data?.getFeedMeetings?.meetings,
  );

  const shouldShowEmptyState = !loading && (isDemoMeeting || !sections?.length);

  return (
    <FlatList
      ref={listRef}
      scrollEnabled={!shouldShowLoadingState}
      contentContainerStyle={styles.container}
      data={sections}
      renderItem={renderItems}
      onEndReached={onLoadMore}
      onEndReachedThreshold={0.5}
      ListHeaderComponent={ListHeaderComponent}
      ListEmptyComponent={
        shouldShowLoadingState ? loadingFeedMeetingCards : null
      }
      ListFooterComponent={
        shouldShowErrorState ? (
          <MessageView
            variant="error"
            title="Could not load your feed"
            description={error?.message}
            action={<Button onPress={() => refetch()}>Retry</Button>}
          />
        ) : shouldShowEmptyState ? (
          <FeedEmptyState />
        ) : !fetchMoreState.ended ? (
          loadingFeedMeetingCards
        ) : null
      }
      refreshControl={
        <RefreshControl
          refreshing={!!data?.getFeedMeetings && loading}
          onRefresh={refetch}
        />
      }
    />
  );
};

const styles = createStyles({
  section: {
    flexDirection: "column",
  },
  sectionList: {
    flexDirection: "column",
  },
  container: {
    paddingHorizontal: 20,
    paddingBottom: 64,
    paddingTop: 24,
    width: "100%",
    maxWidth: 724,
    alignSelf: "center",
    gap: 48,
  },
});
