import { Button } from "@/components/Button";
import { Dialog } from "@/components/Dialog";
import { TextInput } from "@/components/Input";
import { toast } from "@/components/Toast";
import { useUpdateMeetingTitleMutation, type Meeting } from "@/graphql";
import { useState, type FC } from "react";

const MeetingRenameDialogContent: FC<{
  meeting: Meeting;
  close: () => void;
}> = ({ meeting, close }) => {
  const [title, setTitle] = useState(meeting.title);
  const formattedTitle = title.trim();

  const [updateMeetingTitle, { loading }] = useUpdateMeetingTitleMutation({
    variables: {
      meetingId: meeting.id,
      title: formattedTitle,
    },
    onCompleted() {
      toast({
        message: "Meeting title updated",
        type: "success",
      });
      close();
    },
    onError(error) {
      toast({
        title: "Failed to update meeting title",
        message: error.message,
        type: "error",
      });
    },
    update(cache, { data }) {
      const newTitle = data?.updateMeetingTitle;
      if (!newTitle) {
        return;
      }
      cache.modify({
        id: cache.identify(meeting),
        fields: {
          title() {
            return newTitle;
          },
        },
      });
      cache.modify({
        id: cache.identify({
          __typename: "Source",
          id: meeting.id,
          parseId: meeting.id,
        }),
        fields: {
          title() {
            return newTitle;
          },
        },
      });
    },
  });

  const onRename = () => {
    if (!formattedTitle || loading) {
      return;
    }
    updateMeetingTitle();
  };

  return (
    <>
      <Dialog.Header>Rename Meeting</Dialog.Header>
      <Dialog.Body>
        <TextInput
          aria-label="Meeting Title"
          placeholder="Enter the new meeting title"
          value={title}
          onValueChange={setTitle}
          disabled={loading}
        />
        <Button onPress={onRename} disabled={!formattedTitle || loading}>
          Rename
        </Button>
      </Dialog.Body>
    </>
  );
};

export const MeetingRenameDialog: FC<{
  meeting: Meeting;
  isOpen: boolean;
  close: () => void;
}> = ({ meeting, isOpen, close }) => {
  return (
    <Dialog.Root isOpen={isOpen} close={close}>
      <MeetingRenameDialogContent meeting={meeting} close={close} />
    </Dialog.Root>
  );
};
