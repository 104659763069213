export const GLOBAL = {
  purple25: "#FAFAFF",
  purple50: "#F4F3FF",
  purple100: "#EBE9FE",
  purple200: "#D9D6FE",
  purple300: "#BDB4FE",
  purple400: "#9B8AFB",
  purple500: "#7A5AF8",
  purple600: "#6938EF",
  purple700: "#5925DC",
  purple800: "#4A1FB8",
  purple900: "#3E1C96",
  grey25: "#FCFCFD",
  grey50: "#F9FAFB",
  grey100: "#F2F4F7",
  grey200: "#EAECF0",
  grey300: "#D0D5DD",
  grey400: "#98A2B3",
  grey500: "#667085",
  grey600: "#475467",
  grey700: "#344054",
  grey800: "#1D2939",
  grey900: "#101828",
  green25: "#F6FEF9",
  green50: "#ECFDF3",
  green100: "#D1FADF",
  green200: "#A6F4C5",
  green300: "#6CE9A6",
  green400: "#32D583",
  green500: "#12B76A",
  green600: "#039855",
  green700: "#027948",
  green800: "#05603A",
  green900: "#054F31",
  yellow25: "#FFFCF5",
  yellow50: "#FFFAEB",
  yellow100: "#FEEFC6",
  yellow200: "#FEDF89",
  yellow300: "#FEC84B",
  yellow400: "#FDB022",
  yellow500: "#F79009",
  yellow600: "#DC6803",
  yellow700: "#B54708",
  yellow800: "#93370D",
  yellow900: "#792E0D",
  red25: "#FFFAFA",
  red50: "#FEF3F2",
  red100: "#FEE4E2",
  red200: "#FECDC9",
  red300: "#FDA29B",
  red400: "#F97066",
  red500: "#F04438",
  red600: "#D92D20",
  red700: "#B32318",
  red800: "#912018",
  red900: "#7A271A",
  blue25: "#F5FAFF",
  blue50: "#EFF8FF",
  blue100: "#D1E9FF",
  blue200: "#B2DDFF",
  blue300: "#84CAFF",
  blue400: "#53B1FD",
  blue500: "#2E90FA",
  blue600: "#1570EF",
  blue700: "#175CD3",
  blue800: "#1849A9",
  blue900: "#194185",
  pink25: "#FEF6FB",
  pink50: "#FDF2FA",
  pink100: "#FCE7F6",
  pink200: "#FCCEEE",
  pink300: "#FAA7E0",
  pink400: "#F670C7",
  pink500: "#EE46BC",
  pink600: "#DD2590",
  pink700: "#C11574",
  pink800: "#9E165F",
  pink900: "#851651",
  orange25: "#FEFAF5",
  orange50: "#FEF6EE",
  orange100: "#FDEAD7",
  orange200: "#F9DBAF",
  orange300: "#F7B27A",
  orange400: "#F38744",
  orange500: "#EF6820",
  orange600: "#E04F16",
  orange700: "#B93815",
  orange800: "#932F19",
  orange900: "#772917",
  indigo25: "#F5F8FF",
  indigo50: "#EEF4FF",
  indigo100: "#E0EAFF",
  indigo200: "#C7D7FE",
  indigo300: "#A4BCFD",
  indigo400: "#8098F9",
  indigo500: "#6172F3",
  indigo600: "#444CE7",
  indigo700: "#3538CD",
  indigo800: "#2D31A6",
  indigo900: "#2D3282",
  cyan25: "#F5FEFF",
  cyan50: "#ECFDFF",
  cyan100: "#CFF9FE",
  cyan200: "#A5F0FC",
  cyan300: "#67E3F9",
  cyan400: "#22CCEE",
  cyan500: "#06AED4",
  cyan600: "#088AB2",
  cyan700: "#0E7090",
  cyan800: "#155B75",
  cyan900: "#164C63",
  teal25: "#F6FEFC",
  teal50: "#F0FDF9",
  teal100: "#CCFBEF",
  teal200: "#99F6E0",
  teal300: "#5FE9D0",
  teal400: "#2ED3B7",
  teal500: "#15B79E",
  teal600: "#0E9384",
  teal700: "#107569",
  teal800: "#125D56",
  teal900: "#134E48",
};

export const LIGHT = {
  pageBackground: "#FFFFFF",
  layerDefault: "#FFFFFF",
  layerSubtle: GLOBAL.grey25,
  layerMuted: GLOBAL.grey50,
  layerStrong: GLOBAL.grey100,
  layerBrand: GLOBAL.purple500,
  layerBrandLight1: GLOBAL.purple25,
  layerBrandLight2: GLOBAL.purple50,
  layerContrast: "#0C0C0D",
  textPrimary: GLOBAL.grey900,
  textSecondary: GLOBAL.grey700,
  textMuted: GLOBAL.grey500,
  textHint: GLOBAL.grey400,
  textDisabled: GLOBAL.grey300,
  textBrand: GLOBAL.purple700,
  textContrast: "#FFFFFF",
  interactivePrimaryDefault: GLOBAL.purple600,
  interactivePrimaryHover: GLOBAL.purple700,
  interactivePrimaryDisabled: GLOBAL.purple200,
  interactiveSecondaryDefault: GLOBAL.purple50,
  interactiveSecondaryHover: GLOBAL.purple100,
  interactiveSecondaryDisabled: GLOBAL.purple25,
  interactiveNeutral1Default: "#FFFFFF",
  interactiveNeutral1Hover: GLOBAL.grey50,
  interactiveNeutral1Disabled: "#FFFFFF",
  interactiveNeutral2Default: GLOBAL.grey50,
  interactiveNeutral2Hover: GLOBAL.grey100,
  interactiveNeutral2Disabled: GLOBAL.grey25,
  interactiveNeutral3Default: GLOBAL.grey100,
  interactiveNeutral3Hover: GLOBAL.grey200,
  interactiveNeutral3Disabled: GLOBAL.grey50,
  interactiveNeutralAlphaDefault: "#FFFFFF03",
  interactiveNeutralAlphaHover: "#37597B08",
  interactiveDangerPrimaryDefault: GLOBAL.red400,
  interactiveDangerPrimaryHover: GLOBAL.red500,
  interactiveDangerPrimaryDisabled: GLOBAL.red200,
  interactiveDangerSecondaryDefault: GLOBAL.red50,
  interactiveDangerSecondaryHover: GLOBAL.red100,
  interactiveDangerSecondaryDisabled: GLOBAL.red25,
  commandContrastDefault: "#FFFFFF",
  commandContrastHover: "#FFFFFF",
  commandContrastDisabled: "#FFFFFF",
  commandPrimaryDefault: GLOBAL.purple700,
  commandPrimaryHover: GLOBAL.purple800,
  commandPrimaryDisabled: GLOBAL.purple300,
  commandNeutralDefault: GLOBAL.grey600,
  commandNeutralHover: GLOBAL.grey800,
  commandNeutralDisabled: GLOBAL.grey300,
  commandHintDefault: GLOBAL.grey400,
  commandHintHover: GLOBAL.grey600,
  commandHintDisabled: GLOBAL.grey300,
  commandDangerDefault: GLOBAL.red700,
  commandDangerHover: GLOBAL.red800,
  commandDangerDisabled: GLOBAL.red300,
  borderStaticPage: "#FFFFFF",
  borderStaticSubtle: GLOBAL.grey100,
  borderStaticDefault: GLOBAL.grey200,
  borderStaticBrand: GLOBAL.purple500,
  borderStaticTransparent: "rgba(255, 255, 255, 0)",
  borderInteractiveDefault: GLOBAL.grey200,
  borderInteractiveHover: GLOBAL.grey300,
  borderInteractiveActive: GLOBAL.purple600,
  borderInteractiveDisabled: GLOBAL.grey100,
  borderStaticDanger: GLOBAL.red500,
  informationStaticGreenSubtle: GLOBAL.green50,
  informationStaticGreenSolid: GLOBAL.green300,
  informationStaticGreenText: GLOBAL.green700,
  informationStaticYellowSubtle: GLOBAL.yellow50,
  informationStaticYellowSolid: GLOBAL.yellow300,
  informationStaticYellowText: GLOBAL.yellow700,
  informationStaticBlueSubtle: GLOBAL.blue50,
  informationStaticBlueSolid: GLOBAL.blue300,
  informationStaticBlueText: GLOBAL.blue700,
  informationStaticRedSubtle: GLOBAL.red50,
  informationStaticRedSolid: GLOBAL.red300,
  informationStaticRedText: GLOBAL.red700,
  informationInteractiveGreenDefault: GLOBAL.green400,
  informationInteractiveGreenHover: GLOBAL.green500,
  informationInteractiveGreenDisabled: GLOBAL.green200,
  informationInteractiveYellowDefault: GLOBAL.yellow400,
  informationInteractiveYellowHover: GLOBAL.yellow500,
  informationInteractiveYellowDisabled: GLOBAL.yellow200,
  informationInteractiveBlueDefault: GLOBAL.blue400,
  informationInteractiveBlueHover: GLOBAL.blue500,
  informationInteractiveBlueDisabled: GLOBAL.blue200,
  miscellaneousOverlay: "rgba(12, 12, 13, 0.56)",
  miscellaneousSkeletonBackground: GLOBAL.grey50,
  miscellaneousSkeletonElement: GLOBAL.grey200,
  rainbowStaticPurpleSubtle: GLOBAL.purple50,
  rainbowStaticPurpleSolid: GLOBAL.purple300,
  rainbowStaticPurpleText: GLOBAL.purple700,
  rainbowStaticPinkSubtle: GLOBAL.pink50,
  rainbowStaticPinkSolid: GLOBAL.pink300,
  rainbowStaticPinkText: GLOBAL.pink700,
  rainbowStaticOrangeSubtle: GLOBAL.orange50,
  rainbowStaticOrangeSolid: GLOBAL.orange300,
  rainbowStaticOrangeText: GLOBAL.orange700,
  rainbowStaticIndigoSubtle: GLOBAL.indigo50,
  rainbowStaticIndigoSolid: GLOBAL.indigo300,
  rainbowStaticIndigoText: GLOBAL.indigo700,
  rainbowStaticCyanSubtle: GLOBAL.cyan50,
  rainbowStaticCyanSolid: GLOBAL.cyan300,
  rainbowStaticCyanText: GLOBAL.cyan700,
  rainbowStaticTealSubtle: GLOBAL.teal50,
  rainbowStaticTealSolid: GLOBAL.teal300,
  rainbowStaticTealText: GLOBAL.teal700,
  rainbowInteractivePurpleDefault: GLOBAL.purple400,
  rainbowInteractivePurpleHover: GLOBAL.purple500,
  rainbowInteractivePurpleDisabled: GLOBAL.purple200,
  rainbowInteractivePinkDefault: GLOBAL.pink400,
  rainbowInteractivePinkHover: GLOBAL.pink500,
  rainbowInteractivePinkDisabled: GLOBAL.pink200,
  rainbowInteractiveOrangeDefault: GLOBAL.orange400,
  rainbowInteractiveOrangeHover: GLOBAL.orange500,
  rainbowInteractiveOrangeDisabled: GLOBAL.orange200,
  rainbowInteractiveIndigoDefault: GLOBAL.indigo400,
  rainbowInteractiveIndigoHover: GLOBAL.indigo500,
  rainbowInteractiveIndigoDisabled: GLOBAL.indigo200,
  rainbowInteractiveCyanDefault: GLOBAL.cyan400,
  rainbowInteractiveCyanHover: GLOBAL.cyan500,
  rainbowInteractiveCyanDisabled: GLOBAL.cyan200,
  rainbowInteractiveTealDefault: GLOBAL.teal400,
  rainbowInteractiveTealHover: GLOBAL.teal500,
  rainbowInteractiveTealDisabled: GLOBAL.teal200,
};

export const DARK = {
  pageBackground: "#0C0C0D",
  layerDefault: "#0C0C0D",
  layerSubtle: GLOBAL.grey800,
  layerMuted: GLOBAL.grey700,
  layerStrong: GLOBAL.grey600,
  layerBrand: GLOBAL.purple800,
  layerBrandLight1: GLOBAL.purple600,
  layerBrandLight2: GLOBAL.purple700,
  layerContrast: "#FFFFFF",
  textPrimary: GLOBAL.grey25,
  textSecondary: GLOBAL.grey50,
  textMuted: GLOBAL.grey100,
  textHint: GLOBAL.grey200,
  textDisabled: GLOBAL.grey300,
  textBrand: GLOBAL.purple300,
  textContrast: "#0C0C0D",
  interactivePrimaryDefault: GLOBAL.purple700,
  interactivePrimaryHover: GLOBAL.purple800,
  interactivePrimaryDisabled: GLOBAL.purple400,
  interactiveSecondaryDefault: GLOBAL.purple800,
  interactiveSecondaryHover: GLOBAL.purple700,
  interactiveSecondaryDisabled: GLOBAL.purple600,
  interactiveNeutral1Default: "#1D2939",
  interactiveNeutral1Hover: GLOBAL.grey700,
  interactiveNeutral1Disabled: "#1D2939",
  interactiveNeutral2Default: GLOBAL.grey700,
  interactiveNeutral2Hover: GLOBAL.grey600,
  interactiveNeutral2Disabled: GLOBAL.grey800,
  interactiveNeutral3Default: GLOBAL.grey600,
  interactiveNeutral3Hover: GLOBAL.grey500,
  interactiveNeutral3Disabled: GLOBAL.grey700,
  interactiveNeutralAlphaDefault: "#37597B08",
  interactiveNeutralAlphaHover: "#FFFFFF03",
  interactiveDangerPrimaryDefault: GLOBAL.red700,
  interactiveDangerPrimaryHover: GLOBAL.red800,
  interactiveDangerPrimaryDisabled: GLOBAL.red500,
  interactiveDangerSecondaryDefault: GLOBAL.red800,
  interactiveDangerSecondaryHover: GLOBAL.red700,
  interactiveDangerSecondaryDisabled: GLOBAL.red600,
  commandContrastDefault: "#FFFFFF",
  commandContrastHover: "#FFFFFF",
  commandContrastDisabled: "#FFFFFF",
  commandPrimaryDefault: GLOBAL.purple500,
  commandPrimaryHover: GLOBAL.purple600,
  commandPrimaryDisabled: GLOBAL.purple400,
  commandNeutralDefault: GLOBAL.grey500,
  commandNeutralHover: GLOBAL.grey400,
  commandNeutralDisabled: GLOBAL.grey600,
  commandHintDefault: GLOBAL.grey300,
  commandHintHover: GLOBAL.grey200,
  commandHintDisabled: GLOBAL.grey400,
  commandDangerDefault: GLOBAL.red600,
  commandDangerHover: GLOBAL.red500,
  commandDangerDisabled: GLOBAL.red400,
  borderStaticPage: "#1D2939",
  borderStaticSubtle: GLOBAL.grey600,
  borderStaticDefault: GLOBAL.grey500,
  borderStaticBrand: GLOBAL.purple700,
  borderStaticTransparent: "rgba(0, 0, 0, 0)",
  borderInteractiveDefault: GLOBAL.grey500,
  borderInteractiveHover: GLOBAL.grey400,
  borderInteractiveActive: GLOBAL.purple700,
  borderInteractiveDisabled: GLOBAL.grey600,
  borderStaticDanger: GLOBAL.red600,
  informationStaticGreenSubtle: GLOBAL.green50,
  informationStaticGreenSolid: GLOBAL.green300,
  informationStaticGreenText: GLOBAL.green700,
  informationStaticYellowSubtle: GLOBAL.yellow50,
  informationStaticYellowSolid: GLOBAL.yellow300,
  informationStaticYellowText: GLOBAL.yellow700,
  informationStaticBlueSubtle: GLOBAL.blue50,
  informationStaticBlueSolid: GLOBAL.blue300,
  informationStaticBlueText: GLOBAL.blue700,
  informationStaticRedSubtle: GLOBAL.red50,
  informationStaticRedSolid: GLOBAL.red300,
  informationStaticRedText: GLOBAL.red700,
  informationInteractiveGreenDefault: GLOBAL.green400,
  informationInteractiveGreenHover: GLOBAL.green500,
  informationInteractiveGreenDisabled: GLOBAL.green200,
  informationInteractiveYellowDefault: GLOBAL.yellow400,
  informationInteractiveYellowHover: GLOBAL.yellow500,
  informationInteractiveYellowDisabled: GLOBAL.yellow200,
  informationInteractiveBlueDefault: GLOBAL.blue400,
  informationInteractiveBlueHover: GLOBAL.blue500,
  informationInteractiveBlueDisabled: GLOBAL.blue200,
  miscellaneousOverlay: "rgba(12, 12, 13, 0.80)",
  miscellaneousSkeletonBackground: GLOBAL.grey800,
  miscellaneousSkeletonElement: GLOBAL.grey600,
  rainbowStaticPurpleSubtle: GLOBAL.purple50,
  rainbowStaticPurpleSolid: GLOBAL.purple300,
  rainbowStaticPurpleText: GLOBAL.purple700,
  rainbowStaticPinkSubtle: GLOBAL.pink50,
  rainbowStaticPinkSolid: GLOBAL.pink300,
  rainbowStaticPinkText: GLOBAL.pink700,
  rainbowStaticOrangeSubtle: GLOBAL.orange50,
  rainbowStaticOrangeSolid: GLOBAL.orange300,
  rainbowStaticOrangeText: GLOBAL.orange700,
  rainbowStaticIndigoSubtle: GLOBAL.indigo50,
  rainbowStaticIndigoSolid: GLOBAL.indigo300,
  rainbowStaticIndigoText: GLOBAL.indigo700,
  rainbowStaticCyanSubtle: GLOBAL.cyan50,
  rainbowStaticCyanSolid: GLOBAL.cyan300,
  rainbowStaticCyanText: GLOBAL.cyan700,
  rainbowStaticTealSubtle: GLOBAL.teal50,
  rainbowStaticTealSolid: GLOBAL.teal300,
  rainbowStaticTealText: GLOBAL.teal700,
  rainbowInteractivePurpleDefault: GLOBAL.purple400,
  rainbowInteractivePurpleHover: GLOBAL.purple500,
  rainbowInteractivePurpleDisabled: GLOBAL.purple200,
  rainbowInteractivePinkDefault: GLOBAL.pink400,
  rainbowInteractivePinkHover: GLOBAL.pink500,
  rainbowInteractivePinkDisabled: GLOBAL.pink200,
  rainbowInteractiveOrangeDefault: GLOBAL.orange400,
  rainbowInteractiveOrangeHover: GLOBAL.orange500,
  rainbowInteractiveOrangeDisabled: GLOBAL.orange200,
  rainbowInteractiveIndigoDefault: GLOBAL.indigo400,
  rainbowInteractiveIndigoHover: GLOBAL.indigo500,
  rainbowInteractiveIndigoDisabled: GLOBAL.indigo200,
  rainbowInteractiveCyanDefault: GLOBAL.cyan400,
  rainbowInteractiveCyanHover: GLOBAL.cyan500,
  rainbowInteractiveCyanDisabled: GLOBAL.cyan200,
  rainbowInteractiveTealDefault: GLOBAL.teal400,
  rainbowInteractiveTealHover: GLOBAL.teal500,
  rainbowInteractiveTealDisabled: GLOBAL.teal200,
};

export type RainbowColor =
  | "Orange"
  | "Pink"
  | "Purple"
  | "Indigo"
  | "Cyan"
  | "Teal";
