import type { Subscription } from "expo-modules-core";
import { EventEmitter, NativeModulesProxy } from "expo-modules-core";

// Import the native module. On web, it will be resolved to NativeCode.web.ts
// and on native platforms to NativeCode.ts
import { Linking } from "react-native";
import { ChangeEventPayload } from "./src/NativeCode.types";
import NativeCodeModule from "./src/NativeCodeModule";

// Get the native constant value.

const emitter = new EventEmitter(
  NativeCodeModule ?? NativeModulesProxy.NativeCode,
);

export function addChangeListener(
  listener: (event: ChangeEventPayload) => void,
): Subscription {
  return emitter.addListener<ChangeEventPayload>("onChange", listener);
}

export function openInBrowser(url: string) {
  try {
    NativeCodeModule.openInBrowser(url);
  } catch {
    Linking.openURL(url);
  }
}

export { ChangeEventPayload };
