import { useHidePaidFeatures } from "@/features/billing";
import { Breakpoint, useTheme, vars } from "@/styles";
import type {
  BottomTabBarProps,
  BottomTabNavigationOptions,
} from "@react-navigation/bottom-tabs";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import AnalyticsRouteConfig from "../Analytics";
import HomeRouteConfig from "../Home";
import LibraryRouteConfig from "../Library";
import NotebookRouteConfig from "../Notebook";
import { useIsNotificationBarsVisible } from "../components/NotificationBars";
import type { ParamList } from "../types";
import { SIDEBAR_WIDTH, Sidebar } from "./components/Sidebar";
import { TabBar } from "./components/TabBar";
import { useAuth } from "@/features/auth";
import { usePushNotificationRegistration } from "@/features/push-notifications";

const MainStack = createBottomTabNavigator<ParamList>();

export const MainScreen = () => {
  const hidePaidFeatures = useHidePaidFeatures();
  //const phoneCallEnabled = useFeatureValue(FEATURE_FLAGS.PHONE_CALL, false);

  const { user } = useAuth();
  usePushNotificationRegistration(user);

  const theme = useTheme();

  const isLargeScreen = theme.breakpoint >= Breakpoint.Medium;

  const tabBar = useCallback(
    (props: BottomTabBarProps) =>
      isLargeScreen ? <Sidebar {...props} /> : <TabBar {...props} />,
    [isLargeScreen],
  );

  // NOTE: navbar navigator does not account for the notification bar
  // while the root navigator does
  const isNotificationBarVisible = useIsNotificationBarsVisible();
  const screenOptions: BottomTabNavigationOptions = useMemo(
    () => ({
      headerTitleStyle: vars.typography.title1Weight,
      headerShadowVisible: false,
      ...(isNotificationBarVisible && {
        headerStatusBarHeight: 0,
      }),
    }),
    [isNotificationBarVisible],
  );

  return (
    <View style={styles.root}>
      <MainStack.Navigator
        screenOptions={screenOptions}
        tabBar={tabBar}
        sceneContainerStyle={isLargeScreen ? styles.largeContainer : undefined}
      >
        <MainStack.Screen {...HomeRouteConfig} />
        <MainStack.Screen {...NotebookRouteConfig} />
        <MainStack.Screen {...LibraryRouteConfig} />
        {!hidePaidFeatures && <MainStack.Screen {...AnalyticsRouteConfig} />}
        {/* {Platform.OS !== "web" && phoneCallEnabled && !hidePaidFeatures && (
          <MainStack.Screen {...PhoneRouteConfig} />
        )} */}
      </MainStack.Navigator>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: "row",
  },
  largeContainer: {
    paddingLeft: SIDEBAR_WIDTH,
  },
});
