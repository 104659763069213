import { Config } from "@/constants";
import { type FC } from "react";

export const DashboardWebview: FC<{
  path: string;
}> = ({ path }) => {
  const uri = `${Config.DASHBOARD_URL}${path}`;

  return (
    <iframe
      src={uri}
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        outline: "none",
      }}
    />
  );
};
