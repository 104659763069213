import type { FC, PropsWithChildren } from "react";
import { Platform, StyleSheet } from "react-native";
import * as NativeCode from "@/modules/native-code";
import { Text } from "./Text";
import type { TextProps } from "./types";

interface LinkTextProps {
  href: string;
}

export const LinkText: FC<PropsWithChildren<TextProps & LinkTextProps>> = ({
  children,
  href,
  ...props
}) => {
  return (
    <Text
      {...props}
      style={styles.base}
      {...(Platform.OS === "web"
        ? {
            href,
            target: "_blank",
          }
        : {
            onPress: () => NativeCode.openInBrowser(href),
          })}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  base: {
    textDecorationLine: "underline",
  },
});
