import { calculateCurrentIndex } from "@/features/transcript";
import { memo, useEffect, useState, type FC } from "react";
import { StyleSheet, View } from "react-native";
import {
  LoadingTranscriptCaptionList,
  TranscriptCaptionList,
} from "./TranscriptCaptionList";
import { TranscriptContext } from "./TranscriptContext";
import type { TranscriptProps } from "./types";

export const Transcript = memo<TranscriptProps>(function Transcript({
  captions,
  onCaptionClick,
  onCaptionSpeakerClick,
  marks,
  includedIndexes,
  listContentStyle,
  player,
}) {
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!player) return;
    let previousArrayIndex: number | undefined = undefined;
    const onTimeUpdate = () => {
      calculateCurrentIndex(
        {
          currentTime: player.currentTime,
          captions,
          previousArrayIndex,
        },
        (result) => {
          if (!result) {
            setCurrentIndex(undefined);
            return;
          }
          const { arrayIndex, captionIndex } = result;
          setCurrentIndex(captionIndex);
          previousArrayIndex = arrayIndex;
        },
      );
    };
    player.addEventListener("timeupdate", onTimeUpdate);
    return () => {
      player.removeEventListener("timeupdate", onTimeUpdate);
    };
  }, [player, captions]);

  return (
    <TranscriptContext.Provider
      value={{
        onCaptionClick,
        onCaptionSpeakerClick,
        marks,
        includedIndexes,
        currentIndex,
        player,
      }}
    >
      <View style={styles.root}>
        <TranscriptCaptionList
          captions={captions}
          listContentStyle={listContentStyle}
        />
      </View>
    </TranscriptContext.Provider>
  );
});

export const LoadingTranscript: FC = () => {
  return (
    <View style={styles.root}>
      <LoadingTranscriptCaptionList />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
