import type { StackRouteConfig } from "../types";
import { YouScreen } from "./YouScreen";

const YouRouteConfig: StackRouteConfig<"You"> = {
  name: "You",
  component: YouScreen,
  options: {
    title: "Profile",
  },
};

export default YouRouteConfig;

export { YouHeaderButton } from "./YouHeaderButton";
