import { createContext } from "react";
import type { TranscriptProps } from "./types";

interface ContextValue {
  onCaptionClick?: TranscriptProps["onCaptionClick"];
  onCaptionSpeakerClick?: TranscriptProps["onCaptionSpeakerClick"];
  marks?: TranscriptProps["marks"];
  /** caption indexes to filtered in **/
  includedIndexes?: TranscriptProps["includedIndexes"];
  player?: TranscriptProps["player"];
  currentIndex: number | undefined;
}

export const TranscriptContext = createContext({} as ContextValue);
