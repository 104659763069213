import { IconChevronDown } from "@/assets/svg";
import { Button } from "@/components/Button";
import type { Caption } from "@/features/transcript";
import { useTheme } from "@/styles";
import { toHHMMSS, toHourMinuteSecond } from "@/utils/time";
import type { ListRenderItem } from "@shopify/flash-list";
import { FlashList } from "@shopify/flash-list";
import type { FC, RefObject } from "react";
import {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { StyleProp, ViewStyle } from "react-native";
import {
  InteractionManager,
  Text as RNText,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import Animated from "react-native-reanimated";
import { Avatar } from "../Avatar";
import { SkeletonLoading } from "../Loading";
import { Text } from "../Typography";
import { TranscriptContext } from "./TranscriptContext";
import type { CaptionIndexToGroupCaptionIndex, GroupedCaption } from "./types";
import { AutoscrollProvider, useAutoscroll } from "./useAutoscroll";
import { mergeCaptions } from "./utils";

const MAX_SENTENCES = 4;
const MAX_WORDS = 150;
const AnimatedText = Animated.createAnimatedComponent(Text);
const ESTIMATED_ITEM_SIZE = 200;

const CaptionSentence: FC<{
  caption: Caption;
}> = ({ caption }) => {
  const { onCaptionClick, marks, currentIndex } = useContext(TranscriptContext);

  const isCurrent = caption.index === currentIndex;

  const onClick = onCaptionClick ? () => onCaptionClick(caption) : undefined;

  const theme = useTheme();

  // Highlight search query text in caption
  const textEl = useMemo(() => {
    const mark = marks?.[caption.index];
    if (!mark) return caption.text;

    const elements = [];
    let index = 0;
    let lastIndex = 0;
    const captionText = caption.text.toLowerCase();
    while (index !== -1) {
      let length: number;
      [length, index] = mark.reduce(
        (acc, match) => {
          const matchIndex = captionText.indexOf(
            match.word.toLowerCase(),
            lastIndex,
          );
          if (matchIndex === -1) return acc;
          const next = [match.word.length, matchIndex] as [
            length: number,
            index: number,
          ];
          if (acc[1] === -1) return next;
          // find the nearest match among all matches
          if (matchIndex < acc[1]) return next;
          return acc;
        },
        [0, -1] as [length: number, index: number],
      );

      if (index !== -1) {
        if (index > lastIndex) {
          elements.push(caption.text.substring(lastIndex, index));
        }
        elements.push(
          <RNText
            style={
              mark[0].color
                ? {
                    backgroundColor:
                      theme.colors[`rainbowStatic${mark[0].color}Subtle`],
                    color: theme.colors[`rainbowStatic${mark[0].color}Text`],
                  }
                : styles.markedTextDefault
            }
            key={index}
          >
            {caption.text.substring(index, index + length)}
          </RNText>,
        );
        lastIndex = index + length;
      }
    }
    if (lastIndex < caption.text.length) {
      elements.push(caption.text.substring(lastIndex));
    }
    return elements;
  }, [caption, marks, theme]);

  const [isPressed, setIsPressed] = useState(false);

  return (
    <Text
      variant="body1LongRegular"
      color="textSecondary"
      style={[
        isCurrent && styles.sentenceCurrent,
        isPressed && styles.sentencePressed,
      ]}
      onPress={onClick}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
    >
      {textEl}
    </Text>
  );
};

const TranscriptCaptionListItem = memo<{
  groupedCaption: GroupedCaption;
}>(function TranscriptCaptionListItem({ groupedCaption }) {
  const { onCaptionClick, onCaptionSpeakerClick } =
    useContext(TranscriptContext);

  const theme = useTheme();

  return (
    <View
      style={styles.item}
      aria-label={`${groupedCaption.speakerName} ${toHourMinuteSecond(
        groupedCaption.startTime,
      )} ${groupedCaption.text}`}
      role="listitem"
    >
      <View style={styles.itemHeader}>
        <TouchableOpacity
          onPress={() => onCaptionSpeakerClick?.(groupedCaption)}
          style={styles.speaker}
        >
          <View style={styles.speakerInfo}>
            <Avatar
              name={groupedCaption.speakerName}
              size={20}
              shape="square"
            />
            <Text variant="label2Weight" color="textSecondary">
              {groupedCaption.speakerName}
            </Text>
          </View>
          {onCaptionSpeakerClick && (
            <IconChevronDown
              width={16}
              height={16}
              color={theme.colors.textSecondary}
            />
          )}
        </TouchableOpacity>
        <Text color="textHint" variant="body2Regular" fontSize={8}>
          •
        </Text>
        <Text
          variant="body2Regular"
          color="informationStaticBlueText"
          onPress={() => onCaptionClick?.(groupedCaption.captions[0])}
          style={styles.timestamp}
        >
          {toHHMMSS(groupedCaption.startTime)}
        </Text>
      </View>
      <AnimatedText style={styles.transcriptParagraph}>
        {groupedCaption.captions.map((caption) => (
          <Fragment key={caption.index}>
            <CaptionSentence caption={caption} />{" "}
          </Fragment>
        ))}
      </AnimatedText>
    </View>
  );
});

const renderItem: ListRenderItem<GroupedCaption> = ({ item }) => (
  <TranscriptCaptionListItem groupedCaption={item} />
);

const ItemSeparatorComponent: FC = () => <View style={styles.sep} />;

const TranscriptCaptionListInner: FC<{
  listRef: RefObject<FlashList<GroupedCaption>>;
  groupedCaptions: GroupedCaption[];
  listContentStyle?: StyleProp<ViewStyle>;
}> = ({ listRef, groupedCaptions, listContentStyle }) => {
  const { currentIndex, player } = useContext(TranscriptContext);

  const { onScroll, enabled, setEnabled, scrollToCurrentCaption } =
    useAutoscroll();

  const forceScroll = useCallback(() => {
    scrollToCurrentCaption(currentIndex);
    setEnabled(true);
  }, [currentIndex, scrollToCurrentCaption, setEnabled]);

  return (
    <>
      <FlashList
        ref={listRef}
        contentContainerStyle={
          listContentStyle
            ? StyleSheet.flatten([styles.listContent, listContentStyle])
            : styles.listContent
        }
        data={groupedCaptions}
        renderItem={renderItem}
        onScroll={onScroll}
        ItemSeparatorComponent={ItemSeparatorComponent}
        role="list"
        estimatedItemSize={ESTIMATED_ITEM_SIZE}
      />
      {!enabled && !!player && (
        <View style={styles.syncListContainer}>
          <Button
            style={styles.syncListPanel}
            onPress={forceScroll}
            variant="outlined"
          >
            Sync with audio
          </Button>
        </View>
      )}
    </>
  );
};

export const TranscriptCaptionList: FC<{
  captions: Caption[];
  listContentStyle?: StyleProp<ViewStyle>;
}> = ({ captions, listContentStyle }) => {
  const ref = useRef<FlashList<GroupedCaption>>(null);

  const { includedIndexes } = useContext(TranscriptContext);
  const [groupedCaptions, setGroupedCaptions] = useState<GroupedCaption[]>([]);

  const captionIndexToGroupCaptionIndexRef =
    useRef<CaptionIndexToGroupCaptionIndex>([]);

  /**
   * Merge captions that are close together in time
   * and have the same speaker.
   */
  useEffect(() => {
    const interaction = InteractionManager.runAfterInteractions(() => {
      mergeCaptions(
        captions,
        includedIndexes,
        MAX_SENTENCES,
        MAX_WORDS,
        (merged, captionIndexToGroupCaptionIndex) => {
          setGroupedCaptions(merged);
          captionIndexToGroupCaptionIndexRef.current =
            captionIndexToGroupCaptionIndex;
        },
      );
    });
    return () => interaction.cancel();
  }, [captions, includedIndexes]);

  return (
    <View style={styles.root}>
      <AutoscrollProvider
        listRef={ref}
        captionIndexToGroupCaptionIndexRef={captionIndexToGroupCaptionIndexRef}
        captions={captions}
      >
        <TranscriptCaptionListInner
          listRef={ref}
          groupedCaptions={groupedCaptions}
          listContentStyle={listContentStyle}
        />
      </AutoscrollProvider>
    </View>
  );
};

const LoadingTranscriptCaptionListItem: FC = () => {
  return (
    <View style={styles.item}>
      <View style={styles.itemHeader}>
        <View style={styles.speaker}>
          <View style={styles.speakerInfo}>
            <SkeletonLoading width={20} height={20} />
            <SkeletonLoading width={80} height={20} />
          </View>
        </View>
        <Text color="textHint" variant="body2Regular" fontSize={8}>
          •
        </Text>
        <SkeletonLoading width={40} height={20} />
      </View>
      <View style={styles.transcriptParagraph}>
        <SkeletonLoading width="100%" height={60} />
      </View>
    </View>
  );
};

export const LoadingTranscriptCaptionList: FC = () => {
  return (
    <View style={styles.root}>
      <FlashList
        contentContainerStyle={styles.listContent}
        data={Array.from({ length: 10 })}
        renderItem={() => <LoadingTranscriptCaptionListItem />}
        ItemSeparatorComponent={ItemSeparatorComponent}
        role="list"
        scrollEnabled={false}
        estimatedItemSize={ESTIMATED_ITEM_SIZE}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    position: "relative",
    flex: 1,
    minHeight: 0,
    width: "100%",
  },
  listContent: {
    padding: 16,
    paddingBottom: 64,
  },
  item: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 8,
    width: "100%",
  },
  itemHeader: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  speaker: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  speakerInfo: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
  },
  timestamp: {
    textDecorationLine: "underline",
  },
  transcriptParagraph: {
    paddingLeft: 32,
    width: "100%",
  },
  markedTextDefault: {
    // TODO: use theme
    color: "#5925DC",
    backgroundColor: "#F4F3FF",
  },
  sentenceCurrent: {
    color: "#a13070",
    backgroundColor: "#ffe9f8",
  },
  sentencePressed: {
    backgroundColor: "#F4F3FF",
  },
  syncListContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 30,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  syncListPanel: {
    borderRadius: 50,
    paddingHorizontal: 20,
    paddingVertical: 10,
    fontWeight: "bold",
    color: "black",
  },
  sep: {
    height: 24,
  },
});
