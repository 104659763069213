import { IconPause, IconSquare } from "@/assets/svg";
import { Text } from "@/components/Typography";
import { useRecorder } from "@/features/live-record";
import { createStyles, useTheme, vars } from "@/styles";
import { type FC } from "react";
import { TouchableOpacity, View } from "react-native";
import Animated, {
  FadeIn,
  FadeOut,
  useAnimatedStyle,
  withTiming,
} from "react-native-reanimated";
import { useRecordScreenContext } from "../RecordScreenContext";
import { isPendingStreaming } from "./utils";

const RecordButton: FC<{
  startRecording: () => void;
  toggleRecording: () => void;
}> = ({ startRecording, toggleRecording }) => {
  const theme = useTheme();
  const { status } = useRecorder();

  const primaryButtonState =
    status.state === "inactive" || status.state === "paused"
      ? "record"
      : "pause";
  const recordInnerAnimStyle = useAnimatedStyle(
    () => ({
      width: withTiming(primaryButtonState === "record" ? 32 : 48),
      height: withTiming(primaryButtonState === "record" ? 32 : 48),
    }),
    [primaryButtonState],
  );

  return (
    <TouchableOpacity
      style={[styles.button(theme), styles.record]}
      onPress={() => {
        if (status.state === "inactive") {
          startRecording();
        } else {
          toggleRecording();
        }
      }}
      aria-label={
        primaryButtonState === "record" ? "Start recording" : "Pause recording"
      }
      role="button"
    >
      <Animated.View style={[styles.recordInner, recordInnerAnimStyle]} />
      {primaryButtonState === "pause" && (
        <Animated.View
          entering={FadeIn}
          exiting={FadeOut}
          style={styles.recordPauseIcon}
        >
          <IconPause color="#0C0C0D" width={20} height={20} />
        </Animated.View>
      )}
    </TouchableOpacity>
  );
};

const StopButton: FC<{
  stopRecording: () => void;
}> = ({ stopRecording }) => {
  const theme = useTheme();
  const { status } = useRecorder();

  if (status.state === "inactive") return null;

  return (
    <TouchableOpacity
      style={[styles.button(theme), styles.buttonDisabled(theme)]}
      onPress={stopRecording}
      aria-label="Stop recording"
      role="button"
    >
      <IconSquare
        stroke="transparent"
        fill={theme.colors.commandNeutralDefault}
      />
    </TouchableOpacity>
  );
};

export const RecordControls: FC = () => {
  const { status } = useRecorder();
  const { startRecording, stopRecording, toggleRecording } =
    useRecordScreenContext();

  if (isPendingStreaming(status)) {
    return null;
  }

  return (
    <>
      <View style={styles.root}>
        <View style={styles.actions}>
          <RecordButton
            startRecording={startRecording}
            toggleRecording={toggleRecording}
          />
          <StopButton stopRecording={stopRecording} />
        </View>
        {status.state === "inactive" && (
          <Text
            variant="body2Regular"
            color="textHint"
            style={styles.consentText}
          >
            Make sure to get participants consent when recording.
          </Text>
        )}
      </View>
    </>
  );
};

const styles = createStyles({
  root: {
    flexDirection: "column",
    gap: 24,
    paddingTop: 24,
    paddingBottom: 56,
    alignItems: "center",
  },
  actions: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 32,
    paddingBottom: 72,
  },
  record: {
    backgroundColor: vars.colors.red500,
  },
  recordInner: {
    backgroundColor: "#ffffff",
    borderRadius: 9999,
    justifyContent: "center",
    alignItems: "center",
  },
  recordPauseIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: [{ translateX: -10 }, { translateY: -10 }],
  },
  button: (theme) => ({
    width: 72,
    height: 72,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 9999,
    backgroundColor: theme.colors.interactiveNeutral3Default,
  }),
  buttonDisabled: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutral3Disabled,
  }),
  consentText: {
    textAlign: "center",
    maxWidth: 270,
  },
});
