import { Config } from "@/constants";
import { getResponseErrorMessage } from "@/errors";
import { getRequestMetadataHeaders } from "@/utils/request";
import { authApi, createAuthHeaders } from "../auth";

const MAX_RETRIES = 5;

export class MobileApi {
  private async request<T = any>(
    path: string,
    init: RequestInit,
    ctx?: {
      retries?: number;
    },
  ): Promise<T> {
    let res: Response;

    const canRetry = !ctx?.retries || ctx.retries < MAX_RETRIES;
    const retryTimeout = 1000 * (ctx?.retries || 0);
    const retry = async () => {
      await new Promise((resolve) => setTimeout(resolve, retryTimeout));
      return this.request(path, init, {
        retries: (ctx?.retries || 0) + 1,
      });
    };

    try {
      const authTokens = await authApi.getOrRefreshTokens();
      res = await fetch(`${Config.MOBILE_APP_URL}${path}`, {
        ...init,
        headers: {
          ...init.headers,
          ...(authTokens ? createAuthHeaders(authTokens) : {}),
          ...getRequestMetadataHeaders(),
        },
      });
    } catch (err) {
      if (canRetry) {
        return retry();
      }
      throw err;
    }

    if (!res.ok) {
      const message = await getResponseErrorMessage(res);
      const err = new Error(message);
      throw err;
    }

    if (res.status === 204) {
      return null as any;
    }

    return res.json();
  }

  async getUpload(
    meetingId: string,
    input: { contentType: string; size: number; durationMillis?: number },
  ): Promise<{
    uploadUrl: string;
    mediaUrl: string;
  }> {
    // TODO: use URLSearchParams when it is supported in RN
    const urlParams = [];
    urlParams.push(`contentType=${input.contentType}`);
    urlParams.push(`size=${input.size}`);
    if (input.durationMillis) {
      urlParams.push(`durationMillis=${input.durationMillis}`);
    }
    urlParams.push(`client=${Config.NAME}`);
    const { uploadUrl, mediaUrl } = await this.request(
      `/api/upload/${meetingId}` + `?${urlParams.join("&")}`,
      {
        method: "GET",
      },
    );

    return {
      uploadUrl,
      mediaUrl,
    };
  }

  async completeUpload(
    meetingId: string,
    input: { contentType: string },
  ): Promise<void> {
    await this.request(`/api/upload/${meetingId}/complete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        client: Config.NAME,
        contentType: input.contentType,
      }),
    });
  }

  async getRemoteConstants(): Promise<Record<string, any>> {
    return this.request(`/constants/remote`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    });
  }
}
