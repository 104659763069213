/**
 * AudioUploader performs a one-shot HTTP PUT upload after recording finishes.
 * It retries the upload until it succeeds (up to a maximum number of attempts).
 *
 * The uploader reads the audio data from an AudioFileBuffer and sends it using XMLHttpRequest.
 * It tracks progress and updates the streaming status via provided callbacks.
 */

import type { AudioFileBuffer } from "./audio-file-buffer";
import type { StreamerCallback } from "./audio-streamer";
import { StreamingState } from "./constants";

const MAX_UPLOAD_ATTEMPTS = 5;

// MARK: - AudioUploader Class
export class AudioUploader {
  private isUploading = false;
  private attemptCount = 0;
  private uploadUrl = "";

  // MARK: - Initialization
  constructor(
    private audioBuffer: AudioFileBuffer,
    private callback: StreamerCallback,
  ) {}

  // MARK: - Public API
  /**
   * Starts the upload process. Call this after recording is finished.
   */
  startUpload(uploadUrl: string) {
    if (this.isUploading) {
      this.callback.log("AudioUploader: Already uploading. Skipping start.");
      return;
    }
    this.uploadUrl = uploadUrl;
    this.isUploading = true;
    this.attemptCount = 0;
    this.callback.log("AudioUploader: Starting upload.");
    this.attemptUpload();
  }

  // MARK: - Upload Logic
  /**
   * Attempts to upload the audio data using XMLHttpRequest.
   * If the upload fails, a retry is scheduled.
   */
  private attemptUpload() {
    this.attemptCount += 1;
    if (this.attemptCount > MAX_UPLOAD_ATTEMPTS) {
      this.callback.log(
        `AudioUploader: Upload failed after ${MAX_UPLOAD_ATTEMPTS} attempts.`,
      );
      this.callback.onStreamStatusUpdate(StreamingState.INACTIVE);
      this.callback.onStreamFailed("Upload failed after multiple attempts.");
      return;
    }
    this.callback.log(
      `AudioUploader: Attempting upload #${this.attemptCount}.`,
    );
    this.callback.onStreamStatusUpdate(StreamingState.CONNECTING);

    // Reset read offset for progress tracking
    this.audioBuffer.readOffset = 0;
    const data = this.audioBuffer.getData();
    const xhr = new XMLHttpRequest();

    // MARK: - Progress Tracking
    xhr.addEventListener("progress", (event) => {
      if (event.loaded && event.loaded > 0) {
        this.callback.onStreamStatusUpdate(StreamingState.STREAMING);
      }
      this.audioBuffer.readOffset = event.loaded;
    });

    // MARK: - Response Handling
    xhr.addEventListener("load", () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        this.callback.log("AudioUploader: Upload completed.");
        this.callback.onStreamStatusUpdate(StreamingState.INACTIVE);
        this.callback.onStreamCompleted();
      } else {
        //  Only retry on server errors (5xx)
        if (xhr.status >= 500 && xhr.status < 600) {
          this.scheduleRetry(`Upload failed with status ${xhr.status}.`);
        } else {
          this.callback.onStreamFailed(
            `Upload failed with status ${xhr.status}.`,
          );
          this.callback.onStreamStatusUpdate(StreamingState.INACTIVE);
        }
      }
    });

    xhr.addEventListener("error", () => {
      this.scheduleRetry("Network error during upload.");
    });

    xhr.open("PUT", this.uploadUrl, true);
    xhr.setRequestHeader("Content-Type", this.audioBuffer.getMimeType());
    xhr.send(data);
  }

  private scheduleRetry(err: string) {
    this.callback.onStreamError(`Upload error: ${err}`);
    this.callback.onStreamStatusUpdate(StreamingState.CONNECTING);
    const baseDelay = 5;
    const calculatedDelay = baseDelay * Math.pow(1.5, this.attemptCount);
    const retryDelay = Math.min(calculatedDelay, 30); // Cap delay at 30 seconds
    this.callback.log(`AudioUploader: error: ${err}`);
    this.callback.log(
      `AudioUploader: Scheduling retry in ${retryDelay} seconds.`,
    );
    setTimeout(() => {
      this.attemptUpload();
    }, retryDelay * 1000);
  }
}
