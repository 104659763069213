import { Checkbox } from "@/components/Checkbox";
import { RNText, Text } from "@/components/Typography";
import type { LiveActionItem } from "@/graphql";
import { getNameParts } from "@/utils/user";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";
import { useLiveScreenContext } from "../Context/LiveScreenContext";

const ActionItemListItem: FC<{
  item: LiveActionItem;
}> = ({ item }) => {
  const { updateActionItem, meetingId } = useLiveScreenContext();

  const assinee = item.personName
    ? getNameParts(item.personName).firstName
    : item.personEmail
      ? item.personEmail.split("@")[0]
      : undefined;

  const handleActionItemCheck = (checked: boolean) => {
    if (!item.id) {
      // certainly this is just wrong graphql definition
      return;
    }
    updateActionItem({
      ...item,
      id: item.id,
      meetingId,
      checked,
    });
  };

  return (
    <View style={styles.item}>
      <View style={styles.checkbox}>
        <Checkbox
          value={!!item.checked}
          onValueChange={handleActionItemCheck}
        />
      </View>
      <RNText style={styles.textContainer}>
        <Text variant="body2LongRegular" color="textSecondary">
          {item.actionItem}
        </Text>
        {!!assinee && assinee !== "unassigned" && (
          <Text variant="body2LongRegular" color="textHint">
            {" "}
            @{assinee}
          </Text>
        )}
      </RNText>
    </View>
  );
};

export const ActionItemsSection = () => {
  const { liveInsights } = useLiveScreenContext();

  return (
    <View style={styles.root}>
      {liveInsights?.actionItems?.map(
        (actionItem) =>
          !!actionItem && (
            <ActionItemListItem key={actionItem.id} item={actionItem} />
          ),
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    gap: 8,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",

    gap: 16,
  },
  checkbox: {
    paddingVertical: 4,
  },
  textContainer: {
    flex: 1,
  },
});
