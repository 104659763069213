import { IconSearch } from "@/assets/svg";
import { Text } from "@/components/Typography";
import { useTheme } from "@/styles";
import type { FC } from "react";
import { KeyboardAvoidingView, Platform, StyleSheet } from "react-native";

export const EmptyState: FC<{
  title: string;
}> = ({ title }) => {
  const theme = useTheme();

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      style={styles.root}
    >
      <IconSearch color={theme.colors.textMuted} width={64} height={64} />
      <Text variant="title1Weight" color="textHint" style={styles.title}>
        {title}
      </Text>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 64,
    gap: 8,
  },
  title: {
    textAlign: "center",
  },
});
