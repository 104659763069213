import { TextInput } from "@/components/Input";
import { SkeletonLoading } from "@/components/Loading";
import { NEW_COMMENT_SOURCES } from "@/constants";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import { useState, type FC } from "react";
import { StyleSheet, View } from "react-native";
import { useLiveScreenContext } from "../Context/LiveScreenContext";
import { getElapsedDuration } from "../utils";

export const LiveScreenCommentForm: FC<{
  meeting: Meeting;
}> = ({ meeting }) => {
  const { createComment } = useLiveScreenContext();
  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState("");

  const onSubmit = (text: string) => {
    setIsLoading(true);
    createComment({
      meetingId: meeting.id,
      comment: text,
      source: NEW_COMMENT_SOURCES.LIVE,
      // TODO: allow customize privacy and timestamp
      // https://app.shortcut.com/fireflies/story/42368/add-comments-for-a-specific-timestamp
      privacy: "public",
      timestamp: getElapsedDuration(
        meeting.fredJoinedMeetingAt || meeting.startTime,
      ),
    })
      .then(() => {
        setText("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <View style={styles.root}>
      <TextInput
        variant="filled"
        placeholder="Add comments"
        submitButton
        onSubmitEditing={(e) => onSubmit(e.nativeEvent.text)}
        disabled={isLoading}
        value={text}
        onValueChange={setText}
      />
    </View>
  );
};

export const LoadingLiveScreenCommentForm: FC = () => {
  return (
    <View style={styles.root}>
      <SkeletonLoading height={38} width="100%" />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 32,
    paddingBottom: 4,
  },
});
