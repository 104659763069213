import { IconEdit } from "@/assets/svg";
import { Button, IconButton } from "@/components/Button";
import { SelectPopover } from "@/components/Input";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { localRecordStoreApi, useRecorder } from "@/features/live-record";
import { MEETING_PRIVACY_OPTIONS } from "@/features/meeting";
import type { MeetingNotesPrivacy } from "@/graphql";
import { FontStyles, useTheme } from "@/styles";
import { tracker, TRACKING_EVENTS } from "@/tracking";
import { useBooleanState } from "@/utils/states";
import { useMutation } from "@apollo/client";
import { UpdateMeetingDocument } from "@firefliesai/mobile-ff.graphql-client";
import { useEffect, useRef, useState, type FC } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { HeaderRightInactive } from "./HeaderRightInactive";

const useUpdateMeetingMutation = () =>
  useMutation(UpdateMeetingDocument, {
    onCompleted() {
      toast({
        message: "Updated",
      });
    },
    onError(error) {
      toast({
        title: "Failed to update meeting",
        message: error.message,
        type: "error",
      });
    },
  });

export const RecordHeaderTitle: FC = () => {
  const [updateMeeting, { loading }] = useUpdateMeetingMutation();
  const { context, setContext, status } = useRecorder();

  const titleRef = useRef<TextInput>(null);
  const [title, setTitle] = useState(context?.title || "");

  useEffect(() => {
    setTitle(context?.title || "");
  }, [context?.title]);

  const onSaveTitle = () => {
    const meetingId = context?.meetingId;
    if (!meetingId) return;

    const newTitle = title.trim() || context.title;

    if (!newTitle || newTitle === context.title) {
      setTitle(context.title || "");
      return;
    }

    const newContext = {
      ...context,
      title: newTitle,
    };

    setContext(newContext);

    updateMeeting({
      variables: {
        id: meetingId,
        title: newTitle,
      },
    });

    localRecordStoreApi.updateByUri(newContext.fileUri, newContext);

    tracker.track(TRACKING_EVENTS.AUDIO_RECORD_TITLE_EDITED, {
      titleLength: newTitle.length,
    });
  };

  const theme = useTheme();

  const [isFocused, onFocus, onBlur] = useBooleanState();

  if (status.state === "inactive") {
    return <Text variant="title1Weight">Record</Text>;
  }

  return (
    <View style={styles.titleWrapper}>
      <TextInput
        ref={titleRef}
        style={styles.titleInput}
        placeholder="Add title here"
        placeholderTextColor={theme.colors.textHint}
        value={title}
        onChangeText={setTitle}
        editable={!loading}
        numberOfLines={1}
        onFocus={onFocus}
        onBlur={() => {
          onSaveTitle();
          onBlur();
        }}
      />
      {isFocused ? (
        <Button
          onPress={() => {
            onSaveTitle();
            titleRef.current?.blur();
          }}
          disabled={loading}
          size="sm"
          variant="transparentPrimary"
        >
          Save
        </Button>
      ) : (
        <Button
          onPress={() => {
            titleRef.current?.focus();
          }}
          size="xs"
          variant="transparentPrimary"
        >
          <IconEdit color={theme.colors.textMuted} width={18} height={18} />
        </Button>
      )}
    </View>
  );
};

export const RecordHeaderRight: FC<{
  openRecordings: () => void;
}> = ({ openRecordings }) => {
  const [updateMeeting] = useUpdateMeetingMutation();

  const { context, setContext, status } = useRecorder();

  const [isOpenPrivacy, openPrivacy, closePrivacy] = useBooleanState();

  const onUpdatePrivacy = (privacy: MeetingNotesPrivacy) => {
    const meetingId = context?.meetingId;
    if (!meetingId) return;

    const newContext = {
      ...context,
      notesPrivacy: privacy,
    };
    setContext(newContext);

    updateMeeting({
      variables: {
        id: meetingId,
        notesPrivacy: privacy,
      },
    });

    localRecordStoreApi.updateByUri(newContext.fileUri, newContext);

    tracker.track(TRACKING_EVENTS.AUDIO_RECORD_PRIVACY_CHANGED, { privacy });
  };

  const currentPrivacy =
    MEETING_PRIVACY_OPTIONS.find(
      (option) => option.value === context?.notesPrivacy,
    ) || MEETING_PRIVACY_OPTIONS[MEETING_PRIVACY_OPTIONS.length - 1];

  if (status.state === "inactive") {
    return <HeaderRightInactive openRecordings={openRecordings} />;
  }

  return (
    <>
      <IconButton
        label={`Edit privacy. Current: ${currentPrivacy.label}`}
        Icon={currentPrivacy.Icon}
        onPress={openPrivacy}
        variant="neutral"
      />
      <SelectPopover
        isOpen={isOpenPrivacy}
        close={closePrivacy}
        options={MEETING_PRIVACY_OPTIONS}
        value={currentPrivacy.value}
        onValueChange={(value) => onUpdatePrivacy(value as MeetingNotesPrivacy)}
        aria-label="Change privacy"
      />
    </>
  );
};

const styles = StyleSheet.create({
  titleWrapper: {
    flex: 1,
    flexDirection: "row",
    maxWidth: "80%",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
  },
  titleInput: {
    ...FontStyles.title1Weight,
    flexShrink: 1,
  },
});
