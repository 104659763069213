import { IconCircle } from "@/assets/svg";
import { usePulseAnimatedStyle } from "@/components/Animated";
import { useTheme } from "@/styles";
import { useEffect, useRef, useState, type FC } from "react";
import Animated from "react-native-reanimated";
import { useLiveScreenContext } from "../Context/LiveScreenContext";
import { TabName } from "../constants";

export const BookmarksTabTrailing: FC = () => {
  const { ee, liveActivities } = useLiveScreenContext();

  const prevLiveActivityCount = useRef<number>(liveActivities.length);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (liveActivities.length > prevLiveActivityCount.current) {
      setShow(true);
    }
    prevLiveActivityCount.current = liveActivities.length;
  }, [liveActivities.length]);

  useEffect(() => {
    const onTabChange = (name: string) => {
      if (name === TabName.Bookmarks) {
        setShow(false);
      }
    };
    ee.on("tabChange", onTabChange);
    return () => ee.off("tabChange", onTabChange);
  }, [ee]);

  const theme = useTheme();

  const animStyle = usePulseAnimatedStyle();

  return show ? (
    <Animated.View style={animStyle}>
      <IconCircle
        width={6}
        height={6}
        fill={theme.colors.informationStaticGreenSolid}
        color={theme.colors.informationStaticGreenSolid}
      />
    </Animated.View>
  ) : null;
};
