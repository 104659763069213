import { captureException, getErrorMessage } from "@/errors";
import { apolloClient } from "@/graphql";
import { Logger } from "@/logger";
import { getFileInfo } from "@/utils/file";
import { patchAndroidMimeType } from "@/utils/mime";
import type {
  Meeting,
  MeetingNotesPrivacy,
} from "@firefliesai/mobile-ff.graphql-client";
import { CreateMeetingDocument } from "@firefliesai/mobile-ff.graphql-client";
import * as DocumentPicker from "expo-document-picker";
import { GraphQLError } from "graphql";
import { Platform } from "react-native";
import { supportedMimeType, UPLOAD_CLIENT_KEYS } from "./constants";
import { uploadApi } from "./store";
import type { UploadTaskDetail } from "./types";
import { validateFile } from "./utils";

const logger = new Logger("upload-service");

export async function createMeetingForUpload({
  title,
  notesPrivacy,
  startTime,
  endTime,
  client,
  customLanguage,
}: {
  title: string;
  notesPrivacy?: MeetingNotesPrivacy;
  client: string;
  startTime?: Date;
  endTime?: Date;
  customLanguage?: string;
}) {
  logger.info("creating meeting for upload", {
    title,
    notesPrivacy,
    startTime,
    endTime,
  });

  const result = await apolloClient.mutate({
    mutation: CreateMeetingDocument,
    variables: {
      title,
      notesPrivacy,
      startTime,
      endTime,
      client,
      customLanguage,
    },
  });

  if (result.errors?.length) {
    throw new GraphQLError(result.errors[0].message, {
      ...result.errors[0],
    });
  }

  logger.info("meeting created", { id: result.data?.createMeeting?.id });

  return result.data?.createMeeting as Meeting;
}

export async function uploadFromDocument(
  {
    notesPrivacy,
  }: {
    notesPrivacy?: MeetingNotesPrivacy;
  },
  options?: {
    onError?: (error: Error) => void;
    onCompleted?: (task: UploadTaskDetail) => void;
  },
) {
  const result = await DocumentPicker.getDocumentAsync({
    type: Platform.OS === "ios" ? "*/*" : supportedMimeType,
    multiple: false,
    copyToCacheDirectory: true,
  }).catch((err) => {
    captureException(
      new Error(getErrorMessage(err, "DocumentPicker.getDocumentAsync")),
      {
        tags: {
          section: "upload-from-document",
        },
      },
    );
    throw err;
  });
  if (result.canceled) return false;
  const asset = result.assets[0];
  if (!asset) return false;

  const assetMimeType = asset.mimeType
    ? patchAndroidMimeType(asset.mimeType, [asset.uri, asset.name])
    : undefined;

  const info =
    typeof asset.size === "number" && assetMimeType
      ? {
          mimeType: assetMimeType,
          size: asset.size,
        }
      : await getFileInfo(asset.uri, {
          name: asset.name,
          mimeType: assetMimeType,
        });

  await validateFile(info);

  const meeting = await createMeetingForUpload({
    title: asset.name,
    notesPrivacy,
    client: UPLOAD_CLIENT_KEYS.MOBILE_UPLOAD,
  });

  await uploadApi.create(
    {
      uri: asset.uri,
      mimeType: info.mimeType,
      size: info.size,
    },
    {
      meetingId: meeting.id,
      title: asset.name,
      ...options,
    },
  );

  return true;
}
