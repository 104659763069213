import type { CoreFeedMeetingFieldsFragment } from "@/graphql";
import { getWeekGroupLabel, groupByWeeks } from "@/utils/date";

export interface WeekSection {
  isRecent: boolean;
  startTime: Date;
  endTime: Date;
  meetings: CoreFeedMeetingFieldsFragment[];
}

export const groupMeetingsByWeek = (
  meetings: CoreFeedMeetingFieldsFragment[],
): WeekSection[] => {
  const groupedMeetings = groupByWeeks(meetings);

  return groupedMeetings.map((group) => ({
    isRecent: group.isCurrentWeek,
    startTime: group.startTime,
    endTime: group.endTime,
    meetings: group.items,
  }));
};

export const getSectionLabel = (section: WeekSection) => {
  return getWeekGroupLabel(section);
};
