import { toast } from "@/components/Toast";
import { ASK_FRED_PRIVACY } from "@/constants";
import { useAuth } from "@/features/auth";
import type {
  MeetingFredQaQuery,
  MeetingFredQaQueryVariables,
} from "@/graphql";
import {
  MeetingFredQaDocument,
  type AskFredMutation,
  type AskFredMutationVariables,
} from "@/graphql";
import type {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
} from "@apollo/client";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";

export const askFredMutationOptions = (
  meeting: Meeting,
): Pick<
  MutationHookOptions<
    AskFredMutation,
    AskFredMutationVariables,
    DefaultContext,
    ApolloCache<unknown>
  >,
  "update" | "optimisticResponse" | "onError"
> => {
  return {
    update(cache, { data: newData }) {
      if (!newData?.askFred) return;
      const cachedData = cache.readQuery<
        MeetingFredQaQuery,
        MeetingFredQaQueryVariables
      >({
        query: MeetingFredQaDocument,
        variables: {
          meetingId: meeting.id,
        },
      });
      cache.writeQuery<MeetingFredQaQuery, MeetingFredQaQueryVariables>({
        query: MeetingFredQaDocument,
        data: {
          meetingFredQa: (cachedData?.meetingFredQa || []).concat(
            newData.askFred,
          ),
        },
        variables: {
          meetingId: meeting.id,
        },
      });
    },
    // along with the `update` option, this will optimistically show a `...`
    // when we get a response, it will be replaced.
    optimisticResponse(vars) {
      return {
        __typename: "Mutation",
        askFred: {
          __typename: "FredResponse",
          id: `temp-${meeting.id}`,
          question: vars.query,
          answer: "",
          rating: null,
          status: null,
          meetingId: meeting.id,
          privacy: vars.privacy || ASK_FRED_PRIVACY.OWNER,
          source: vars.source || null,
          createdAt: new Date().toJSON(),
          userId: useAuth.getState().user?.id || "?",
        },
      };
    },
    onError(err) {
      toast({
        title: "Could not send askFred query",
        type: "error",
        message: err.message,
      });
    },
  };
};
