import { Config } from "@/constants";
import { CanceledError } from "@/errors";
import { getLoginTrackingParams } from "@/tracking";
import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import { Platform } from "react-native";
import type { AuthTokens } from "./types";

export const oauth2Login = async (
  type: "google" | "outlook",
): Promise<AuthTokens> => {
  const baseUrl = `${Config.MOBILE_APP_URL}/api/auth/${type}/authorize`;
  const urlParams = new URLSearchParams({
    platform: Platform.OS,
  });
  const trackingParams = getLoginTrackingParams();
  Object.entries(trackingParams).forEach(([key, value]) => {
    if (value) urlParams.append(key, value);
  });
  const url = `${baseUrl}?${urlParams.toString()}`;
  const result = await WebBrowser.openAuthSessionAsync(
    url,
    Linking.createURL("login"),
    { showInRecents: true },
  );
  if (result.type === "cancel" || result.type === "dismiss") {
    throw new CanceledError();
  }
  if (result.type !== "success") {
    throw new Error("Login failed");
  }
  try {
    WebBrowser.dismissAuthSession();
  } catch {
    // do nothing
  }
  if (Platform.OS === "web") {
    const url = new URL(result.url);
    const error = url.searchParams.get("error");
    const expiresAtParam = url.searchParams.get("expires_at");
    const refreshExpiresAtParam = url.searchParams.get("refresh_expires_at");
    if (error) {
      throw new Error(error);
    }
    return {
      accessToken: null,
      refreshToken: null,
      expiresAt: expiresAtParam ? Number(expiresAtParam) : null,
      refreshExpiresAt: refreshExpiresAtParam
        ? Number(refreshExpiresAtParam)
        : null,
    };
  } else {
    const { queryParams } = Linking.parse(result.url);
    if (!queryParams) {
      throw new Error("No query params");
    }
    if (queryParams.error) {
      throw new Error(queryParams.error as string);
    }
    const params = queryParams as AuthLoginResponseParams;
    return {
      accessToken: params.access_token || null,
      refreshToken: params.refresh_token || null,
      expiresAt: params.expires_at ? Number(params.expires_at) : null,
      refreshExpiresAt: params.refresh_expires_at
        ? Number(params.refresh_expires_at)
        : null,
    };
  }
};

export type AuthLoginResponseParams = {
  access_token: string | undefined;
  refresh_token: string | undefined;
  /** expiry of access token in seconds */
  expires_at: string | undefined;
  /** expiry of refresh token in seconds */
  refresh_expires_at: string | undefined;
};
