import { createStyles } from "@/styles";
import type { FC, ReactNode } from "react";
import { View } from "react-native";
import { Button } from "../Button";
import { Text } from "../Typography";
import { DialogHeader, DialogRoot } from "./Dialog";
import type { DialogProps } from "./types";

export type ConfirmationDialogProps = Omit<DialogProps, "onDismiss"> & {
  title: string;
  children?: string | ReactNode;
  cancelText?: string;
  onCancel?: () => void;
  confirmText?: string;
  onConfirm?: () => void;
  highlightCancel?: boolean;
  loading?: boolean;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  confirmText = "Confirm",
  onConfirm,
  cancelText = "Cancel",
  onCancel,
  highlightCancel = false,
  children,
  loading,
  ...props
}) => {
  const cancel = () => {
    props.close?.();
    onCancel?.();
  };

  const confirm = () => {
    props.close?.();
    onConfirm?.();
  };

  return (
    <DialogRoot {...props} onDismiss={() => {}}>
      <DialogHeader>{title}</DialogHeader>
      <View style={styles.content}>
        {children && (
          <Text variant="title2Regular" color="textSecondary">
            {children}
          </Text>
        )}
        <View style={styles.buttons}>
          {cancelText && (
            <Button
              variant={highlightCancel ? "primary" : "outlined"}
              onPress={cancel}
            >
              {cancelText}
            </Button>
          )}
          <Button
            variant={highlightCancel ? "outlined" : "primary"}
            disabled={loading}
            onPress={confirm}
          >
            {confirmText}
          </Button>
        </View>
      </View>
    </DialogRoot>
  );
};

const styles = createStyles({
  content: {
    padding: 20,
    gap: 24,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 12,
  },
});
