import { useId } from "react";
import type { SvgProps } from "react-native-svg";
import Svg, { Defs, LinearGradient, Path, Stop } from "react-native-svg";

const IconLogoText = (props: SvgProps) => {
  const id = useId();

  return (
    <Svg width={113} height={28} fill="none" {...props}>
      <Path fill={`url(#${id}_a)`} d="M7.89 2H0v7.832h7.89V2Z" />
      <Path
        fill={`url(#${id}_b)`}
        d="M17.238 11.455h-7.89v7.832h7.89v-7.832Z"
      />
      <Path
        fill={`url(#${id}_c)`}
        d="M17.238 2h-7.89v7.832H24v-1.12c0-1.78-.712-3.487-1.98-4.746A6.788 6.788 0 0 0 17.237 2Z"
      />
      <Path
        fill={`url(#${id}_d)`}
        d="M0 11.455v7.832c0 1.78.713 3.488 1.981 4.747A6.789 6.789 0 0 0 6.763 26H7.89V11.455H0Z"
      />
      <Path fill={`url(#${id}_e)`} d="m0 2 7.89 7.832H0V2Z" opacity={0.18} />
      <Path
        fill={`url(#${id}_f)`}
        d="m9.348 11.455 7.89 7.832h-7.89v-7.832Z"
        opacity={0.18}
      />
      <Path
        fill={`url(#${id}_g)`}
        d="M0 19.287c0 1.78.713 3.488 1.981 4.747A6.789 6.789 0 0 0 6.763 26H7.89V11.455L0 19.287Z"
        opacity={0.18}
      />
      <Path
        fill={`url(#${id}_h)`}
        d="M17.238 2c1.794 0 3.514.707 4.782 1.966a6.688 6.688 0 0 1 1.982 4.747v1.119H9.348L17.238 2Z"
        opacity={0.18}
      />
      <Path
        d="M37.0551 12.6287H35.2266V20.8387H33.1652V12.6287H32V10.9616H33.1652V10.2625C33.1652 9.12719 33.4639 8.3026 34.0615 7.78873C34.6709 7.26291 35.621 7 36.9117 7V8.70294C36.2902 8.70294 35.854 8.82245 35.6031 9.06146C35.3521 9.28852 35.2266 9.68886 35.2266 10.2625V10.9616H37.0551V12.6287Z"
        fill="currentColor"
      />
      <Path
        d="M39.5473 9.65301C39.1768 9.65301 38.8661 9.52753 38.6152 9.27657C38.3642 9.02561 38.2387 8.7149 38.2387 8.34443C38.2387 7.97396 38.3642 7.66325 38.6152 7.41229C38.8661 7.16133 39.1768 7.03585 39.5473 7.03585C39.9058 7.03585 40.2105 7.16133 40.4615 7.41229C40.7125 7.66325 40.8379 7.97396 40.8379 8.34443C40.8379 8.7149 40.7125 9.02561 40.4615 9.27657C40.2105 9.52753 39.9058 9.65301 39.5473 9.65301ZM40.5511 10.9616V20.8387H38.5076V10.9616H40.5511Z"
        fill="currentColor"
      />
      <Path
        d="M44.9191 12.3956C45.2179 11.8937 45.6123 11.5053 46.1022 11.2305C46.6042 10.9437 47.1957 10.8003 47.8769 10.8003V12.9155H47.357C46.5564 12.9155 45.9469 13.1187 45.5286 13.525C45.1223 13.9313 44.9191 14.6364 44.9191 15.6402V20.8387H42.8756V10.9616H44.9191V12.3956Z"
        fill="currentColor"
      />
      <Path
        d="M58.4493 15.6581C58.4493 16.0286 58.4254 16.3632 58.3776 16.662H50.8308C50.8906 17.4507 51.1834 18.0841 51.7092 18.5621C52.235 19.0401 52.8804 19.2791 53.6452 19.2791C54.7446 19.2791 55.5214 18.819 55.9755 17.8988H58.1804C57.8816 18.8071 57.3379 19.554 56.5492 20.1396C55.7724 20.7132 54.8044 21 53.6452 21C52.7011 21 51.8526 20.7909 51.0997 20.3726C50.3588 19.9424 49.7732 19.3449 49.343 18.58C48.9247 17.8032 48.7156 16.907 48.7156 15.8912C48.7156 14.8754 48.9188 13.9851 49.3251 13.2202C49.7434 12.4434 50.323 11.8459 51.0639 11.4277C51.8168 11.0094 52.6772 10.8003 53.6452 10.8003C54.5773 10.8003 55.4079 11.0034 56.1369 11.4097C56.8658 11.816 57.4335 12.3897 57.8398 13.1306C58.2461 13.8596 58.4493 14.7021 58.4493 15.6581ZM56.3161 15.0128C56.3042 14.2599 56.0353 13.6564 55.5095 13.2023C54.9836 12.7482 54.3323 12.5211 53.5556 12.5211C52.8505 12.5211 52.247 12.7482 51.7451 13.2023C51.2431 13.6445 50.9444 14.248 50.8488 15.0128H56.3161Z"
        fill="currentColor"
      />
      <Path
        d="M64.2427 12.6287H62.4142V20.8387H60.3528V12.6287H59.1876V10.9616H60.3528V10.2625C60.3528 9.12719 60.6515 8.3026 61.2491 7.78873C61.8585 7.26291 62.8086 7 64.0993 7V8.70294C63.4778 8.70294 63.0416 8.82245 62.7907 9.06146C62.5397 9.28852 62.4142 9.68886 62.4142 10.2625V10.9616H64.2427V12.6287Z"
        fill="currentColor"
      />
      <Path
        d="M67.7387 7.57362V20.8387H65.6952V7.57362H67.7387Z"
        fill="currentColor"
      />
      <Path
        d="M71.1029 9.65301C70.7324 9.65301 70.4217 9.52753 70.1708 9.27657C69.9198 9.02561 69.7943 8.7149 69.7943 8.34443C69.7943 7.97396 69.9198 7.66325 70.1708 7.41229C70.4217 7.16133 70.7324 7.03585 71.1029 7.03585C71.4614 7.03585 71.7661 7.16133 72.0171 7.41229C72.2681 7.66325 72.3935 7.97396 72.3935 8.34443C72.3935 8.7149 72.2681 9.02561 72.0171 9.27657C71.7661 9.52753 71.4614 9.65301 71.1029 9.65301ZM72.1067 10.9616V20.8387H70.0632V10.9616H72.1067Z"
        fill="currentColor"
      />
      <Path
        d="M83.4837 15.6581C83.4837 16.0286 83.4598 16.3632 83.412 16.662H75.8653C75.925 17.4507 76.2178 18.0841 76.7436 18.5621C77.2694 19.0401 77.9148 19.2791 78.6796 19.2791C79.779 19.2791 80.5558 18.819 81.0099 17.8988H83.2148C82.916 18.8071 82.3723 19.554 81.5836 20.1396C80.8068 20.7132 79.8388 21 78.6796 21C77.7355 21 76.887 20.7909 76.1341 20.3726C75.3932 19.9424 74.8076 19.3449 74.3774 18.58C73.9591 17.8032 73.75 16.907 73.75 15.8912C73.75 14.8754 73.9532 13.9851 74.3595 13.2202C74.7778 12.4434 75.3574 11.8459 76.0983 11.4277C76.8512 11.0094 77.7116 10.8003 78.6796 10.8003C79.6117 10.8003 80.4423 11.0034 81.1713 11.4097C81.9003 11.816 82.4679 12.3897 82.8742 13.1306C83.2805 13.8596 83.4837 14.7021 83.4837 15.6581ZM81.3505 15.0128C81.3386 14.2599 81.0697 13.6564 80.5439 13.2023C80.018 12.7482 79.3667 12.5211 78.59 12.5211C77.8849 12.5211 77.2814 12.7482 76.7795 13.2023C76.2775 13.6445 75.9788 14.248 75.8832 15.0128H81.3505Z"
        fill="currentColor"
      />
      <Path
        d="M88.7214 21C87.9446 21 87.2455 20.8626 86.6241 20.5877C86.0146 20.3009 85.5306 19.9185 85.1721 19.4405C84.8136 18.9505 84.6223 18.4067 84.5984 17.8092H86.7137C86.7495 18.2275 86.9467 18.58 87.3052 18.8668C87.6757 19.1417 88.1358 19.2791 88.6855 19.2791C89.2591 19.2791 89.7013 19.1716 90.012 18.9565C90.3347 18.7294 90.496 18.4426 90.496 18.096C90.496 17.7256 90.3168 17.4507 89.9582 17.2714C89.6117 17.0922 89.056 16.895 88.2911 16.6799C87.5502 16.4767 86.9467 16.2796 86.4807 16.0883C86.0146 15.8971 85.6083 15.6044 85.2617 15.21C84.9271 14.8156 84.7598 14.2958 84.7598 13.6504C84.7598 13.1246 84.9151 12.6466 85.2258 12.2164C85.5366 11.7742 85.9787 11.4277 86.5524 11.1767C87.1379 10.9257 87.8072 10.8003 88.56 10.8003C89.6834 10.8003 90.5856 11.0871 91.2668 11.6607C91.9599 12.2224 92.3304 12.9932 92.3782 13.9731H90.3347C90.2988 13.5309 90.1196 13.1784 89.7969 12.9155C89.4742 12.6526 89.0381 12.5211 88.4883 12.5211C87.9506 12.5211 87.5383 12.6227 87.2515 12.8259C86.9646 13.029 86.8212 13.2979 86.8212 13.6325C86.8212 13.8954 86.9168 14.1165 87.108 14.2958C87.2993 14.475 87.5323 14.6184 87.8072 14.726C88.082 14.8216 88.4883 14.9471 89.0261 15.1024C89.7431 15.2936 90.3287 15.4908 90.7828 15.694C91.2489 15.8852 91.6492 16.172 91.9838 16.5544C92.3185 16.9368 92.4917 17.4447 92.5037 18.0781C92.5037 18.6398 92.3483 19.1417 92.0376 19.5839C91.7269 20.026 91.2847 20.3726 90.7111 20.6236C90.1494 20.8745 89.4862 21 88.7214 21Z"
        fill="currentColor"
      />
      <Path
        d="M95.1778 20.9641C94.8074 20.9641 94.4967 20.8387 94.2457 20.5877C93.9948 20.3367 93.8693 20.026 93.8693 19.6556C93.8693 19.2851 93.9948 18.9744 94.2457 18.7234C94.4967 18.4725 94.8074 18.347 95.1778 18.347C95.5364 18.347 95.8411 18.4725 96.0921 18.7234C96.343 18.9744 96.4685 19.2851 96.4685 19.6556C96.4685 20.026 96.343 20.3367 96.0921 20.5877C95.8411 20.8387 95.5364 20.9641 95.1778 20.9641Z"
        fill="currentColor"
      />
      <Path
        d="M97.6375 15.8553C97.6375 14.8634 97.8406 13.9851 98.2469 13.2202C98.6652 12.4554 99.2269 11.8638 99.932 11.4456C100.649 11.0154 101.438 10.8003 102.298 10.8003C103.075 10.8003 103.75 10.9556 104.324 11.2663C104.909 11.5651 105.375 11.9415 105.722 12.3956V10.9616H107.783V20.8387H105.722V19.3688C105.375 19.8348 104.903 20.2232 104.306 20.5339C103.708 20.8446 103.027 21 102.262 21C101.414 21 100.637 20.7849 99.932 20.3547C99.2269 19.9125 98.6652 19.303 98.2469 18.5262C97.8406 17.7375 97.6375 16.8472 97.6375 15.8553ZM105.722 15.8912C105.722 15.21 105.579 14.6184 105.292 14.1165C105.017 13.6146 104.652 13.2322 104.198 12.9693C103.744 12.7064 103.254 12.5749 102.728 12.5749C102.203 12.5749 101.713 12.7064 101.258 12.9693C100.804 13.2202 100.434 13.5967 100.147 14.0986C99.8722 14.5886 99.7348 15.1741 99.7348 15.8553C99.7348 16.5365 99.8722 17.134 100.147 17.6479C100.434 18.1618 100.804 18.5561 101.258 18.831C101.725 19.0939 102.215 19.2254 102.728 19.2254C103.254 19.2254 103.744 19.0939 104.198 18.831C104.652 18.5681 105.017 18.1857 105.292 17.6837C105.579 17.1699 105.722 16.5723 105.722 15.8912Z"
        fill="currentColor"
      />
      <Path
        d="M111.149 9.65301C110.778 9.65301 110.468 9.52753 110.217 9.27657C109.966 9.02561 109.84 8.7149 109.84 8.34443C109.84 7.97396 109.966 7.66325 110.217 7.41229C110.468 7.16133 110.778 7.03585 111.149 7.03585C111.507 7.03585 111.812 7.16133 112.063 7.41229C112.314 7.66325 112.439 7.97396 112.439 8.34443C112.439 8.7149 112.314 9.02561 112.063 9.27657C111.812 9.52753 111.507 9.65301 111.149 9.65301ZM112.153 10.9616V20.8387H110.109V10.9616H112.153Z"
        fill="currentColor"
      />
      <Defs>
        <LinearGradient
          id={`${id}_a`}
          x1={19.299}
          x2={-13.867}
          y1={21.815}
          y2={-13.031}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E82A73" />
          <Stop offset={0.113} stopColor="#DE2D7A" />
          <Stop offset={0.3} stopColor="#C5388F" />
          <Stop offset={0.54} stopColor="#9B4AB0" />
          <Stop offset={0.818} stopColor="#6262DE" />
          <Stop offset={0.994} stopColor="#3B73FF" />
        </LinearGradient>
        <LinearGradient
          id={`${id}_b`}
          x1={19.415}
          x2={-13.75}
          y1={21.707}
          y2={-13.139}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FF3C82" />
          <Stop offset={0.103} stopColor="#F53E88" />
          <Stop offset={0.274} stopColor="#DC4598" />
          <Stop offset={0.492} stopColor="#B251B2" />
          <Stop offset={0.745} stopColor="#7961D7" />
          <Stop offset={0.994} stopColor="#3B73FF" />
        </LinearGradient>
        <LinearGradient
          id={`${id}_c`}
          x1={24.925}
          x2={13.687}
          y1={16.466}
          y2={-24.259}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E82A73" />
          <Stop offset={0.113} stopColor="#DE2D7A" />
          <Stop offset={0.3} stopColor="#C5388F" />
          <Stop offset={0.54} stopColor="#9B4AB0" />
          <Stop offset={0.818} stopColor="#6262DE" />
          <Stop offset={0.994} stopColor="#3B73FF" />
        </LinearGradient>
        <LinearGradient
          id={`${id}_d`}
          x1={13.863}
          x2={-26.367}
          y1={26.989}
          y2={14.733}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E82A73" />
          <Stop offset={0.113} stopColor="#DE2D7A" />
          <Stop offset={0.3} stopColor="#C5388F" />
          <Stop offset={0.54} stopColor="#9B4AB0" />
          <Stop offset={0.818} stopColor="#6262DE" />
          <Stop offset={0.994} stopColor="#3B73FF" />
        </LinearGradient>
        <LinearGradient
          id={`${id}_e`}
          x1={-3.858}
          x2={7.41}
          y1={-7.782}
          y2={18.038}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E82A73" />
          <Stop offset={0.114} stopColor="#DE286E" />
          <Stop offset={0.303} stopColor="#C52361" />
          <Stop offset={0.544} stopColor="#9B1A4D" />
          <Stop offset={0.825} stopColor="#620F30" />
          <Stop offset={0.994} stopColor="#3D081E" />
        </LinearGradient>
        <LinearGradient
          id={`${id}_f`}
          x1={5.489}
          x2={16.758}
          y1={1.673}
          y2={27.494}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E82A73" />
          <Stop offset={0.114} stopColor="#DE286E" />
          <Stop offset={0.303} stopColor="#C52361" />
          <Stop offset={0.544} stopColor="#9B1A4D" />
          <Stop offset={0.825} stopColor="#620F30" />
          <Stop offset={0.994} stopColor="#3D081E" />
        </LinearGradient>
        <LinearGradient
          id={`${id}_g`}
          x1={-2.619}
          x2={22.901}
          y1={1.259}
          y2={18.213}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E82A73" />
          <Stop offset={0.114} stopColor="#DE286E" />
          <Stop offset={0.303} stopColor="#C52361" />
          <Stop offset={0.544} stopColor="#9B1A4D" />
          <Stop offset={0.825} stopColor="#620F30" />
          <Stop offset={0.994} stopColor="#3D081E" />
        </LinearGradient>
        <LinearGradient
          id={`${id}_h`}
          x1={-558.664}
          x2={-554.291}
          y1={553.006}
          y2={587.533}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E82A73" />
          <Stop offset={0.114} stopColor="#DE286E" />
          <Stop offset={0.303} stopColor="#C52361" />
          <Stop offset={0.544} stopColor="#9B1A4D" />
          <Stop offset={0.825} stopColor="#620F30" />
          <Stop offset={0.994} stopColor="#3D081E" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default IconLogoText;
