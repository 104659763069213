import {
  IconCheckSquare,
  IconCircle,
  IconMessageCircle,
  IconSoundbite,
  IconThumbsDown,
  IconThumbsUp,
} from "@/assets/svg";
import { Avatar } from "@/components/Avatar";
import { BottomSheetMenuModal } from "@/components/BottomSheet";
import { RNText, Text } from "@/components/Typography";
import { useAuth } from "@/features/auth";
import { createStyles, useTheme, vars, type Theme } from "@/styles";
import { useBooleanState } from "@/utils/states";
import { toHHMMSS } from "@/utils/time";
import type { FC } from "react";
import { Pressable, View } from "react-native";
import { useLiveScreenContext } from "../Context/LiveScreenContext";
import type { LiveMeetingActivity } from "../types";
import { getLiveActivityTimestamp, getTimeString } from "./utils";

const getThemedMarkPinTypeSlots = (theme: Theme) => ({
  concern: {
    label: "Concern",
    icon: (
      <View
        style={[
          styles.icon,
          { backgroundColor: theme.colors.rainbowStaticOrangeSubtle },
        ]}
      >
        <IconThumbsDown
          color={theme.colors.rainbowStaticOrangeSolid}
          width={20}
          height={20}
        />
      </View>
    ),
  },
  "action-item": {
    label: "Action Item",
    icon: (
      <View
        style={[
          styles.icon,
          { backgroundColor: theme.colors.rainbowStaticPurpleSubtle },
        ]}
      >
        <IconCheckSquare
          color={theme.colors.rainbowStaticPurpleText}
          width={20}
          height={20}
        />
      </View>
    ),
  },
  positive: {
    label: "Positive",
    icon: (
      <View
        style={[
          styles.icon,
          { backgroundColor: theme.colors.rainbowStaticTealSolid },
        ]}
      >
        <IconThumbsUp
          color={theme.colors.rainbowStaticTealSubtle}
          width={20}
          height={20}
        />
      </View>
    ),
  },
  important: {
    label: "Important",
    icon: (
      <View
        style={[
          styles.icon,
          { backgroundColor: theme.colors.rainbowStaticCyanSubtle },
        ]}
      >
        <IconCheckSquare
          color={theme.colors.rainbowStaticCyanSolid}
          width={20}
          height={20}
        />
      </View>
    ),
  },
});

const getHeaderSlots = (
  theme: Theme,
  activity: LiveMeetingActivity,
): {
  action: string;
  label: string;
  icon?: JSX.Element;
  userName?: string;
  userPicture?: string;
} => {
  const markPinTypeSlotsMap = getThemedMarkPinTypeSlots(theme);
  if ("markType" in activity) {
    if (activity.markPinType === "soundbite") {
      return {
        action: "added",
        label: "Soundbite",
        userName: activity.userFirstName || "Unknown",
        userPicture: activity.userPicture || undefined,
      };
    }
    const markPinTypeSlot =
      markPinTypeSlotsMap[
        activity.markPinType as keyof typeof markPinTypeSlotsMap
      ];
    return {
      action: "bookmarked",
      label: markPinTypeSlot.label,
      icon: markPinTypeSlot.icon,
      userName: activity.userFirstName || "Unknown",
      userPicture: activity.userPicture || undefined,
    };
  } else if ("comment" in activity) {
    return {
      action: "added",
      label: "Comment",
      icon: (
        <View
          style={[styles.icon, { backgroundColor: theme.colors.layerMuted }]}
        >
          <IconMessageCircle
            color={theme.colors.textMuted}
            width={20}
            height={20}
          />
        </View>
      ),
      userName:
        activity.creatorProfile?.firstName || activity.name || "Unknown",
      userPicture:
        activity.creatorProfile?.picture || activity.picture || undefined,
    };
  }
  return {
    action: "added",
    label: "Bookmark",
  };
};

export const ActivityListItem: FC<{
  activity: LiveMeetingActivity;
}> = ({ activity }) => {
  const theme = useTheme();

  const { action, label, icon, userName, userPicture } = getHeaderSlots(
    theme,
    activity,
  );
  const timestamp = getLiveActivityTimestamp(activity);

  const [isOpenMenu, openMenu, closeMenu] = useBooleanState();

  const { deleteActivity } = useLiveScreenContext();
  const { user } = useAuth();

  const onLongPress = () => {
    if (activity.userId === user?.id || activity.userId === user?.notebookId) {
      openMenu();
    }
  };

  return (
    <>
      <Pressable
        style={({ pressed }) => [
          styles.root(theme),
          pressed && styles.rootHover(theme),
        ]}
        onLongPress={onLongPress}
      >
        <View style={styles.header}>
          <Avatar
            size={24}
            name={userName}
            picture={userPicture}
            shape="square"
          />
          <View style={styles.info}>
            <View style={styles.row}>
              <Text variant="body2Weight" color="textSecondary">
                {userName}
              </Text>
              <IconCircle
                color={theme.colors.textHint}
                fill={theme.colors.textHint}
                width={2}
                height={2}
              />
              <Text variant="body2Regular" color="textSecondary">
                {getTimeString(activity)}
              </Text>
            </View>
            <View style={styles.row}>
              <RNText>
                <Text variant="body2Regular" color="textHint">
                  {action}{" "}
                </Text>
                <Text variant="body2Regular" color="textSecondary">
                  {label}
                </Text>
                <Text variant="body2Regular" color="textHint">
                  {` at`}
                </Text>
              </RNText>
              <Text variant="body2Regular" color="textMuted">
                {toHHMMSS(timestamp)}
              </Text>
            </View>
          </View>
          {icon}
        </View>
        {"comment" in activity && (
          <Text variant="body2Regular" color="textSecondary">
            {activity.comment}
          </Text>
        )}
        {"markPinType" in activity && activity.markPinType === "soundbite" && (
          <View style={styles.soundbite(theme)}>
            <View style={styles.soundbiteLeading}>
              <IconSoundbite
                color={theme.colors.commandPrimaryDefault}
                width={20}
                height={20}
              />
              <Text variant="body2Regular" color="textSecondary">
                Soundbite
              </Text>
            </View>
            <Text variant="body2Regular" color="textHint">
              {(activity.futureOffsetSeconds || 0) +
                (activity.pastOffsetSeconds || 0)}{" "}
              Sec
            </Text>
          </View>
        )}
      </Pressable>
      <BottomSheetMenuModal
        isOpen={isOpenMenu}
        close={closeMenu}
        items={[
          {
            label: "Delete",
            onPress: () => {
              deleteActivity(activity);
            },
          },
        ]}
      />
    </>
  );
};

const styles = createStyles({
  root: (theme) => ({
    padding: 20,
    flexDirection: "column",
    width: "100%",
    gap: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.colors.borderStaticDefault,
    backgroundColor: theme.colors.layerDefault,
    ...vars.elevations[1],
  }),
  rootHover: (theme) => ({
    backgroundColor: theme.colors.layerMuted,
  }),
  header: {
    flexDirection: "row",
    gap: 12,
  },
  info: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 4,
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
    flexWrap: "wrap",
  },
  icon: {
    width: 44,
    height: 44,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  soundbite: (theme) => ({
    padding: 16,
    flexDirection: "row",
    backgroundColor: theme.colors.layerSubtle,
    borderRadius: 4,
    justifyContent: "space-between",
  }),
  soundbiteLeading: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
});
