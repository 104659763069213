// Import the native module. On web, it will be resolved to MediaSession.web.ts
// and on native platforms to MediaSession.ts
import { Platform } from "react-native";
import MediaSessionModule from "./src/MediaSessionModule";

export function setActionHandler(
  action: MediaSessionAction,
  handler: MediaSessionActionHandler | null,
) {
  MediaSessionModule.setActionHandler(action, handler);
}

export function setMetadata(metadata: MediaMetadataInit | null): void {
  MediaSessionModule.setMetadata(metadata);
}

export function setPositionState(state: MediaPositionState): void {
  MediaSessionModule.setPositionState(state);
}

export function setPlaybackState(state: MediaSessionPlaybackState): void {
  MediaSessionModule.setPlaybackState(state);
}

export function setNotificationChannel(
  notificationChannelId: string,
  notificationid: number,
): void {
  if (Platform.OS !== "android") return;
  MediaSessionModule.setNotificationChannel(
    notificationChannelId,
    notificationid,
  );
}
