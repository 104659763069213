export enum PROCESS_MEETING_STATUS {
  NOT_STARTED = "not_started",
  STARTED = "started",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum MEETING_PUPPET_STATUS {
  STARTED = "started",
  ACTIVE = "active",
  PROCESSING = "processing",
  CONNECT_FAIL = "connectfail",
  ENDED = "ended",
  DELAYED = "delayed",
  FAILED = "failed",
}

export enum SKIP_SUMMARY_REASONS {
  TRANSCRIPTION_FROM_CHROME_EXTENSION = "Meeting was transcribed using Chrome Extension",
  TRANSCRIPTION_FROM_MOBILE = "Meeting was transcribed using Mobile App",
  UNLIMITED_TRANSCRIPTION_SHARE_ALL = "User is on unlimited transcriptions because of share all setting",
  NOT_ENOUGH_CAPTIONS = "Summary not processed because it did not match the minimum",
  NO_ACTIVE_EXTENSIONS = "No active extensions found for this user",
}
