import {
  LocalRecordingsDialog,
  UnuploadedRecordingBanner,
  useRecorder,
} from "@/features/live-record";
import { useBooleanState } from "@/utils/states";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useLayoutEffect, type FC } from "react";
import { StyleSheet, View } from "react-native";
import type { ParamList } from "../types";
import {
  RecordHeaderRight,
  RecordHeaderTitle,
} from "./components/RecordHeader";
import { RecordScreenContextProvider } from "./RecordScreenContext";
import { RecordView } from "./RecordView";

export const RecordScreen: FC<NativeStackScreenProps<ParamList, "Record">> = ({
  route,
  navigation,
}) => {
  const [isOpenRecordings, openRecordings, closeRecordings] = useBooleanState();

  const { status } = useRecorder();

  useLayoutEffect(() => {
    if (route.params?.action === "local-recordings") {
      openRecordings();
    }

    navigation.setOptions({
      headerTitle: () => <RecordHeaderTitle />,
      headerRight: () => <RecordHeaderRight openRecordings={openRecordings} />,
    });
  }, [route.params?.action, openRecordings, navigation]);

  return (
    <RecordScreenContextProvider>
      <View style={styles.root}>
        <UnuploadedRecordingBanner
          openRecordings={openRecordings}
          state={status.state}
          streamingState={status.streamingState}
        />
        <RecordView />
        <LocalRecordingsDialog
          isOpen={isOpenRecordings}
          close={closeRecordings}
        />
      </View>
    </RecordScreenContextProvider>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
