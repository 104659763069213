export class AudioRecorder {
  private audioContext: AudioContext | null = null;
  private mediaStream: MediaStream | null = null;
  private mediaStreamSource: MediaStreamAudioSourceNode | null = null;
  private isRecording = false;
  private audioDataCallback: ((data: Uint8Array) => void) | null = null;
  private sampleRate: number;
  private totalSamples = 0; // Track total samples to calculate duration

  // Holds the latest meter reading in dBFS
  private currentMeterValue = -Infinity;

  constructor(callback: (data: Uint8Array) => void, sampleRate: number) {
    this.audioDataCallback = callback;
    this.sampleRate = sampleRate;
  }

  async startRecording(): Promise<void> {
    if (this.isRecording) {
      throw new Error("Recording is already in progress.");
    }
    this.totalSamples = 0; // Reset on every new start

    this.audioContext = new AudioContext({ sampleRate: this.sampleRate });

    // Load and register the AudioWorkletProcessor
    await this.audioContext.audioWorklet.addModule(
      URL.createObjectURL(this.getWorkletProcessorBlob()),
    );

    this.mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    this.mediaStreamSource = this.audioContext.createMediaStreamSource(
      this.mediaStream,
    );

    const audioWorkletNode = new AudioWorkletNode(
      this.audioContext,
      "pcm-processor",
      { processorOptions: { sampleRate: this.sampleRate } },
    );

    audioWorkletNode.port.onmessage = (event) => {
      const { type } = event.data;

      // PCM data (raw 16-bit samples)
      if (type === "data" && this.isRecording && this.audioDataCallback) {
        const data = new Uint8Array(event.data.pcm);
        this.totalSamples += data.length / 2; // Each sample is 2 bytes
        this.audioDataCallback(data);
      }
      // Meter data (dBFS)
      else if (type === "meter") {
        this.currentMeterValue = event.data.dBFS;
      }
    };

    this.mediaStreamSource.connect(audioWorkletNode);
    audioWorkletNode.connect(this.audioContext.destination);
    this.isRecording = true;
  }

  // Duration in seconds
  public getCurrentDuration(): number {
    return this.totalSamples / this.sampleRate;
  }

  // Current level in dBFS
  //  -Infinity means silence (or below floating point precision)
  public getCurrentMeter(): number {
    return this.currentMeterValue;
  }

  public pauseRecording(): void {
    if (!this.isRecording) {
      throw new Error("No active recording to pause.");
    }
    this.isRecording = false;
  }

  public resumeRecording(): void {
    if (this.isRecording) {
      throw new Error("Recording is already in progress.");
    }
    this.isRecording = true;
  }

  public stopRecording(): void {
    if (!this.audioContext) {
      throw new Error("Recording was not started.");
    }

    this.isRecording = false;

    if (this.mediaStreamSource) {
      this.mediaStreamSource.disconnect();
    }

    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }

    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
      this.mediaStream = null;
    }
  }

  private getWorkletProcessorBlob(): Blob {
    const processorCode = `
      class PCMProcessor extends AudioWorkletProcessor {
        constructor(options) {
          super();
          this.sampleRate = options.processorOptions.sampleRate;
        }

        process(inputs, outputs, parameters) {
          const input = inputs[0];
          if (input && input[0]) {
            const floatSamples = input[0];

            //
            // --- Convert float samples (-1.0 to +1.0) to 16-bit PCM ---
            //
            const pcmData = new Uint8Array(floatSamples.length * 2);
            for (let i = 0; i < floatSamples.length; i++) {
              const sample = Math.max(-1, Math.min(1, floatSamples[i])); // Clamp
              const intSample = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
              pcmData[i * 2] = intSample & 0xFF;
              pcmData[i * 2 + 1] = (intSample >> 8) & 0xFF;
            }

            //
            // --- Compute dBFS for metering (using RMS) ---
            //
            let sumSquares = 0;
            for (let i = 0; i < floatSamples.length; i++) {
              const s = floatSamples[i];
              sumSquares += s * s;
            }
            const rms = Math.sqrt(sumSquares / floatSamples.length);
            let dBFS = 20 * Math.log10(rms);
            // Handle case where rms=0 -> log10(0) = -Infinity
            if (!Number.isFinite(dBFS)) {
              dBFS = -Infinity;
            }

            //
            // --- Post messages back to main thread ---
            //
            this.port.postMessage({ type: 'data', pcm: pcmData.buffer }, [pcmData.buffer]);
            this.port.postMessage({ type: 'meter', dBFS });
          }
          return true; // Keep processor alive
        }
      }

      registerProcessor('pcm-processor', PCMProcessor);
    `;
    return new Blob([processorCode], { type: "application/javascript" });
  }
}
