import { Text } from "@/components/Typography";
import { createStyles, useTheme } from "@/styles";
import type { FC, PropsWithChildren } from "react";
import { Pressable } from "react-native";

export const Chip: FC<
  PropsWithChildren<{
    onPress?: () => void;
    selected?: boolean;
  }>
> = ({ selected, onPress, children }) => {
  const theme = useTheme();
  return (
    <Pressable
      style={({ pressed }) => [
        styles.root,
        styles.base(theme),
        selected && styles.selected(theme),
        pressed && styles.pressed,
      ]}
      onPress={onPress}
      role="checkbox"
      aria-checked={selected}
    >
      <Text color={selected ? "commandContrastDefault" : "textSecondary"}>
        {children}
      </Text>
    </Pressable>
  );
};

const styles = createStyles({
  root: {
    borderWidth: 1,
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 9999,
  },
  pressed: {
    opacity: 0.5,
  },
  base: (theme) => ({
    backgroundColor: theme.colors.layerDefault,
    borderColor: theme.colors.borderStaticSubtle,
  }),
  selected: (theme) => ({
    backgroundColor: theme.colors.interactivePrimaryDefault,
    borderColor: theme.colors.interactivePrimaryDefault,
  }),
});
