import { Breakpoint, createStyles, vars } from "@/styles";

export const sharedStyles = createStyles({
  container: {
    paddingHorizontal: 24,
    maxWidth: 728,
    width: "100%",
    marginHorizontal: "auto",
    alignSelf: "center",
  },
  tabPanel: {
    width: "100%",
    height: "100%",
  },
  fab: (theme) => ({
    backgroundColor: theme.colors.layerDefault,
    width: 48,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.colors.borderStaticDefault,
    ...vars.elevations[2],
  }),
  fabBadge: (theme) => ({
    position: "absolute",
    top: -8,
    right: -8,
    width: 24,
    textAlign: "center",
    backgroundColor: theme.colors.layerBrandLight2,
    color: theme.colors.textBrand,
    paddingHorizontal: 4,
    borderRadius: 16,
  }),
});

export const layoutStyles = createStyles({
  side: (theme) => ({
    flexDirection: "column",
    ...(theme.breakpoint === Breakpoint.Large ? { width: 340 } : { flex: 1 }),
  }),
  sideLarge: (theme) => ({
    flexDirection: "column",
    ...(theme.breakpoint === Breakpoint.Large ? { width: 432 } : { flex: 1 }),
  }),
  sideTabs: (theme) => ({
    ...(theme.breakpoint === Breakpoint.Large
      ? { borderRightWidth: 1 }
      : { borderLeftWidth: 1 }),
    borderColor: theme.colors.borderStaticSubtle,
    padding: 8,
    gap: 12,
    width: 49,
  }),
});
