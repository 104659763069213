import { IconArrowUpRight, IconCheckCircle } from "@/assets/svg";
import { LinkButton } from "@/components/Button";
import { MessageView } from "@/components/Message";
import { PROCESS_MEETING_STATUS, RouteNames } from "@/constants";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import type { FC } from "react";

export const NonLiveMeetingView: FC<{
  meeting: Meeting;
}> = ({ meeting }) => {
  if (meeting.processMeetingStatus === PROCESS_MEETING_STATUS.COMPLETED) {
    return (
      <MessageView
        Icon={IconCheckCircle}
        iconColor="rainbowStaticPurpleText"
        title={`Your meeting "${meeting.title}" has been transcribed successfully`}
        description="All bookmarks and comments are added to your meeting recording"
        action={
          <LinkButton
            href={{
              pathname: RouteNames.Notepad,
              params: {
                id: meeting.id,
              },
            }}
            Icon={IconArrowUpRight}
          >
            View recording
          </LinkButton>
        }
      />
    );
  }

  return (
    <MessageView
      title={`Your meeting "${meeting.title}" is being processed`}
      description="Your meeting will be available shortly"
      action={
        <LinkButton
          href={{
            pathname: RouteNames.MeetingStatus,
          }}
          Icon={IconArrowUpRight}
        >
          View meeting status
        </LinkButton>
      }
    />
  );
};
