import { toast } from "@/components/Toast";
import { Config, RouteNames } from "@/constants";
import {
  captureException,
  getErrorMessage,
  setSentryContext,
  setSentryTag,
} from "@/errors";
import { appReviewApi } from "@/features/app-review";
import { createMeetingForUpload } from "@/features/upload";
import {
  apolloClient,
  GetTranscriptFfAuthDocument,
  type GetTranscriptFfAuthQuery,
  type GetTranscriptFfAuthQueryVariables,
} from "@/graphql";
import { Logger } from "@/logger";
import * as AudioRecord from "@/modules/audio-record";
import { AudioRecordErrorCode } from "@/modules/audio-record";
import { RootNavigation } from "@/screens/RootNavigation";
import { tracker, TRACKING_EVENTS } from "@/tracking";
import { createDirectoryIfNotExist, getFileInfo } from "@/utils/file";
import { getExtensionFromMimeType } from "@/utils/mime";
import { CodedError } from "expo-modules-core";
import { useEffect } from "react";
import { Platform } from "react-native";
import { create } from "zustand";
import { mobileApi } from "../mobile-api";
import { getUploadAndMediaUrl } from "../upload/utils";
import {
  MIME_TYPE_RECORDING_OPTIONS_MAP,
  RECORDING_DIRECTORY,
} from "./constants";
import { AudioInputUnavailableError } from "./errors";
import { localRecordStoreApi } from "./local-record.store";
import type { RecordingContext, RecordingStates } from "./types";
import {
  getDefaultRecordingTitle,
  getRecordingErrorSentryFingerprint,
  requestMicrophonePermissionOrFail,
} from "./utils";

export type StartRecordingOptions = Required<
  Pick<
    RecordingContext,
    "title" | "notesPrivacy" | "usingStream" | "language" | "mimeType"
  >
>;

interface RecorderStoreValue {
  status: RecordingStates;
  context: RecordingContext | null;

  setContext: (context: RecordingContext) => void;

  addEventListener: typeof AudioRecord.addEventListener;

  start(ctx: StartRecordingOptions): Promise<{
    fileUri: string;
    context: RecordingContext;
  }>;
  startUpload(): Promise<void>;
  stop(): Promise<{
    fileUri: string;
    context: RecordingContext;
  }>;
  pause(): Promise<void>;
  resume(): Promise<void>;
  release(): void;
}

const logger = new Logger("recorder");

export const useRecorder = create<RecorderStoreValue>((set, get) => {
  return {
    status: AudioRecord.getStatus(),
    context: AudioRecord.getContext() as RecordingContext,
    addEventListener: AudioRecord.addEventListener,

    setContext(context) {
      AudioRecord.setContext(context);
      set({ context });
    },

    async start(ctx) {
      logger.info("record starting", ctx);

      try {
        await requestMicrophonePermissionOrFail();

        // create directory if it doesn't exist
        if (Platform.OS !== "web") {
          await createDirectoryIfNotExist(RECORDING_DIRECTORY);
        }

        const startTime = new Date();

        const newMeeting = await createMeetingForUpload({
          title: ctx.title || getDefaultRecordingTitle(startTime),
          notesPrivacy: ctx.notesPrivacy,
          ...(ctx.language && { customLanguage: ctx.language }),
          startTime,
          client: `mobile-record`,
        });

        const mimeType = ctx.mimeType;
        const recordingOptions = MIME_TYPE_RECORDING_OPTIONS_MAP[mimeType];
        if (!recordingOptions) {
          throw new Error("Invalid MIME type");
        }

        const fileExtension = getExtensionFromMimeType(mimeType);

        const fileUri = `${RECORDING_DIRECTORY}${newMeeting.id}.${fileExtension}`;

        let socketAuthToken: string | undefined | null;
        if (ctx.usingStream) {
          const transcriptTokenResponse = await apolloClient.query<
            GetTranscriptFfAuthQuery,
            GetTranscriptFfAuthQueryVariables
          >({
            query: GetTranscriptFfAuthDocument,
            variables: {
              meetingId: newMeeting.id,
              source: "mobile-ff",
            },
          });
          socketAuthToken = transcriptTokenResponse.data.getTranscriptFFAuth;
          if (!socketAuthToken) {
            throw new Error("Failed to get transcript token");
          }
        }

        await AudioRecord.startRecording({
          ...recordingOptions,
          meetingId: newMeeting.id,
          socketUrl: Config.REALTIME_FF_HOST_WS,
          ...(socketAuthToken && { socketAuthToken }),
          fileUri,
          useUploader: !ctx.usingStream,
          notificationAndroid: {
            channelId: "ff_recording",
            channelName: "Recording",
            notificationId: 100,
            contentTitle: "Fireflies",
            contentText: "A recording is in progress.",
            uploadContentText: "Uploading recording...",
          },
        });

        logger.info(`recording started to ${fileUri}`);

        const context: RecordingContext = {
          ...ctx,
          title: ctx.title || "",
          fileUri: fileUri,
          meetingId: newMeeting.id,
          startTime: startTime.getTime(),
        };

        AudioRecord.setContext(context);
        set({ context });

        tracker.track(TRACKING_EVENTS.AUDIO_RECORD_STARTED, {
          meetingId: context.meetingId,
          language: context.language,
          notesPrivacy: context.notesPrivacy,
          usingStream: context.usingStream,
          mimeType: context.mimeType,
        });

        return {
          fileUri,
          context,
        };
      } catch (err) {
        captureException(new Error(getErrorMessage(err, "recorder: start")), {
          contexts: {
            status: { ...get().status },
            context: { ...get().context },
          },
          tags: {
            section: "record-store",
          },
          fingerprint: [`{{ default }}`, String(err.code || err.message)],
        });

        set({ status: AudioRecord.getStatus() });
        if (
          err instanceof CodedError &&
          err.code === AudioRecordErrorCode.ErrAudioInputUnavailable
        ) {
          throw new AudioInputUnavailableError();
        } else {
          throw err;
        }
      }
    },
    async stop() {
      logger.info("record stopping");

      try {
        // need to get context before it resets to 0
        const ctx = {
          ...get().context,
          endTime: Date.now(),
          durationMillis: get().status?.durationMillis,
          // even if fileUri is not set here, it will be set in the next line
        } as RecordingContext;

        const { uri } = await AudioRecord.stopRecording();

        ctx.fileUri = uri;

        logger.info(`record stopped`, ctx);

        set({ context: ctx });

        tracker.track(TRACKING_EVENTS.AUDIO_RECORD_STOPPED, {
          meetingId: ctx.meetingId,
          language: ctx.language,
          notesPrivacy: ctx.notesPrivacy,
          durationMins: Math.round((ctx.durationMillis || 0) / 1000 / 60),
          usingStream: ctx.usingStream,
          mimeType: ctx.mimeType,
        });

        if (!ctx.usingStream) {
          await startUploadRecording(ctx);
        }

        return {
          fileUri: uri,
          context: ctx,
        };
      } catch (err) {
        captureException(new Error(getErrorMessage(err, "recorder: stop")), {
          tags: {
            section: "record-store",
          },
          fingerprint: getRecordingErrorSentryFingerprint(err),
        });

        // reset states if error
        set({ status: AudioRecord.getStatus() });

        throw err;
      }
    },
    async pause() {
      logger.info("record pausing");

      const ctx = get().context;
      const status = get().status;

      try {
        await AudioRecord.pauseRecording();

        tracker.track(TRACKING_EVENTS.AUDIO_RECORD_PAUSED, {
          meetingId: ctx?.meetingId,
          language: ctx?.language,
          notesPrivacy: ctx?.notesPrivacy,
          durationMins: Math.round((status.durationMillis || 0) / 1000 / 60),
          usingStream: ctx?.usingStream,
          mimeType: ctx?.mimeType,
        });
      } catch (err) {
        captureException(new Error(getErrorMessage(err, "recorder: pause")), {
          tags: {
            section: "record-store",
          },
          fingerprint: getRecordingErrorSentryFingerprint(err),
        });

        // reset states if error
        set({ status: AudioRecord.getStatus() });

        throw err;
      }
    },
    async resume() {
      logger.info("record resuming");

      try {
        await AudioRecord.resumeRecording();

        const ctx = get().context;
        const status = get().status;

        tracker.track(TRACKING_EVENTS.AUDIO_RECORD_RESUMED, {
          meetingId: ctx?.meetingId,
          language: ctx?.language,
          notesPrivacy: ctx?.notesPrivacy,
          durationMins: Math.round((status.durationMillis || 0) / 1000 / 60),
          usingStream: ctx?.usingStream,
          mimeType: ctx?.mimeType,
        });
      } catch (err) {
        captureException(new Error(getErrorMessage(err, "recorder: resume")), {
          tags: {
            section: "record-store",
          },
          fingerprint: getRecordingErrorSentryFingerprint(err),
        });

        set({ status: AudioRecord.getStatus() });

        if (
          err instanceof CodedError &&
          err.code === AudioRecordErrorCode.ErrAudioInputUnavailable
        ) {
          throw new AudioInputUnavailableError();
        } else {
          throw err;
        }
      }
    },
    async startUpload() {
      const ctx = get().context;
      if (!ctx) {
        throw new Error("Recording context is not set");
      }
      return startUploadRecording(ctx);
    },
    release() {
      AudioRecord.release();
    },
  };
});

async function startUploadRecording(ctx: RecordingContext) {
  try {
    const fileInfo = await getFileInfo(ctx.fileUri);

    if (!ctx.meetingId) {
      throw new Error("Meeting ID is not set");
    }

    // get upload url and start upload
    const { uploadUrl } = await getUploadAndMediaUrl({
      mimeType: ctx.mimeType,
      size: fileInfo.size,
      durationMillis: ctx.durationMillis,
      meetingId: ctx.meetingId,
    });

    AudioRecord.startUpload(uploadUrl);

    tracker.track(TRACKING_EVENTS.AUDIO_RECORD_UPLOAD_STARTED, {
      meetingId: ctx.meetingId,
      durationMins: Math.ceil((ctx.durationMillis || 0) / 1000 / 60),
    });
  } catch (err) {
    captureException(
      new Error(getErrorMessage(err, "recorder: startUploadRecording")),
      {
        tags: {
          section: "record-store",
          meetingId: ctx.meetingId,
        },
        contexts: {
          status: { ...useRecorder.getState().status },
          context: { ...useRecorder.getState().context },
        },
        fingerprint: getRecordingErrorSentryFingerprint(err),
      },
    );

    AudioRecord.setStreamingError(err.message);

    throw err;
  }
}

export const recorderApi = useRecorder.getState() as Pick<
  RecorderStoreValue,
  | "start"
  | "stop"
  | "pause"
  | "resume"
  | "addEventListener"
  | "setContext"
  | "startUpload"
  | "release"
>;

export const useRecordListeners = () => {
  useEffect(() => {
    const statusListener = AudioRecord.addEventListener("status", (status) => {
      useRecorder.setState({ status });
    });

    const streamCompleteListener = AudioRecord.addEventListener(
      "streamCompleted",
      async (e) => {
        const meetingId = e.meetingId;
        if (!meetingId) return;

        try {
          const record = localRecordStoreApi.findByMeetingId(meetingId);
          localRecordStoreApi.updateByMeetingId(meetingId, {
            streamed: true,
          });

          const durationMins = Math.ceil(
            (record?.durationMillis || 0) / 1000 / 60,
          );

          if (!record?.mimeType) {
            // We can ensure new versions of the app will have the mimeType set
            throw new Error("MIME type is not set");
          }

          await mobileApi.completeUpload(meetingId, {
            contentType: record.mimeType,
          });

          localRecordStoreApi.updateByMeetingId(meetingId, {
            uploaded: true,
          });

          if (record?.usingStream) {
            tracker.track(TRACKING_EVENTS.AUDIO_RECORD_STREAM_COMPLETED, {
              meetingId: meetingId,
              durationMins,
            });
          } else {
            tracker.track(TRACKING_EVENTS.AUDIO_RECORD_UPLOAD_COMPLETED, {
              meetingId: meetingId,
              durationMins,
            });
          }

          toast({
            message: "Your recording is being processed.",
            type: "success",
            duration: 10000,
            action: {
              onPress: () => {
                RootNavigation.navigate(RouteNames.MeetingStatus);
              },
              label: "Status",
            },
          });

          appReviewApi.increaseRecordCount();
        } catch (err) {
          toast({
            message:
              "Failed to upload recording. Please try again later in the Local Recordings screen.",
            type: "error",
            duration: 10000,
          });

          captureException(
            new Error(getErrorMessage(err, "recorder: streamCompleted")),
            {
              tags: {
                section: "record-store",
                meetingId,
              },
              contexts: {
                error: {
                  ...err,
                },
                status: { ...useRecorder.getState().status },
                context: { ...useRecorder.getState().context },
              },
              fingerprint: getRecordingErrorSentryFingerprint(err),
            },
          );
        }
      },
    );
    const streamFailedListener = AudioRecord.addEventListener(
      "streamFailed",
      async (e) => {
        const meetingId = e.meetingId;

        captureException(
          new Error(
            getErrorMessage(
              {
                message: e.message,
              },
              "recorder: streamFailed",
            ),
          ),
          {
            tags: {
              section: "record-store",
              meetingId,
              streamType: e.type,
            },
            contexts: {
              error: {
                ...e,
              },
              status: { ...useRecorder.getState().status },
              context: { ...useRecorder.getState().context },
            },
            fingerprint: getRecordingErrorSentryFingerprint(e),
          },
        );
      },
    );
    const errorListener = AudioRecord.addEventListener("error", (err) => {
      captureException(
        new Error(`recorder: error: ${err.message} (${err.code})`),
        {
          tags: {
            section: "record-store",
            meetingId: useRecorder.getState().context?.meetingId,
          },
          contexts: {
            error: {
              ...err,
            },
            status: { ...useRecorder.getState().status },
            context: { ...useRecorder.getState().context },
          },
          extra: {
            stack: err.stack,
          },
          fingerprint: getRecordingErrorSentryFingerprint(err),
        },
      );
      toast({
        message: err.message,
        type: "error",
      });
    });
    const logListener = AudioRecord.addEventListener("log", (event) => {
      logger.info(`[AudioRecordModule] ${event.message}`);
    });
    const interruptListener = AudioRecord.addEventListener(
      "interrupt",
      (event) => {
        logger.info(`[AudioRecordModule] interrupted: ${event.reason}`);
      },
    );

    return () => {
      statusListener.remove();
      streamCompleteListener.remove();
      streamFailedListener.remove();
      errorListener.remove();
      logListener.remove();
      interruptListener.remove();
    };
  }, []);
};

let recordingTrackTimer: ReturnType<typeof setInterval> | undefined;
useRecorder.subscribe((state, prevState) => {
  if (state.status.state !== prevState.status.state) {
    clearInterval(recordingTrackTimer);
    if (state.status.state !== "inactive") {
      setSentryContext("recorder", {
        status: state.status,
        context: state.context,
      });
      setSentryTag("is_recording", "true");

      if (state.status.state === "recording") {
        recordingTrackTimer = setInterval(
          () => {
            const latestState = useRecorder.getState();
            setSentryContext("recorder", {
              status: latestState.status,
              context: latestState.context,
            });
          },
          // track every 2 minutes
          2 * 60 * 1000,
        );
      }
    } else {
      setSentryContext("recorder", null);

      setSentryTag("is_recording", undefined);
    }
  }
});
