import { IconPlus } from "@/assets/svg";
import { Button } from "@/components/Button";
import { useOpenAddModal } from "@/features/global-modals";
import { useRecorder } from "@/features/live-record";
import { createStyles } from "@/styles";
import type { FC } from "react";

export const FloatingAddButton: FC = () => {
  const { status: recordStatus } = useRecorder();

  const openAddModal = useOpenAddModal();

  if (recordStatus.state !== "inactive" || !openAddModal) {
    return null;
  }

  return (
    <Button
      Icon={IconPlus}
      style={styles.button}
      onPress={() => {
        openAddModal("home/add-button");
      }}
    >
      Transcribe
    </Button>
  );
};

export const SolidAddButton: FC = () => {
  const { status: recordStatus } = useRecorder();

  const openAddModal = useOpenAddModal();

  if (recordStatus.state !== "inactive" || !openAddModal) {
    return null;
  }

  return (
    <Button
      Icon={IconPlus}
      onPress={() => {
        openAddModal("home/add-button");
      }}
    >
      Transcribe
    </Button>
  );
};

const styles = createStyles({
  button: {
    position: "absolute",
    bottom: 12,
    minWidth: 142,
    left: "50%",
    marginLeft: -142 / 2,
    borderRadius: 9999,
    paddingHorizontal: 0,
  },
});
