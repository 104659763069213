import { BottomSheetMenuModal } from "@/components/BottomSheet";
import { Button } from "@/components/Button";
import { Dialog } from "@/components/Dialog";
import { LoadingSpinner } from "@/components/Loading";
import { Separator } from "@/components/Separator";
import { Switch } from "@/components/Switch";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { Config, USE_COOKIE_AUTH } from "@/constants";
import {
  NetworkError,
  captureException,
  getResponseErrorMessage,
} from "@/errors";
import { authApi } from "@/features/auth";
import { createStyles } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import { triggerDownloadFile } from "@/utils/file";
import { isTruthy } from "@/utils/object";
import { useBooleanState } from "@/utils/states";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import { useState, type FC } from "react";
import { View } from "react-native";

const TranscriptMeetingDownloadDialogContent: FC<{
  meeting: Meeting;
  close: () => void;
}> = ({ meeting, close }) => {
  const [includeTimestamp, setIncludeTimestamp] = useState(true);
  const [includeSpeaker, setIncludeSpeaker] = useState(true);

  const [isProcessing, setIsProcessing] = useState(false);

  const slugTitle = meeting.title
    .replace(/[^a-z0-9]/gi, "-")
    .replace(/-+/g, "-");

  const doDownload = async (format: string) => {
    if (isProcessing) return;
    try {
      setIsProcessing(true);
      const authTokens = await authApi.getOrRefreshTokens();
      const res = await fetch(
        `${Config.DOWNLOAD_URL}/download/${format}/${slugTitle}/${meeting.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: authTokens?.accessToken,
            speaker: includeSpeaker,
            timestamp: includeTimestamp,
          }),
          ...(USE_COOKIE_AUTH && { credentials: "include" }),
        },
      ).catch(() => {
        throw new NetworkError();
      });
      if (!res.ok) {
        throw new Error(await getResponseErrorMessage(res));
      }

      const json = await res.json();

      if (!json.ok) {
        throw new Error(`Download failed: Received non-ok status`);
      }

      triggerDownloadFile(json.downloadUrl);

      tracker.track(TRACKING_EVENTS.USER_DOWNLOADED_TRANSCRIPT, {
        meetingId: meeting.id,
        transcriptFormat: format,
      });

      close();
    } catch (error) {
      captureException(error, {
        contexts: {
          download: {
            type: "transcript",
            format,
            meetingId: meeting.id,
          },
        },
        tags: {
          meetingId: meeting.id,
          section: "meeting-download-dialog",
        },
      });

      toast({
        title: "Could not download transcript",
        message: error.message,
        type: "error",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Dialog.Header>Download Transcript</Dialog.Header>

      {isProcessing ? (
        <View style={styles.loading}>
          <LoadingSpinner />
        </View>
      ) : (
        <>
          <View style={styles.header}>
            <Text variant="label2Regular" color="textSecondary">
              Select the transcript format to download
            </Text>
          </View>
          <View style={styles.menu}>
            <Button variant="outlined" onPress={() => doDownload("docx")}>
              Word (DOCX)
            </Button>
            <Button variant="outlined" onPress={() => doDownload("pdf")}>
              PDF
            </Button>
            <Button variant="outlined" onPress={() => doDownload("srt")}>
              SRT
            </Button>
            <Button variant="outlined" onPress={() => doDownload("csv")}>
              CSV
            </Button>
            <Button variant="outlined" onPress={() => doDownload("json")}>
              JSON
            </Button>
          </View>
          <Separator />
          <View style={styles.footer}>
            <View style={styles.switchControl}>
              <Switch
                value={includeTimestamp}
                onValueChange={setIncludeTimestamp}
                aria-label="Include timestamp in transcript download"
              />
              <Text color="textMuted">Include timestamp</Text>
            </View>
            <View style={styles.switchControl}>
              <Switch
                value={includeSpeaker}
                onValueChange={setIncludeSpeaker}
                aria-label="Include speakers in transcript download"
              />
              <Text color="textMuted">Include speakers</Text>
            </View>
          </View>
        </>
      )}
    </>
  );
};

export const MeetingDownloadDialog: FC<{
  meeting: Meeting;
  isOpen: boolean;
  close: () => void;
}> = ({ meeting, isOpen, close }) => {
  const [isOpenTranscript, openTranscript, closeTranscript] = useBooleanState();

  return (
    <>
      <BottomSheetMenuModal
        isOpen={isOpen}
        close={close}
        items={[
          !!meeting.audio_url && {
            label: "Download Audio",
            onPress() {
              triggerDownloadFile(meeting.audio_url as string);
              tracker.track(TRACKING_EVENTS.USER_DOWNLOADED_RECORDING, {
                meetingId: meeting.id,
                sourceType: "audio",
              });
            },
          },
          !!meeting.video_url && {
            label: "Download Video",
            onPress() {
              triggerDownloadFile(meeting.video_url as string);
              tracker.track(TRACKING_EVENTS.USER_DOWNLOADED_RECORDING, {
                meetingId: meeting.id,
                sourceType: "video",
              });
            },
          },
          !!meeting.captions?.length && {
            label: "Download Transcript",
            onPress: openTranscript,
          },
        ].filter(isTruthy)}
      />
      <Dialog.Root
        variant="basic"
        isOpen={isOpenTranscript}
        close={closeTranscript}
      >
        <TranscriptMeetingDownloadDialogContent
          meeting={meeting}
          close={closeTranscript}
        />
      </Dialog.Root>
    </>
  );
};

const styles = createStyles({
  header: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  menu: {
    flexDirection: "column",
    padding: 16,
    gap: 8,
  },
  item: {},
  footer: {
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: 16,
    gap: 8,
  },
  switchControl: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  loading: {
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});
