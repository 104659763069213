import { useGetCurrentUserQuery } from "@/graphql";
import { isFreeUser as checkIfUserIsFree } from "@firefliesai/payments-ff.utils";
import { useAuth } from "../auth";

export const useBilling = () => {
  const { user } = useAuth();
  const { data, loading } = useGetCurrentUserQuery({
    fetchPolicy: "cache-and-network",
    skip: !user,
  });
  const isFreeUser = !!user && checkIfUserIsFree(user.paidUser);
  const stripeData = data?.user?.teamStripeAccount?.[0];

  /**
   * AI Prompts
   */
  const aiExtensionsSubscription = stripeData?.aiExtensionsSubscription;
  const teamPromptsConsumed = data?.user?.teamPromptsConsumed;
  const isAllowedToUsePrompts = !!data?.user?.isAllowedToUsePrompts;

  /**
   * Storage consumption in minutes
   */
  const teamMinutesConsumed = data?.user?.teamMinutesConsumed;
  const reachedTeamMinutesConsumedLimit = !!(
    teamMinutesConsumed &&
    !teamMinutesConsumed.isInfinity &&
    teamMinutesConsumed.minutesConsumed! >= teamMinutesConsumed.tierLimit!
  );

  /**
   * Number of transcription credits left
   */
  const freeMeetingsLeft = data?.user?.freeMeetingsLeft;
  // if freeMeetingsLeft is undefined, it means unlimited meetings
  const reachedFreeMeetingsLimit =
    typeof freeMeetingsLeft === "number" ? freeMeetingsLeft <= 0 : false;

  return {
    loading,
    isFreeUser,
    teamPromptsConsumed,
    isAllowedToUsePrompts,
    aiExtensionsSubscription,
    teamMinutesConsumed: data?.user?.teamMinutesConsumed,
    reachedTeamMinutesConsumedLimit,
    freeMeetingsLeft,
    reachedFreeMeetingsLimit,
  };
};
