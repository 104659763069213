import { Button } from "@/components/Button";
import { MessageView } from "@/components/Message";
import { RefreshControl } from "@/components/RefreshControl";
import { SegmentedControl } from "@/components/SegmentedControl";
import { useAuth } from "@/features/auth";
import { useTeam } from "@/features/team";
import { EnumTaskOwnerType, useFetchMore } from "@/graphql";
import { createStyles } from "@/styles";
import { useQuery } from "@apollo/client";
import type {
  CoreFeedMeetingFieldsFragment,
  CoreTaskFieldsFragment,
} from "@firefliesai/tasks-ff.graphql-client";
import { GetFeedTasksDocument } from "@firefliesai/tasks-ff.graphql-client";
import type { RouteProp } from "@react-navigation/native";
import {
  useNavigation,
  useRoute,
  useScrollToTop,
} from "@react-navigation/native";
import { useCallback, useMemo, useRef, type FC } from "react";
import type { FlatList, ListRenderItem } from "react-native";
import { View } from "react-native";
import Animated, { LinearTransition } from "react-native-reanimated";
import type { ParamList } from "src/screens/types";
import { TasksContext } from "./TasksContext";
import {
  LoadingTaskMeetingCard,
  TaskMeetingCard,
} from "./components/TaskMeetingCard";
import { TasksEmptyState } from "./components/TasksEmptyState";

enum TaskTab {
  ALL_TASKS = "all",
  MY_TASKS = "mine",
}

const LIMIT = 20;

const renderItem: ListRenderItem<CoreFeedMeetingFieldsFragment> = ({
  item,
}) => <TaskMeetingCard meeting={item} key={item._id} />;

const loadingTaskMeetingCards = (
  <>
    {Array.from({ length: 3 }).map((_, index) => (
      <LoadingTaskMeetingCard key={index} />
    ))}
  </>
);

const itemLayoutAnimation = LinearTransition.duration(250);

const TASK_TABS_DATA = [
  {
    value: TaskTab.ALL_TASKS,
    label: "All Tasks",
  },
  {
    value: TaskTab.MY_TASKS,
    label: "My Tasks",
  },
];

export const Tasks: FC = () => {
  const { user } = useAuth();
  const { userGroups } = useTeam();
  const userGroupIds = useMemo(
    () => userGroups?.map((group) => group._id) || [],
    [userGroups],
  );

  const { params } = useRoute<RouteProp<ParamList, "Home">>();
  const navigation = useNavigation();

  const taskTab = (params?.task_tab || TaskTab.ALL_TASKS) as TaskTab;
  const onChangeTaskTab = (tab: TaskTab) => {
    navigation.setParams({ task_tab: tab });
  };

  const onlyMine = taskTab === TaskTab.MY_TASKS;
  const { data, fetchMore, loading, refetch, error } = useQuery(
    GetFeedTasksDocument,
    {
      variables: {
        onlyMine,
      },
      skip: !user,
      notifyOnNetworkStatusChange: true,
    },
  );

  const [onLoadMore, fetchMoreState] = useFetchMore({
    data,
    fetchMore,
    limit: LIMIT,
    path: "getFeedTasks.meetings",
  });

  const listRef = useRef<FlatList>(null);

  const userEmail = user?.email;
  const currentUserParseId = user?.id;

  const filterMyTasks = useCallback(
    (task: CoreTaskFieldsFragment) => {
      return (
        task.ownerEmail === userEmail ||
        (!!task.ownerId &&
          userGroupIds.includes(task.ownerId) &&
          task.ownerType === EnumTaskOwnerType.UserGroup)
      );
    },
    [userEmail, userGroupIds],
  );

  const sortTasks = useCallback(
    (a: CoreTaskFieldsFragment, b: CoreTaskFieldsFragment) => {
      // Prioritize non-completed tasks over completed
      const aCompleted = a.completedAt ? 1 : 0;
      const bCompleted = b.completedAt ? 1 : 0;

      if (aCompleted !== bCompleted) {
        return aCompleted - bCompleted;
      }

      // If both tasks are non-completed, apply further sorting
      if (!a.completedAt && !b.completedAt) {
        // Sort user tasks first
        const aOwnerIdPriority = a.ownerId === currentUserParseId ? 0 : 1;
        const bOwnerIdPriority = b.ownerId === currentUserParseId ? 0 : 1;

        if (aOwnerIdPriority !== bOwnerIdPriority) {
          return aOwnerIdPriority - bOwnerIdPriority;
        }

        // Sort user user groups tasks second
        const aUserGroupPriority =
          !!a.ownerId && userGroupIds.includes(a.ownerId) ? 0 : 1;
        const bUserGroupPriority =
          !!b.ownerId && userGroupIds.includes(b.ownerId) ? 0 : 1;

        if (aUserGroupPriority !== bUserGroupPriority) {
          return aUserGroupPriority - bUserGroupPriority;
        }

        // "zzz" will sort last
        const aOwner = a.ownerId ? a.ownerId : "zzz";
        const bOwner = b.ownerId ? b.ownerId : "zzz";

        if (aOwner !== bOwner) {
          const aName =
            // @ts-expect-error groupName/name may not be defined
            a.ownerProfile?.groupName ||
            // @ts-expect-error groupName/name may not be defined
            a.ownerProfile?.name ||
            a.ownerEmail ||
            "zzz";
          const bName =
            // @ts-expect-error groupName/name may not be defined
            b.ownerProfile?.groupName ||
            // @ts-expect-error groupName/name may not be defined
            b.ownerProfile?.name ||
            b.ownerEmail ||
            "zzz";

          return aName.localeCompare(bName);
        }
      }

      // Sort completed tasks by most recently completed
      if (a.completedAt && b.completedAt) {
        const aTime = new Date(a.completedAt).getTime();
        const bTime = new Date(b.completedAt).getTime();
        return bTime - aTime;
      }

      return 0;
    },
    [currentUserParseId, userGroupIds],
  );

  const meetings = data?.getFeedTasks?.meetings;

  const shouldShowLoadingState = Boolean(loading && !meetings?.length);
  const shouldShowErrorState = Boolean(
    !loading && error && !data?.getFeedTasks,
  );
  const shouldShowEmptyState = !loading && !meetings?.length;

  useScrollToTop(listRef);

  return (
    <TasksContext.Provider value={{ onlyMine, filterMyTasks, sortTasks }}>
      <Animated.FlatList
        contentContainerStyle={styles.container}
        ref={listRef}
        scrollEnabled={!shouldShowLoadingState}
        data={meetings}
        renderItem={renderItem}
        onEndReached={onLoadMore}
        onEndReachedThreshold={0.5}
        ListHeaderComponent={
          <View style={styles.header}>
            <SegmentedControl
              data={TASK_TABS_DATA}
              value={taskTab}
              onChange={onChangeTaskTab}
            />
          </View>
        }
        ListEmptyComponent={
          shouldShowEmptyState ? loadingTaskMeetingCards : null
        }
        ListFooterComponent={
          shouldShowErrorState ? (
            <MessageView
              variant="error"
              title="Could not load tasks"
              description={error?.message}
              action={<Button onPress={() => refetch()}>Retry</Button>}
            />
          ) : shouldShowEmptyState ? (
            <TasksEmptyState />
          ) : !fetchMoreState.ended ? (
            loadingTaskMeetingCards
          ) : null
        }
        refreshControl={
          <RefreshControl
            refreshing={!!data?.getFeedTasks && loading}
            onRefresh={refetch}
          />
        }
        itemLayoutAnimation={itemLayoutAnimation}
      />
    </TasksContext.Provider>
  );
};

const styles = createStyles({
  container: {
    maxWidth: 724,
    alignSelf: "center",
    width: "100%",
  },
  header: {
    gap: 4,
    paddingTop: 24,
    paddingHorizontal: 20,
    alignItems: "flex-start",
  },
});
