import { MEETING_PUPPET_STATUS } from "@/constants";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";

export enum LiveMeetingEvents {
  LIVE_MEETING_ACTIVITY_BROADCAST_EVENT = "live.meeting.activity.broadcast.event",
  LIVE_TRANSCRIPT_BROADCAST_EVENT = "transcription.broadcast.event",
  LIVE_MEETING_ACTIVITY_DELETED_EVENT = "live.meeting.activity.deleted.event",
  REALTIME_NOTE_BROADCAST = "realtime.note.broadcast.event",
  REALTIME_ACTION_ITEMS_BROADCAST = "realtime.action.items.broadcast.event",
  REALTIME_ACTION_ITEMS_UPDATE = "realtime.action.items.update.event",
  REALTIME_ACTION_ITEMS_UDPATE_BROADCAST = "realtime.action.items.update.broadcast.event",
}

export const isLiveMeeting = (meeting: Meeting) =>
  meeting.status === MEETING_PUPPET_STATUS.ACTIVE;

export enum TabName {
  Notes = "notes",
  Transcript = "transcript",
  Bookmarks = "bookmarks",
}
