import { Children, useEffect, useRef, type FC, type ReactElement } from "react";
import type { StyleProp, ViewStyle } from "react-native";
import type { TabsRef } from "../Tabs";
import { Tabs } from "../Tabs";

export const Carousel: FC<{
  children: Array<ReactElement>;
  delay?: number;
  disableSlide?: boolean;
  style?: StyleProp<ViewStyle>;
}> = ({ children, delay = 5000, disableSlide, style }) => {
  const childrenArr = Children.toArray(children);

  const tabRef = useRef<TabsRef>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!tabRef.current) {
        return;
      }
      const currTab = tabRef.current.index;
      const nextIndex = (currTab + 1) % childrenArr.length;
      tabRef.current.setTab(nextIndex);
    }, delay);

    return () => clearInterval(interval);
  }, [childrenArr, delay]);

  return (
    <Tabs.Root
      ref={tabRef}
      hideTabBar
      disabled={disableSlide}
      style={style}
      keyboardDismissMode="none"
    >
      {Children.map(childrenArr, (child, index) => (
        <Tabs.Panel key={index} name={String(index)} title={String(index)}>
          {child}
        </Tabs.Panel>
      ))}
    </Tabs.Root>
  );
};
