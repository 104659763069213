import { Config, USE_COOKIE_AUTH } from "@/constants";
import { NetworkError, captureException, setSentryUser } from "@/errors";
import { storage } from "@/features/storage";
import { Logger } from "@/logger";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import { add } from "date-fns";
import { Platform } from "react-native";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import type { AuthTokens, AuthUser } from "./types";
import {
  TokenExpiredError,
  getUserByAccessToken,
  refreshAccessToken,
} from "./userService";
import { isTokenExpired } from "./utils";

interface AuthStore {
  user: AuthUser | null;
  tokens: AuthTokens | null;
  login: (tokens: AuthTokens) => Promise<void>;
  logout: (reason: string) => Promise<void>;
  refresh: () => Promise<AuthTokens | false>;
  updateUser: (updatedFields: Partial<AuthUser>) => void;
  getOrRefreshTokens: () => AuthTokens | null | Promise<AuthTokens | null>;
}

const TAG = "auth-store";

const logger = new Logger(TAG);

export const useAuth = create(
  persist<AuthStore>(
    (set, get) => {
      logger.info("intializing auth store");

      let refreshPromise: Promise<AuthTokens | false> | null = null;

      return {
        tokens: null,
        user: null,
        async login(tokens) {
          logger.info("login called");
          const authedUser = await getUserByAccessToken(tokens.accessToken);
          if (!authedUser) {
            throw new Error("Failed to get user by access token");
          }

          set({
            user: authedUser,
            tokens,
          });

          // wait a bit before tracking the login event
          // to make sure the user is set via useAuth.subscribe
          setTimeout(() => {
            tracker.track(TRACKING_EVENTS.APP_LOGGED_IN);
          }, 5000);
        },
        async refresh() {
          if (refreshPromise) return refreshPromise;
          refreshPromise = (async () => {
            logger.info(
              `refresh called. auth expires: expiresAt = ${get().tokens?.expiresAt}, refreshExpiresAt = ${get().tokens?.refreshExpiresAt}.`,
            );
            const tokens = get().tokens;
            const { accessToken, refreshToken } = tokens || {};
            if (Platform.OS !== "web" && !accessToken) {
              logger.warn("refresh called without access token");
              return false;
            }
            try {
              const refreshedTokens = await refreshAccessToken(
                accessToken || null,
                refreshToken || null,
              );

              if (!refreshedTokens) {
                this.logout("Refresh auth failed because missing tokens");
                return false;
              }

              set({
                user: await getUserByAccessToken(
                  refreshedTokens?.accessToken || null,
                ),
                ...(refreshedTokens && { tokens: refreshedTokens }),
              });

              return refreshedTokens;
            } catch (err) {
              if (err instanceof NetworkError) {
                // we don't want to log out the user if the refresh failed because of a network error
                logger.error("refresh failed because of network error");
                return false;
              }
              logger.error(`refresh failed: ${err.message}`);
              get().logout("Refresh auth failed because of an error");
              if (!(err instanceof TokenExpiredError)) {
                captureException(err as Error, {
                  tags: {
                    section: TAG,
                  },
                });
              }
              return false;
            }
          })().then((result) => {
            refreshPromise = null;
            return result;
          });
          return refreshPromise;
        },
        async logout(reason) {
          logger.info(`logout called: ${reason}`);

          set({
            user: null,
            tokens: null,
          });

          if (USE_COOKIE_AUTH) {
            // remove cookie
            fetch(`${Config.MOBILE_APP_URL}/api/auth/logout`, {
              method: "POST",
              credentials: "include",
            }).catch(() => {});
          }

          await tracker.track(TRACKING_EVENTS.APP_LOGGED_OUT, {
            reason,
          });
        },
        updateUser(updateFields: Partial<AuthUser>) {
          const currUser = get().user;
          if (!currUser) return;
          set({
            user: {
              ...currUser,
              ...updateFields,
            },
          });
        },
        async getOrRefreshTokens(): Promise<AuthTokens | null> {
          if (process.env.EXPO_PUBLIC_DEV_ACCESS_TOKEN) {
            return {
              accessToken: process.env.EXPO_PUBLIC_DEV_ACCESS_TOKEN,
              refreshToken: null,
              expiresAt: add(new Date(), { hours: 1 }).getTime(),
              refreshExpiresAt: null,
            };
          }
          const authState = get();
          if (!authState.tokens) return null;
          if (isTokenExpired(authState.tokens)) {
            return this.refresh().then((res) => res || null);
          }
          return authState.tokens;
        },
      };
    },
    {
      name: "auth",
      storage: createJSONStorage(() => storage),
      onRehydrateStorage: () => (state) => {
        logger.info("loaded auth from storage", { user: state?.user });
        void initAuth(state?.tokens);
      },
    },
  ),
);

export const authApi = useAuth.getState() as Pick<
  AuthStore,
  "login" | "logout" | "refresh" | "getOrRefreshTokens" | "updateUser"
>;

useAuth.subscribe((state, prevState) => {
  if (prevState.user !== state.user) {
    setSentryUser(state.user);
    tracker.setUser(state.user);
  }
});

async function initAuth(prevTokens: AuthStore["tokens"] | undefined) {
  try {
    const user = await getUserByAccessToken(prevTokens?.accessToken || null);

    if (!user) {
      authApi.logout("initAuth: getUserByAccessToken returns no user");
      return;
    }

    useAuth.setState({
      user,
    });
  } catch (err) {
    if (err instanceof TokenExpiredError) {
      // if the refresh fails, it will log out internally
      const res = await authApi.refresh();
      if (res) return;
    }
    // likely the refresh token is expired
    authApi.logout("Failed to get initial user by access token");
  }
}
