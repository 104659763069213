import { IconChevronLeft, IconLink2 } from "@/assets/svg";
import { IconButton } from "@/components/Button";
import { HeaderSideContainer } from "@/components/Layout";
import { SkeletonLoading } from "@/components/Loading";
import { toast } from "@/components/Toast";
import { Config } from "@/constants";
import {
  MEETING_PRIVACY_ICON_MAP,
  ShareMeetingDialog,
} from "@/features/meeting";
import type { Meeting } from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import { copyAsText } from "@/utils/clipboard";
import { useBooleanState } from "@/utils/states";
import { useQuery } from "@apollo/client";
import { GetMeetingDocument } from "@firefliesai/mobile-ff.graphql-client";
import type { NativeStackHeaderProps } from "@react-navigation/native-stack";
import type { FC } from "react";
import { View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { LoadingMeetingInfoRow, MeetingInfoRow } from "./MeetingInfoRow";

const HeaderRight: FC<{
  meeting: Meeting;
}> = ({ meeting }) => {
  const [isOpenShare, openShare, closeShare] = useBooleanState();

  const PrivacyIcon =
    MEETING_PRIVACY_ICON_MAP[meeting.notesPrivacy] ||
    MEETING_PRIVACY_ICON_MAP.owner;

  return (
    <HeaderSideContainer>
      <IconButton
        label="Share meeting"
        onPress={openShare}
        Icon={PrivacyIcon}
        variant="neutral"
      />
      <IconButton
        label="Copy meeting link"
        Icon={IconLink2}
        onPress={() => {
          copyAsText(
            `${Config.DASHBOARD_URL}/live/${meeting.id}?ref=mobile`,
          ).then(() => toast("Meeting link copied to clipboard"));
        }}
        variant="neutral"
      />
      <ShareMeetingDialog
        meeting={meeting}
        isOpen={isOpenShare}
        close={closeShare}
      />
    </HeaderSideContainer>
  );
};

export const LoadingHeaderRight: FC = () => {
  return (
    <HeaderSideContainer>
      <SkeletonLoading width={36} height={36} />
      <SkeletonLoading width={36} height={36} />
    </HeaderSideContainer>
  );
};

export const Header: FC<NativeStackHeaderProps> = ({
  navigation,
  route,
  back,
}) => {
  const meetingId = (
    route.params as {
      id: string;
    }
  ).id;

  const { data } = useQuery(GetMeetingDocument, {
    variables: {
      id: meetingId,
    },
  });

  const meeting = data?.meeting;

  const theme = useTheme();

  return (
    <SafeAreaView style={styles.root(theme)} edges={["top"]}>
      <IconButton
        label="Back"
        Icon={IconChevronLeft}
        variant="neutral"
        aria-label={back?.title || "Go back"}
        onPress={() =>
          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate("Home")
        }
        size="sm"
      />
      <View style={styles.content}>
        {meeting ? (
          <MeetingInfoRow meeting={meeting} />
        ) : (
          <LoadingMeetingInfoRow />
        )}
      </View>
      {meeting ? <HeaderRight meeting={meeting} /> : <LoadingHeaderRight />}
    </SafeAreaView>
  );
};

const styles = createStyles({
  root: (theme) => ({
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 24,
    paddingVertical: 8,
    backgroundColor: theme.colors.layerDefault,
    gap: 8,
  }),
  content: {
    flex: 1,
  },
});
