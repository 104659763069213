import { IconUpload } from "@/assets/svg";
import { Button } from "@/components/Button";
import { toast } from "@/components/Toast";
import { useAuth } from "@/features/auth";
import { useBilling } from "@/features/billing";
import { openStoragePaywallModal } from "@/features/global-modals";
import { uploadFromDocument } from "@/features/upload";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { MeetingNotesPrivacy } from "@firefliesai/mobile-ff.graphql-client";
import type { FC } from "react";
import { useCallback } from "react";

export const DocumentUploaderButton: FC = () => {
  const { user } = useAuth();

  const { reachedTeamMinutesConsumedLimit } = useBilling();

  const pickDocument = useCallback(async () => {
    if (reachedTeamMinutesConsumedLimit) {
      openStoragePaywallModal("upload/header-button");

      return;
    }
    try {
      const uploaded = await uploadFromDocument(
        {
          notesPrivacy: user?.privacy as MeetingNotesPrivacy,
        },
        {
          onError(err) {
            toast({
              type: "error",
              message: err.message,
            });
          },
        },
      );
      if (uploaded) {
        toast({
          message: "Upload has been started",
          duration: 6000,
        });
        tracker.track(TRACKING_EVENTS.FILE_UPLOADED, {
          from: "upload/header-button",
        });
      }
    } catch (err) {
      toast({
        title: "Could not upload file",
        message: err.message,
        type: "error",
      });
    }
  }, [user?.privacy, reachedTeamMinutesConsumedLimit]);

  return (
    <>
      <Button size="sm" Icon={IconUpload} onPress={pickDocument}>
        Upload
      </Button>
    </>
  );
};
