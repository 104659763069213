import { Config } from "@/constants";
import type {
  CreateMarkMutationVariables,
  GetTranscriptFfAuthQuery,
  GetTranscriptFfAuthQueryVariables,
  LiveActionItem,
  LiveInsights,
  LiveNote,
  Meeting,
  NewMeetingNoteCommentMutationVariables,
} from "@/graphql";
import { GetTranscriptFfAuthDocument } from "@/graphql";
import { Logger } from "@/logger";
import { useApolloClient } from "@apollo/client";
import type { Emitter } from "mitt";
import mitt from "mitt";
import type { FC, PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { io } from "socket.io-client";
import { InitErrorBanner } from "../components/InitErrorBanner";
import { LiveMeetingEvents } from "../constants";
import type {
  DeleteLiveMeetingActivityEvent,
  EventEmitterEventMaps,
  LiveMeetingActivity,
  LiveMeetingSocket,
  UpdateActionItem,
} from "../types";
import { useLiveActivities } from "./useLiveActivities";
import { useLiveInsights } from "./useLiveInsights";

interface LiveScreenContextValue {
  liveInsights: LiveInsights | null;
  loadingLiveInsights: boolean;
  refetchLiveInsights: () => void;
  liveActivities: LiveMeetingActivity[];
  refetchLiveActivities: () => void;
  meetingId: string;
  updateActionItem: (data: UpdateActionItem) => void;
  createMark: (variables: CreateMarkMutationVariables) => Promise<unknown>;
  createComment: (
    variables: NewMeetingNoteCommentMutationVariables,
  ) => Promise<unknown>;
  deleteActivity: (activity: LiveMeetingActivity) => Promise<unknown>;
  ee: Emitter<EventEmitterEventMaps>;
}

const LiveScreenContext = createContext({} as LiveScreenContextValue);

const logger = new Logger("LiveScreenProvider");

export const LiveScreenProvider: FC<
  PropsWithChildren<{
    meeting: Meeting;
  }>
> = ({ meeting, children }) => {
  const [initError, setInitError] = useState<Error | null>(null);
  const [connected, setConnected] = useState(false);
  const [realtimeToken, setRealtimeToken] = useState<string | null>(null);

  // use a state to make sure we always clean up the previous socket instance
  const [socket, setSocket] = useState<LiveMeetingSocket | null>(null);

  const [ee] = useState(() => mitt<EventEmitterEventMaps>());

  const {
    liveInsights,
    loadingLiveInsights,
    upsertLiveInsightsCache,
    refetchLiveInsights,
  } = useLiveInsights({
    meeting,
    realtimeToken,
  });

  const {
    liveActivities,
    upsertLiveActivitiesCache,
    deleteLiveActivityCache,
    refetchLiveActivities,
    createMark,
    createComment,
    deleteActivity,
  } = useLiveActivities({
    meeting,
  });

  useEffect(() => {
    if (!socket) {
      return;
    }

    const onConnect = () => {
      logger.info("Connected to realtime transcript socket");
      setConnected(true);
    };
    socket.on("connect", onConnect);

    const onDisconnect = () => {
      logger.info("Disconnected from realtime transcript socket");
      setConnected(false);
    };
    socket.on("disconnect", onDisconnect);

    const onLiveMeetingActivityBroadcast = (data: LiveMeetingActivity) => {
      upsertLiveActivitiesCache(data);
    };
    socket.on(
      LiveMeetingEvents.LIVE_MEETING_ACTIVITY_BROADCAST_EVENT,
      onLiveMeetingActivityBroadcast,
    );

    const onLiveMeetingActivityDeleted = (
      data: DeleteLiveMeetingActivityEvent,
    ) => {
      deleteLiveActivityCache(data);
    };
    socket.on(
      LiveMeetingEvents.LIVE_MEETING_ACTIVITY_DELETED_EVENT,
      onLiveMeetingActivityDeleted,
    );

    const onRealtimeNoteBroadcast = (data: LiveNote) => {
      upsertLiveInsightsCache({ notes: [data] });
    };
    socket.on(
      LiveMeetingEvents.REALTIME_NOTE_BROADCAST,
      onRealtimeNoteBroadcast,
    );

    const onRealtimeActionItemsBroadcast = (data: LiveActionItem[]) => {
      upsertLiveInsightsCache({ actionItems: data });
    };
    socket.on(
      LiveMeetingEvents.REALTIME_ACTION_ITEMS_BROADCAST,
      onRealtimeActionItemsBroadcast,
    );

    const onRealtimeActionItemsUpdateBroadcast = (data: UpdateActionItem) => {
      upsertLiveInsightsCache({ actionItems: [data] });
    };
    socket.on(
      LiveMeetingEvents.REALTIME_ACTION_ITEMS_UDPATE_BROADCAST,
      onRealtimeActionItemsUpdateBroadcast,
    );

    return () => {
      socket.disconnect();
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off(
        LiveMeetingEvents.LIVE_MEETING_ACTIVITY_BROADCAST_EVENT,
        onLiveMeetingActivityBroadcast,
      );
      socket.off(
        LiveMeetingEvents.LIVE_MEETING_ACTIVITY_DELETED_EVENT,
        onLiveMeetingActivityDeleted,
      );
      socket.off(
        LiveMeetingEvents.REALTIME_NOTE_BROADCAST,
        onRealtimeNoteBroadcast,
      );
      socket.off(
        LiveMeetingEvents.REALTIME_ACTION_ITEMS_BROADCAST,
        onRealtimeActionItemsBroadcast,
      );
      socket.off(
        LiveMeetingEvents.REALTIME_ACTION_ITEMS_UDPATE_BROADCAST,
        onRealtimeActionItemsUpdateBroadcast,
      );
    };
  }, [
    socket,
    upsertLiveInsightsCache,
    deleteLiveActivityCache,
    upsertLiveActivitiesCache,
  ]);

  const apolloClient = useApolloClient();

  const init = useCallback(async () => {
    setInitError(null);
    logger.info("Initializing realtime transcript socket", {
      meetingId: meeting.id,
    });
    const res = await apolloClient.query<
      GetTranscriptFfAuthQuery,
      GetTranscriptFfAuthQueryVariables
    >({
      query: GetTranscriptFfAuthDocument,
      variables: {
        meetingId: meeting.id,
      },
    });
    if (res.error) {
      setInitError(res.error);
      return;
    }
    const authToken = res.data.getTranscriptFFAuth;
    if (!authToken) {
      setInitError(
        new Error("Cannot query authentication token for realtime transcript"),
      );
      return;
    }
    setRealtimeToken(authToken);
    logger.info("Got realtime transcript auth token, connecting to socket");
    const socket = io(`${Config.REALTIME_FF_HOST_WS}/transcription`, {
      auth: {
        token: authToken,
      },
      query: {
        sample_rate: 48000,
      },
      autoConnect: true,
      reconnection: true,
      transports: ["websocket", "polling"],
    });
    setSocket(socket);
  }, [meeting.id, apolloClient]);

  useEffect(() => {
    if (connected) {
      return;
    }
    init();
  }, [connected, init]);

  const updateActionItem = useCallback(
    (data: UpdateActionItem) => {
      if (!socket) {
        return;
      }
      // update the local cache first
      upsertLiveInsightsCache({ actionItems: [data] });
    },
    [socket, upsertLiveInsightsCache],
  );

  return (
    <LiveScreenContext.Provider
      value={{
        liveInsights,
        refetchLiveInsights,
        loadingLiveInsights,
        refetchLiveActivities,
        liveActivities,
        updateActionItem,
        createMark,
        createComment,
        deleteActivity,
        meetingId: meeting.id,
        ee,
      }}
    >
      {initError && <InitErrorBanner error={initError} />}
      {children}
    </LiveScreenContext.Provider>
  );
};

export const useLiveScreenContext = () => useContext(LiveScreenContext);
