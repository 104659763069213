import { toast } from "@/components/Toast";
import { Config } from "@/constants";
import { captureException } from "@/errors";
import { LoginView, authApi, useAuth } from "@/features/auth";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as ExpoLinking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import * as NativeCode from "modules/native-code";
import { useCallback, useEffect, useRef, type FC } from "react";
import { Platform, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import type { ParamList } from "../types";

export const LoginScreen: FC<NativeStackScreenProps<ParamList, "Login">> = ({
  navigation,
  route,
}) => {
  useEffect(() => {
    if (Platform.OS === "web") {
      WebBrowser.maybeCompleteAuthSession({
        skipRedirectCheck: true,
      });
    }
  }, []);

  const url = ExpoLinking.useURL();
  useEffect(() => {
    if (Platform.OS === "web") return;
    if (!url) return;
    const timeout = setTimeout(() => {
      if (url?.includes(Config.DASHBOARD_URL)) {
        // do not open dashboard login url in the app
        NativeCode.openInBrowser(url);
      }
    }, 1500);
    return () => clearTimeout(timeout);
  }, [url]);

  const onLogin = useCallback(() => {
    navigation.replace("Main");
  }, [navigation]);

  const { user } = useAuth();

  const hasNavigated = useRef(false);
  useEffect(() => {
    if (user) {
      if (!hasNavigated.current) {
        hasNavigated.current = true;
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.replace("Main");
        }
      }
    }
  }, [user, navigation]);

  useEffect(() => {
    const accessToken = route.params?.access_token || null;
    const refreshToken = route.params?.refresh_token || null;
    const expiresAt =
      (route.params?.expires_at && parseInt(route.params.expires_at, 10)) ||
      null;
    const refreshExpiresAt =
      (route.params?.refresh_expires_at &&
        parseInt(route.params.refresh_expires_at, 10)) ||
      null;

    if (accessToken) {
      authApi
        .login({
          accessToken,
          refreshToken,
          expiresAt,
          refreshExpiresAt,
        })
        .then(onLogin)
        .catch((error) => {
          captureException(error);
          toast({
            title: "Login failed",
            message: error.message,
            type: "error",
          });
        });
    }
  }, [
    route.params?.access_token,
    route.params?.refresh_token,
    route.params?.expires_at,
    route.params?.refresh_expires_at,
    onLogin,
  ]);

  return (
    <>
      <SafeAreaView style={styles.root}>
        <LoginView onLogin={onLogin} />
      </SafeAreaView>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
