import {
  IconGrid,
  IconHash,
  IconLayers,
  IconLogoText,
  IconSettings,
  IconUpload,
  IconUsers,
  IconZap,
} from "@/assets/svg";
import { Separator } from "@/components/Separator";
import { Text } from "@/components/Typography";
import { Config, RouteNames } from "@/constants";
import { useHidePaidFeatures } from "@/features/billing";
import * as NativeCode from "@/modules/native-code";
import { createStyles, useTheme } from "@/styles";
import type { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import type { FC } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { LinkPressable } from "./LinkPressable";
import type { TabButtonProps } from "./utils";
import { getTabButtonProps } from "./utils";

const SidebarButton: FC<
  TabButtonProps & {
    externalUrl?: string;
  }
> = ({ title, focused, Icon, name, externalUrl, onPress, onLongPress }) => {
  const theme = useTheme();

  const hidePaidFeatures = useHidePaidFeatures();

  const node = (
    <View style={[styles.button, focused && styles.buttonFocused(theme)]}>
      <Icon
        width={18}
        height={18}
        color={
          focused
            ? theme.colors.commandPrimaryDefault
            : theme.colors.commandNeutralDefault
        }
      />
      <Text color={focused ? "commandPrimaryDefault" : "commandNeutralDefault"}>
        {title}
      </Text>
    </View>
  );

  if (hidePaidFeatures && externalUrl) {
    return (
      <Pressable
        onPress={() => NativeCode.openInBrowser(externalUrl)}
        aria-label={title}
      >
        {node}
      </Pressable>
    );
  }

  return (
    <LinkPressable
      href={name}
      onPress={onPress}
      onLongPress={onLongPress}
      aria-selected={focused}
      aria-label={title}
    >
      {node}
    </LinkPressable>
  );
};

export const Sidebar: FC<BottomTabBarProps> = ({
  state,
  descriptors,
  navigation,
}) => {
  const theme = useTheme();

  const insets = useSafeAreaInsets();

  return (
    <View style={[styles.root(theme), { paddingTop: insets.top }]}>
      <View style={styles.logo}>
        <IconLogoText color={theme.colors.textPrimary} />
      </View>
      <ScrollView>
        {state.routes.map((route, index) => {
          return (
            <SidebarButton
              key={route.key}
              {...getTabButtonProps(
                state,
                navigation,
                descriptors,
                route,
                index,
              )}
            />
          );
        })}
        <Separator style={styles.separator} />
        <SidebarButton
          title="Meeting Status"
          name={RouteNames.MeetingStatus}
          Icon={IconZap}
        />
        <SidebarButton
          title="Uploads"
          name={RouteNames.Upload}
          Icon={IconUpload}
        />
        <SidebarButton
          title="Integrations"
          name={RouteNames.Integrations}
          Icon={IconLayers}
        />
        <SidebarButton
          title="Apps"
          name={RouteNames.Extensions}
          Icon={IconGrid}
        />
        <SidebarButton
          title="Topic Tracker"
          name={RouteNames.TopicTracker}
          externalUrl={`${Config.DASHBOARD_URL}/topics`}
          Icon={IconHash}
        />
        <View style={styles.separator} />
        <SidebarButton title="Team" name={RouteNames.Team} Icon={IconUsers} />
        <SidebarButton
          title="Settings"
          name={RouteNames.Settings}
          externalUrl={`${Config.DASHBOARD_URL}/settings`}
          Icon={IconSettings}
        />
      </ScrollView>
    </View>
  );
};

export const SIDEBAR_WIDTH = 224;

const styles = createStyles({
  root: (theme) => ({
    borderRightWidth: 1,
    borderRightColor: theme.colors.borderStaticSubtle,
    width: SIDEBAR_WIDTH,
    backgroundColor: theme.colors.layerBrandLight1,
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
  }),
  logo: {
    paddingVertical: 24,
    alignItems: "center",
  },
  button: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: 12,
    width: "100%",
  },
  buttonFocused: (theme) => ({
    backgroundColor: theme.colors.interactiveSecondaryDefault,
  }),
  section: (theme) => ({
    borderBottomColor: theme.colors.borderStaticSubtle,
    borderBottomWidth: 1,
    paddingVertical: 16,
  }),
  separator: {
    marginVertical: 16,
  },
});
