import { RouteNames } from "@/constants";
import { useShouldShowOnboarding } from "@/features/onboarding";
import { useNavigation } from "@react-navigation/native";
import type { FC } from "react";
import { useEffect, useRef } from "react";

export const OnboardingRedirectComponent: FC = () => {
  const redirectedRef = useRef(false);

  const navigation = useNavigation();

  const { shouldShowOnboardings, loading } = useShouldShowOnboarding();

  useEffect(() => {
    if (loading) return;
    // only show onboarding if the user has not completed any steps
    if (shouldShowOnboardings) {
      if (redirectedRef.current) return;
      redirectedRef.current = true;
      navigation.navigate(RouteNames.Onboarding);
    }
  }, [navigation, shouldShowOnboardings, loading]);

  return null;
};
