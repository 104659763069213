export interface AudioWriteData {
  // The encoded data to write.
  data: Uint8Array;
  // Optional position in the buffer; if omitted, data is appended.
  position?: number;
}

/**
 * Child classes must override:
 *  - encode(pcmData) to convert PCM data into an AudioWriteData,
 *  - flush() to finalize and return any remaining AudioWriteData.
 */
export abstract class AudioFileBuffer {
  samplingRate: number;
  writing = true;
  readOffset = 0;
  fileSize = 0;
  readChunkSize: number;

  // The audio data is maintained in memory.
  private data = new Uint8Array();

  constructor({
    samplingRate,
    readChunkSize,
  }: {
    samplingRate: number;
    readChunkSize: number;
  }) {
    this.samplingRate = samplingRate;
    this.readChunkSize = readChunkSize;
  }

  // MARK: - readNextChunk
  readNextChunk(): Uint8Array | null {
    if (this.readOffset >= this.fileSize) {
      return null;
    }
    const chunkSize = Math.min(
      this.readChunkSize,
      this.fileSize - this.readOffset,
    );
    const chunk = this.data.slice(this.readOffset, this.readOffset + chunkSize);
    this.readOffset += chunkSize;
    return chunk;
  }

  // MARK: - writeData
  writeData(pcmData: Uint8Array): void {
    if (!this.writing) {
      throw new Error("Writing has ended.");
    }
    const encodedData = this.encode(pcmData);
    if (encodedData) {
      this.writeToBuffer(encodedData);
    }
  }

  private writeToBuffer({ position, data }: AudioWriteData): void {
    if (typeof position === "number") {
      // Ensure the buffer is large enough to hold data at the specified position.
      const newSize = Math.max(this.fileSize, position + data.byteLength);
      if (newSize > this.data.byteLength) {
        const newBuffer = new Uint8Array(newSize);
        newBuffer.set(this.data, 0);
        this.data = newBuffer;
      }
      this.data.set(data, position);
      this.fileSize = newSize;
    } else {
      // Append data at the end of the buffer.
      this.data = mergeBuffers(this.data, data);
      this.fileSize += data.byteLength;
    }
  }

  endWrite(): void {
    if (!this.writing) {
      throw new Error("Writing has already ended.");
    }
    const flushedData = this.flush();
    if (flushedData) {
      this.writeToBuffer(flushedData);
    }
    this.writing = false;
  }

  rewindReadOffset(backendOffset: number): void {
    const targetOffset = backendOffset + this.headerSize();
    if (targetOffset < this.readOffset) {
      this.readOffset = targetOffset;
    }
  }

  // MARK: - Getter Methods
  getData(): Uint8Array {
    return this.data;
  }

  // MARK: - Abstract Methods
  abstract getBufferType(): string;
  abstract getMimeType(): string;
  abstract shouldEncodeAudioInMediaStorage(): boolean;
  abstract headerSize(): number;
  abstract flush(): AudioWriteData | null;
  abstract encode(pcmData: Uint8Array): AudioWriteData | null;
}

/**
 * Utility function to merge two Uint8Arrays.
 */
export function mergeBuffers(
  leftBuffer: Uint8Array,
  rightBuffer: Uint8Array,
): Uint8Array {
  const tmpArray = new Uint8Array(
    leftBuffer.byteLength + rightBuffer.byteLength,
  );
  tmpArray.set(leftBuffer, 0);
  tmpArray.set(rightBuffer, leftBuffer.byteLength);
  return tmpArray;
}

/**
 * Utility function to convert a Uint8Array to a Base64 string.
 */
export function uint8ArrayToBase64(uint8Array: Uint8Array): string {
  const binaryString = String.fromCharCode(...uint8Array);
  return btoa(binaryString);
}
