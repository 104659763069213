import { useTheme } from "@/styles";
import type { FC } from "react";
import { useEffect } from "react";
import type { DimensionValue } from "react-native";
import Animated, {
  ReduceMotion,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from "react-native-reanimated";

export const SkeletonLoading: FC<{
  width: DimensionValue;
  height: DimensionValue;
  variant?: "circle" | "rect" | "rounded";
}> = ({ width, height, variant }) => {
  const theme = useTheme();

  const opacityValue = useSharedValue(1);

  useEffect(() => {
    opacityValue.value = withRepeat(
      withSequence(
        withTiming(0.5, { duration: 1500, reduceMotion: ReduceMotion.Never }),
        withTiming(1, { duration: 1500, reduceMotion: ReduceMotion.Never }),
      ),
      -1,
      false,
      undefined,
      ReduceMotion.Never,
    );
    return () => {
      opacityValue.value = 1;
    };
  }, [opacityValue]);

  const animatedStyle = useAnimatedStyle(
    () => ({ opacity: opacityValue.value }),
    [],
  );

  return (
    <Animated.View
      style={[
        {
          backgroundColor: theme.colors.miscellaneousSkeletonElement,
          borderRadius:
            variant === "circle" ? 9999 : variant === "rect" ? 0 : 8,
          maxWidth: "100%",
        },
        animatedStyle,
        { width, height },
      ]}
    />
  );
};
