import { IconButton } from "@/components/Button";
import { SelectPopover, TextInput } from "@/components/Input";
import { useRecorder } from "@/features/live-record";
import { MEETING_PRIVACY_OPTIONS } from "@/features/meeting";
import type { MeetingNotesPrivacy } from "@/graphql";
import { useBooleanState } from "@/utils/states";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";
import { useRecordScreenContext } from "../RecordScreenContext";

export const RecordStartForm: FC = () => {
  const { status } = useRecorder();
  const { setStartInputs, startInputs } = useRecordScreenContext();
  const [isOpenPrivacy, openPrivacy, closePrivacy] = useBooleanState();

  if (status.state !== "inactive") {
    return null;
  }

  const currentPrivacy =
    MEETING_PRIVACY_OPTIONS.find(
      (option) => option.value === startInputs.notesPrivacy,
    ) || MEETING_PRIVACY_OPTIONS[MEETING_PRIVACY_OPTIONS.length - 1];

  return (
    <View style={styles.root}>
      <TextInput
        style={styles.input}
        placeholder="Recording title (optional)"
        value={startInputs.title}
        onValueChange={(text) =>
          setStartInputs((prev) => ({
            ...prev,
            title: text,
          }))
        }
      />
      <IconButton
        label={`Edit privacy. Current: ${currentPrivacy.label}`}
        Icon={currentPrivacy.Icon}
        variant="outlined"
        onPress={openPrivacy}
      />
      <SelectPopover
        isOpen={isOpenPrivacy}
        close={closePrivacy}
        options={MEETING_PRIVACY_OPTIONS}
        value={currentPrivacy.value}
        onValueChange={(value) => {
          setStartInputs((prev) => ({
            ...prev,
            notesPrivacy: value as MeetingNotesPrivacy,
          }));
        }}
        aria-label="Change privacy"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    gap: 8,
  },
  input: {
    flex: 1,
  },
});
