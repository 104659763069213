import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";
import { Dialog } from "@/components/Dialog";
import { Text } from "@/components/Typography";
import { useAuth } from "@/features/auth";
import {
  localRecordStoreApi,
  RECORDING_DIRECTORY,
  useLocalRecordStore,
} from "@/features/live-record";
import { getLogFilePaths } from "@/logger";
import { getFileUrisInDirectory, shareFile } from "@/utils/file";
import * as Device from "expo-device";
import * as MailComposer from "expo-mail-composer";
import { useEffect, useMemo, useState, type FC } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

interface Data {
  logFiles: string[];
  recordingFiles: string[];
}

const FileEntry: FC<{
  uri: string;
  selected: boolean;
  toggleFileUri: (uri: string) => void;
}> = ({ uri, selected, toggleFileUri }) => {
  const name = useMemo(() => {
    const possibleLocalRecord = localRecordStoreApi.findByUri(uri);
    if (possibleLocalRecord?.title) {
      return possibleLocalRecord.title;
    }
    return uri.split("/").pop();
  }, [uri]);

  return (
    <View style={styles.entry}>
      <Checkbox value={selected} onValueChange={() => toggleFileUri(uri)} />
      <Text style={styles.entryName} onLongPress={() => shareFile(uri)}>
        {name}
      </Text>
    </View>
  );
};

const DiagnosticDialogContent: FC<{
  close: () => void;
}> = () => {
  const [data, setData] = useState<Data | null>(null);

  const [selectedFileUris, setSelectedFileUris] = useState<string[]>([]);

  const toggleFileUri = (uri: string) => {
    setSelectedFileUris(
      selectedFileUris.includes(uri)
        ? selectedFileUris.filter((selectedUri) => selectedUri !== uri)
        : [...selectedFileUris, uri],
    );
  };

  useEffect(() => {
    Promise.all([
      getLogFilePaths(),
      getFileUrisInDirectory(RECORDING_DIRECTORY),
    ]).then(([logFiles, recordingFiles]) => {
      setData({ logFiles, recordingFiles: [...recordingFiles].reverse() });
      // default to selecting all log files
      setSelectedFileUris([...logFiles]);
    });
  }, []);

  const onShare = () => {
    const deviceJson = JSON.stringify({
      brand: Device.brand,
      menufacturer: Device.manufacturer,
      modelName: Device.modelName,
      osBuildId: Device.osBuildId,
      osName: Device.osName,
      osVersion: Device.osVersion,
      platformApiLevel: Device.platformApiLevel,
      supportedCpuArchitectures: Device.supportedCpuArchitectures,
      totalMemory: Device.totalMemory,
    });

    const selectedRecordings = useLocalRecordStore
      .getState()
      .recordings.filter((recording) =>
        selectedFileUris.some((uri) => recording.fileUris.includes(uri)),
      )
      .map((recording) => ({
        title: recording.title,
        meetingId: recording.meetingId,
        startTime: recording.startTime,
        endTime: recording.endTime,
      }));
    const localRecordingJson = JSON.stringify(selectedRecordings);

    const userId = useAuth.getState().user?.id;

    MailComposer.composeAsync({
      attachments: selectedFileUris,
      subject: `Fireflies mobile app - Diagnostic information - ${userId}`,
      body: `User ID: ${userId}

Device Information:
${deviceJson}

Local Recording Store Map
${localRecordingJson}
`,
    });
  };

  return (
    <>
      <Dialog.Header>Share diagnostic information</Dialog.Header>
      <View style={styles.body}>
        <Text variant="body2LongRegular" color="textSecondary">
          To help us diagnose your issue, please select the information you
          would like to share with us.
        </Text>
        <ScrollView>
          <View style={styles.section}>
            <Text variant="label1Weight">Log files</Text>
            {data?.logFiles.map((file) => (
              <FileEntry
                key={file}
                uri={file}
                selected={selectedFileUris.includes(file)}
                toggleFileUri={toggleFileUri}
              />
            ))}
          </View>
          <View style={styles.section}>
            <Text variant="label1Weight">Recording files</Text>
            {data?.recordingFiles.map((file) => (
              <FileEntry
                key={file}
                uri={file}
                selected={selectedFileUris.includes(file)}
                toggleFileUri={toggleFileUri}
              />
            ))}
          </View>
        </ScrollView>
        <Button onPress={onShare} variant="primary">
          Share
        </Button>
      </View>
    </>
  );
};

export const DiagnosticDialog: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  return (
    <Dialog.Root variant="fullscreen" isOpen={isOpen} close={close}>
      <DiagnosticDialogContent close={close} />
    </Dialog.Root>
  );
};

const styles = StyleSheet.create({
  body: {
    gap: 8,
    padding: 20,
    flex: 1,
  },
  entry: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  entryName: {
    flex: 1,
  },
  section: {
    gap: 8,
    marginBottom: 16,
  },
});
