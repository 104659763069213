import { RouteNames } from "@/constants";
import { createStyles, useTheme, vars } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import {
  useNavigationState,
  type NavigationContainerRefWithCurrent,
} from "@react-navigation/native";
import { BlurView } from "expo-blur";
import { type FC } from "react";
import { Modal, TouchableOpacity, View } from "react-native";
import { create } from "zustand";
import type { ParamList } from "../../../screens/types";
import { useAuth } from "../store";
import { LoginView } from "./LoginView";
import { IconClose } from "@/assets/svg";

const IGNORE_ROUTE_NAMES: string[] = [
  RouteNames.Login,
  RouteNames.Notepad,
  RouteNames.Record, // avoid disrupt recording flow
  RouteNames.Bite,
  RouteNames.Playlist,
];

const useShouldOpenInCurrentRoute = () => {
  const currentRouteName = useNavigationState((state) => {
    if (!state) return null;
    const route = state.routes[state.index];
    return route?.name;
  });

  return !currentRouteName || !IGNORE_ROUTE_NAMES.includes(currentRouteName);
};

const authModalStore = create<{
  isManuallyOpened: boolean;
  open: (fromRef: string) => void;
  close: () => void;
}>((set) => ({
  isManuallyOpened: false,
  open: (fromRef: string) => {
    tracker.track(TRACKING_EVENTS.LOGIN_POPUP_OPENED, {
      from: fromRef,
    });

    set({ isManuallyOpened: true });
  },
  close: () => {
    set({ isManuallyOpened: false });
  },
}));

export const openLoginPopup = (fromRef: string) => {
  authModalStore.getState().open(fromRef);
};

export const useIsLoginPopupVisible = () => {
  const { isManuallyOpened } = authModalStore();
  const shouldOpenInCurrentRoute = useShouldOpenInCurrentRoute();
  return isManuallyOpened || shouldOpenInCurrentRoute;
};

export const LoginPopup: FC<{
  navigationRef: NavigationContainerRefWithCurrent<ParamList>;
}> = () => {
  const { user } = useAuth();

  const { isManuallyOpened, close } = authModalStore();
  const shouldOpenInCurrentRoute = useShouldOpenInCurrentRoute();

  const theme = useTheme();

  return (
    <Modal
      visible={!user && (shouldOpenInCurrentRoute || isManuallyOpened)}
      transparent
      animationType="fade"
      onRequestClose={close}
    >
      <BlurView
        style={styles.container(theme)}
        intensity={20}
        experimentalBlurMethod="dimezisBlurView"
      >
        <View style={styles.content(theme)}>
          <LoginView onLogin={close} />

          {isManuallyOpened && (
            <TouchableOpacity
              style={styles.closeIcon}
              onPress={close}
              hitSlop={10}
            >
              <IconClose
                color={theme.colors.commandHintDefault}
                width={25}
                height={25}
              />
            </TouchableOpacity>
          )}
        </View>
      </BlurView>
    </Modal>
  );
};

const styles = createStyles({
  container: (theme) => ({
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    zIndex: 10,
    backgroundColor: theme.colors.miscellaneousOverlay,
  }),
  content: (theme) => ({
    width: "100%",
    height: "100%",
    maxHeight: 700,
    maxWidth: 600,
    backgroundColor: theme.colors.layerDefault,
    borderRadius: 20,
    ...vars.elevations[1],
  }),
  closeIcon: {
    position: "absolute",
    top: 25,
    right: 10,
  },
});
