import {
  IconCalendar,
  IconGoogleGColor,
  IconMicrosoftColor,
} from "@/assets/svg";
import { TextInput } from "@/components/Input";
import { LoadingSpinner } from "@/components/Loading";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { Config } from "@/constants";
import { CanceledError, captureException } from "@/errors";
import { lightTheme, useTheme, vars } from "@/styles";
import { useBooleanState } from "@/utils/states";
import type { FC } from "react";
import { useState, type ReactNode } from "react";
import {
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { oauth2Login } from "../oauth2";
import { authApi } from "../store";
import { CalendarAccessExplainDialog } from "./CalendarAccessExplainDialog";
import { Footer } from "./Footer";
import { Header } from "./Header";

const ManualLogin: FC<{
  onLogin: () => void;
}> = ({ onLogin }) => {
  return (
    <TextInput
      placeholder="Enter access token to manual login"
      onSubmitEditing={(e) => {
        const accessToken = e.nativeEvent.text.trim();
        if (!accessToken) return;
        authApi
          .login({
            accessToken,
            refreshToken: null,
            expiresAt: null,
            refreshExpiresAt: null,
          })
          .then(onLogin)
          .catch((error) => {
            toast({
              message: error.message,
            });
          });
      }}
    />
  );
};

const LoginButton: FC<{
  icon: ReactNode;
  text: string;
  onPress: () => void;
}> = ({ icon, text, onPress }) => {
  return (
    <Pressable style={styles.loginButton} onPress={onPress}>
      {icon}
      <Text
        variant="label1Weight"
        color="commandNeutralDefault"
        maxFontSizeMultiplier={1.25}
      >
        {text}
      </Text>
    </Pressable>
  );
};

export const LoginView: FC<{
  onLogin: () => void;
}> = ({ onLogin }) => {
  const [
    isCalendarAccessExplainOpen,
    openCalendarAccessExplain,
    closeCalendarAccessExplain,
  ] = useBooleanState();

  const [loading, setLoading] = useState(false);

  const doLogin = (type: "google" | "outlook") => {
    if (loading) return;
    setLoading(true);
    oauth2Login(type)
      .then(
        (tokens) => {
          authApi
            .login(tokens)
            .then(onLogin)
            .catch((error) => {
              captureException(error, {
                tags: {
                  section: "login",
                },
              });
              toast({
                message: error.message,
              });
            });
        },
        (err) => {
          if (err instanceof CanceledError) return;
          toast({
            message: err.message,
          });
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const theme = useTheme();

  return (
    <>
      <ScrollView contentContainerStyle={styles.container}>
        <Header />
        <View style={styles.card}>
          {loading ? (
            <View style={styles.loading}>
              <LoadingSpinner />
            </View>
          ) : (
            <View style={styles.buttons}>
              <LoginButton
                icon={<IconGoogleGColor width={20} height={20} />}
                text="Continue with Google"
                onPress={() => doLogin("google")}
              />
              <LoginButton
                icon={<IconMicrosoftColor width={20} height={20} />}
                text="Continue with Microsoft"
                onPress={() => doLogin("outlook")}
              />
              {Config.APP_ENV !== "production" && (
                <ManualLogin onLogin={onLogin} />
              )}
            </View>
          )}
          <TouchableOpacity
            onPress={openCalendarAccessExplain}
            style={styles.calendarNotice}
          >
            <IconCalendar
              width={16}
              height={16}
              color={theme.colors.textSecondary}
            />
            <Text variant="body2Regular" color="textSecondary">
              Why does Fireflies require access to my calendar?
            </Text>
          </TouchableOpacity>
        </View>
        <Footer />
      </ScrollView>
      <CalendarAccessExplainDialog
        isOpen={isCalendarAccessExplainOpen}
        close={closeCalendarAccessExplain}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 24,
    alignItems: "center",
    gap: 8,
    flex: 1,
  },
  card: {
    marginTop: 42,
    width: "100%",
    maxWidth: 524,
    gap: 16,
    height: 144,
    justifyContent: "space-between",
  },
  buttons: {
    gap: 12,
    width: "100%",
  },
  calendarNotice: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    opacity: 0.8,
  },
  loginButton: {
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 44,
    borderWidth: 1,
    backgroundColor: lightTheme.colors.layerDefault,
    borderColor: lightTheme.colors.borderStaticSubtle,
    width: "100%",
    paddingHorizontal: 24,
    gap: 16,
    ...vars.elevations[1],
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
