import { FeedGistBullet } from "@/components/Feed";
import { toHHMMSS } from "@/utils/time";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";
import { useLiveScreenContext } from "../Context/LiveScreenContext";

export const AiNotesSection: FC = () => {
  const { liveInsights } = useLiveScreenContext();

  return (
    <View style={styles.root}>
      {liveInsights?.notes?.map(
        (note) =>
          !!note?.note && (
            <FeedGistBullet
              key={note.id}
              emoji={note.emoji}
              sentence={note.note}
              subtext={note.endTime ? toHHMMSS(note.endTime) : undefined}
            />
          ),
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    gap: 8,
  },
});
