import { useTheme } from "@/styles";
import { Link } from "@react-navigation/native";
import type { PropsWithChildren } from "react";
import { forwardRef, useState } from "react";
import type { Text } from "react-native";
import { getStyles } from "./styles";
import type { LinkViewProps } from "./types";
import { createTo } from "./utils";

export const LinkView = forwardRef<Text, PropsWithChildren<LinkViewProps>>(
  function LinkViewWeb(
    { href, style, animated, children, onPress, ...props },
    ref,
  ) {
    const [isActive, setIsActive] = useState(false);

    const theme = useTheme();

    return (
      <Link
        ref={ref}
        to={createTo(href)}
        style={getStyles(theme, { animated, style })({ pressed: isActive })}
        onPointerEnter={() => {
          setIsActive(true);
        }}
        onPointerLeave={() => {
          setIsActive(false);
        }}
        // @ts-ignore: FIXME: onPress event might be incompatible
        onPress={onPress}
        {...props}
      >
        {children}
      </Link>
    );
  },
);
