import type { CoreTaskFieldsFragment } from "@firefliesai/tasks-ff.graphql-client";
import { createContext } from "react";

export const TasksContext = createContext(
  {} as {
    onlyMine: boolean;
    filterMyTasks: (task: CoreTaskFieldsFragment) => boolean;
    sortTasks: (a: CoreTaskFieldsFragment, b: CoreTaskFieldsFragment) => number;
  },
);
