import { ConfirmationDialog } from "@/components/Dialog";
import type { FC } from "react";
import { ADD_MODAL_NAME, AddModal } from "./add-modal/AddModal";
import {
  ADD_TO_PLAYLIST_MODAL_NAME,
  AddToPlaylistDialog,
} from "./add-to-playlist-modal/AddToPlaylistDialog";
import { CONFIRMATION_DIALOG_MODAL_NAME } from "./confirmation-dialog-modal/prompt-confirmation";
import {
  CREATE_PLAYLIST_MODAL_NAME,
  CreatePlaylistDialog,
} from "./create-playlist-modal/CreatePlaylistModal";
import {
  DOWNLOAD_PAYWALL_MODAL_NAME,
  DownloadPaywallModal,
} from "./download-paywall-modal/DownloadPaywallModal";
import { useGlobalModalsStore } from "./global-modals.store";
import {
  SMART_SEARCH_PAYWALL_MODAL_NAME,
  SmartSearchPaywallModal,
} from "./smart-search-paywall-modal/SmartSearchPaywallModal";
import {
  STORAGE_PAYWALL_MODAL_NAME,
  StoragePaywallModal,
} from "./storage-paywall-modal/StoragePaywallModal";

export const GlobalModals: FC = () => {
  const { modals, modalProps, closeModal } = useGlobalModalsStore();

  const isOpen = (modalName: string) => {
    if (!modals.length) return false;
    return modals[modals.length - 1] === modalName;
  };

  return (
    <>
      <AddModal
        isOpen={isOpen(ADD_MODAL_NAME)}
        close={closeModal}
        {...modalProps[ADD_MODAL_NAME]}
      />
      <StoragePaywallModal
        isOpen={isOpen(STORAGE_PAYWALL_MODAL_NAME)}
        close={closeModal}
        {...modalProps[STORAGE_PAYWALL_MODAL_NAME]}
      />
      <SmartSearchPaywallModal
        isOpen={isOpen(SMART_SEARCH_PAYWALL_MODAL_NAME)}
        close={closeModal}
        {...modalProps[SMART_SEARCH_PAYWALL_MODAL_NAME]}
      />
      <CreatePlaylistDialog
        isOpen={isOpen(CREATE_PLAYLIST_MODAL_NAME)}
        close={closeModal}
        {...modalProps[CREATE_PLAYLIST_MODAL_NAME]}
      />
      <AddToPlaylistDialog
        isOpen={isOpen(ADD_TO_PLAYLIST_MODAL_NAME)}
        close={closeModal}
        {...modalProps[ADD_TO_PLAYLIST_MODAL_NAME]}
      />
      <DownloadPaywallModal
        isOpen={isOpen(DOWNLOAD_PAYWALL_MODAL_NAME)}
        close={closeModal}
      />
      <ConfirmationDialog
        isOpen={isOpen(CONFIRMATION_DIALOG_MODAL_NAME)}
        close={closeModal}
        {...modalProps[CONFIRMATION_DIALOG_MODAL_NAME]}
      />
    </>
  );
};
