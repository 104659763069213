import { promptConfirmation } from "@/features/global-modals";
import type { RecordingStates } from "@/features/live-record";
import { openStoreUrl } from "@/utils/updates";

export const warnNewUpdatesBeforeRecording = () =>
  new Promise<boolean>((resolve) => {
    promptConfirmation({
      title: "A new version is available",
      children:
        "Recording with an outdated version of the app may lead to data loss.",
      cancelText: "Record anyway",
      confirmText: "Update now",
      onCancel: () => resolve(true),
      onConfirm: () => {
        resolve(false);
        openStoreUrl();
      },
    });
  });

export const isPendingStreaming = (status: RecordingStates) =>
  (status.state === "inactive" && status.streamingState !== "inactive") ||
  !!status.streamingError;
