import type { StackRouteConfig } from "../types";
import { Header } from "./components/Header";
import { LiveScreen } from "./LiveScreen";

const LiveRouteConfig: StackRouteConfig<"Live"> = {
  name: "Live",
  component: LiveScreen,
  options: {
    title: "",
    header: (props) => <Header {...props} />,
  },
};

export default LiveRouteConfig;
