import { Select } from "@/components/Input";
import { SUPPORTED_LANGUAGES_OPTIONS } from "@/constants";
import { useUpdateOptionsLanguage } from "@/features/settings";
import type { User } from "@/graphql";
import type { FC } from "react";
import {
  SettingField,
  SettingLabel,
  SettingsGroup,
} from "../components/SettingsForm";

export const MeetingSettingsLanguage: FC<{
  user: User;
}> = ({ user }) => {
  const { language, updateLanguage, loadingUpdateOptions } =
    useUpdateOptionsLanguage({ user });

  return (
    <SettingsGroup title="Meeting Language">
      <SettingField>
        <SettingLabel
          id="language-setting-section-label"
          title="Language used to transcribe meetings"
          description="Select the language spoken during your meetings to get transcripts and summaries in that language"
        />
        <Select
          value={language}
          onValueChange={updateLanguage}
          options={SUPPORTED_LANGUAGES_OPTIONS}
          disabled={loadingUpdateOptions}
          aria-labelledby="language-setting-section-label"
          showSearch
        />
      </SettingField>
    </SettingsGroup>
  );
};
