import { RecordIndicator } from "@/components/Animated";
import { Button } from "@/components/Button";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import type { RecordingStates } from "@/features/live-record";
import { recorderApi, useRecorder } from "@/features/live-record";
import type { RecordingStatus } from "@/modules/audio-record";
import { toHHMMSS, toHourMinuteSecond } from "@/utils/time";
import { useEffect, useState, type FC } from "react";
import { StyleSheet, View } from "react-native";
import { useRemoteConstantsStore } from "src/features/remote-constants/remote-constants.store";
import { RecordLanguageSelect } from "./RecordLanguageSelect";
import { RecordStartForm } from "./RecordStartForm";
import { isPendingStreaming } from "./utils";

const PendingStreamErrorStatusView: FC<{
  error: string;
}> = ({ error }) => {
  const [loading, setLoading] = useState(false);

  return (
    <View style={styles.statusView}>
      <Text variant="title2Regular">Could not upload recording</Text>
      <Text
        variant="body2Regular"
        color="commandDangerDefault"
        style={styles.textCentered}
      >
        {error}
      </Text>
      <View style={styles.statusActions}>
        <Button
          variant="secondary"
          onPress={() => {
            recorderApi.release();
            toast({
              title: "Upload cancelled",
              message:
                "You can reupload the recording via the Local Recordings tab.",
              type: "info",
            });
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onPress={() => {
            setLoading(true);
            recorderApi
              .startUpload()
              .catch((err) => {
                toast({
                  title: "Could not retry upload",
                  message: (err as Error).message,
                  type: "error",
                });
              })
              .finally(() => setLoading(false));
          }}
          disabled={loading}
        >
          Retry
        </Button>
      </View>
    </View>
  );
};

const PendingStreamStatusView: FC<{
  status: RecordingStates;
}> = ({ status }) => {
  const [showLongConnectingMessage, setShowLongConnectingMessage] =
    useState(false);

  useEffect(() => {
    if (status.streamingState === "connecting") {
      const timeout = setTimeout(() => {
        // wait for 10 seconds before showing the error message
        setShowLongConnectingMessage(true);
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      setShowLongConnectingMessage(false);
    }
  }, [status.streamingState]);

  return (
    <>
      <View style={styles.statusView}>
        <Text variant="title2Regular">
          {status.streamingState === "connecting"
            ? `Connecting to server...`
            : `Uploading recording ・ ${Math.floor((status.fileReadOffset / status.fileSize) * 100)}%`}
        </Text>
        <Text
          variant="body2Regular"
          color="textHint"
          style={styles.textCentered}
        >
          Your recording is being uploaded. Closing the application will stop it
          from completing.
        </Text>
      </View>
      {showLongConnectingMessage && (
        <View style={styles.statusView}>
          <Text
            variant="body2Regular"
            color="commandDangerDefault"
            style={styles.textCentered}
          >
            We are unable to connect to the server. Please check your internet
            connection, and the app will try to reconnect automatically. If the
            problem persists, please contact our support team.
          </Text>
        </View>
      )}
    </>
  );
};

const OngoingRecordingStatusView: FC<{
  status: RecordingStatus;
}> = ({ status }) => {
  const { MAX_RECORDING_DURATION } = useRemoteConstantsStore();

  return (
    <View style={styles.status}>
      <RecordIndicator invisible={status.state === "inactive"} />
      <View style={styles.statusView}>
        <Text
          variant="heading2"
          color="textPrimary"
          maxFontSizeMultiplier={1.25}
        >
          {toHHMMSS(Math.ceil(status.durationMillis / 1000), true)}
        </Text>
        {!!MAX_RECORDING_DURATION &&
          MAX_RECORDING_DURATION >= 0 &&
          status.state === "inactive" && (
            <Text variant="body2Weight" color="textHint">
              Limit: {toHourMinuteSecond(MAX_RECORDING_DURATION / 1000)}
            </Text>
          )}
        <View style={styles.form}>
          <RecordStartForm />
          <RecordLanguageSelect />
        </View>
      </View>
    </View>
  );
};

export const RecordAudioView: FC = () => {
  const { status } = useRecorder();

  return (
    <View style={styles.root}>
      {isPendingStreaming(status) ? (
        status.streamingError ? (
          <PendingStreamErrorStatusView error={status.streamingError} />
        ) : (
          <PendingStreamStatusView status={status} />
        )
      ) : (
        <OngoingRecordingStatusView status={status} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    gap: 32,
  },
  status: {
    alignItems: "center",
    justifyContent: "center",
    gap: 28,
    width: "100%",
  },
  textCentered: {
    textAlign: "center",
  },
  statusView: {
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    paddingHorizontal: 32,
    maxWidth: 360,
    width: "100%",
  },
  statusActions: {
    flexDirection: "row",
    gap: 12,
  },
  form: {
    gap: 8,
    marginTop: 20,
  },
});
