import { IconCheck, IconLink } from "@/assets/svg";
import { Button } from "@/components/Button";
import { Dialog } from "@/components/Dialog";
import { SelectField, TextField } from "@/components/Input";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { SUPPORTED_LANGUAGES_OPTIONS } from "@/constants";
import { authApi } from "@/features/auth";
import {
  useBeginConferenceMutation,
  useGetCurrentUserQuery,
  useSaveOptionsMutation,
} from "@/graphql";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import { findBests as findLanguageBests } from "@firefliesai/shared-libs.utils/dist/asr/asr.helper";
import type { FC } from "react";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import Animated, { FadeInDown } from "react-native-reanimated";

const TranscribeLiveMeetingModalContent: FC = () => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  const { data: dataUser } = useGetCurrentUserQuery();

  const [language, setLanguage] = useState(
    () =>
      (dataUser?.user?.preferredLanguage &&
        findLanguageBests(dataUser.user.preferredLanguage)[0]?.languageCode) ||
      "en",
  );

  const [beginConference, { loading, data }] = useBeginConferenceMutation({
    onCompleted({ beginConference }, options) {
      tracker.track(TRACKING_EVENTS.ADD_TOLIVE_MEETING_SUBMITTED, {
        url: options?.variables?.url,
        liveMeeting: beginConference?.liveMeeting,
      });

      if (!beginConference?.liveMeeting) {
        toast({
          title: "Could not add to meeting",
          message: beginConference?.message || "Something went wrong",
          type: "error",
        });
      }
    },
    onError(err) {
      toast({
        title: "Could not add to meeting",
        message: err.message,
        type: "error",
      });
    },
  });

  const [saveOptions] = useSaveOptionsMutation({
    onCompleted({ saveOptions }) {
      if (saveOptions?.preferredLanguage) {
        authApi.updateUser({
          preferredLanguage: saveOptions.preferredLanguage,
        });
      }
    },
    onError(err) {
      toast({
        title: "Could not update language",
        message: err.message,
        type: "error",
      });
    },
  });

  const onSubmit = () => {
    // try to normalize the url
    let fixedUrl = url.trim();
    if (!fixedUrl.startsWith("http")) {
      fixedUrl = `https://${fixedUrl}`;
    }

    beginConference({
      variables: {
        url: fixedUrl,
        title: title.trim() ? title.trim() : undefined,
      },
    });
  };

  if (data?.beginConference?.liveMeeting) {
    return (
      <Animated.View style={styles.success} entering={FadeInDown.duration(300)}>
        <View style={styles.successIcon}>
          <IconCheck color="#ffffff" />
        </View>
        <Text fontWeight="500" fontSize={18} style={styles.successText}>
          Fireflies assistant has been invited to the meeting
        </Text>
        <Text color="textHint" style={styles.successText}>
          Once joined, Fireflies notetaker assistant will automatically start
          taking notes.
        </Text>
      </Animated.View>
    );
  }

  return (
    <>
      <Text color="textSecondary">
        Fireflies notetaker assistant will be added to the meeting.
      </Text>
      <TextField
        label="Name your meeting (Optional)"
        value={title}
        onValueChange={setTitle}
      />
      <TextField
        label="Meeting link"
        description="Capture meetings from GMeet, Zoom, MS teams, and more."
        Icon={IconLink}
        value={url}
        onValueChange={setUrl}
      />
      <SelectField
        label="Meeting Language"
        onValueChange={(newValue) => {
          setLanguage(newValue);
          saveOptions({
            variables: {
              preferredLanguage: newValue,
            },
          });
        }}
        value={language}
        options={SUPPORTED_LANGUAGES_OPTIONS}
      />
      <Button
        style={styles.button}
        disabled={!url.trim() || loading}
        onPress={onSubmit}
      >
        Invite Fireflies
      </Button>
    </>
  );
};

export const TranscribeLiveMeetingModal: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  return (
    <Dialog.Root variant="bottomSheet" isOpen={isOpen} close={close}>
      <Dialog.Header>Add to live meeting</Dialog.Header>
      <View style={styles.root}>
        <TranscribeLiveMeetingModalContent />
      </View>
    </Dialog.Root>
  );
};

const styles = StyleSheet.create({
  root: {
    gap: 12,
    padding: 20,
  },
  button: {
    marginTop: 8,
  },
  success: {
    alignItems: "center",
    gap: 8,
  },
  successIcon: {
    backgroundColor: "rgb(55, 179, 78)",
    alignItems: "center",
    justifyContent: "center",
    width: 82,
    height: 82,
    borderRadius: 9999,
    borderWidth: 16,
    borderColor: "rgb(234, 251, 237)",
    marginBottom: 8,
  },
  successText: {
    textAlign: "center",
  },
});
