import { IconZap } from "@/assets/svg";
import { Button } from "@/components/Button";
import { Text } from "@/components/Typography";
import {
  PAYWALL_VISIBILITY,
  startUpgradeFlow,
  useBilling,
  useShouldShowUnlimitedRecordingCampaign,
} from "@/features/billing";
import { useTheme } from "@/styles";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";

const PaywallBanner: FC<{
  title: string;
  description: string;
  backgroundColor: string;
  iconColor: string;
  pwl: string;
}> = ({ title, description, backgroundColor, iconColor, pwl }) => {
  return (
    <View style={[styles.banner, { backgroundColor }]}>
      <View style={styles.leading}>
        <IconZap fill={iconColor} color={iconColor} width={20} height={20} />
        <View style={styles.texts}>
          <Text variant="body2Weight">{title}</Text>
          <Text variant="body2Regular">{description}</Text>
        </View>
      </View>
      {PAYWALL_VISIBILITY === "show" && (
        <View style={styles.actions}>
          <Button
            variant="transparentPrimary"
            size="xs"
            onPress={() => startUpgradeFlow({ pwl })}
          >
            Upgrade
          </Button>
        </View>
      )}
    </View>
  );
};

export const useShowPaywallBanners = () => {
  const { reachedTeamMinutesConsumedLimit, reachedFreeMeetingsLimit } =
    useBilling();

  return reachedTeamMinutesConsumedLimit || reachedFreeMeetingsLimit;
};

export const PaywallBanners: FC = () => {
  const { reachedTeamMinutesConsumedLimit, reachedFreeMeetingsLimit } =
    useBilling();

  const shouldMentionUnlimitedRecording =
    useShouldShowUnlimitedRecordingCampaign();

  const theme = useTheme();

  if (
    (!reachedTeamMinutesConsumedLimit && !reachedFreeMeetingsLimit) ||
    PAYWALL_VISIBILITY === "hidden"
  ) {
    return null;
  }

  return (
    <View style={styles.banners}>
      {reachedFreeMeetingsLimit && (
        <PaywallBanner
          title="Credits Exhausted"
          description={`Future meetings will not be transcribed until you upgrade your plan.${
            shouldMentionUnlimitedRecording
              ? " You can still transcribe unlimited voice notes."
              : ""
          }`}
          backgroundColor={theme.colors.rainbowStaticTealSubtle}
          iconColor={theme.colors.rainbowStaticTealSolid}
          pwl="mobile-feed-credits"
        />
      )}
      {reachedTeamMinutesConsumedLimit && (
        <PaywallBanner
          title="Storage Exhausted"
          description="Delete old meetings or upgrade your plan to continue using Fireflies."
          backgroundColor={theme.colors.rainbowStaticOrangeSubtle}
          iconColor={theme.colors.rainbowStaticOrangeSolid}
          pwl="mobile-feed-storage"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  banners: {
    flexDirection: "column",
    gap: 8,
    marginBottom: 12,
    width: "100%",
  },
  banner: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 8,
  },
  leading: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
    alignSelf: "stretch",
  },
  texts: {
    flexDirection: "column",
    flex: 1,
    minWidth: 0,
  },
  actions: {
    flexDirection: "row",
    paddingLeft: 24,
    alignItems: "flex-start",
    justifyContent: "center",
  },
});
