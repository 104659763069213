import { IconX } from "@/assets/svg";
import { Avatar } from "@/components/Avatar";
import { TextButton } from "@/components/Button";
import { Dialog } from "@/components/Dialog";
import type { TagInputProps } from "@/components/Input";
import { SelectField, TagInput } from "@/components/Input";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { Config } from "@/constants";
import type {
  FindUsersMeetingIsSharedWithResponse,
  SharedMeetingTtlInput,
} from "@/graphql";
import {
  FindUsersMeetingIsSharedWithDocument,
  useGetPublicProfilesQuery,
  useShareMeetingsMutation,
  type Meeting,
} from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import { isEmail } from "@/utils/email";
import { isDefined } from "@/utils/object";
import { useCallback, useMemo, useState, type FC } from "react";
import { Pressable, ScrollView, TouchableOpacity, View } from "react-native";
import { TTL_OPTIONS } from "./constants";
import { appReviewApi } from "@/features/app-review";

const EmailUserListItem: FC<{
  email: string;
  name?: string | null;
  picture?: string | null;
  onRemove?: () => void;
}> = ({ email, name, picture, onRemove }) => {
  const theme = useTheme();
  return (
    <View style={styles.listItem}>
      <Avatar size={32} name={name || email} picture={picture} shape="square" />
      <View style={styles.listItemContent}>
        <Text color="textPrimary" variant="body2Regular">
          {name || email}
        </Text>
        {name && (
          <Text color="textHint" variant="body3Regular">
            {email}
          </Text>
        )}
      </View>
      {onRemove && (
        <TouchableOpacity
          style={styles.clear}
          onPress={onRemove}
          role="button"
          aria-label={`Remove ${name || email}`}
        >
          <IconX width={20} height={20} color={theme.colors.textHint} />
        </TouchableOpacity>
      )}
    </View>
  );
};

const ttlToString = (ttl: SharedMeetingTtlInput | null) => {
  if (!ttl) return "";
  return `${ttl.unit}:${ttl.duration}`;
};

const ttlFromString = (ttl: string): SharedMeetingTtlInput | null => {
  if (!ttl) return null;
  const [unit, duration] = ttl.split(":");
  return { unit, duration: Number(duration) };
};

export const ShareMeetingDialogEmailAddingContent: FC<{
  meeting: Meeting;
  close: () => void;
  findUsersMeetingIsSharedWith:
    | FindUsersMeetingIsSharedWithResponse
    | undefined
    | null;
}> = ({ meeting, close, findUsersMeetingIsSharedWith }) => {
  const suggestedEmails = useMemo(() => {
    const allSharedEmails = [
      ...(findUsersMeetingIsSharedWith?.teammates || []),
      ...(findUsersMeetingIsSharedWith?.nonTeammates || []),
    ]
      .map((user) => user?.email)
      .filter(isDefined);

    const set = new Set(
      [...(meeting.allEmails || [])].filter(
        (email) => !allSharedEmails.includes(email),
      ),
    ) as Set<string>;
    return Array.from(set);
  }, [
    meeting,
    findUsersMeetingIsSharedWith?.nonTeammates,
    findUsersMeetingIsSharedWith?.teammates,
  ]);

  const { data: dataPublicProfiles } = useGetPublicProfilesQuery({
    variables: {
      emails: suggestedEmails,
    },
  });

  const profileMap = useMemo(() => {
    const map: Record<
      string,
      { email?: string | null; name?: string | null; picture?: string | null }
    > = {};
    dataPublicProfiles?.publicProfiles?.forEach((profile) => {
      if (!profile.email) return;
      map[profile.email] = profile;
    });
    return map;
  }, [dataPublicProfiles]);

  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [tagInputText, setTagInputText] = useState("");

  const [ttl, setTtl] = useState<SharedMeetingTtlInput | null>(null);

  const options = useMemo(() => {
    const allOptions =
      suggestedEmails
        ?.filter((email) => !selectedEmails.includes(email))
        ?.sort((a, b) => a.localeCompare(b)) || [];

    if (!tagInputText) return allOptions;

    return allOptions.filter((email) =>
      email.toLowerCase().includes(tagInputText.trim().toLowerCase()),
    );
  }, [suggestedEmails, selectedEmails, tagInputText]);

  const onChangeValue = useCallback<TagInputProps["onChangeValue"]>(
    (value, option, action) => {
      if (action === "add") {
        option = option.trim().toLowerCase();
        if (!isEmail(option)) return false;
        setSelectedEmails((emails) => Array.from(new Set([...emails, option])));
      } else {
        setSelectedEmails(value);
      }
    },
    [],
  );

  const [shareMeetings, { loading }] = useShareMeetingsMutation({
    refetchQueries: [FindUsersMeetingIsSharedWithDocument],
    variables: {
      meetingId: meeting.id,
      inviteeList: selectedEmails,
      reason: Config.NAME,
      ttl,
    },
    onCompleted() {
      toast({
        message: "You have shared successfully",
        type: "success",
      });
      appReviewApi.increaseSharedMeetingCount();
      close();
    },
    onError(err) {
      toast({
        title: "Could not share meeting",
        message: err.message,
        type: "error",
      });
    },
  });

  const theme = useTheme();

  return (
    <>
      <Dialog.Header
        actions={
          <TextButton
            disabled={selectedEmails.length <= 0 || loading}
            onPress={() => shareMeetings()}
          >
            Invite
          </TextButton>
        }
      >
        Invite a person
      </Dialog.Header>
      <View style={styles.root}>
        <TagInput
          value={selectedEmails}
          onChangeValue={onChangeValue}
          placeholder="Add emails or people..."
          autoComplete="email"
          text={tagInputText}
          onChangeText={setTagInputText}
          autoFocus
          variant="filled"
          style={styles.input}
        />
        <View style={styles.content}>
          <View style={styles.container}>
            <SelectField
              label="Access expires after"
              options={TTL_OPTIONS.map((ttl) => ({
                ...ttl,
                value: ttlToString(ttl.value),
              }))}
              value={ttlToString(ttl)}
              onValueChange={(value) => setTtl(ttlFromString(value))}
            />
          </View>
          <View style={styles.listSection}>
            <View style={styles.container}>
              <Text variant="label2Weight">Select a person</Text>
            </View>
            <ScrollView contentContainerStyle={styles.list}>
              {options.map((email) => {
                return (
                  <Pressable
                    key={email}
                    onPress={() => {
                      setSelectedEmails((emails) =>
                        Array.from(new Set([...emails, email])),
                      );
                      setTagInputText("");
                    }}
                    style={({ pressed }) => [
                      pressed && styles.pressableActive(theme),
                    ]}
                  >
                    <EmailUserListItem {...profileMap?.[email]} email={email} />
                  </Pressable>
                );
              })}
            </ScrollView>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = createStyles({
  root: {
    gap: 8,
    flex: 1,
  },
  input: {
    borderRadius: 0,
  },
  content: {
    flex: 1,
    gap: 8,
  },
  container: {
    paddingHorizontal: 16,
  },
  pressableActive: (theme) => ({
    backgroundColor: theme.colors.layerMuted,
  }),
  listSection: {
    flex: 1,
  },
  list: {
    gap: 4,
  },
  listItem: {
    flexDirection: "row",
    paddingHorizontal: 20,
    paddingVertical: 4,
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 12,
    minHeight: 52,
  },
  listItemContent: {
    flex: 1,
  },
  clear: {
    padding: 4,
  },
});
