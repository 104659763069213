import type { StackRouteConfig } from "../types";
import { MeetingStatusScreen } from "./MeetingStatusScreen";

const MeetingStatusRouteConfig: StackRouteConfig<"MeetingStatus"> = {
  name: "MeetingStatus",
  component: MeetingStatusScreen,
  options: {
    title: "Meeting Status",
  },
};

export default MeetingStatusRouteConfig;
