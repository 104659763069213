import * as Application from "expo-application";
import { Platform } from "react-native";

function assert<T>(key: string, value: T | undefined | null): value is T {
  if (value === undefined || value === null || Number.isNaN(value)) {
    throw new Error(`Invalid config value for key "${key}"`);
  }
  return true;
}

const ConfigInput = {
  NAME: "mobile",
  APP_NAME: `${Application.applicationId || "mobile"} (${Platform.OS})`,
  VERSION: Application.nativeApplicationVersion || "0.0.0",
  APP_ENV: process.env.EXPO_PUBLIC_APP_ENV as
    | "preview"
    | "production"
    | "development",
  GRAPHQL_URL: process.env.EXPO_PUBLIC_GRAPHQL_URL,
  DASHBOARD_URL: process.env.EXPO_PUBLIC_DASHBOARD_URL,
  DOWNLOAD_URL: process.env.EXPO_PUBLIC_DOWNLOAD_URL,
  USER_SERVICE_URL: process.env.EXPO_PUBLIC_USER_SERVICE_URL,
  MOBILE_APP_URL: process.env.EXPO_PUBLIC_MOBILE_APP_URL,
  LANDING_URL: process.env.EXPO_PUBLIC_LANDING_URL,
  SENTRY_DSN: process.env.EXPO_PUBLIC_SENTRY_DSN,
  SEGMENT_WRITE_KEY: process.env.EXPO_PUBLIC_SEGMENT_WRITE_KEY,
  REALTIME_FF_HOST_WS: process.env.EXPO_PUBLIC_REALTIME_FF_HOST_WS,
};

for (const envKey of Object.keys(ConfigInput)) {
  assert(envKey, ConfigInput[envKey as keyof typeof ConfigInput]);
}

export const Config = ConfigInput as {
  [key in keyof typeof ConfigInput]: NonNullable<(typeof ConfigInput)[key]>;
};
