import { IconDottedCircle, IconMoreVertical, IconSettings } from "@/assets/svg";
import { BottomSheetMenuModal } from "@/components/BottomSheet";
import { HeaderIconButton, HeaderSideContainer } from "@/components/Layout";
import { RecordSettingsModal } from "@/features/live-record";
import { useBooleanState } from "@/utils/states";
import { type FC } from "react";

export const HeaderRightInactive: FC<{
  openRecordings: () => void;
}> = ({ openRecordings }) => {
  const [isOpenRecordSettings, openRecordSettings, closeRecordSettings] =
    useBooleanState();
  const [isOpenMoreModal, openMoreModal, closeMoreModal] = useBooleanState();

  return (
    <>
      <HeaderSideContainer>
        <HeaderIconButton
          Icon={IconMoreVertical}
          label="Local Records and Settings"
          onPress={openMoreModal}
        />
      </HeaderSideContainer>
      <BottomSheetMenuModal
        isOpen={isOpenMoreModal}
        close={closeMoreModal}
        items={[
          {
            Icon: IconDottedCircle,
            label: "Local Recordings",
            onPress: openRecordings,
          },
          {
            Icon: IconSettings,
            label: "Record Settings",
            onPress: openRecordSettings,
          },
        ]}
      />
      <RecordSettingsModal
        isOpen={isOpenRecordSettings}
        close={closeRecordSettings}
      />
    </>
  );
};
