import { KeyboardAvoidingView } from "@/components/Layout";
import type { TabsRef } from "@/components/Tabs";
import { Tabs } from "@/components/Tabs";
import type { Meeting } from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import { useCallback, useRef, type FC } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { BookmarksTabTrailing } from "./Bookmarks/BookmarksTabTrailing";
import { LiveTranscriptBookmarks } from "./Bookmarks/LiveTranscriptBookmarks";
import { useLiveScreenContext } from "./Context/LiveScreenContext";
import {
  LiveScreenActions,
  LoadingLiveScreenActions,
} from "./Footer/LiveScreenActions";
import {
  LiveScreenCommentForm,
  LoadingLiveScreenCommentForm,
} from "./Footer/LiveScreenCommentForm";
import {
  LiveScreenNotes,
  LoadingLiveScreenNotes,
} from "./Notes/LiveScreenNotes";
import { TabName } from "./constants";

export const LiveScreenContent: FC<{
  meeting: Meeting | null;
}> = ({ meeting }) => {
  const theme = useTheme();
  const tabRef = useRef<TabsRef>(null);

  const { ee } = useLiveScreenContext();

  const onTabChange = useCallback(
    (name: string) => {
      ee?.emit("tabChange", name);
    },
    [ee],
  );

  return (
    <KeyboardAvoidingView style={styles.root}>
      <Tabs.Root ref={tabRef} disabled={!meeting} onTabChange={onTabChange}>
        <Tabs.Panel name={TabName.Notes} title="Notes">
          {meeting ? (
            <LiveScreenNotes meeting={meeting} />
          ) : (
            <LoadingLiveScreenNotes />
          )}
        </Tabs.Panel>
        <Tabs.Panel
          name={TabName.Bookmarks}
          title="Bookmarks"
          tabTrailing={meeting ? <BookmarksTabTrailing /> : undefined}
        >
          {meeting ? <LiveTranscriptBookmarks meeting={meeting} /> : null}
        </Tabs.Panel>
      </Tabs.Root>
      <SafeAreaView
        edges={{ bottom: "maximum" }}
        style={styles.footer(theme)}
        mode="padding"
      >
        {meeting ? (
          <>
            <LiveScreenActions meeting={meeting} />
            <LiveScreenCommentForm meeting={meeting} />
          </>
        ) : (
          <>
            <LoadingLiveScreenActions />
            <LoadingLiveScreenCommentForm />
          </>
        )}
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

const styles = createStyles({
  root: {
    flex: 1,
  },
  footer: (theme) => ({
    borderTopColor: theme.colors.borderStaticDefault,
    borderTopWidth: 1,
    flexDirection: "column",
    paddingBottom: 12,
  }),
});
