import { IconUploadCloud } from "@/assets/svg";
import { Dialog } from "@/components/Dialog";
import { PaywallContent } from "@/components/Paywall";
import { useTheme } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { FC } from "react";
import { globalModalsApi } from "../global-modals.store";

export const STORAGE_PAYWALL_MODAL_NAME = "storage-paywall-modal";

export const StoragePaywallModal: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const theme = useTheme();

  return (
    <Dialog.Root isOpen={isOpen} close={close}>
      <PaywallContent
        header={
          <IconUploadCloud
            color={theme.colors.informationStaticYellowText}
            width={24}
            height={24}
          />
        }
        title="You have run out of storage"
        description="Delete old meetings or upgrade your plan to continue using Fireflies."
        pwl="mobile-storage"
      />
    </Dialog.Root>
  );
};

export const openStoragePaywallModal = (fromRef: string) => {
  globalModalsApi.openModal(STORAGE_PAYWALL_MODAL_NAME);

  tracker.track(TRACKING_EVENTS.PAYWALL_SHOWN, {
    pwl: "storage",
    from: fromRef,
  });
};
