import type { ImageStyle } from "expo-image";
import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  Dimensions,
  StyleSheet,
  type TextStyle,
  type ViewStyle,
} from "react-native";
import { Breakpoint, responsiveSizes } from "./breakpoint";
import * as Colors from "./color";

export const lightTheme = {
  name: "light",
  colors: Colors.LIGHT,
  breakpoint: Breakpoint.Small,
} as const;

export const darkTheme = {
  name: "dark",
  colors: Colors.DARK,
  breakpoint: Breakpoint.Small,
};

export const ThemeContext = createContext({} as Theme);

export const useTheme = () => useContext(ThemeContext);

/**
 * Same as StyleSheet.create, but with support for themed functions.
 * If a style is a function, it will be called with the theme and cached.
 */
export function createStyles<
  T extends ThemableNamedStyles<T> | ThemableNamedStyles<any>,
>(styles: T | ThemableNamedStyles<T>) {
  const staticStylesObj = {} as Record<keyof T, Style>;
  const dynamicStylesObj = {} as Record<keyof T, (theme: Theme) => Style>;

  for (const key of Object.keys(styles)) {
    const s = styles[key as keyof T];
    if (typeof s === "function") {
      const cache = new Map<Theme, Style>();
      dynamicStylesObj[key as keyof T] = (theme: Theme) => {
        let cached = cache.get(theme);
        if (!cached) {
          cached = s(theme);
          cache.set(theme, cached);
        }
        return cached;
      };
    } else {
      staticStylesObj[key as keyof T] = s as Style;
    }
  }

  const staticStyles = StyleSheet.create(staticStylesObj);
  return { ...staticStyles, ...dynamicStylesObj } as T;
}

type Style = ViewStyle | TextStyle | ImageStyle;

type ThemableNamedStyles<T> = {
  [P in keyof T]: Style | ((theme: Theme) => Style);
};

export type Theme = typeof lightTheme | typeof darkTheme;

const getBreakpoint = (width: number) => {
  if (width >= responsiveSizes.large) {
    return Breakpoint.Large;
  }
  if (width >= responsiveSizes.medium) {
    return Breakpoint.Medium;
  }
  return Breakpoint.Small;
};

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [breakpoint, setBreakpoint] = useState(() =>
    getBreakpoint(Dimensions.get("window").width),
  );

  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", ({ window }) => {
      const newBreakpoint = getBreakpoint(window.width);
      if (newBreakpoint !== breakpoint) {
        setBreakpoint(newBreakpoint);
      }
    });
    return () => subscription.remove();
  }, [breakpoint]);

  const theme = useMemo(
    () => ({
      ...lightTheme,
      breakpoint,
    }),
    [breakpoint],
  );

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};
