import type { Highlight, Source } from "@/graphql";
import { format, isValid } from "date-fns";
import { type FC } from "react";
import { StyleSheet, View } from "react-native";
import { Avatar } from "../Avatar";
import { SkeletonLoading } from "../Loading";
import { Text } from "../Typography";
import { MeetingAddedByIcon } from "./MeetingAddedByIcon";

interface MeetingListItemProps {
  meeting: Source;
  highlight?: Highlight;
}

const now = new Date();

export const MEETING_LIST_ITEM_HEIGHT = 114;

const parseHighlightSentence = (sentence: string) => {
  const elements = [];
  let index = 0;
  let lastIndex = 0;
  while (index !== -1) {
    index = sentence.indexOf("<em>", lastIndex);
    if (index !== -1) {
      if (index > lastIndex) {
        elements.push(sentence.substring(lastIndex, index));
      }
      const closingIndex = sentence.indexOf("</em>", lastIndex);
      if (closingIndex === -1) {
        // invalid closure
        break;
      }
      elements.push(
        <Text style={styles.searchedText} key={index}>
          {sentence.substring(index + 4, closingIndex)}
        </Text>,
      );
      lastIndex = closingIndex + 5;
    }
  }
  if (lastIndex < sentence.length) {
    elements.push(sentence.substring(lastIndex));
  }

  return <>{elements}</>;
};

export const MeetingListItem: FC<MeetingListItemProps> = ({
  meeting,
  highlight,
}) => {
  const date = meeting.date ? new Date(meeting.date) : null;
  const isSameYear = date?.getFullYear() === now.getFullYear();

  const meetingCreatorName =
    meeting.creatorProfile?.name || meeting.creator_email;

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.meta}>
          <Avatar
            size={40}
            name={meetingCreatorName}
            picture={meeting.creatorProfile?.picture}
            shape="square"
          />
          <View style={styles.content}>
            <View style={styles.titleWrapper}>
              <Text
                variant="title3Weight"
                numberOfLines={1}
                style={styles.title}
              >
                {meeting.title}
              </Text>
              <MeetingAddedByIcon meeting={meeting} iconOnly />
            </View>
            <Text variant="body2Regular" color="textMuted">
              {meetingCreatorName}
            </Text>
            <View style={styles.sub}>
              <Text variant="body2Regular" color="textHint">
                {!!date &&
                  isValid(date) &&
                  format(
                    date,
                    isSameYear ? "MMM d · h:mm a" : "MMM d, yyyy · h:mm a",
                  )}
              </Text>
              <Text variant="body2Regular" color="textHint">
                ·
              </Text>
              <Text color="textHint" variant="body2Regular">
                {Math.round(meeting.durationMins || meeting.duration || 0)} mins
              </Text>
            </View>
          </View>
        </View>
        {highlight && (
          <View
            style={styles.highlight}
            role="list"
            aria-label="caption mentions"
          >
            {highlight.captions?.slice(0, 3).map((caption) => (
              <Text
                key={caption.index ?? caption.time}
                color="textMuted"
                role="listitem"
              >
                {parseHighlightSentence(caption.sentence || "")}
              </Text>
            ))}
          </View>
        )}
      </View>
    </View>
  );
};

export const LoadingMeetingListItem: FC = () => {
  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.meta}>
          <SkeletonLoading width={40} height={40} variant="rounded" />
          <View style={styles.contentLoading}>
            <SkeletonLoading width={220} height={18} />
            <SkeletonLoading width={100} height={16} />
            <View style={styles.sub}>
              <SkeletonLoading width={50} height={16} />
              <SkeletonLoading width={60} height={16} />
              <SkeletonLoading width={45} height={16} />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    minHeight: MEETING_LIST_ITEM_HEIGHT,
    paddingVertical: 10,
    justifyContent: "center",
  },
  container: {
    paddingHorizontal: 8,
    flexDirection: "column",
    gap: 8,
  },
  content: {
    flex: 1,
    gap: 2,
  },
  contentLoading: {
    flex: 1,
    gap: 4,
  },
  sub: {
    flexDirection: "row",
    gap: 4,
  },
  meta: {
    flexDirection: "row",
    gap: 16,
    width: "100%",
  },
  highlight: {
    flexDirection: "column",
    gap: 8,
  },
  searchedText: {
    color: "#000",
    backgroundColor: "#ffff45",
  },
  titleWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  title: {
    flexShrink: 1,
  },
});
