import { IconSquare } from "@/assets/svg";
import { usePulseAnimatedStyle } from "@/components/Animated";
import { LinkView } from "@/components/Link";
import { Text } from "@/components/Typography";
import { RouteNames } from "@/constants";
import { type PhoneCallStates, usePhoneCall } from "@/features/phone-call";
import { createStyles, useTheme, vars } from "@/styles";
import { toHHMMSS } from "@/utils/time";
import { useNavigationState } from "@react-navigation/native";
import type { FC } from "react";
import { useMemo } from "react";
import { View } from "react-native";
import Animated, {
  useAnimatedStyle,
  withTiming,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useRecorder } from "../record.store";
import type { RecordingStates } from "../types";

type FloatingCardContentProps =
  | {
      status: PhoneCallStates;
      type: "phoneCall";
    }
  | {
      status: RecordingStates;
      type: "record";
    };

const getStatusTexts = (
  props: FloatingCardContentProps,
): {
  title: string;
  subtitle?: string;
} => {
  if (props.type === "phoneCall") {
    return {
      title:
        props.status.state === "ongoing"
          ? `Ongoing Call ・ ${toHHMMSS(props.status.durationMillis / 1000)}`
          : "Connecting",
    };
  } else {
    switch (props.status.state) {
      case "inactive": {
        // it means the recording is still being uploaded after stopping
        return {
          title: `Uploading ・ ${Math.min(100, Math.floor((props.status.fileReadOffset / props.status.fileSize) * 100))}%`,
          subtitle:
            "Your recording is being uploaded. Closing the application will stop it from completing.",
        };
      }
      case "paused": {
        return { title: "Paused" };
      }
      case "recording": {
        return {
          title: `Recording ・ ${toHHMMSS(props.status.durationMillis / 1000)}`,
        };
      }
    }
  }
};

const FloatingCardContent: FC<FloatingCardContentProps> = (props) => {
  const theme = useTheme();
  const animStyle = usePulseAnimatedStyle();
  const currentRouteName = useNavigationState(
    (state) => state?.routes[state.index]?.name,
  );

  const insets = useSafeAreaInsets();

  const screenType = useMemo<"active" | "main" | "other" | null>(() => {
    if (!currentRouteName) return null;

    if (
      currentRouteName ===
      (props.type === "phoneCall" ? RouteNames.PhoneCall : RouteNames.Record)
    ) {
      return "active";
    } else if (
      ["Home", "Notebook", "Library", "Analytics", "Phone", "Main"].includes(
        currentRouteName,
      )
    ) {
      return "main";
    } else {
      return "other";
    }
  }, [currentRouteName, props.type]);

  const animRootStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateY: withTiming(
            screenType === "active"
              ? 100
              : screenType === "main"
                ? -50 - insets.bottom
                : -insets.bottom,
          ),
        },
      ],
    }),
    [screenType],
  );

  const bringToFrontActionHref =
    props.type === "phoneCall" ? RouteNames.PhoneCall : RouteNames.Record;

  const stopActionHref =
    props.type === "phoneCall"
      ? { pathname: RouteNames.PhoneCall, params: { action: "stop" } }
      : { pathname: RouteNames.Record, params: { action: "stop" } };

  const statusTexts = getStatusTexts(props);

  return (
    <Animated.View style={[styles.root(theme), animRootStyle]}>
      <LinkView style={styles.leading} href={bringToFrontActionHref}>
        {props.status.state !== "inactive" && (
          <Animated.View style={[styles.dot(theme), animStyle]} />
        )}
        <View style={styles.texts}>
          <Text variant="title3Regular">{statusTexts.title}</Text>
          {statusTexts.subtitle && (
            <Text variant="body3Regular" color="textHint">
              {statusTexts.subtitle}
            </Text>
          )}
        </View>
      </LinkView>
      {props.status.state !== "inactive" && (
        <LinkView style={styles.button(theme)} animated href={stopActionHref}>
          <IconSquare
            stroke="transparent"
            fill={theme.colors.commandNeutralDefault}
          />
        </LinkView>
      )}
    </Animated.View>
  );
};

export const LiveRecordFloatingCard = () => {
  const { status: phoneCallStatus } = usePhoneCall();
  const { status: recordStatus } = useRecorder();

  if (phoneCallStatus.state !== "inactive") {
    return <FloatingCardContent status={phoneCallStatus} type="phoneCall" />;
  }

  if (
    recordStatus.state !== "inactive" ||
    recordStatus.streamingState !== "inactive"
  ) {
    return <FloatingCardContent status={recordStatus} type="record" />;
  }
};

const styles = createStyles({
  root: (theme) => ({
    ...vars.elevations[2],
    position: "absolute",
    left: 16,
    right: 16,
    bottom: 16,
    backgroundColor: theme.colors.layerDefault,
    borderRadius: 9999,
    borderWidth: 1,
    borderColor: theme.colors.borderStaticSubtle,
    flexDirection: "row",
    padding: 8,
    paddingLeft: 24,
    alignItems: "center",
  }),
  texts: {
    flexDirection: "column",
    gap: 4,
  },
  leading: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
    flex: 1,
  },
  dot: (theme) => ({
    backgroundColor: theme.colors.informationStaticRedText,
    width: 8,
    height: 8,
    borderRadius: 9999,
  }),
  button: (theme) => ({
    width: 56,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 9999,
    backgroundColor: theme.colors.interactiveNeutral3Default,
  }),
});
