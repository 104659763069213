import type { Channel } from "@/graphql";

export const LOCAL_CHANNEL_NAMES = {
  all: "All Meetings",
  mine: "My Meetings",
  shared: "Shared With Me",
} as const;

export const LOCAL_CHANNELS: Channel[] = [
  {
    title: LOCAL_CHANNEL_NAMES.all,
    _id: "all",
  },
  {
    title: LOCAL_CHANNEL_NAMES.mine,
    _id: "mine",
  },
  {
    title: LOCAL_CHANNEL_NAMES.shared,
    _id: "shared",
  },
];
