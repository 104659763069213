import { setSentryContext, setSentryTag } from "@/errors";
import { LoginPopup } from "@/features/auth";
import { GlobalModals } from "@/features/global-modals";
import {
  LiveRecordController,
  useRecordListeners,
} from "@/features/live-record";
import { ShareTargetComponent } from "@/features/share-target";
import { Logger } from "@/logger";
import { darkTheme, lightTheme, useTheme, vars } from "@/styles";
import { persistReferral } from "@/tracking";
import type { Theme } from "@react-navigation/native";
import { NavigationContainer } from "@react-navigation/native";
import type { NativeStackNavigationOptions } from "@react-navigation/native-stack";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import type { JsonMap } from "@segment/analytics-react-native";
import { useCallback, useEffect, useRef, type FC } from "react";
import { AppState } from "react-native";
import BiteRouteConfig from "./Bite";
import ExtensionsRouteConfig from "./Extensions";
import IntegrationsRouteConfig from "./Integrations";
import LiveRouteConfig from "./Live";
import { LoginRouteConfig } from "./Login";
import MainRouteConfig from "./Main";
import MeetingStatusRouteConfig from "./MeetingStatus";
import { NotFoundRouteConfig } from "./NotFound";
import NotepadRouteConfig from "./Notepad";
import OnboardingRouteConfig, {
  OnboardingRedirectComponent,
} from "./Onboarding";
import PlaylistRouteConfig from "./Playlist";
import RecordRouteConfig from "./Record";
import ReferRouteConfig from "./Refer";
import { navigationRef } from "./RootNavigation";
import SearchRouteConfig from "./Search";
import SettingsRouteConfig from "./Settings";
import TeamRouteConfig from "./Team";
import TopicTrackerRouteConfig from "./TopicTracker";
import UploadRouteConfig from "./Upload";
import YouRouteConfig from "./You";
import { NotificationBars } from "./components/NotificationBars";
import { linking } from "./linking";
import type { ParamList } from "./types";
import { useScreenTimeTracker } from "@/features/app-review";
import { ShortcutRedirectComponent } from "@/features/os-shortcuts";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
    interface RootParamList extends ParamList {}
  }
}

const Stack = createNativeStackNavigator<ParamList>();

const navigationLightTheme: Theme = {
  dark: false,
  colors: {
    background: lightTheme.colors.pageBackground,
    border: lightTheme.colors.borderStaticSubtle,
    card: lightTheme.colors.layerDefault,
    primary: lightTheme.colors.layerBrand,
    notification: lightTheme.colors.layerBrand,
    text: lightTheme.colors.textSecondary,
  },
};

const navigationDarkTheme: Theme = {
  dark: true,
  colors: {
    background: darkTheme.colors.pageBackground,
    border: darkTheme.colors.borderStaticSubtle,
    card: darkTheme.colors.layerDefault,
    primary: darkTheme.colors.layerBrand,
    notification: darkTheme.colors.layerBrand,
    text: darkTheme.colors.textSecondary,
  },
};

const screenOptions: NativeStackNavigationOptions = {
  headerShadowVisible: false,
  headerTitleStyle: vars.typography.title1Weight,
  headerBackTitleVisible: false,
};

const logger = new Logger("navigator");

const onReady = () => {
  persistReferral(navigationRef);
};

export const Navigator: FC = () => {
  const theme = useTheme();
  useRecordListeners();
  const { onNavigationStateChange } = useScreenTimeTracker();

  const routeNameRef = useRef("");
  const routeParamsRef = useRef<JsonMap | undefined>(undefined);

  const onStateChange = useCallback(() => {
    if (!navigationRef.current?.isReady()) return;
    const currentRoute = navigationRef.current?.getCurrentRoute();
    if (!currentRoute) return;
    if (
      routeNameRef.current !== currentRoute.name ||
      routeParamsRef.current !== currentRoute.params
    ) {
      routeNameRef.current = currentRoute.name;
      routeParamsRef.current = currentRoute.params as JsonMap | undefined;

      onNavigationStateChange(currentRoute.name);

      logger.info(`navigated to ${currentRoute.name}`, {
        type: "navigation",
        ...currentRoute.params,
      });

      setSentryContext("navigation", {
        route: currentRoute.name,
        params: currentRoute.params,
      });
      setSentryTag("route", currentRoute.name);
    }
  }, [onNavigationStateChange]);

  useEffect(() => {
    const sub = AppState.addEventListener("change", (state) => {
      logger.info("app state changed", {
        state,
      });
    });
    return () => {
      sub.remove();
    };
  }, []);

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      theme={
        theme.name === "light" ? navigationLightTheme : navigationDarkTheme
      }
      onReady={onReady}
      onStateChange={onStateChange}
    >
      <NotificationBars />
      <Stack.Navigator screenOptions={screenOptions} initialRouteName="Main">
        <Stack.Screen {...MainRouteConfig} />
        <Stack.Screen {...RecordRouteConfig} />
        <Stack.Screen {...NotepadRouteConfig} />
        <Stack.Screen {...BiteRouteConfig} />
        <Stack.Screen {...PlaylistRouteConfig} />
        <Stack.Screen {...SettingsRouteConfig} />
        <Stack.Screen {...MeetingStatusRouteConfig} />
        <Stack.Screen {...UploadRouteConfig} />
        <Stack.Screen {...IntegrationsRouteConfig} />
        <Stack.Screen {...ExtensionsRouteConfig} />
        <Stack.Screen {...TopicTrackerRouteConfig} />
        <Stack.Screen {...TeamRouteConfig} />
        <Stack.Screen {...YouRouteConfig} />
        <Stack.Screen {...SearchRouteConfig} />
        <Stack.Screen {...ReferRouteConfig} />
        <Stack.Screen {...LoginRouteConfig} />
        <Stack.Screen {...OnboardingRouteConfig} />
        <Stack.Screen {...NotFoundRouteConfig} />
        {/* <Stack.Screen {...PhoneCallRouteConfig} /> */}
        <Stack.Screen {...LiveRouteConfig} />
      </Stack.Navigator>
      <LiveRecordController />
      <ShareTargetComponent />
      <OnboardingRedirectComponent />
      <ShortcutRedirectComponent />
      <GlobalModals />
      <LoginPopup navigationRef={navigationRef} />
    </NavigationContainer>
  );
};
