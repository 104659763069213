import { endOfWeek, format, isSameYear, startOfWeek } from "date-fns";
import { getCalendars } from "expo-localization";

export type WeekGrouped<T> = {
  isCurrentWeek: boolean;
  startTime: Date;
  endTime: Date;
  items: T[];
};

export function groupByWeeks<
  T extends {
    startTime?: Date | string | null;
    date?: Date | string | null;
  },
>(items: T[]): WeekGrouped<T>[] {
  const groupedItems = items.reduce(
    (acc, item) => {
      const dateStr = item.startTime || item.date;
      if (!dateStr) {
        return acc;
      }
      const date = new Date(dateStr);
      const week = startOfWeek(date).getTime();

      if (!acc[week]) {
        acc[week] = [];
      }

      acc[week].push(item);
      return acc;
    },
    {} as Record<number, T[]>,
  );

  const thisWeek = startOfWeek(new Date()).getTime();

  return Object.entries(groupedItems)
    .map(([weekStr, items]) => {
      const week = Number(weekStr);

      return {
        isCurrentWeek: week === thisWeek,
        startTime: new Date(week),
        endTime: endOfWeek(week),
        items: items.sort((a, b) => {
          const aDate = a.startTime || a.date;
          const bDate = b.startTime || b.date;
          if (!aDate || !bDate) {
            return 0;
          }
          return new Date(bDate).getTime() - new Date(aDate).getTime();
        }),
      };
    })
    .sort((a, b) => b.startTime.getTime() - a.startTime.getTime());
}

export function getWeekGroupLabel(section: { startTime: Date; endTime: Date }) {
  const isInSameYear = isSameYear(section.startTime, section.endTime);
  const isSameYearAsNow = isSameYear(section.startTime, new Date());
  const dateFormat = isInSameYear && isSameYearAsNow ? "MMM d" : "MMM d, yyyy";

  return `${format(section.startTime, dateFormat)} - ${format(section.endTime, dateFormat)}`;
}

export function getTimeZone() {
  return getCalendars()?.[0]?.timeZone;
}
