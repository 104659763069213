import { Platform } from "react-native";
import { useAuth } from "../auth";
import { isFreeUser } from "@firefliesai/payments-ff.utils";

export const useHidePaidFeatures = () => {
  const { user } = useAuth();

  if (Platform.OS !== "ios") return false;

  return !user || isFreeUser(user.paidUser);
};
