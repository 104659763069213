@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,400;0,9..40,500;0,9..40,700;1,9..40,400;1,9..40,500;1,9..40,700&family=Inter:wght@400;500;700&display=swap");

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #f2f4f7;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background: #f2f4f7;
}

button {
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}
