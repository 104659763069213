export const BOOKMARK_PIN_TYPES = {
  IMPORTANT: "important",
  ACTION: "action-item",
  POSITIVE: "positive",
  CONCERN: "concern",
  SOUND_BITE: "soundbite",
};

export const BOOKMARK_ACTION_TYPES = {
  BOOKMARK: "bookmark",
  SOUND_BITE: "soundbite",
};

export const CREATE_BOOKMARK_SOURCES = {
  LIVE: "live",
  NOTEPAD: "notepad",
};

export const BOOKMARK_TYPES = {
  LIKE: "like",
  DISLIKE: "dislike",
  PIN: "pin",
};

export const NEW_COMMENT_SOURCES = {
  LIVE: "live",
  NOTEPAD: "notepad",
};
