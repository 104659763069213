import { IconDownload, IconEdit, IconShare, IconTrash } from "@/assets/svg";
import { BottomSheetMenuModal } from "@/components/BottomSheet";
import { useAuth } from "@/features/auth";
import { useHidePaidFeatures } from "@/features/billing";
import { openDownloadPaywallModal } from "@/features/global-modals";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import { isTruthy } from "@/utils/object";
import { useBooleanState } from "@/utils/states";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import { isPaidUser } from "@firefliesai/payments-ff.utils";
import type { FC } from "react";
import { ShareMeetingDialog } from "../share-meeting/ShareMeetingDialog";
import { useCanManageMeeting } from "../utils/permissions";
import { MeetingDeleteDialog } from "./MeetingDeleteDialog";
import { MeetingDownloadDialog } from "./MeetingDownloadDialog";
import { MeetingRenameDialog } from "./MeetingRenameDialog";

export const MeetingBottomSheetMenu: FC<{
  meeting: Meeting;
  isOpen: boolean;
  close: () => void;
  onDelete?: () => void;
  showDownload?: boolean;
  fromRef: string;
}> = ({ meeting, isOpen, close, onDelete, showDownload, fromRef }) => {
  const [isOpenShare, openShare, closeShare] = useBooleanState();
  const [isOpenDelete, openDelete, closeDelete] = useBooleanState();
  const [isOpenDownload, openDownload, closeDownload] = useBooleanState();
  const [isOpenRename, openRename, closeRename] = useBooleanState();

  const canManage = useCanManageMeeting(meeting);

  const hidePaidFeature = useHidePaidFeatures();

  const { user } = useAuth();

  return (
    <>
      <BottomSheetMenuModal
        isOpen={isOpen}
        close={close}
        items={[
          {
            Icon: IconShare,
            label: "Share meeting",
            onPress() {
              openShare();
              tracker.track(TRACKING_EVENTS.SHARE_DIALOG_OPENED, {
                type: "meeting",
                from: "bottom-sheet-menu",
                meetingId: meeting.id,
                privacy: meeting.notesPrivacy,
              });
            },
          },
          canManage && {
            Icon: IconEdit,
            label: "Rename meeting",
            onPress: openRename,
          },
          !hidePaidFeature &&
            showDownload && {
              Icon: IconDownload,
              label: "Download meeting",
              onPress() {
                if (!user?.paidUser || !isPaidUser(user?.paidUser)) {
                  openDownloadPaywallModal(fromRef);
                  return;
                }
                openDownload();
                tracker.track(TRACKING_EVENTS.DOWNLOAD_MODAL_OPENED, {
                  type: "meeting",
                  from: "bottom-sheet-menu",
                  meetingId: meeting.id,
                });
              },
            },
          canManage && {
            Icon: IconTrash,
            label: "Delete meeting",
            onPress: openDelete,
          },
        ].filter(isTruthy)}
      />
      <ShareMeetingDialog
        meeting={meeting}
        isOpen={isOpenShare}
        close={closeShare}
      />
      <MeetingDeleteDialog
        meeting={meeting}
        isOpen={isOpenDelete}
        close={closeDelete}
        onDelete={onDelete}
        fromRef={fromRef}
      />
      <MeetingDownloadDialog
        meeting={meeting}
        isOpen={isOpenDownload}
        close={closeDownload}
      />
      <MeetingRenameDialog
        meeting={meeting}
        isOpen={isOpenRename}
        close={closeRename}
      />
    </>
  );
};
