import { IconFred, IconX } from "@/assets/svg";
import { Text } from "@/components/Typography";
import { createStyles, useTheme } from "@/styles";
import type { FC } from "react";
import { TouchableOpacity, View } from "react-native";
import { AskFredAiModelSelect } from "./AskFredAiModelSelect";

export const AskFredHeader: FC<{
  close: () => void;
  aiModel: string;
  setAiModel: (aiModel: string) => void;
}> = ({ close, aiModel, setAiModel }) => {
  const theme = useTheme();

  return (
    <>
      <View style={styles.header(theme)}>
        <View style={styles.headerLeft}>
          <IconFred width={27} height={27} />
          <Text fontWeight="500" fontSize={16}>
            AskFred
          </Text>
        </View>
        <AskFredAiModelSelect aiModel={aiModel} setAiModel={setAiModel} />
        <TouchableOpacity aria-label="Close" onPress={close} role="button">
          <IconX color={theme.colors.textMuted} />
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = createStyles({
  header: (theme) => ({
    borderBottomWidth: 1,
    borderColor: theme.colors.borderStaticSubtle,
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 12,
  }),
  headerLeft: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    flex: 1,
  },
});
