import * as MediaSession from "@/modules/media-session";
import { useEffect } from "react";
import { usePlayer } from "./PlayerContext";
import type { Options } from "./types";

MediaSession.setNotificationChannel("media-session", 101);

export const useMusicControl = (options: Options | null) => {
  const { player } = usePlayer();

  useEffect(() => {
    if (!options?.sources) {
      MediaSession.setMetadata(null);
      return;
    }
    MediaSession.setMetadata({
      title: options.metadata?.title || "Fireflies",
      artist: options.metadata?.artist,
      album: options.metadata?.album,
      artwork: options.metadata?.artwork
        ? [{ src: options.metadata.artwork }]
        : [],
    });
  }, [options]);

  useEffect(() => {
    return () => {
      MediaSession.setMetadata(null);
    };
  }, []);

  useEffect(() => {
    if (!player) {
      for (const action of [
        "play",
        "pause",
        "seekforward",
        "seekbackward",
        "seekto",
      ] satisfies MediaSessionAction[]) {
        MediaSession.setActionHandler(action, null);
      }
      return;
    }

    MediaSession.setActionHandler("play", () => player.play());
    MediaSession.setActionHandler("pause", () => player.pause());
    MediaSession.setActionHandler("seekforward", () => {
      player.currentTime += 15;
    });
    MediaSession.setActionHandler("seekbackward", () => {
      player.currentTime -= 5;
    });
    MediaSession.setActionHandler("seekto", (details) => {
      if (typeof details.seekTime === "number")
        player.currentTime = details.seekTime;
    });

    const onPositionStateChange = () => {
      MediaSession.setPositionState({
        duration: player.duration,
        playbackRate: player.playbackRate,
        position: player.currentTime,
      });
    };

    const onPlaybackStateChange = () => {
      MediaSession.setPlaybackState(player.paused ? "paused" : "playing");
    };
    const onWaiting = () => {
      MediaSession.setPlaybackState("paused");
    };

    player.addEventListener("durationchange", onPositionStateChange);
    player.addEventListener("seeked", () => {
      setTimeout(onPositionStateChange, 1000);
    });
    player.addEventListener("play", onPlaybackStateChange);
    player.addEventListener("pause", onPlaybackStateChange);
    player.addEventListener("waiting", onWaiting);

    return () => {
      // playback state
      player.removeEventListener("durationchange", onPositionStateChange);
      player.removeEventListener("play", onPlaybackStateChange);
      player.removeEventListener("pause", onPlaybackStateChange);
      player.removeEventListener("waiting", onWaiting);
    };
  }, [player]);
};
