import { IconCopy, IconFred } from "@/assets/svg";
import { Button } from "@/components/Button";
import type { MarkdownStyle } from "@/components/Markdown";
import { Markdown, markdownToHtml } from "@/components/Markdown";
import { createStyles, useTheme, vars } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import type { FC } from "react";
import { useMemo } from "react";
import { Pressable, View } from "react-native";
import { useExternalLinkHandler } from "../../utils/external-link";
import { transformContent } from "../../utils/transform-content";
import { FredTyping } from "./FredTyping";
import { copyText, markdownify } from "./utils";

const markdownStyle: Partial<MarkdownStyle> = {
  body: {
    ...vars.typography.body2Regular,
  },
  em: {
    ...vars.typography.body2Regular,
  },
  strong: {
    ...vars.typography.body2Weight,
  },
};

export const AskFredResponseFredMessage: FC<{
  answer: string | null | undefined;
  rating?: string | null | undefined;
  meeting: Meeting;
}> = ({ answer, meeting }) => {
  const theme = useTheme();
  const markdownAnswer = useMemo(
    () => (answer ? transformContent(meeting, markdownify(answer)) : null),
    [answer, meeting],
  );

  const onLinkPress = useExternalLinkHandler(meeting);

  const onCopy = () => {
    if (!markdownAnswer) return;
    const html = markdownToHtml(markdownAnswer);
    copyText(html, true);
    tracker.track(TRACKING_EVENTS.ASK_FRED_RESPONSE_COPIED, {
      meetingId: meeting.id,
    });
  };

  return (
    <View style={styles.answerWrapper}>
      <View style={styles.answerAvatar(theme)}>
        <IconFred width={20} height={20} />
      </View>
      <View style={styles.col}>
        <Pressable
          style={[styles.answer(theme), !markdownAnswer && styles.answerTyping]}
          onLongPress={onCopy}
        >
          {markdownAnswer ? (
            <Markdown
              color="textSecondary"
              onLinkPress={onLinkPress}
              style={markdownStyle}
            >
              {markdownAnswer}
            </Markdown>
          ) : (
            <FredTyping />
          )}
        </Pressable>
        {markdownAnswer && (
          <View style={styles.row}>
            <Button
              Icon={IconCopy}
              aria-label="Copy answer"
              variant="transparentNeutral"
              size="xs"
              onPress={onCopy}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = createStyles({
  answerWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 8,
    marginRight: "auto",
  },
  answerAvatar: (theme) => ({
    width: 32,
    height: 32,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme.colors.layerBrandLight2,
  }),
  answer: (theme) => ({
    backgroundColor: theme.colors.layerBrandLight2,
    padding: 12,
    borderRadius: 8,
    height: "auto",
  }),
  answerTyping: {
    height: 32,
    flex: 0,
  },
  col: {
    flex: 1,
    flexDirection: "column",
    gap: 4,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
});
