import {
  IconGlobe,
  IconLock,
  IconSmartphone,
  IconTeammates_participants,
  IconUsers,
} from "@/assets/svg";
import { MeetingNotesPrivacy } from "@firefliesai/mobile-ff.graphql-client";

export const MEETING_PRIVACY_ICON_MAP = {
  [MeetingNotesPrivacy.Link]: IconGlobe,
  [MeetingNotesPrivacy.Teammatesandparticipants]: IconTeammates_participants,
  [MeetingNotesPrivacy.Participants]: IconUsers,
  [MeetingNotesPrivacy.Teammates]: IconSmartphone,
  [MeetingNotesPrivacy.Owner]: IconLock,
};

export const MEETING_PRIVACY_OPTIONS = [
  {
    value: MeetingNotesPrivacy.Link,
    label: "Anyone with Link",
    description: "Anyone can access with the link",
    Icon: MEETING_PRIVACY_ICON_MAP[MeetingNotesPrivacy.Link],
  },
  {
    value: MeetingNotesPrivacy.Teammatesandparticipants,
    label: "Teammates and Participants",
    description: "Teammates and participants can access with the link",
    Icon: MEETING_PRIVACY_ICON_MAP[
      MeetingNotesPrivacy.Teammatesandparticipants
    ],
  },
  {
    value: MeetingNotesPrivacy.Teammates,
    label: "Teammates",
    description: "Only teammates can access with the link",
    Icon: MEETING_PRIVACY_ICON_MAP[MeetingNotesPrivacy.Teammates],
  },
  {
    value: MeetingNotesPrivacy.Participants,
    label: "Participants",
    description: "Only participants can access with the link",
    Icon: MEETING_PRIVACY_ICON_MAP[MeetingNotesPrivacy.Participants],
  },
  {
    value: MeetingNotesPrivacy.Owner,
    label: "Only owner",
    description: "Only owner can access with the link",
    Icon: MEETING_PRIVACY_ICON_MAP[MeetingNotesPrivacy.Owner],
  },
];
