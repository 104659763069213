import { AnimatedTouchableHighlight } from "@/components/Animated";
import { createStyles, useTheme, vars } from "@/styles";
import type { FC } from "react";
import type { TouchableHighlightProps } from "react-native";
import { ZoomIn, ZoomOut } from "react-native-reanimated";
import type { SvgProps } from "react-native-svg";

const fabStyles = createStyles({
  fab: (theme) => ({
    backgroundColor: theme.colors.layerDefault,
    width: 48,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.colors.borderStaticDefault,
    ...vars.elevations[2],
  }),
});

export const FabButton: FC<
  {
    Icon: FC<SvgProps>;
  } & TouchableHighlightProps
> = ({ Icon, ...props }) => {
  const theme = useTheme();

  return (
    <AnimatedTouchableHighlight
      underlayColor={theme.colors.layerSubtle}
      {...props}
      style={[fabStyles.fab(theme), props.style]}
      entering={ZoomIn.duration(100)}
      exiting={ZoomOut.duration(100)}
    >
      <Icon width={24} height={24} color={theme.colors.commandNeutralDefault} />
    </AnimatedTouchableHighlight>
  );
};
