import * as Clipboard from "expo-clipboard";
import { Platform } from "react-native";

export const copyAsHtml = (text: string) => {
  const html = Platform.OS === "ios" ? `<meta charset="utf-8">${text}` : text;
  return Clipboard.setStringAsync(html, {
    inputFormat: Clipboard.StringFormat.HTML,
  });
};

export const copyAsText = (text: string) => {
  return Clipboard.setStringAsync(text);
};
