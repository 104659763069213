import { Text } from "@/components/Typography";
import { ASK_FRED_PRIVACY } from "@/constants";
import { type FredResponse } from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import type { FC } from "react";
import { useEffect, useMemo, useRef } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { AskFredEmptyState } from "./AskFredEmptyState";
import { AskFredResponseFredMessage } from "./AskFredResponseFredMessage";
import { copyText } from "./utils";

const AskFredQA: FC<{
  response: FredResponse;
  meeting: Meeting;
}> = ({ response, meeting }) => {
  const theme = useTheme();
  return (
    <View style={styles.qa}>
      <Pressable
        style={styles.question(theme)}
        onLongPress={() => {
          copyText(response.question);
        }}
      >
        <Text color="textSecondary" variant="body2Regular">
          {response.question}
        </Text>
      </Pressable>
      <AskFredResponseFredMessage
        answer={response.answer}
        rating={response.rating}
        meeting={meeting}
      />
    </View>
  );
};

export const AskFredMessages: FC<{
  meeting: Meeting;
  meetingFredQa: FredResponse[];
  aiModel: string;
  promptSuggestions?: string[];
}> = ({ meeting, meetingFredQa, aiModel, promptSuggestions }) => {
  const ref = useRef<ScrollView>(null);

  useEffect(() => {
    ref.current?.scrollToEnd();
  }, [meetingFredQa]);

  const userFredQA = useMemo(
    () =>
      meetingFredQa.filter(
        (response) => response.privacy !== ASK_FRED_PRIVACY.FRED,
      ),
    [meetingFredQa],
  );

  return (
    <ScrollView
      ref={ref}
      style={styles.root}
      contentContainerStyle={styles.content}
    >
      {userFredQA.length ? (
        userFredQA.map((response) => (
          <AskFredQA key={response.id} response={response} meeting={meeting} />
        ))
      ) : (
        <AskFredEmptyState
          meeting={meeting}
          aiModel={aiModel}
          promptSuggestions={promptSuggestions}
        />
      )}
    </ScrollView>
  );
};

const styles = createStyles({
  root: {
    flex: 1,
  },
  content: {
    padding: 16,
    gap: 16,
  },
  qa: {
    gap: 12,
    flex: 1,
  },
  question: (theme) => ({
    backgroundColor: theme.colors.layerMuted,
    padding: 12,
    borderRadius: 8,
    maxWidth: "85%",
    marginLeft: "auto",
  }),
});
