import { IconStarPurple } from "@/assets/svg";
import { PAYWALL_VISIBILITY, startUpgradeFlow } from "@/features/billing";
import { createStyles, useTheme } from "@/styles";
import { LinearGradient } from "expo-linear-gradient";
import type { FC, ReactNode } from "react";
import { View } from "react-native";
import { Button } from "../Button";
import { Text } from "../Typography";

export const PaywallContent: FC<{
  title: string;
  description: string;
  header?: ReactNode;
  pwl?: string;
  // TODO: implement
  forAskFred?: boolean;
}> = ({ title, description, header, pwl }) => {
  const theme = useTheme();

  return (
    <LinearGradient
      colors={[theme.colors.layerBrandLight1, theme.colors.layerDefault]}
      start={[0.5, 1]}
      end={[0.5, 0]}
      style={styles.root(theme)}
    >
      {header || <IconStarPurple width={32} height={32} />}
      <View style={styles.details}>
        <Text variant="title1Weight" style={styles.text}>
          {title}
        </Text>
        <Text variant="body2LongRegular" color="textMuted" style={styles.text}>
          {description}
        </Text>
      </View>
      {PAYWALL_VISIBILITY === "show" && (
        <Button onPress={() => startUpgradeFlow({ pwl })}>Upgrade</Button>
      )}
    </LinearGradient>
  );
};

const styles = createStyles({
  root: (theme) => ({
    borderColor: theme.colors.borderStaticSubtle,
    borderWidth: 1,
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 36,
    gap: 24,
    alignItems: "center",
    borderRadius: 8,
  }),
  details: {
    gap: 12,
    width: "100%",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
  },
});
