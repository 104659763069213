import { IconArrowUp, IconX } from "@/assets/svg";
import { useTheme } from "@/styles";
import { useBooleanState } from "@/utils/states";
import type { FC, ReactNode } from "react";
import { forwardRef, useCallback, useEffect, useState } from "react";
import type {
  AccessibilityProps,
  TextInputProps as RNTextInputProps,
  StyleProp,
  TextInputProps,
  TextInputSubmitEditingEventData,
  ViewStyle,
} from "react-native";
import { TextInput as RNTextInput, TouchableOpacity, View } from "react-native";
import type { SvgProps } from "react-native-svg";
import type { IconButtonProps } from "../Button";
import { IconButton } from "../Button";
import { getNumberOfLinesStyle, styles } from "./Input.styles";

export type UsuableRNTexInputProps = Pick<
  RNTextInputProps,
  | "autoFocus"
  | "enterKeyHint"
  | "placeholder"
  | "autoCapitalize"
  | "autoComplete"
  | "autoCorrect"
  | "numberOfLines"
  | "returnKeyType"
  | "textAlignVertical"
>;

export interface InputProps extends UsuableRNTexInputProps {
  value?: string;
  onValueChange?: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  onSubmitEditing?: (e: {
    nativeEvent: TextInputSubmitEditingEventData;
  }) => void;
  onClear?: () => void;
  Icon?: FC<SvgProps>;
  trailing?: ReactNode;
  submitButton?: boolean | IconButtonProps;
  clearable?: boolean;
  style?: StyleProp<ViewStyle>;
  variant?: "outlined" | "filled";
  /**
   * non design system prop
   */
  size?: "sm" | "md";
  onFocus?: TextInputProps["onFocus"];
  onBlur?: TextInputProps["onBlur"];
}

export const TextInput = forwardRef<
  RNTextInput,
  InputProps & AccessibilityProps
>(function TextInput(
  {
    value: propValue,
    onValueChange,
    defaultValue,
    disabled,
    onSubmitEditing,
    onClear,
    Icon,
    trailing,
    clearable,
    style,
    variant = "outlined",
    onFocus,
    onBlur,
    numberOfLines,
    submitButton,
    ...props
  },
  ref,
) {
  const theme = useTheme();
  const [hover, setHoverTrue, setHoverFalse] = useBooleanState();
  const [focused, setFocusedTrue, setFocusedFalse] = useBooleanState();

  // need to track this value in case of
  // uncontrolled component
  const [value, setValue] = useState(propValue || defaultValue || "");
  useEffect(() => {
    if (typeof propValue === "string") setValue(propValue);
  }, [propValue]);

  if (clearable && value) {
    trailing = (
      <TouchableOpacity
        aria-label="Clear input"
        onPress={() => {
          onValueChange?.("");
          onClear?.();
        }}
        style={styles.clear}
        role="button"
      >
        <IconX color={theme.colors.textHint} width={20} height={20} />
      </TouchableOpacity>
    );
  }

  const onButtonSubmit = () => {
    onSubmitEditing?.({ nativeEvent: { text: value } });
  };

  const onChangeText = useCallback(
    (text: string) => {
      setValue(text);
      onValueChange?.(text);
    },
    [onValueChange],
  );

  const showSubmitButton = !!focused || value.trim();

  return (
    <View
      style={[
        styles.root,
        styles[variant](theme),
        hover && styles[`${variant}Hover`](theme),
        focused && styles[`${variant}Focused`](theme),
        style,
      ]}
    >
      {Icon && (
        <View style={styles.leading}>
          <Icon color={theme.colors.textHint} width={20} height={20} />
        </View>
      )}
      <RNTextInput
        ref={ref}
        placeholderTextColor={theme.colors.textHint}
        onPointerEnter={setHoverTrue}
        onPointerLeave={setHoverFalse}
        onFocus={(e) => {
          setFocusedTrue();
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocusedFalse();
          onBlur?.(e);
        }}
        readOnly={disabled}
        selectTextOnFocus={!disabled}
        value={value}
        onChangeText={onChangeText}
        defaultValue={defaultValue}
        onSubmitEditing={onSubmitEditing}
        style={[
          styles.input(theme),
          disabled && styles.inputDisabled(theme),
          getNumberOfLinesStyle(numberOfLines),
        ]}
        multiline={!!numberOfLines && numberOfLines > 1}
        cursorColor={theme.colors.layerContrast}
        {...props}
      />
      <View style={styles.trailing}>
        {trailing}
        {submitButton && showSubmitButton && (
          <IconButton
            Icon={IconArrowUp}
            size="sm"
            {...(typeof submitButton === "object" && submitButton)}
            label={
              typeof submitButton === "object" ? submitButton.label : "Submit"
            }
            disabled={disabled || !value.trim()}
            onPress={onButtonSubmit}
          />
        )}
      </View>
    </View>
  );
});
