import type { TabRouteConfig } from "../types";
import { HomeScreen } from "./HomeScreen";
import { HeaderRight } from "./components/HeaderRight";

const HomeRouteConfig: TabRouteConfig<"Home"> = {
  name: "Home",
  component: HomeScreen,
  options: {
    title: "Home",
    headerRight() {
      return <HeaderRight />;
    },
  },
};

export default HomeRouteConfig;
