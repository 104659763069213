import {
  IconActivity,
  IconDottedCircle,
  IconGrid,
  IconHash,
  IconHelpCircle,
  IconLayers,
  IconLogOut,
  IconSend,
  IconSettings,
  IconStar,
  IconUpload,
  IconUsers,
  IconZap,
} from "@/assets/svg";
import { Avatar } from "@/components/Avatar";
import type { Href } from "@/components/Link";
import { LinkView } from "@/components/Link";
import { Separator } from "@/components/Separator";
import { Tag } from "@/components/Tag";
import { Text } from "@/components/Typography";
import { Config, RouteNames } from "@/constants";
import type { AuthUser } from "@/features/auth";
import { authApi, useAuth } from "@/features/auth";
import { useHidePaidFeatures } from "@/features/billing";
import * as NativeCode from "@/modules/native-code";
import { createStyles, useTheme } from "@/styles";
import { useBooleanState } from "@/utils/states";
import { Tier, getTier } from "@firefliesai/payments-ff.utils";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as Application from "expo-application";
import type { FC } from "react";
import { Platform, Pressable, ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import type { SvgProps } from "react-native-svg";
import type { ParamList } from "../types";
import { CreditStorageBanner } from "./components/CreditStorageBanner";
import { DiagnosticDialog } from "./components/DiagnosticDialog";
import { MobileRecordBanner } from "./components/MobileRecordBanner";

const tierMap = {
  [Tier.FREE]: "Free",
  [Tier.PRO]: "Pro",
  [Tier.BUSINESS]: "Business",
  [Tier.ENTERPRISE]: "Enterprise",
};

const getTierName = (planNickname: string) => {
  const tier = getTier(planNickname);

  return tierMap[tier] || "";
};

const YouBanner: FC<{ user: AuthUser }> = ({ user }) => {
  const hidePaidFeatures = useHidePaidFeatures();

  return (
    <>
      <View style={styles.user}>
        <Avatar
          name={user.name}
          picture={user.photoUrl}
          size={48}
          shape="square"
        />
        <View style={styles.userInfo}>
          <Text variant="title2Weight" color="textPrimary">
            {user.name}
          </Text>
          <Text variant="body2Regular" color="textMuted">
            {user.email}
          </Text>
        </View>
        {!hidePaidFeatures && (
          <Tag>{getTierName(user.paidUser).toUpperCase()}</Tag>
        )}
      </View>
    </>
  );
};

const MenuItem: FC<{
  Icon?: FC<SvgProps>;
  label: string;
  isNew?: boolean;
  onPress?: () => void;
  href?: Href;
  externalUrl?: string;
}> = ({ Icon, label, isNew, href, externalUrl, onPress }) => {
  const theme = useTheme();

  const hidePaidFeatures = useHidePaidFeatures();

  const node = (
    <View style={styles.menuItem}>
      {Icon && (
        <Icon color={theme.colors.textSecondary} width={24} height={24} />
      )}
      <Text color="textSecondary">{label}</Text>
      {isNew && (
        <View style={styles.menuItemNew(theme)}>
          <Text color="commandContrastDefault">New</Text>
        </View>
      )}
    </View>
  );

  if (onPress) {
    return (
      <Pressable onPress={onPress} aria-label={label}>
        {node}
      </Pressable>
    );
  }

  if (!href && externalUrl) {
    return (
      <Pressable onPress={() => NativeCode.openInBrowser(externalUrl)}>
        {node}
      </Pressable>
    );
  }

  if (hidePaidFeatures && externalUrl) {
    return (
      <Pressable onPress={() => NativeCode.openInBrowser(externalUrl)}>
        {node}
      </Pressable>
    );
  }

  return (
    <LinkView href={href as string} animated>
      {node}
    </LinkView>
  );
};

export const YouScreen: FC<NativeStackScreenProps<ParamList, "You">> = () => {
  const { user: currentUser } = useAuth();

  const [isOpenDiagnostic, openDiagnostic, closeDiagnostic] = useBooleanState();

  if (!currentUser) return null;

  return (
    <>
      <ScrollView style={styles.root}>
        <SafeAreaView style={styles.content} edges={["bottom"]}>
          <YouBanner user={currentUser} />
          <CreditStorageBanner />
          <MobileRecordBanner />
          <View style={styles.menu}>
            <MenuItem
              Icon={IconZap}
              label="Meeting Status"
              href={RouteNames.MeetingStatus}
            />
            <MenuItem
              Icon={IconUpload}
              label="Uploads"
              href={RouteNames.Upload}
            />
            <MenuItem
              Icon={IconDottedCircle}
              label="Local Recordings"
              href={{
                pathname: RouteNames.Record,
                params: { action: "local-recordings" },
              }}
            />
            <Separator />
            <MenuItem
              Icon={IconLayers}
              label="Integrations"
              href={RouteNames.Integrations}
            />
            <MenuItem
              Icon={IconGrid}
              label="Apps"
              href={RouteNames.Extensions}
              isNew
            />
            <MenuItem
              Icon={IconHash}
              label="Topic Tracker"
              href={RouteNames.TopicTracker}
              externalUrl={`${Config.DASHBOARD_URL}/topics`}
            />
            <Separator />
            <MenuItem Icon={IconUsers} label="Team" href={RouteNames.Team} />
            <MenuItem
              Icon={IconSettings}
              label="Settings"
              href={RouteNames.Settings}
            />
            <Separator />
            {Platform.OS !== "ios" && (
              <MenuItem
                Icon={IconSend}
                label="Refer & earn credits"
                href={RouteNames.Refer}
                isNew
              />
            )}
            <MenuItem
              Icon={IconStar}
              label="Share feedback"
              externalUrl={`${Config.MOBILE_APP_URL}/to/mobile-app-feedback`}
            />
            <MenuItem
              Icon={IconHelpCircle}
              label="Help center"
              externalUrl="https://guide.fireflies.ai/hc/en-us"
            />
            {Platform.OS !== "web" && (
              <MenuItem
                Icon={IconActivity}
                label="Diagnostic"
                onPress={openDiagnostic}
              />
            )}
            <Separator />
            <MenuItem
              Icon={IconLogOut}
              label="Log out"
              onPress={() => {
                authApi.logout("User action");
              }}
            />
          </View>
          <Text variant="body2Regular" color="textMuted">
            Version: {Application.nativeApplicationVersion} (
            {Application.nativeBuildVersion}) / {Config.APP_ENV}
          </Text>
        </SafeAreaView>
      </ScrollView>
      <DiagnosticDialog isOpen={isOpenDiagnostic} close={closeDiagnostic} />
    </>
  );
};

const styles = createStyles({
  root: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    gap: 16,
  },
  userInfo: {
    flex: 1,
  },
  userTierText: {
    textTransform: "capitalize",
  },
  menu: {
    flexDirection: "column",
    gap: 12,
    paddingBottom: 24,
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingHorizontal: 4,
    paddingVertical: 8,
  },
  user: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    paddingVertical: 16,
    borderRadius: 8,
    flex: 1,
  },
  menuItemNew: (theme) => ({
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 9999,
    backgroundColor: theme.colors.layerBrand,
    marginLeft: 4,
  }),
});
