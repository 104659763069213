import { Platform } from "react-native";

export const PAYWALL_VISIBILITY = Platform.select<
  "show" | "no-action" | "hidden"
>({
  web: "show",
  // android is okay with us showing paywall as long as there is no cta
  android: "no-action",
  ios: "hidden",
  default: "hidden",
});
