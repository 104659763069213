import { IconAlignLeft } from "@/assets/svg";
import { AnimatedTouchableOpacity } from "@/components/Animated";
import { Text } from "@/components/Typography";
import { useAuth } from "@/features/auth";
import { useAskFredMutation } from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import type { FC } from "react";
import { useMemo } from "react";
import { View } from "react-native";
import { FadeInRight } from "react-native-reanimated";
import { AskFredResponseFredMessage } from "./AskFredResponseFredMessage";
import { askFredMutationOptions } from "./shared";

export const AskFredEmptyState: FC<{
  meeting: Meeting;
  aiModel: string;
  promptSuggestions?: string[];
}> = ({ meeting, promptSuggestions, aiModel }) => {
  const theme = useTheme();
  const { user } = useAuth();

  const [askFred, { loading }] = useAskFredMutation({
    ...askFredMutationOptions(meeting),
  });

  const doAskFred = (suggestedPrompt: string) => {
    if (loading) return;
    askFred({
      variables: {
        meetingId: meeting.id,
        query: suggestedPrompt,
        captionIndices: [],
        model: aiModel,
      },
    });
    tracker.track(TRACKING_EVENTS.MEETING_ASK_FRED_SUBMITTED, {
      meetingId: meeting.id,
      queryLength: suggestedPrompt.length,
      isSuggestedPrompt: true,
    });
  };

  const suggestedPrompts = useMemo(
    () =>
      promptSuggestions
        ? Array.from(promptSuggestions)
            // get random 3 suggestions
            .sort(() => Math.random() - Math.random())
            .slice(0, 3)
        : null,
    [promptSuggestions],
  );

  return (
    <View style={styles.qa}>
      <AskFredResponseFredMessage
        answer={`Hi ${user?.name},\nI am an AI Assistant who can help you filter specific information from your meetings.${
          suggestedPrompts?.length
            ? `\n\nYou can ask specific questions related to this meeting. Here are some examples to start:`
            : ""
        }`}
        meeting={meeting}
      />
      {loading && !suggestedPrompts?.length && (
        <AskFredResponseFredMessage answer={null} meeting={meeting} />
      )}
      {suggestedPrompts?.map((suggestedPrompt, index) => (
        <AnimatedTouchableOpacity
          key={suggestedPrompt}
          style={styles.suggestedPrompt(theme)}
          onPress={() => doAskFred(suggestedPrompt)}
          entering={FadeInRight.duration(300).delay(index * 100)}
        >
          <View style={styles.suggestedPromptIcon(theme)}>
            <IconAlignLeft
              color={theme.colors.textHint}
              width={14}
              height={14}
            />
          </View>
          <Text color="textSecondary" style={styles.suggestedPromptText}>
            {suggestedPrompt}
          </Text>
        </AnimatedTouchableOpacity>
      ))}
    </View>
  );
};

const styles = createStyles({
  qa: {
    gap: 12,
    flex: 1,
  },
  suggestedPrompt: (theme) => ({
    padding: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.colors.borderStaticSubtle,
    width: "85%",
    marginLeft: "auto",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 12,
  }),
  suggestedPromptIcon: (theme) => ({
    width: 24,
    height: 24,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme.colors.borderStaticSubtle,
    borderRadius: 4,
  }),
  suggestedPromptText: {
    flex: 1,
  },
});
