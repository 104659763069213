import { Text } from "@/components/Typography";
import { useGetCurrentUserQuery } from "@/graphql";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import type { FC, PropsWithChildren } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import type { ParamList } from "../types";
import { AiCustomizationIndustry } from "./ai-customization/AiCustomizationIndustry";
import { SettingsUpdateOnWeb } from "./components/SettingsUpdateOnWeb";
import { MeetingComplianceParticipantNotification } from "./meeting-compliance/MeetingComplianceParticipantNotification";
import { MeetingSettingsAutoJoin } from "./meeting-settings/MeetingSettingsAutoJoin";
import { MeetingSettingsLanguage } from "./meeting-settings/MeetingSettingsLanguage";
import { MeetingSettingsPrivacy } from "./meeting-settings/MeetingSettingsPrivacy";
import { MeetingSettingsRecap } from "./meeting-settings/MeetingSettingsRecap";
import { MeetingSettingsRules } from "./meeting-settings/MeetingSettingsRules";
import { MeetingSettingsRecordVideo } from "./meeting-settings/MeetingSettingsRecordVideo";
import { Config } from "@/constants";
import { useTeam } from "@/features/team";

const SettingSection: FC<
  PropsWithChildren<{
    title: string;
  }>
> = ({ title, children }) => {
  return (
    <View style={styles.section}>
      <Text variant="heading5Weight" color="textBrand">
        {title}
      </Text>
      <View style={styles.sectionContent}>{children}</View>
    </View>
  );
};

export const SettingsScreen: FC<
  NativeStackScreenProps<ParamList, "Settings">
> = () => {
  const { data: dataUser } = useGetCurrentUserQuery();
  const { isAdmin } = useTeam();

  const user = dataUser?.user;

  if (!user) {
    return null;
  }

  return (
    <View style={styles.root}>
      <ScrollView contentContainerStyle={styles.content}>
        <SettingSection title="Meeting Settings">
          <MeetingSettingsLanguage user={user} />
          <MeetingSettingsAutoJoin user={user} />
          <MeetingSettingsRecap user={user} />
          <MeetingSettingsPrivacy user={user} />
          <MeetingSettingsRecordVideo user={user} />
          <MeetingSettingsRules user={user} />
        </SettingSection>
        <SettingSection title="Meeting Compliance">
          <MeetingComplianceParticipantNotification user={user} />
        </SettingSection>
        <SettingSection title="AI Customization">
          <AiCustomizationIndustry user={user} />
        </SettingSection>
        <SettingSection title="Account Settings">
          <SettingsUpdateOnWeb
            label="account settings"
            url={`${Config.DASHBOARD_URL}/settings`}
          />
        </SettingSection>
        {isAdmin && (
          <SettingSection title="Team Settings">
            <SettingsUpdateOnWeb
              label="team settings"
              url={`${Config.DASHBOARD_URL}/team/settings`}
            />
          </SettingSection>
        )}
        {/* <SettingSection title="Phone Number">
          <PhoneCallSettings />
        </SettingSection> */}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  content: {
    padding: 24,
    paddingBottom: 64,
    gap: 64,
  },
  section: {
    flexDirection: "column",
    gap: 24,
  },
  sectionContent: {
    flexDirection: "column",
    gap: 36,
  },
});
