import { useTheme } from "@/styles";
import type { FC, PropsWithChildren } from "react";
import type { AccessibilityProps } from "react-native";
import { Pressable } from "react-native";
import { Text } from "../Typography";
import { getStyles } from "./styles";
import type { ButtonProps } from "./types";

export const Button: FC<PropsWithChildren<ButtonProps & AccessibilityProps>> = (
  props,
) => {
  const { children, onPress, disabled, Icon, ...restProps } = props;

  const isStringChildren = Array.isArray(children)
    ? !children.some((child) => typeof child === "object")
    : typeof children !== "object";

  const theme = useTheme();

  const { pressableStyle, textColor, textVariant, iconOnly, iconSize } =
    getStyles(theme, props);

  return (
    <Pressable
      {...restProps}
      style={pressableStyle}
      onPress={onPress}
      disabled={disabled}
      role="button"
    >
      {Icon && (
        <Icon
          width={iconSize}
          height={iconSize}
          color={theme.colors[textColor]}
          strokeWidth={1.5}
        />
      )}
      {isStringChildren && !iconOnly ? (
        <Text color={textColor} variant={textVariant}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Pressable>
  );
};
