import { Config } from "@/constants";
import { openInBrowser } from "@/modules/native-code";

export const startUpgradeFlow = ({
  pwl,
}: {
  pwl?: string;
} = {}) => {
  openInBrowser(`${Config.DASHBOARD_URL}/upgrade${pwl ? `?pwl=${pwl}` : ""}`);
};
