import { SharePrivacyField } from "@/components/Share";
import { toast } from "@/components/Toast";
import type { Meeting, MeetingNotesPrivacy, Source } from "@/graphql";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import { useMutation } from "@apollo/client";
import { UpdateMeetingDocument } from "@firefliesai/mobile-ff.graphql-client";
import type { FC } from "react";
import { MEETING_PRIVACY_OPTIONS } from "../constants/privacy";
import { useCanManageMeeting } from "../utils/permissions";

export const ShareMeetingDialogPrivacy: FC<{
  meeting: Meeting;
}> = ({ meeting }) => {
  const [updateMeeting, { loading }] = useMutation(UpdateMeetingDocument, {
    onError(err) {
      toast({
        title: "Could not update meeting",
        message: err.message,
        type: "error",
      });
    },
  });

  const canChange = useCanManageMeeting(meeting);

  return (
    <SharePrivacyField
      value={meeting.notesPrivacy || (meeting as Source).privacy}
      onChange={(value) => {
        updateMeeting({
          variables: {
            id: meeting.id,
            notesPrivacy: value as MeetingNotesPrivacy,
          },
        });
        tracker.track(TRACKING_EVENTS.MEETING_PRIVACY_UPDATED, {
          id: meeting.id,
          previousPrivacy: meeting.notesPrivacy,
          privacy: value,
          from: "meeting-share-dialog",
        });
      }}
      loading={loading}
      canChange={canChange}
      options={MEETING_PRIVACY_OPTIONS}
    />
  );
};
