export default {
  setActionHandler(
    action: MediaSessionAction,
    handler: MediaSessionActionHandler | null,
  ) {
    if (!("mediaSession" in navigator)) return;
    navigator.mediaSession.setActionHandler(action, handler);
  },

  setMetadata(metadata: MediaMetadataInit | null) {
    if (!("mediaSession" in navigator)) return;
    if (!metadata) {
      navigator.mediaSession.playbackState = "none";
      navigator.mediaSession.metadata = null;
      return;
    }
    navigator.mediaSession.metadata = new MediaMetadata(metadata);
  },

  setPositionState(state: MediaPositionState) {
    if (!("mediaSession" in navigator)) return;
    navigator.mediaSession.setPositionState(state);
  },

  setPlaybackState(state: MediaSessionPlaybackState) {
    if (!("mediaSession" in navigator)) return;
    navigator.mediaSession.playbackState = state;
  },

  setNotificationChannel() {
    // android only
  },
};
