import { IconLock } from "@/assets/svg";
import { ErrorScreen, NotFoundScreen } from "@/components/Error";
import { isForbiddenError, shouldRetryError } from "@/errors";
import { openLoginPopup, useAuth } from "@/features/auth";
import { useQuery } from "@apollo/client";
import { GetMeetingDocument } from "@firefliesai/mobile-ff.graphql-client";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { type FC } from "react";
import type { ParamList } from "../types";
import { LiveScreenProvider } from "./Context/LiveScreenContext";
import { LiveScreenContent } from "./LiveScreenContent";
import { NonLiveMeetingView } from "./components/NonLiveMeetingView";
import { isLiveMeeting } from "./constants";

export const LiveScreen: FC<NativeStackScreenProps<ParamList, "Live">> = ({
  route,
}) => {
  const meetingId = route.params.id;

  const { user } = useAuth();

  const { data, error, loading, refetch } = useQuery(GetMeetingDocument, {
    variables: {
      id: meetingId,
    },
    notifyOnNetworkStatusChange: true,
    // poll every 30s to check if the
    // meeting has been completed
    pollInterval: 30 * 1000,
  });

  if (!data?.meeting && loading) {
    return <LiveScreenContent meeting={null} />;
  }

  if (!data?.meeting && error) {
    if (isForbiddenError(error?.graphQLErrors?.[0] || error)) {
      if (!user) {
        return (
          <ErrorScreen
            login={() => openLoginPopup("live")}
            Icon={IconLock}
            title="This meeting is private"
            description="Login to check your meeting access"
          />
        );
      }

      return (
        <ErrorScreen
          Icon={IconLock}
          title="You do not have permission to view this meeting"
          description="Request meeting host for access"
        />
      );
    }
    return (
      <ErrorScreen
        description={error.message}
        retry={shouldRetryError(error) ? refetch : undefined}
      />
    );
  }

  if (!data?.meeting) {
    return (
      <NotFoundScreen
        title="Meeting not found"
        description="It does not exist or you do not have access"
      />
    );
  }

  if (!isLiveMeeting(data.meeting)) {
    return <NonLiveMeetingView meeting={data.meeting} />;
  }

  return (
    <LiveScreenProvider meeting={data.meeting}>
      <LiveScreenContent meeting={data.meeting} />
    </LiveScreenProvider>
  );
};
