import { IconCircle } from "@/assets/svg";
import { usePulseAnimatedStyle } from "@/components/Animated";
import { SkeletonLoading } from "@/components/Loading";
import { Text } from "@/components/Typography";
import { createStyles, useTheme } from "@/styles";
import { toHHMMSS } from "@/utils/time";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import { useEffect, useState, type FC } from "react";
import { View } from "react-native";
import Animated from "react-native-reanimated";
import { isLiveMeeting } from "../constants";
import { getElapsedDuration } from "../utils";

export const MeetingInfoRow: FC<{
  meeting: Meeting;
}> = ({ meeting }) => {
  const theme = useTheme();

  const meetingStartTime = meeting.fredJoinedMeetingAt || meeting.startTime;

  const [duration, setDuration] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const elapsed = getElapsedDuration(meetingStartTime);
      if (elapsed >= 0) setDuration(elapsed);
    }, 1000);
    return () => clearInterval(interval);
  }, [meetingStartTime]);

  const pulseAnimStyle = usePulseAnimatedStyle();

  return (
    <View style={styles.root}>
      <View style={styles.title}>
        <Text variant="title3Weight" numberOfLines={1} style={styles.titleText}>
          {meeting.title}
        </Text>
      </View>
      {isLiveMeeting(meeting) && (
        <View style={styles.info}>
          <Text variant="body3Regular" color="textHint">
            {toHHMMSS(duration)}
          </Text>
          <View style={styles.rec(theme)}>
            <Animated.View style={pulseAnimStyle}>
              <IconCircle
                width={6}
                height={6}
                color={theme.colors.informationStaticRedText}
                fill={theme.colors.informationStaticRedText}
              />
            </Animated.View>
            <Text variant="body3Regular" color="informationStaticRedText">
              REC
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export const LoadingMeetingInfoRow: FC = () => {
  return (
    <View style={styles.root}>
      <View style={styles.title}>
        <SkeletonLoading width={248} height={18} />
      </View>
      <View style={styles.info}>
        <SkeletonLoading width={40} height={18} />
        <SkeletonLoading width={45} height={20} />
      </View>
    </View>
  );
};

const styles = createStyles({
  root: {
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 4,
  },
  rec: (theme) => ({
    backgroundColor: theme.colors.informationStaticRedSubtle,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 2,
    paddingHorizontal: 6,
    borderRadius: 4,
    gap: 4,
  }),
  title: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  titleText: {
    flex: 1,
  },
  info: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
});
