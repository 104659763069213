import { LinkText, Text } from "@/components/Typography";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";

export const Footer: FC = () => {
  return (
    <View style={styles.footer}>
      <Text
        style={styles.termNotice}
        color="textSecondary"
        fontSize={12}
        allowFontScaling={false}
      >
        By signing up, I agree to Fireflies&lsquo;{" "}
        <LinkText href="https://fireflies.ai/terms-of-service.pdf">
          Terms of Service
        </LinkText>
        ,{" "}
        <LinkText href="https://fireflies.ai/privacy">Privacy Policy</LinkText>{" "}
        and{" "}
        <LinkText href="https://fireflies.ai/data_processing_terms.pdf">
          Data Processing Terms
        </LinkText>
        .
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    marginTop: "auto",
    gap: 20,
    alignItems: "center",
  },
  securedText: {
    color: "#054F31",
    fontSize: 10,
  },
  termNotice: {
    textAlign: "center",
    opacity: 0.8,
  },
});
