import { type FC } from "react";
import { LiveRecordFloatingCard } from "./LiveRecordFloatingCard";
import { LiveRecordInterruptListener } from "./LiveRecordInterruptListener";

export const LiveRecordController: FC = () => {
  return (
    <>
      <LiveRecordInterruptListener />
      <LiveRecordFloatingCard />
    </>
  );
};
