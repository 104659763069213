import { IconChevronDown, IconChevronUp } from "@/assets/svg";
import { useTheme } from "@/styles";
import { useBooleanState } from "@/utils/states";
import type { ReactNode } from "react";
import { useId, useMemo, type FC } from "react";
import type { AccessibilityProps, StyleProp, ViewStyle } from "react-native";
import { Pressable, StyleSheet, View } from "react-native";
import type { SvgProps } from "react-native-svg";
import type { TextProps } from "../Typography";
import { Text } from "../Typography";
import { styles } from "./Input.styles";
import { SelectPopover } from "./SelectPopover";
import type { InputProps } from "./TextInput";

export interface CoreSelectProps<TValue> {
  options: SelectOption<TValue>[];
  value: TValue;
  onValueChange: (value: TValue, option: SelectOption<TValue>) => void;
  showSearch?: boolean;
  freeSolo?: boolean;
}

export interface SelectProps<TValue> extends CoreSelectProps<TValue> {
  Icon?: FC<SvgProps>;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  size?: InputProps["size"];
  renderCurrentOption?: (option: SelectOption<TValue>) => React.ReactNode;
  textProps?: TextProps;
  inline?: boolean;
}

export interface SelectOption<TValue> {
  Icon?: FC<SvgProps>;
  left?: ReactNode;
  label: string | ReactNode;
  value: TValue;
  meta?: any;
  alt?: string | string[];
  sticky?: boolean;
}

export function Select<TValue>({
  disabled,
  Icon,
  style,
  value,
  onValueChange,
  options,
  size = "md",
  renderCurrentOption,
  textProps,
  inline,
  showSearch,
  freeSolo,
  ...props
}: SelectProps<TValue> & AccessibilityProps) {
  const theme = useTheme();

  const [isOpen, open, close] = useBooleanState();

  const currentValueNode = useMemo(() => {
    const currentOption = options.find((option) => option.value === value);
    if (!currentOption) return String(value);
    return renderCurrentOption?.(currentOption) || currentOption.label;
  }, [options, renderCurrentOption, value]);

  const id = useId();

  return (
    <>
      <Pressable
        style={({ pressed }) => [
          styles.root,
          styles.filled(theme),
          styles[size],
          selectStyles.root,
          pressed && styles.filledHover(theme),
          disabled && selectStyles.disableOpacity,
          inline && selectStyles.inline,
          style,
        ]}
        onPress={open}
        disabled={disabled}
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls={id}
        {...props}
      >
        {Icon && (
          <Icon width={14} height={14} color={theme.colors.textSecondary} />
        )}
        <Text
          color="textSecondary"
          numberOfLines={1}
          {...textProps}
          style={[!inline && selectStyles.fillText, textProps?.style]}
        >
          {currentValueNode}
        </Text>
        {!disabled &&
          (isOpen ? (
            <IconChevronUp color={theme.colors.textSecondary} />
          ) : (
            <IconChevronDown color={theme.colors.textSecondary} />
          ))}
      </Pressable>
      {/* Why do we put this <View> here? It is because without it, when the modal is opened, it somehow
       * shift the layout of the Pressable above. But wait! <Modal> is not supposed affect the layout because
       * it is rendered on top of everything else using OS-native view right?
       * Well, no clue, it somehow does lol so this kinda fixes it.
       */}
      <View>
        <SelectPopover
          isOpen={isOpen}
          close={close}
          onValueChange={onValueChange}
          value={value}
          options={options}
          id={id}
          showSearch={showSearch}
          freeSolo={freeSolo}
        />
      </View>
    </>
  );
}

const selectStyles = StyleSheet.create({
  root: {
    gap: 8,
    paddingHorizontal: 8,
  },
  fillText: {
    flex: 1,
  },
  disableOpacity: {
    opacity: 0.5,
  },
  inline: {
    paddingHorizontal: 0,
    backgroundColor: "transparent",
  },
});
