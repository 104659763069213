import { toast } from "@/components/Toast";
import {
  ExtensionType,
  MeetingSummaryStatus,
  useRunManuallyMutation,
} from "@/graphql";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import { useCallback } from "react";

export const useReprocessSummary = (meeting: Meeting) => {
  const [runManually, { loading, data }] = useRunManuallyMutation({
    update(cache, { data }) {
      if (data?.runManually) {
        cache.modify<Meeting>({
          id: cache.identify(meeting),
          fields: {
            summaryStatus() {
              return MeetingSummaryStatus.Processing;
            },
          },
        });
      }
    },
    onCompleted(data) {
      if (data.runManually) {
        toast({
          message: "Summary is being reprocessed. Please check back later",
        });
      } else {
        toast({
          message: "Something went wrong. Please try again later",
        });
      }
    },
    onError(err) {
      toast({
        title: "Could not reprocess meeting summary",
        type: "error",
        message: err.message,
      });
    },
    variables: {
      meetingId: meeting.id,
      extensionType: ExtensionType.SingleSummary,
    },
  });

  const doRunManually = useCallback(() => {
    runManually();
  }, [runManually]);

  return [
    doRunManually,
    {
      loading,
      data,
    },
  ] as const;
};
