import { createStyles, useTheme, type Theme } from "@/styles";
import type { FC } from "react";
import type {
  AccessibilityProps,
  PressableStateCallbackType,
  StyleProp,
  ViewStyle,
} from "react-native";
import { Pressable, StyleSheet } from "react-native";
import type { SvgProps } from "react-native-svg";

type IconButtonVariant =
  | "primary"
  | "primaryTransparent"
  | "neutral"
  | "outlined";

type IconButtonSize = "xs" | "sm" | "md" | "lg";

export interface IconButtonProps {
  variant?: IconButtonVariant;
  size?: IconButtonSize;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  label: string;
  Icon: FC<SvgProps>;
}

// TODO: implement Label tooltip

export const IconButton: FC<IconButtonProps & AccessibilityProps> = (props) => {
  const theme = useTheme();

  const { label, Icon, size, style, disabled, variant, onPress, ...restProps } =
    props;

  const { pressableStyle, iconColor, iconSize } = getStyles(theme, {
    size,
    variant,
    style,
    disabled,
    Icon,
    label,
  });

  return (
    <Pressable
      {...restProps}
      onPress={onPress}
      disabled={disabled}
      style={pressableStyle}
      aria-label={restProps["aria-label"] ?? label}
      role="button"
    >
      <Icon width={iconSize} height={iconSize} color={iconColor} />
    </Pressable>
  );
};

const getStyles = (
  theme: Theme,
  { size = "md", disabled, style, variant = "primary" }: IconButtonProps,
) => {
  const iconColor =
    theme.colors[iconColorMap[`${variant}${disabled ? "Disabled" : ""}`]];

  return {
    pressableStyle: ({ pressed }: PressableStateCallbackType) => [
      baseStyles.root,
      baseStyles[size],
      variantStyles[variant](theme),
      pressed && variantStyles[`${variant}Hover`](theme),
      disabled && variantStyles[`${variant}Disabled`](theme),
      style,
    ],
    iconColor,
    iconSize: iconSizeMap[size],
  };
};

const iconColorMap = {
  primary: "commandContrastDefault",
  primaryDisabled: "commandContrastDisabled",
  primaryTransparent: "commandPrimaryDefault",
  primaryTransparentDisabled: "commandPrimaryDisabled",
  neutral: "commandNeutralDefault",
  neutralDisabled: "commandNeutralDisabled",
  outlined: "commandNeutralDefault",
  outlinedDisabled: "commandNeutralDisabled",
} satisfies Record<string, keyof Theme["colors"]>;

const iconSizeMap = {
  xs: 16,
  sm: 20,
  md: 20,
  lg: 24,
};

const baseStyles = StyleSheet.create({
  root: {
    display: "flex",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  xs: {
    width: 24,
    height: 24,
  },
  sm: {
    width: 32,
    height: 32,
  },
  md: {
    width: 36,
    height: 36,
  },
  lg: {
    width: 40,
    height: 40,
  },
});

const variantStyles = createStyles({
  primary: (theme) => ({
    backgroundColor: theme.colors.interactivePrimaryDefault,
  }),
  primaryHover: (theme) => ({
    backgroundColor: theme.colors.interactivePrimaryHover,
  }),
  primaryDisabled: (theme) => ({
    backgroundColor: theme.colors.interactivePrimaryDisabled,
  }),
  primaryTransparent: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutralAlphaDefault,
  }),
  primaryTransparentHover: (theme) => ({
    backgroundColor: theme.colors.interactiveSecondaryHover,
  }),
  primaryTransparentDisabled: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutralAlphaDefault,
  }),
  neutral: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutralAlphaDefault,
  }),
  neutralHover: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutralAlphaHover,
  }),
  neutralDisabled: (theme) => ({
    backgroundColor: theme.colors.interactiveNeutralAlphaDefault,
  }),
  outlined: (theme) => ({
    borderWidth: 1,
    borderColor: theme.colors.borderInteractiveDefault,
    backgroundColor: theme.colors.interactiveNeutral1Default,
  }),
  outlinedHover: (theme) => ({
    borderColor: theme.colors.borderInteractiveHover,
    backgroundColor: theme.colors.interactiveNeutral1Hover,
  }),
  outlinedDisabled: (theme) => ({
    borderColor: theme.colors.borderInteractiveDisabled,
    backgroundColor: theme.colors.interactiveNeutral1Disabled,
  }),
});
