import { TextInput } from "@/components/Input";
import { useBilling } from "@/features/billing";
import { useAskFredMutation } from "@/graphql";
import { createStyles, useTheme } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import type { FC } from "react";
import { useCallback, useState } from "react";
import { View } from "react-native";
import { askFredMutationOptions } from "./shared";

export const AskFredInput: FC<{ meeting: Meeting; aiModel: string }> = ({
  meeting,
  aiModel,
}) => {
  const theme = useTheme();

  const [value, setValue] = useState("");

  const [askFred, { loading }] = useAskFredMutation({
    onCompleted() {
      setValue("");
    },
    ...askFredMutationOptions(meeting),
  });

  const onSubmit = useCallback(() => {
    if (loading) return;
    const query = value.trim();
    if (!query) return;
    askFred({
      variables: {
        meetingId: meeting.id,
        query,
        captionIndices: [],
        model: aiModel,
      },
    });
    tracker.track(TRACKING_EVENTS.MEETING_ASK_FRED_SUBMITTED, {
      meetingId: meeting.id,
      queryLength: query.length,
    });
  }, [askFred, loading, meeting, value, aiModel]);

  const { isAllowedToUsePrompts } = useBilling();

  const disabled = loading || !isAllowedToUsePrompts;

  return (
    <View style={styles.root}>
      <TextInput
        style={styles.input(theme)}
        submitButton
        placeholder="Ask anything, Ask Fred "
        returnKeyType="search"
        autoFocus={isAllowedToUsePrompts}
        value={value}
        onValueChange={setValue}
        onSubmitEditing={onSubmit}
        disabled={disabled}
        numberOfLines={3}
        textAlignVertical="top"
      />
    </View>
  );
};

const styles = createStyles({
  root: {
    padding: 12,
  },
  input: (theme) => ({
    backgroundColor: theme.colors.layerMuted,
    borderWidth: 0,
    borderRadius: 12,
  }),
  send: (theme) => ({
    backgroundColor: theme.colors.commandHintDefault,
    borderRadius: 9999,
    padding: 3,
  }),
});
