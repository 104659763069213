import { Audio } from "expo-av";

export const permissionDeniedCanAskAgainError = new Error(
  "Allow access to microphone to continue",
);

export const permissionDeniedError = new Error(
  "Open settings and allow access to microphone to continue",
);

export async function requestMicrophonePermissionOrFail() {
  const { granted, canAskAgain } = await Audio.requestPermissionsAsync();
  if (!granted) {
    if (canAskAgain) {
      throw permissionDeniedCanAskAgainError;
    }
    throw permissionDeniedError;
  }
  return true;
}
