import { IconCalendar, IconMic, IconUpload, IconVideo } from "@/assets/svg";
import { BottomSheetMenuModal } from "@/components/BottomSheet";
import { StaticTag } from "@/components/Tag";
import { RouteNames } from "@/constants";
import {
  useBilling,
  useHidePaidFeatures,
  useShouldShowUnlimitedRecordingCampaign,
} from "@/features/billing";
import { RootNavigation } from "@/screens/RootNavigation";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import { useBooleanState } from "@/utils/states";
import { useMemo, type FC } from "react";
import { globalModalsApi } from "../global-modals.store";
import { openStoragePaywallModal } from "../storage-paywall-modal/StoragePaywallModal";
import { ScheduleMeetingModal } from "./components/ScheduleMeetingModal";
import { TranscribeLiveMeetingModal } from "./components/TranscribeLiveMeetingModal";
import { pickDocument } from "./components/pickDocument";

export const ADD_MODAL_NAME = "add-modal";

export const AddModal: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const [isOpenSchedule, openSchedule, closeSchedule] = useBooleanState();

  const [isOpenTranscribeLive, openTranscribeLive, closeTranscribeLive] =
    useBooleanState();

  const shouldShowUnlimitedTag = useShouldShowUnlimitedRecordingCampaign();

  return (
    <>
      <BottomSheetMenuModal
        isOpen={isOpen}
        close={close}
        items={[
          {
            Icon: IconMic,
            label: "Record audio",
            right: shouldShowUnlimitedTag ? (
              <StaticTag color="purple">Unlimited</StaticTag>
            ) : null,
            onPress: () => {
              RootNavigation.navigate(RouteNames.Record);
            },
          },
          {
            Icon: IconVideo,
            label: "Add to live meeting",
            onPress: () => {
              openTranscribeLive();
              tracker.track(TRACKING_EVENTS.ADD_TO_LIVE_MEETING_MODAL_OPENED, {
                from: "home/add-button",
              });
            },
          },
          {
            Icon: IconCalendar,
            label: "Schedule meeting",
            onPress: () => {
              openSchedule();
              tracker.track(TRACKING_EVENTS.SCHEDULE_MEETING_MODAL_OPENED, {
                from: "home/add-button",
              });
            },
          },
          {
            Icon: IconUpload,
            label: "Upload Audio or Video",
            onPress: () => {
              pickDocument();
              tracker.track(TRACKING_EVENTS.DOCUMENT_PICKER_OPENED, {
                from: "home/add-button",
              });
            },
          },
        ]}
      />
      <ScheduleMeetingModal isOpen={isOpenSchedule} close={closeSchedule} />
      <TranscribeLiveMeetingModal
        isOpen={isOpenTranscribeLive}
        close={closeTranscribeLive}
      />
    </>
  );
};

export const useOpenAddModal = () => {
  const hidePaidFeatures = useHidePaidFeatures();

  const { reachedTeamMinutesConsumedLimit } = useBilling();

  return useMemo(() => {
    if (reachedTeamMinutesConsumedLimit && hidePaidFeatures) {
      // on iOS, we are not allowed to show any in-app purchase UI
      return undefined;
    }

    return (fromRef: string) => {
      if (reachedTeamMinutesConsumedLimit) {
        openStoragePaywallModal(fromRef);
        return;
      }

      globalModalsApi.openModal(ADD_MODAL_NAME);
      tracker.track(TRACKING_EVENTS.TRANSCRIBE_MENU_OPENED, {
        from: fromRef,
      });
    };
  }, [hidePaidFeatures, reachedTeamMinutesConsumedLimit]);
};
