export const isAuthExpiredError = (error: any) =>
  error.extensions?.code === "TokenExpiredError" ||
  error.message?.toLowerCase().includes("token expired");

export const isUnauthenticatedError = (error: any) =>
  error.extensions?.code === "UnauthenticatedError" ||
  error.message?.toLowerCase().includes("authentication") ||
  error.message?.toLowerCase().includes("must be logged in") ||
  error.message?.toLowerCase().includes("unauthorized") ||
  error.message?.toLowerCase().includes("unauthenticated") ||
  error.message?.toLowerCase().includes("invalid authorization") ||
  error.extensions?.response?.status === 401 ||
  error.message?.includes("401");

export const isForbiddenError = (error: any) =>
  error.extensions?.code === "ForbiddenError" ||
  error.message?.toLowerCase().includes("forbidden") ||
  error.extensions?.response?.status === 403 ||
  error.message?.includes("403") ||
  error.message?.toLowerCase().includes("don't have permission");

export const isNotFoundError = (error: any) =>
  error.extensions?.code === "NotFoundError" ||
  error.message?.toLowerCase().includes("not found") ||
  error.extensions?.response?.status === 404 ||
  error.message?.includes("404");

export const shouldRetryError = (error: any) =>
  !isNotFoundError(error) &&
  !isForbiddenError(error) &&
  !isUnauthenticatedError(error);
