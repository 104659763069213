import { EventEmitter, NativeModulesProxy } from "expo-modules-core";

// Import the native module. On web, it will be resolved to ExpoAudioRecord.web.ts
// and on native platforms to ExpoAudioRecord.ts
import AudioRecordModule from "./AudioRecordModule";
import type {
  ChangeEventMap,
  RecordingContext,
  RecordingOptions,
  RecordingStatus,
} from "./types";

const emitter = new EventEmitter(
  AudioRecordModule ?? NativeModulesProxy.AudioRecord,
);

if (typeof window !== "undefined") {
  AudioRecordModule.emitter = emitter;
}

export function addEventListener<T extends keyof ChangeEventMap>(
  type: T,
  listener: (event: ChangeEventMap[T]) => void,
): {
  remove: () => void;
} {
  return emitter.addListener<ChangeEventMap[T]>(type, listener);
}

export async function startRecording(options: RecordingOptions): Promise<void> {
  return await AudioRecordModule.startRecording(options);
}

export async function stopRecording(): Promise<{
  uri: string;
}> {
  return await AudioRecordModule.stopRecording();
}

export async function pauseRecording(): Promise<void> {
  return await AudioRecordModule.pauseRecording();
}

export async function resumeRecording(): Promise<void> {
  return await AudioRecordModule.resumeRecording();
}

export function startUpload(uploadUrl: string): void {
  return AudioRecordModule.startUpload(uploadUrl);
}

export function getStatus(): RecordingStatus {
  return AudioRecordModule.getStatus();
}

export function getContext(): RecordingContext {
  return AudioRecordModule.getContext();
}

export function setContext(context: RecordingContext) {
  AudioRecordModule.setContext(context);
}

export function setStreamingError(error: string) {
  AudioRecordModule.setStreamingError(error);
}

export function release(): void {
  return AudioRecordModule.release();
}

export type {
  ChangeEventMap,
  DataChangeEvent,
  ErrorEvent,
  RecordingContext,
  RecordingOptions,
  RecordingStatus,
} from "./types";

export const AudioRecordErrorCode = {
  ErrNoPermission: AudioRecordModule.ERR_NO_PERMISSION as string,
  ErrNoInProgressRecording:
    AudioRecordModule.ERR_NO_IN_PROGRESS_RECORDING as string,
  ErrInProgressRecording: AudioRecordModule.ERR_IN_PROGRESS_RECORDING as string,
  ErrAudioInputUnavailable:
    AudioRecordModule.ERR_AUDIO_INPUT_UNAVAILABLE as string,
  ErrInternal: AudioRecordModule.ERR_INTERNAL as string,
};
