import { toast } from "@/components/Toast";
import { Config, USE_COOKIE_AUTH } from "@/constants";
import {
  captureException,
  isAuthExpiredError,
  isUnauthenticatedError,
} from "@/errors";
import { authApi, createAuthHeaders, useAuth } from "@/features/auth";
import { Logger } from "@/logger";
import { getRequestMetadataHeaders } from "@/utils/request";
import type { Context, InMemoryCacheConfig } from "@apollo/client";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { useApolloClientDevTools } from "@dev-plugins/apollo-client";
import { cacheConfig as bitesCacheConfig } from "@firefliesai/bites-ff.graphql-client";
import { apolloCacheConfig as mobileCacheConfig } from "@firefliesai/mobile-ff.graphql-client";
import deepmerge from "deepmerge";
import * as Application from "expo-application";
import { print } from "graphql";
import type { FC, PropsWithChildren } from "react";
import { mergeDashboardMeetingResults } from "./pagination.utils";

if (__DEV__) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const transactionIdLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getRequestMetadataHeaders(),
    },
  };
});

const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const authTokens = await authApi.getOrRefreshTokens();
  return {
    headers: {
      ...headers,
      ...(authTokens ? createAuthHeaders(authTokens) : {}),
    },
  };
});

const logger = new Logger("apollo");

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    logger.error("GraphQL error", {
      graphQLErrors,
      networkError,
      operation,
      response,
    });
    if (networkError) {
      // auth error sometimes get reported as network error
      if (
        isAuthExpiredError(networkError) ||
        isUnauthenticatedError(networkError)
      ) {
        return;
      }
      toast({
        title: "Could not connect to server",
        message: networkError.message,
        type: "error",
      });
    }
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (isUnauthenticatedError(err)) {
          // ignore
          return;
        }

        captureException(
          new Error(
            `GraphQL error for ${operation.operationName}: ${err.message}`,
            {
              cause: err,
            },
          ),
          {
            contexts: {
              operation: {
                query: print(operation.query),
                variables: operation.variables,
                extensions: operation.extensions,
              },
              graphQLError: err as Context,
              graphQLResponse: response as Context,
            },
            tags: {
              graphql: true,
              operationName: operation.operationName,
              transaction_id: operation.getContext().headers["x-request-id"],
            },
            fingerprint: ["{{ default }}", operation.operationName],
          },
        );
      }
    }
  },
);

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) =>
      !isUnauthenticatedError(error) && !isAuthExpiredError(error),
  },
});

const httpLink = new HttpLink({
  uri: Config.GRAPHQL_URL,
  ...(USE_COOKIE_AUTH && { credentials: "include" }),
});

const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Profile: {
      keyFields: ["email"],
    },
    MeetingCaption: {
      keyFields: false,
    },
    MeetingStatusData: {
      keyFields: ["objectId"],
    },
    FeedMeeting: {
      fields: {
        startTime: {
          read(date) {
            return date ? new Date(date) : null;
          },
        },
        endTime: {
          read(date) {
            return date ? new Date(date) : null;
          },
        },
      },
    },
    Query: {
      fields: {
        getChannelMeetings: {
          keyArgs: ["channelId", "search", "isPrivate"],
          merge: mergeDashboardMeetingResults("from"),
        },
        globalSearch: {
          keyArgs: ["channelId", "search", "keywords", "filters"],
          merge: mergeDashboardMeetingResults("from"),
        },
        getChannel: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: "Channel",
              _id: args?.id,
            });
          },
        },
        getUserMeetingsForStatus: {
          keyArgs: ["start", "end"],
          merge: mergeDashboardMeetingResults((options) => {
            return (options.args?.batch || 0) * (options.args?.limit || 10);
          }),
        },
        soundbite: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: "Soundbite",
              id: args?.id,
            });
          },
        },
        playlist: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: "Playlist",
              id: args?.id,
            });
          },
        },
        meetingNoteAnalytics: {
          keyArgs: ["parseId"],
        },
        getFeedMeetings: {
          keyArgs: false,
          merge: mergeDashboardMeetingResults("skip"),
        },
        getLiveMeetingInsights: {
          keyArgs: ["meetingId"],
        },
        meetingNoteComments: {
          keyArgs: ["meetingId"],
        },
        getLiveMeetingMarks: {
          keyArgs: ["meetingId"],
        },
      },
    },
  },
};

const allCacheConfig: InMemoryCacheConfig[] = [
  cacheConfig,
  bitesCacheConfig,
  mobileCacheConfig,
];

export const client = new ApolloClient({
  cache: new InMemoryCache(
    allCacheConfig.reduce(
      (acc, config) => deepmerge(acc, config),
      {} as InMemoryCacheConfig,
    ),
  ),
  connectToDevTools: __DEV__,
  link: ApolloLink.from([
    transactionIdLink,
    authLink,
    errorLink,
    retryLink,
    httpLink,
  ]),
  name: Application.applicationId || Config.NAME,
  version: Application.nativeApplicationVersion || undefined,
  defaultOptions: {
    query: {
      // without this, an error might cause loading state to stay true forever
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

useAuth.subscribe((state, prevState) => {
  if (prevState.user?.id !== state.user?.id) {
    logger.info("auth user id changes. refetch observable queries");
    client.reFetchObservableQueries(true);
  }
});

export const GraphQLProvider: FC<PropsWithChildren> = ({ children }) => {
  useApolloClientDevTools(client);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
