import {
  useNavigation,
  useRoute,
  type RouteProp,
} from "@react-navigation/native";
import { useCallback } from "react";

type ParamList = {
  [key: string]: any;
};

export const useRouteParamState = (paramKey: string) => {
  const route = useRoute<RouteProp<ParamList>>();
  const navigation = useNavigation();

  const value = route.params ? route.params[paramKey] : undefined;

  const setValue = useCallback(
    (newValue: any) => {
      if (newValue !== value) {
        navigation.setParams({ [paramKey]: newValue });
      }
    },
    [navigation, paramKey, value],
  );

  return [value, setValue] as const;
};
