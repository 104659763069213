import { IconInfo } from "@/assets/svg";
import { Button } from "@/components/Button";
import { Dialog } from "@/components/Dialog";
import { Select } from "@/components/Input";
import { Text } from "@/components/Typography";
import { SUPPORTED_LANGUAGES_OPTIONS } from "@/constants";
import { useRecordPreferencesStore } from "@/features/live-record";
import { useTheme } from "@/styles";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";

export const PromptLanguageDialog: FC<{
  isOpen: boolean;
  close: () => void;
  startRecording: () => void;
}> = ({ isOpen, close, startRecording }) => {
  const { language, setLanguage } = useRecordPreferencesStore();

  const theme = useTheme();

  return (
    <Dialog.Root isOpen={isOpen} close={close} variant="bottomSheet">
      <Dialog.Header>Meeting Language</Dialog.Header>
      <Dialog.Body>
        <Select
          value={language}
          onValueChange={setLanguage}
          options={SUPPORTED_LANGUAGES_OPTIONS}
          aria-label="Select recording language"
          showSearch
        />
        <View style={styles.footnote}>
          <IconInfo width={16} height={16} color={theme.colors.textHint} />
          <Text
            style={styles.footnoteText}
            variant="body2Regular"
            color="textHint"
          >
            You won&apos;t be able to change the language after the recording
            starts
          </Text>
        </View>
      </Dialog.Body>
      <Dialog.Actions>
        <Button variant="outlined" onPress={close}>
          Cancel
        </Button>
        <Button
          onPress={() => {
            close();
            startRecording();
          }}
        >
          Start Recording
        </Button>
      </Dialog.Actions>
    </Dialog.Root>
  );
};

const styles = StyleSheet.create({
  footnote: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 8,
  },
  footnoteText: {
    flex: 1,
  },
});
