import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";
import { FileLogger, LogLevel as RNFLLogLevel } from "react-native-file-logger";
import { addBreadcrumb as addSentryBreadcrumb } from "../errors/sentry";

if (Platform.OS !== "web") {
  FileLogger.configure({
    captureConsole: false,
    dailyRolling: false,
    maximumFileSize: 1024 * 1024, // 1MB
    maximumNumberOfFiles: 3,
    logsDirectory: `${FileSystem.documentDirectory}logs/`.substring(
      `file://`.length,
    ),
  });
}

type LogMetadata = {
  /**
   * https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
   */
  type?:
    | "default"
    | "debug"
    | "error"
    | "navigation"
    | "http"
    | "info"
    | "query"
    | "transaction"
    | "ui"
    | "user";
} & Record<string, any>;

type LogLevel = "info" | "warning" | "error" | "debug";

const FILE_LOGGER_LEVEL = {
  info: RNFLLogLevel.Info,
  warning: RNFLLogLevel.Warning,
  error: RNFLLogLevel.Error,
  debug: RNFLLogLevel.Debug,
};

export class Logger {
  constructor(public tag: string) {}
  private write(
    level: LogLevel,
    message: string,
    { type, ...metadata }: LogMetadata = {},
  ) {
    const messageStr = `[${this.tag}] ${message}`;
    const timestamp = Date.now();

    const consoleLogger = {
      info: console.info,
      warning: console.warn,
      error: console.error,
      debug: console.debug,
    }[level];

    if (Platform.OS === "web" || __DEV__) {
      consoleLogger(messageStr, metadata);
    }
    if (Platform.OS !== "web") {
      FileLogger.write(
        FILE_LOGGER_LEVEL[level],
        JSON.stringify({
          timestamp,
          level,
          message,
          tag: this.tag,
          ...metadata,
        }),
      );
      addSentryBreadcrumb({
        category: this.tag,
        message,
        data: metadata,
        level,
        type: type || (level === "error" && "error") || "default",
        timestamp: timestamp / 1000, // Sentry expects seconds
      });
    }
  }
  info(message: string, metadata?: LogMetadata) {
    this.write("info", message, metadata);
  }
  warn(message: string, metadata?: LogMetadata) {
    this.write("warning", message, metadata);
  }
  error(message: string | Error, metadata?: LogMetadata) {
    this.write("error", String(message), metadata);
  }
  debug(message: string, metadata?: LogMetadata) {
    this.write("debug", message, metadata);
  }
}

export const getLogFilePaths = async () => {
  return FileLogger.getLogFilePaths().then((paths) =>
    paths.map((path) => `file://${path}`),
  );
};
