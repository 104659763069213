import { Breakpoint, createStyles, useTheme } from "@/styles";
import type { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import { type FC } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { LinkPressable } from "./LinkPressable";
import type { TabButtonProps } from "./utils";
import { getTabButtonProps } from "./utils";

const TabBarButton: FC<TabButtonProps> = ({
  title,
  name,
  focused,
  Icon,
  onPress,
  onLongPress,
}) => {
  const theme = useTheme();

  return (
    <LinkPressable
      aria-selected={focused}
      aria-label={title}
      style={styles.button}
      onPress={onPress}
      onLongPress={onLongPress}
      href={name}
    >
      <Icon
        width={24}
        height={24}
        color={focused ? theme.colors.textPrimary : theme.colors.textHint}
      />
    </LinkPressable>
  );
};

export const TabBar: FC<BottomTabBarProps> = ({
  state,
  descriptors,
  navigation,
}) => {
  const theme = useTheme();

  if (theme.breakpoint >= Breakpoint.Medium) return null;

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={styles.root(theme)}
      aria-label="Bottom tab navigator"
    >
      {state.routes.map((route, index) => {
        return (
          <TabBarButton
            key={route.key}
            {...getTabButtonProps(state, navigation, descriptors, route, index)}
          />
        );
      })}
    </SafeAreaView>
  );
};

const styles = createStyles({
  root: (theme) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 32,
    backgroundColor: theme.colors.pageBackground,
    borderTopWidth: 1,
    borderTopColor: theme.colors.borderStaticSubtle,
  }),
  button: {
    padding: 16,
  },
});
