import { Config } from "@/constants";
import type { LinkingOptions, PathConfig } from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as ShareTarget from "modules/share-target";
import type { ParamList } from "./types";
import {
  getLastNotificationResponseAsync,
  addNotificationResponseReceivedListener,
  removeNotificationSubscription,
} from "@/utils/notifications";
import { tracker, TRACKING_EVENTS } from "@/tracking";

const prefix = Linking.createURL("/");

const onPushNotificationOpened = ({
  payload,
  fromBackground,
}: {
  payload: Record<string, any> | undefined;
  fromBackground: boolean;
}) => {
  if (payload?.tId) {
    tracker.track(TRACKING_EVENTS.PUSH_NOTIFICATION_OPENED, {
      templateId: payload.tId,
      fromBackground,
    });
  }
};

export const linking: LinkingOptions<ParamList> =
  ShareTarget.withLinkingOptions({
    prefixes: [prefix, Config.MOBILE_APP_URL, Config.DASHBOARD_URL],
    config: {
      initialRouteName: "Main",
      screens: {
        Main: {
          initialRouteName: "Home",
          path: "",
          screens: {
            Home: "",
            Notebook: {
              path: "notebook/:channel?",
              parse: {
                channel: (channel: string) => {
                  if (!channel) return undefined;
                  // TODO: remove this once dashboard fixed %3A%3A format
                  channel = decodeURIComponent(channel);
                  if (channel.includes("::")) return channel.split("::")[1];
                  return channel;
                },
              },
            },
            Library: "library/:tab?",
            Analytics: "analytics",
          },
        } as PathConfig<ParamList>,
        Search: {
          path: "search",
          parse: {
            mine: (mine) => mine === "true" || mine === "1",
          },
        },
        You: "you",
        Record: "record",
        Notepad: {
          path: "view/:id",
          parse: {
            id: (id) => {
              if (id.includes("::")) return id.split("::")[1];
              return id;
            },
          },
        },
        Login: "login",
        Bite: {
          path: "soundbites/:id",
          parse: {
            index: (index) => parseInt(index),
          },
        },
        Playlist: "playlists/:id",
        Library: "playlists",
        Settings: "settings",
        MeetingStatus: "status",
        Upload: "upload",
        Integrations: "integrations",
        Extensions: "apps",
        TopicTracker: "topics",
        Team: "team",
        Refer: "refer",
        Onboarding: "setup",
        Phone: "phone/:tab?",
        Live: "live/:id",
        NotFound: "*",
      },
    },
    async getInitialURL() {
      // Check if app was cold started from a deep link
      const url = await Linking.getInitialURL();

      if (url != null) {
        return url;
      }

      // Check if there is an initial notification
      const message = await getLastNotificationResponseAsync();

      onPushNotificationOpened({
        payload: message?.notification.request.content.data,
        fromBackground: false,
      });

      // in case this returns undefined, the app will stay in MainScreen
      return message?.notification.request.content.data?.url;
    },
    subscribe(listener) {
      const onReceiveURL = ({ url }: { url: string }) => listener(url);

      // Listen to incoming links from deep linking
      const onReceiveUrlListener = Linking.addEventListener(
        "url",
        onReceiveURL,
      );

      // Listen to push notification actions(user tap) when the app is alive, background or foregound
      const pushActionSubscription = addNotificationResponseReceivedListener(
        (message) => {
          const url = message?.notification.request.content.data?.url;
          onPushNotificationOpened({
            payload: message?.notification.request.content.data,
            fromBackground: true,
          });

          if (url) {
            listener(url);
          }
        },
      );

      return () => {
        onReceiveUrlListener.remove();
        removeNotificationSubscription(pushActionSubscription);
      };
    },
  });
