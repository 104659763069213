import { toHHMMSS } from "@/utils/time";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "../Typography";

export interface TimestampProps {
  time: number;
  endTime?: number;
  color?: "emphasize" | "default";
}

export const Timestamp: FC<TimestampProps> = ({ time, endTime, color }) => {
  const textColor =
    color === "emphasize" ? "informationStaticBlueText" : "textMuted";
  return (
    <View style={styles.root}>
      <Text variant="body2Regular" color={textColor}>
        {toHHMMSS(time)}
      </Text>
      {endTime && (
        <>
          <Text variant="body2Regular" color="textHint">
            -
          </Text>
          <Text variant="body2Regular" color={textColor}>
            {toHHMMSS(endTime)}
          </Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    gap: 4,
    alignItems: "center",
    justifyContent: "center",
  },
});
