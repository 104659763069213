import { LinkView } from "@/components/Link";
import { LoadingScreen, LoadingSpinner } from "@/components/Loading";
import { MeetingListItem } from "@/components/Meeting";
import { Separator } from "@/components/Separator";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { RouteNames } from "@/constants";
import { LOCAL_CHANNELS } from "@/features/channel";
import type { Source } from "@/graphql";
import { useChannelQuery, useFetchMore, useGlobalSearchQuery } from "@/graphql";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { ListRenderItem } from "react-native";
import { FlatList, StyleSheet, View } from "react-native";
import { Chip } from "../components/Chip";
import { EmptyState } from "../components/EmptyState";

const LIMIT = 20;

export const MeetingSearch: FC<{
  searchQuery: string;
  channelId?: string;
}> = ({ searchQuery, channelId }) => {
  const [matchTitleOnly, setMatchTitleOnly] = useState(false);
  const [matchChannelOnly, setMatchChannelOnly] = useState(!!channelId);

  const localChannel = channelId
    ? LOCAL_CHANNELS.find((c) => c._id === channelId)
    : undefined;
  const { data: channelData } = useChannelQuery({
    variables: { id: channelId as string },
    skip: !channelId || !!localChannel,
  });
  const filteredChannel = localChannel || channelData?.getChannel;

  const filters = useMemo(() => {
    let match: string[] | undefined;
    if (matchTitleOnly) {
      (match = match || []).push("title");
    }
    return { match };
  }, [matchTitleOnly]);

  const { data, loading, fetchMore } = useGlobalSearchQuery({
    variables: {
      channelId: (matchChannelOnly && channelId) || "all",
      from: 0,
      size: LIMIT,
      keywords: searchQuery,
      filters,
    },
    skip: !searchQuery,
    onError(err) {
      toast({
        title: "Could not query meeting",
        type: "error",
        message: err.message,
      });
    },
  });

  const results = data?.globalSearch?.meetings;
  const total = data?.globalSearch?.total || 0;

  const [onLoadMore, fetchMoreState, resetFetchMore] = useFetchMore({
    fetchMore,
    data,
    limit: LIMIT,
    path: "globalSearch.meetings",
    skipVariable: "from",
  });

  useEffect(resetFetchMore, [searchQuery, channelId, resetFetchMore]);

  const renderItem = useCallback<ListRenderItem<Source>>(
    ({ item }) => (
      <LinkView
        key={item.parseId}
        onPress={() => {
          tracker.track(TRACKING_EVENTS.GLOBAL_SEARCH_RESULT_CLICKED, {
            meetingId: item.parseId,
            channelId,
            keywords: searchQuery,
            filters,
          });
        }}
        href={{
          pathname: RouteNames.Notepad,
          params: { id: item.parseId as string },
        }}
        animated="highlight"
      >
        <MeetingListItem
          meeting={item}
          highlight={item._highlight || undefined}
        />
      </LinkView>
    ),
    [channelId, searchQuery, filters],
  );

  return (
    <View style={styles.root}>
      <View style={styles.filters}>
        {!!filteredChannel && filteredChannel._id !== "all" && (
          <Chip
            selected={matchChannelOnly}
            onPress={() => setMatchChannelOnly(!matchChannelOnly)}
          >
            #{filteredChannel.title}
          </Chip>
        )}
        <Chip
          selected={matchTitleOnly}
          onPress={() => setMatchTitleOnly(!matchTitleOnly)}
        >
          Match title only
        </Chip>
      </View>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {searchQuery && (
            <View style={styles.header}>
              <Text color="textMuted">
                {total} results for &quot;{searchQuery}&quot;
              </Text>
            </View>
          )}
          {!results?.length ? (
            <EmptyState
              title={
                searchQuery
                  ? `No meetings found for "${searchQuery}"`
                  : "Enter a search term to find your meetings"
              }
            />
          ) : (
            <FlatList
              style={styles.list}
              contentContainerStyle={styles.listContent}
              data={results}
              renderItem={renderItem}
              onEndReached={onLoadMore}
              onEndReachedThreshold={0.5}
              ListFooterComponent={
                !fetchMoreState.ended ? <LoadingSpinner /> : null
              }
              ItemSeparatorComponent={Separator}
            />
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    gap: 8,
  },
  filters: {
    flexDirection: "row",
    gap: 8,
    paddingHorizontal: 16,
    flexWrap: "wrap",
  },
  header: {
    paddingHorizontal: 16,
  },
  list: {
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 16,
    paddingBottom: 12,
  },
});
