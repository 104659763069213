import {
  IconBookmark,
  IconLayers,
  IconMessageCircle,
  IconSoundbite,
  IconSparkles,
} from "@/assets/svg";
import { Carousel } from "@/components/Carousel";
import { MessageView } from "@/components/Message";
import { Text } from "@/components/Typography";
import { useTheme } from "@/styles";
import { LinearGradient } from "expo-linear-gradient";
import { useMemo, type FC } from "react";
import { Image, StyleSheet, View } from "react-native";
import imageTipsAsk from "./images/tips-ask.png";
import imageTipsBookmark from "./images/tips-bookmark.png";
import imageTipsComment from "./images/tips-comment.png";
import imageTipsIntegration from "./images/tips-integration.png";
import imageTipsSoundbite from "./images/tips-soundbite.png";

const tips = [
  {
    image: imageTipsBookmark,
    title: "Bookmark key moments",
    Icon: IconBookmark,
  },
  {
    image: imageTipsComment,
    title: "Leave time-stamped comments",
    Icon: IconMessageCircle,
  },
  {
    image: imageTipsSoundbite,
    title: "Save key moments as soundbites",
    Icon: IconSoundbite,
  },
  {
    image: imageTipsIntegration,
    title: "Send notes to your work apps",
    Icon: IconLayers,
  },
  {
    image: imageTipsAsk,
    title: "AskFred - Ask Anything",
    Icon: IconSparkles,
  },
];

export const EmptyAiNoteSection: FC = () => {
  const theme = useTheme();

  const tipNodes = useMemo(() => {
    return tips.map((tip) => {
      return (
        <View style={styles.tip} key={tip.title}>
          <Image source={tip.image} style={styles.tipImage} />
          <View style={styles.tipFooter}>
            <tip.Icon width={20} height={20} color={theme.colors.textHint} />
            <Text variant="title3Regular" color="textHint">
              {tip.title}
            </Text>
          </View>
        </View>
      );
    });
  }, [theme.colors.textHint]);

  return (
    <LinearGradient
      style={styles.root}
      colors={[theme.colors.rainbowStaticTealSubtle, theme.colors.layerDefault]}
    >
      <MessageView
        title="Your live AI notes will appear here"
        description="Fireflies notetaker will start writing notes in a few minutes."
      />
      <View style={styles.tips}>
        <Carousel style={styles.carousel}>{tipNodes}</Carousel>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 84,
    backgroundColor: "red",
    paddingBottom: 32,
  },
  carousel: {
    pointerEvents: "none",
  },
  tips: {
    // because auto height is not a thing in React Native
    minHeight: 400,
  },
  tip: {
    flexDirection: "column",
    padding: 24,
    gap: 16,
    alignItems: "center",
  },
  tipImage: {
    height: 200,
    width: 364,
    resizeMode: "contain",
  },
  tipFooter: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
});
