import { LoadingScreen } from "@/components/Loading";
import { toast } from "@/components/Toast";
import { useAuth } from "@/features/auth";
import { promptConfirmation } from "@/features/global-modals";
import {
  AudioInputUnavailableError,
  localRecordStoreApi,
  permissionDeniedError,
  recorderApi,
  SilentRecordingDialog,
  useExperimentalRecordConfig,
  useRecorder,
  useRecordPreferencesStore,
} from "@/features/live-record";
import { MEETING_PRIVACY_OPTIONS } from "@/features/meeting";
import { getLocalUserFlag, setLocalUserFlag } from "@/features/user-flags";
import type { MeetingNotesPrivacy } from "@/graphql";
import { useHasNewUpdates } from "@/utils/updates";
import type { RouteProp } from "@react-navigation/native";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren,
} from "react";
import { Linking, Modal } from "react-native";
import type { ParamList } from "../types";
import { PromptLanguageDialog } from "./components/PromptLanguageDialog";
import { warnNewUpdatesBeforeRecording } from "./components/utils";

type StartInputs = {
  title: string;
  notesPrivacy: MeetingNotesPrivacy;
  language: string;
};

interface RecordScreenContextValue {
  startRecording: () => Promise<void>;
  toggleRecording: () => Promise<void>;
  stopRecording: () => Promise<void>;
  loading: string | null;
  startInputs: StartInputs;
  setStartInputs: React.Dispatch<React.SetStateAction<StartInputs>>;
}

const RecordScreenContext = createContext({} as RecordScreenContextValue);

const getInitialStartInputs = (): StartInputs => ({
  title: "",
  language: useRecordPreferencesStore.getState().language || "en",
  notesPrivacy:
    (useAuth.getState().user?.privacy as MeetingNotesPrivacy | undefined) ||
    MEETING_PRIVACY_OPTIONS[0].value,
});

export const RecordScreenContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const hasNewUpdate = useHasNewUpdates();

  const [loading, setLoading] = useState<string | null>(null);
  const recordConfig = useExperimentalRecordConfig();

  const [isOpenPromptLanguage, setIsOpenPromptLanguage] = useState(false);

  const [showAudioInputUnavailableBanner, setShowAudioInputUnavailableBanner] =
    useState(false);

  const [startInputs, setStartInputs] = useState<StartInputs>(
    getInitialStartInputs,
  );

  const startRecording = useCallback(async () => {
    if (loading) return;

    const { status } = useRecorder.getState();

    if (status.state !== "inactive") {
      return;
    }

    try {
      if (hasNewUpdate) {
        const recordAnyway = await warnNewUpdatesBeforeRecording();
        if (!recordAnyway) return;
      }

      const shownLanguageSelectBeforeRecording = getLocalUserFlag(
        "record.language-select-prompt.shown",
      );

      if (!shownLanguageSelectBeforeRecording) {
        setIsOpenPromptLanguage(true);
        setLocalUserFlag("record.language-select-prompt.shown", true);
        return;
      }

      setLoading("Starting recording...");

      const { context, fileUri } = await recorderApi.start({
        mimeType: recordConfig.mimeType,
        usingStream: recordConfig.enableStreaming,
        language: startInputs.language,
        notesPrivacy: startInputs.notesPrivacy,
        title: startInputs.title.trim(),
      });

      localRecordStoreApi.add({
        ...context,
        fileUris: [fileUri],
        uploaded: false,
        streamed: false,
      });

      setStartInputs(getInitialStartInputs());
    } catch (err) {
      if (err === permissionDeniedError) {
        toast({
          message: (err as Error).message,
          type: "error",
          action: {
            label: "Settings",
            onPress: () => Linking.openSettings(),
          },
          duration: 6000,
        });
      } else if (err instanceof AudioInputUnavailableError) {
        setShowAudioInputUnavailableBanner(true);
      } else {
        toast({
          title: "Could not start recording",
          message: (err as Error).message,
          type: "error",
        });
      }
    } finally {
      setLoading(null);
    }
  }, [
    hasNewUpdate,
    loading,
    recordConfig.mimeType,
    recordConfig.enableStreaming,
    startInputs,
  ]);

  const toggleRecording = useCallback(async () => {
    const { status } = useRecorder.getState();

    if (loading) return;

    const intent: "start" | "pause" | "resume" = "start";

    try {
      if (status.state === "paused") {
        await recorderApi.resume();
      } else if (status.state === "recording") {
        await recorderApi.pause();
      }
    } catch (err) {
      if (err === permissionDeniedError) {
        toast({
          message: (err as Error).message,
          type: "error",
          action: {
            label: "Settings",
            onPress: () => Linking.openSettings(),
          },
          duration: 6000,
        });
      } else if (err instanceof AudioInputUnavailableError) {
        setShowAudioInputUnavailableBanner(true);
      } else {
        toast({
          ...(intent === "start" && {
            title: "Could not start recording",
          }),
          message: (err as Error).message,
          type: "error",
        });
      }
    } finally {
      setLoading(null);
    }
  }, [loading]);

  const stopRecording = useCallback(async () => {
    if (loading) return;

    const { context } = useRecorder.getState();

    if (!context?.title) {
      const confirmedProceedWithDefaultTitle = await new Promise<boolean>(
        (resolve) =>
          promptConfirmation({
            title: "Proceed without meeting title?",
            children:
              "Adding a title makes it easier to find the recording later.",
            cancelText: "Add title",
            confirmText: "Proceed anyway",
            highlightCancel: true,
            onCancel: () => resolve(false),
            onConfirm: () => {
              // TODO: on iOS, if a dialog leads to another dialog, it will lead to undefined behavior
              // Since we will open the "loading" dialog right after this, we need to delay the resolution
              setTimeout(() => resolve(true), 1000);
            },
          }),
      );

      if (!confirmedProceedWithDefaultTitle) {
        return;
      }
    }

    setLoading("Finalizing recording...");

    try {
      const { context } = await recorderApi.stop();

      localRecordStoreApi.updateByUri(context.fileUri, context);
    } catch (err) {
      toast({
        title: "Could not stop recording",
        message: (err as Error).message,
        type: "error",
      });
    }

    setLoading(null);
  }, [loading]);

  const route = useRoute<RouteProp<ParamList, "Record">>();
  const navigation = useNavigation();
  useLayoutEffect(() => {
    navigation.setParams({ action: undefined });
    if (route.params?.action === "stop") {
      promptConfirmation({
        title: "Stop recording?",
        cancelText: "Continue recording",
        confirmText: "Stop recording",
        onConfirm: stopRecording,
      });
    } else if (route.params?.action === "start") {
      startRecording();
    }
  }, [route.params?.action, stopRecording, startRecording, navigation]);

  const value = useMemo(
    () => ({
      startRecording,
      toggleRecording,
      stopRecording,
      loading,
      startInputs,
      setStartInputs,
    }),
    [startRecording, toggleRecording, stopRecording, startInputs, loading],
  );

  return (
    <RecordScreenContext.Provider value={value}>
      {children}
      <Modal transparent visible={!!loading}>
        <LoadingScreen withOverlay title={loading || ""} />
      </Modal>
      <PromptLanguageDialog
        isOpen={isOpenPromptLanguage}
        close={() => setIsOpenPromptLanguage(false)}
        startRecording={startRecording}
      />
      <SilentRecordingDialog
        title="Recording Unavailable"
        isOpen={showAudioInputUnavailableBanner}
        close={() => setShowAudioInputUnavailableBanner(false)}
      />
    </RecordScreenContext.Provider>
  );
};

export const useRecordScreenContext = () => useContext(RecordScreenContext);
