import type { Theme } from "@/styles";
import { Fonts, useTheme, vars } from "@/styles";
import { forwardRef, memo, useImperativeHandle, useMemo } from "react";
import type { TextStyle, ViewStyle } from "react-native";
import MarkdownDisplay from "react-native-markdown-display";
import { markdownToHtml } from "./utils";

export interface MarkdownInstance {
  getHtml: () => string;
}

export const Markdown = memo(
  forwardRef<
    MarkdownInstance,
    {
      children: string;
      onLinkPress?: (url: string) => boolean;
      color?: keyof Theme["colors"];
      style?: Partial<MarkdownStyle>;
    }
  >(function Markdown(
    { children, onLinkPress, color = "textPrimary", style: customStyle },
    ref,
  ) {
    const theme = useTheme();
    const style = useMemo(() => {
      const baseStyles = getStyles(theme, color);
      for (const key of Object.keys(baseStyles) as Array<keyof MarkdownStyle>) {
        if (customStyle?.[key]) {
          baseStyles[key] = {
            ...baseStyles[key],
            ...customStyle[key],
          };
        }
      }
      return baseStyles;
    }, [theme, color, customStyle]);

    useImperativeHandle(ref, () => ({
      getHtml: () => markdownToHtml(children),
    }));

    return (
      <MarkdownDisplay
        mergeStyle={false}
        style={style}
        onLinkPress={onLinkPress}
      >
        {children}
      </MarkdownDisplay>
    );
  }),
);

// https://github.com/iamacup/react-native-markdown-display/blob/master/src/lib/styles.js
const getStyles = (
  theme: Theme,
  color: keyof Theme["colors"],
): MarkdownStyle => ({
  body: {
    ...vars.typography.body1LongRegular,
    color: theme.colors[color],
  },
  heading1: {
    ...vars.typography.heading3Weight,
    marginBottom: 16,
  },
  heading2: {
    ...vars.typography.heading4Weight,
    marginBottom: 16,
  },
  heading3: {
    ...vars.typography.heading5Weight,
    marginBottom: 16,
  },
  heading4: {
    ...vars.typography.title1Weight,
    marginBottom: 16,
  },
  heading5: {
    ...vars.typography.title2Weight,
    marginBottom: 16,
  },
  heading6: {
    ...vars.typography.title3Weight,
    marginBottom: 16,
  },
  hr: {
    backgroundColor: theme.colors.borderStaticDefault,
    height: 1,
  },
  strong: vars.typography.body1LongWeight,
  em: {
    ...vars.typography.body1LongRegular,
    fontFamily: Fonts.dmSans["400"].italic,
  },
  list_item: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 12,
  },
  bullet_list_icon: {
    marginLeft: 10,
    marginRight: 10,
  },
  ordered_list_icon: {
    marginLeft: 10,
    marginRight: 10,
  },
  link: {
    color: theme.colors.commandPrimaryDefault,
  },
  paragraph: {
    marginBottom: 12,
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
});

export type MarkdownStyle = {
  body: TextStyle;
  heading1: TextStyle;
  heading2: TextStyle;
  heading3: TextStyle;
  heading4: TextStyle;
  heading5: TextStyle;
  heading6: TextStyle;
  hr: ViewStyle;
  strong: TextStyle;
  em: TextStyle;
  list_item: ViewStyle;
  bullet_list_icon: ViewStyle;
  ordered_list_icon: ViewStyle;
  link: TextStyle;
  paragraph: ViewStyle;
};
