import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { storage } from "../storage";

interface RecordPreferenceStoreValue {
  language: string;
  setLanguage(language: string): void;
}

export const useRecordPreferencesStore = create(
  persist<RecordPreferenceStoreValue>(
    (set) => ({
      language: "en",
      setLanguage(language: string) {
        set({ language });
      },
    }),
    {
      name: "record-preferences",
      storage: createJSONStorage(() => storage),
    },
  ),
);
