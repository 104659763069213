import { IconPlus } from "@/assets/svg";
import { Button } from "@/components/Button";
import { LinkView } from "@/components/Link";
import { Breakpoint, createStyles, useTheme } from "@/styles";
import { useBooleanState } from "@/utils/states";
import { type CoreFeedMeetingFieldsFragment } from "@firefliesai/tasks-ff.graphql-client";
import { memo, useContext, useMemo, type FC } from "react";
import { View } from "react-native";
import {
  LoadingMeetingHeader,
  MeetingHeader,
} from "../../components/MeetingHeader";
import { meetingCardStyles } from "../../components/styles";
import { TasksContext } from "../TasksContext";
import { CreateTaskModal } from "./CreateTaskModal";
import { ListTaskItem, LoadingListTaskItem } from "./ListTaskItem/ListTaskItem";

export const TaskMeetingCard = memo<{
  meeting: CoreFeedMeetingFieldsFragment;
}>(function TaskMeetingCard({ meeting }) {
  const theme = useTheme();

  const [isOpenCreate, openCreate, closeCreate] = useBooleanState();

  const { onlyMine, filterMyTasks, sortTasks } = useContext(TasksContext);
  const tasks = useMemo(() => {
    if (onlyMine) {
      return meeting.tasks.filter(filterMyTasks).sort(sortTasks);
    }
    return Array.from(meeting.tasks).sort(sortTasks);
  }, [filterMyTasks, onlyMine, meeting.tasks, sortTasks]);

  if (onlyMine && !tasks.length) return null;

  return (
    <>
      <View style={meetingCardStyles.root}>
        <LinkView
          href={{
            pathname: "Notepad",
            params: { id: meeting._id, ref: "tasks" },
          }}
          animated
          style={styles.horPadding}
        >
          <MeetingHeader meeting={meeting} />
        </LinkView>
        <View style={[meetingCardStyles.content(theme), styles.content(theme)]}>
          {tasks.map((task) => (
            <ListTaskItem key={task.id} meeting={meeting} task={task} />
          ))}
          <Button
            Icon={IconPlus}
            variant="transparentNeutral"
            onPress={openCreate}
            style={styles.newButton}
          >
            New
          </Button>
        </View>
      </View>
      <CreateTaskModal
        meeting={meeting}
        isOpen={isOpenCreate}
        close={closeCreate}
      />
    </>
  );
});

export const LoadingTaskMeetingCard: FC = () => {
  const theme = useTheme();
  return (
    <View style={meetingCardStyles.root}>
      <View style={styles.horPadding}>
        <LoadingMeetingHeader />
      </View>
      <View style={[meetingCardStyles.content(theme), styles.content(theme)]}>
        {Array.from({ length: 4 }).map((_, index) => (
          <LoadingListTaskItem key={index} />
        ))}
      </View>
    </View>
  );
};

const styles = createStyles({
  content: (theme) => ({
    gap: 4,
    paddingRight: theme.breakpoint === Breakpoint.Small ? 0 : 16,
  }),
  horPadding: {
    paddingHorizontal: 20,
  },
  newButton: {
    alignSelf: "flex-start",
  },
});
