import type { FieldFunctionOptions } from "@apollo/client";

const removeDuplicateObjects = <T>(arr: T[], options: FieldFunctionOptions) => {
  const existingIds = new Set<string>();
  const result: T[] = [];
  for (const item of arr) {
    if (!item) {
      continue;
    }
    const id = options.toReference(item)?.__ref;
    if (!id) {
      result.push(item);
    } else if (!existingIds.has(id)) {
      existingIds.add(id);
      result.push(item);
    } else {
      // duplicate, ignore
    }
  }
  return result;
};

/**
 * Merging that works with queries that return in the format of { meetings: [any], ...any }
 */
export const mergeDashboardMeetingResults =
  (offsetArg: string | ((options: FieldFunctionOptions) => number) = "from") =>
  (existing: any, incoming: any, options: FieldFunctionOptions) => {
    const merged = {
      ...(existing || {}),
      // to get fields like 'total', 'totalCount', etc.
      ...incoming,
      meetings: existing?.meetings?.slice(0) || [],
    };

    const offset =
      (typeof offsetArg === "function"
        ? offsetArg(options)
        : options.args?.[offsetArg]) || 0;

    for (let i = 0; i < incoming.meetings.length; ++i) {
      merged.meetings[offset + i] = incoming.meetings[i];
    }
    merged.meetings = removeDuplicateObjects(merged.meetings, options);

    return merged;
  };
