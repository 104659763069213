import { IconChrome, IconDottedCircle } from "@/assets/svg";
import type { Source } from "@/graphql";
import { createStyles, useTheme, vars } from "@/styles";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import { useMemo, type FC } from "react";
import { View } from "react-native";

export const MeetingAddedByIcon: FC<{
  meeting: Pick<Meeting | Source, "addedBy">;
  iconOnly?: boolean;
}> = ({ meeting, iconOnly }) => {
  const theme = useTheme();

  const info = useMemo(() => {
    if (!meeting.addedBy) return null;
    if (meeting.addedBy.includes("-record"))
      return {
        Icon: IconDottedCircle,
        label: meeting.addedBy,
      };
    if (meeting.addedBy.includes("chrome"))
      return {
        Icon: IconChrome,
        label: meeting.addedBy,
      };
    return null;
  }, [meeting.addedBy]);

  if (!info) return null;

  const iconNode = (
    <info.Icon
      width={16}
      height={16}
      color={vars.colors.grey500}
      accessibilityLabel={`Added by ${info.label}`}
    />
  );

  if (iconOnly) return iconNode;

  return <View style={styles.addedByIcon(theme)}>{iconNode}</View>;
};

const styles = createStyles({
  addedByIcon: (theme) => ({
    backgroundColor: theme.colors.layerMuted,
    height: 26,
    width: 32,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 18,
  }),
});
