import { AudioRecordErrorCode } from "@/modules/audio-record";
import { format } from "date-fns";
import { Audio } from "expo-av";
import type { LocalRecordingFile } from "./types";

export const permissionDeniedCanAskAgainError = new Error(
  "Allow access to microphone to continue",
);

export const permissionDeniedError = new Error(
  "Open settings and allow access to microphone to continue",
);

export async function requestMicrophonePermissionOrFail() {
  const { granted, canAskAgain } = await Audio.requestPermissionsAsync();
  if (!granted) {
    if (canAskAgain) {
      throw permissionDeniedCanAskAgainError;
    }
    throw permissionDeniedError;
  }
  return true;
}

export const getFileName = (file: LocalRecordingFile) =>
  file.context?.title || file.uri.split("/").pop() || "";

export const getNonRawFileUri = (file: LocalRecordingFile) => {
  if (!file.extensionInfo.isRaw) {
    return file.uri;
  }
  return (
    file.uri.substring(
      0,
      file.uri.length - file.extensionInfo.extension.length,
    ) + file.extensionInfo.containedExtension
  );
};

export const getDefaultRecordingTitle = (startTime: Date) => {
  return format(startTime, "MMM dd, hh:mm aa");
};

export const getRecordingErrorSentryFingerprint = (err: {
  code?: string;
  message: string;
}) => {
  // many errors got grouped as Internal so we use the message to differentiate
  if (err.code && err.code !== AudioRecordErrorCode.ErrInternal) {
    return ["{{ default }}", err.code];
  }
  return ["{{ default }}", err.message];
};
