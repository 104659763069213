import {
  IconCloudOff,
  IconDownload,
  IconPause,
  IconPlay,
  IconTrash,
  IconUploadCloud,
} from "@/assets/svg";
import { Button } from "@/components/Button";
import { ConfirmationDialog, Dialog } from "@/components/Dialog";
import { LoadingScreen } from "@/components/Loading";
import { MessageView } from "@/components/Message";
import { toast } from "@/components/Toast";
import { Text } from "@/components/Typography";
import { captureException, getErrorMessage } from "@/errors";
import { usePhoneCall } from "@/features/phone-call";
import { useUpload } from "@/features/upload";
import { Logger } from "@/logger";
import { useTheme } from "@/styles";
import { deleteFile, getFileUrisInDirectory, shareFile } from "@/utils/file";
import { getMimeType } from "@/utils/mime";
import { toHHMMSS } from "@/utils/time";
import { FlashList, type ListRenderItemInfo } from "@shopify/flash-list";
import { format } from "date-fns";
import type { AVPlaybackStatus } from "expo-av";
import { Audio } from "expo-av";
import * as FileSystem from "expo-file-system";
import prettyBytes from "pretty-bytes";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
} from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { RECORDING_DIRECTORY, RECORDING_FILE_EXTENSIONS } from "../constants";
import { localRecordStoreApi } from "../local-record.store";
import { useRecorder } from "../record.store";
import type { LocalRecordingFile, RecordingContext } from "../types";
import { getFileName } from "../utils";
import {
  RecordUploadModal,
  RecordUploadModalContext,
  useRecordUploadModalContext,
} from "./RecordUploadModal";
import { useBooleanState } from "@/utils/states";

const logger = new Logger("LocalRecordings");

const pageSize = 20;

const now = new Date();

async function getFileInfoBatch(
  uris: string[],
  startIndex: number,
  endIndex: number,
): Promise<LocalRecordingFile[]> {
  const slice = uris.slice(startIndex, endIndex);

  const files = await Promise.all(
    slice.map(async (uri) => {
      // Determine extension
      const ext =
        RECORDING_FILE_EXTENSIONS.find((item) =>
          uri.endsWith(item.extension),
        ) || RECORDING_FILE_EXTENSIONS[RECORDING_FILE_EXTENSIONS.length - 1];

      try {
        // TODO: we should update this to use .list() for having info of multiple files in a single query
        // https://docs.expo.dev/versions/latest/sdk/filesystem-next/#list
        const info = await FileSystem.getInfoAsync(uri);
        if (info.exists) {
          return {
            uri,
            info,
            context: localRecordStoreApi.findByUri(uri),
            extensionInfo: ext,
          } satisfies LocalRecordingFile;
        }
      } catch (err) {
        captureException(err);
      }
      return null;
    }),
  );

  return files
    .filter((f) => !!f)
    .sort(
      (a, b) =>
        (b.info?.modificationTime || 0) - (a.info?.modificationTime || 0),
    );
}

const keyExtractor = (item: LocalRecordingFile) => item.uri;

const LocalRecordingFileItem: FC<{
  file: LocalRecordingFile;
  onSelected: (file: LocalRecordingFile) => void;
  onFileDelete: (file: LocalRecordingFile) => void;
}> = ({ file, onFileDelete, onSelected }) => {
  const [playbackStatus, setPlaybackStatus] = useState<AVPlaybackStatus>();
  const [isOpenDeleteDialog, openDeleteDialog, closeDeleteDialog] =
    useBooleanState();

  const soundRef = useRef<Audio.Sound | null>(null);

  const isPlaying = Boolean(
    playbackStatus?.isLoaded && playbackStatus.isPlaying,
  );

  const { uploads } = useUpload();
  const isBeingUploaded = Boolean(
    // the includes() allows us to also consider the raw version
    // which is always in the <file>.pcm/aac format
    uploads.find((upload) => file.uri.includes(upload.fileUri)),
  );

  const fileName = getFileName(file);

  const promptRawMessage = () => {
    toast({
      title: "Raw recording format",
      message:
        "Recording is in raw format and cannot be used. Please contact support for assistance.",
    });
  };

  useEffect(() => {
    // Cleanup any existing sound when the file prop changes.
    // this is critical for FlashList's recycling mechanism
    // otherwise wrong(cached) audios will play for random list items
    if (soundRef.current) {
      soundRef.current.unloadAsync();
      soundRef.current = null;
      setPlaybackStatus(undefined);
    }
  }, [file.uri]);

  const togglePlay = async () => {
    if (file.extensionInfo.isRaw) {
      promptRawMessage();
      return;
    }
    try {
      await Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
      if (!soundRef.current) {
        await Audio.Sound.createAsync(
          { uri: file.uri },
          { shouldPlay: true },
          setPlaybackStatus,
        ).then((value) => {
          soundRef.current = value.sound;
        });
      } else {
        if (isPlaying) {
          await soundRef.current.stopAsync();
        } else {
          await soundRef.current.setStatusAsync({
            shouldPlay: true,
            positionMillis: 0,
          });
        }
      }
    } catch (e) {
      captureException(
        new Error(getErrorMessage(e, "RecordingRecover#togglePlay")),
        {
          tags: { section: "recording-recover" },
          contexts: {
            file: {
              ...file,
            },
          },
        },
      );
      toast({
        title: "Failed to play recording",
        type: "error",
        message:
          "The recording file might be damaged. Please contact support for assistance.",
      });
    }
  };

  useEffect(() => {
    return () => {
      soundRef.current?.unloadAsync();
    };
  }, []);

  const dateStr = useMemo(() => {
    const startTimestamp =
      file.context?.startTime || (file.info?.modificationTime || 0) * 1000;
    if (!startTimestamp) return "";

    const date = new Date(startTimestamp);

    const isSameYear = date.getFullYear() === now.getFullYear();

    return format(date, isSameYear ? "MMM d · h:mm a" : "MMM d, yyyy · h:mm a");
  }, [file.context?.startTime, file.info?.modificationTime]);

  const durationStr =
    !!file.context?.durationMillis &&
    toHHMMSS(file.context.durationMillis / 1000);

  const onUpload = () => {
    if (file.extensionInfo.isRaw) {
      promptRawMessage();
    } else {
      onSelected(file);
    }
  };

  const onShareClick = () => {
    shareFile(file.uri).catch((err) => {
      captureException(err, {
        tags: {
          section: "recording-recover",
        },
      });
      toast({
        title: "Failed to share recording",
        type: "error",
        message: err.message,
      });
    });
  };

  const theme = useTheme();

  const isUnuploaded = !file.context?.uploaded && !isBeingUploaded;
  const isStreamed = file.context?.streamed === true;

  return (
    <View style={styles.item}>
      <View style={styles.playWrapper}>
        <Button
          size="sm"
          Icon={isPlaying ? IconPause : IconPlay}
          aria-label="Play"
          variant="outlined"
          style={styles.playIcon}
          onPress={togglePlay}
        />
        <Text variant="label3Regular" color="textMuted">
          {durationStr}
        </Text>
      </View>
      <View style={styles.contentWrapper}>
        <View style={styles.itemDetail}>
          <View style={styles.titleWrapper}>
            {isUnuploaded && !isStreamed && (
              <IconCloudOff
                color={theme.colors.commandDangerDefault}
                aria-label="Unuploaded recording"
                width={14}
                height={14}
              />
            )}
            <Text
              style={styles.title}
              variant="label1Weight"
              color="textSecondary"
            >
              {fileName}
            </Text>
          </View>
          <Text variant="label2Regular" color="textHint">
            {dateStr}
            {" · "}
            {file.info?.size ? prettyBytes(file.info.size) : "??"}{" "}
            {file.extensionInfo.extension.split(".").pop()?.toUpperCase()}
            {!!file.context?.meetingId && `\nID: ${file.context?.meetingId}`}
          </Text>
        </View>
        <View style={styles.buttons}>
          <Button
            Icon={IconUploadCloud}
            variant="transparentNeutral"
            onPress={onUpload}
            size="xs"
            disabled={isBeingUploaded}
          >
            {isBeingUploaded ? "Uploading..." : "Upload"}
          </Button>
          <Button
            Icon={IconDownload}
            variant="transparentNeutral"
            onPress={onShareClick}
            size="xs"
          >
            Save to
          </Button>
          <Button
            Icon={IconTrash}
            variant="transparentNeutral"
            disabled={isBeingUploaded}
            onPress={openDeleteDialog}
            size="xs"
          >
            Delete
          </Button>
        </View>
      </View>

      <ConfirmationDialog
        title="Delete recording"
        isOpen={isOpenDeleteDialog}
        close={closeDeleteDialog}
        onConfirm={() => onFileDelete(file)}
        confirmText="Delete"
        cancelText="Cancel"
      >
        {`Are you sure you want to delete "${fileName}" from your device?`}
      </ConfirmationDialog>
    </View>
  );
};

export const LocalRecordingsList: React.FC<{ close?: () => void }> = ({
  close,
}) => {
  const fileUrisRef = useRef<string[]>();
  const [processedFiles, setProcessedFiles] = useState<LocalRecordingFile[]>();
  const [isLoading, setIsLoading] = useState(false);

  const { setUploadIntent } = useRecordUploadModalContext();

  const loadMore = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      if (!fileUrisRef.current) {
        const uris = await getFileUrisInDirectory(RECORDING_DIRECTORY);
        fileUrisRef.current = uris;
        logger.info("fetched local recording file uris", {
          fileCount: uris.length,
        });
      }
      const uris = fileUrisRef.current;

      if (!processedFiles && !uris.length) {
        setProcessedFiles([]);
        return;
      }
      if (!!processedFiles && processedFiles.length >= uris.length) return;

      const startIndex = processedFiles?.length
        ? uris.indexOf(processedFiles[processedFiles.length - 1].uri) + 1
        : 0;
      const endIndex = Math.min(startIndex + pageSize, uris.length);

      if (startIndex >= endIndex) return;

      const newChunk = await getFileInfoBatch(uris, startIndex, endIndex);

      // deduplicated by URI to prevent race conditions
      setProcessedFiles((prev) => {
        prev = prev || [];
        const prevUris = new Set(prev.map((file) => file.uri));
        const uniqueNewFiles = newChunk.filter(
          (file) => !prevUris.has(file.uri),
        );
        return [...prev, ...uniqueNewFiles];
      });
    } catch (err) {
      captureException(err);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, processedFiles]);

  useEffect(() => {
    // Initial load
    if (!processedFiles) {
      loadMore();
    }
  }, [loadMore, processedFiles]);

  const handleFileDelete = useCallback(async (file: LocalRecordingFile) => {
    try {
      await deleteFile(file.uri);
      localRecordStoreApi.removeByUri(file.uri);
      setProcessedFiles((prev) => prev?.filter((f) => f.uri !== file.uri));
      fileUrisRef.current = fileUrisRef.current?.filter((u) => u !== file.uri);

      toast({ message: "Recording deleted" });
    } catch (err) {
      captureException(err);
      toast({
        title: "Delete error",
        type: "error",
        message: "Failed to delete the recording.",
      });
    }
  }, []);

  const handleFileSelect = useCallback(
    (file: LocalRecordingFile) => {
      close?.();
      const mimeType = file.context?.mimeType || getMimeType(file.uri);
      if (!mimeType) {
        toast({
          title: "Failed to upload",
          type: "error",
          message: "Unknown file type",
        });
        return;
      }

      setUploadIntent({
        ...file.context,
        fileUri: file.uri,
        title: getFileName(file),
        usingStream: file.context?.streamed === true,
        mimeType,
      });
    },
    [close, setUploadIntent],
  );

  const renderItem = useCallback(
    (info: ListRenderItemInfo<LocalRecordingFile>) => {
      const file = info.item;
      return (
        <LocalRecordingFileItem
          file={file}
          onFileDelete={handleFileDelete}
          onSelected={handleFileSelect}
        />
      );
    },
    [handleFileDelete, handleFileSelect],
  );

  const renderFooter = useCallback(() => {
    if (!processedFiles?.length) return null;
    if (!isLoading) return null;

    return (
      <View style={styles.footer}>
        <ActivityIndicator size="small" />
      </View>
    );
  }, [isLoading, processedFiles?.length]);

  const onRefresh = useCallback(() => {
    setProcessedFiles(undefined);
    fileUrisRef.current = undefined;
    loadMore();
  }, [loadMore]);

  if (!processedFiles) {
    return <LoadingScreen />;
  }

  return (
    <FlashList
      estimatedItemSize={80}
      data={processedFiles}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      contentContainerStyle={styles.listContent}
      onEndReached={loadMore}
      onEndReachedThreshold={0.5}
      ListFooterComponent={renderFooter}
      onRefresh={onRefresh}
      refreshing={isLoading}
      ListEmptyComponent={
        !isLoading ? (
          <View>
            <Text style={styles.emptyRecordingsText}>
              You don&apos;t have any recordings.
            </Text>
          </View>
        ) : null
      }
    />
  );
};

export const LocalRecordingsDialog: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const { status } = useRecorder();
  const { status: callStatus } = usePhoneCall();

  const [uploadIntent, setUploadIntent] = useState<RecordingContext | null>(
    null,
  );

  return (
    <RecordUploadModalContext.Provider
      value={{ uploadIntent, setUploadIntent }}
    >
      <Dialog.Root isOpen={isOpen} close={close} variant="fullscreen">
        <Dialog.Header>Local Recordings</Dialog.Header>
        {status.state === "inactive" || callStatus.state === "inactive" ? (
          <LocalRecordingsList close={close} />
        ) : (
          <MessageView
            title="Local Recordings"
            description="You can access your local recordings when you are not recording."
          />
        )}
      </Dialog.Root>
      <RecordUploadModal />
    </RecordUploadModalContext.Provider>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 21,
    paddingBottom: 32,
  },
  item: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 21,
    marginTop: 16,
  },
  itemDetail: {
    flex: 1,
  },
  playIcon: {
    borderRadius: 9999,
  },
  playWrapper: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
  },
  contentWrapper: {
    flex: 1,
    flexDirection: "column",
    gap: 8,
  },
  buttons: {
    flexDirection: "row",
    gap: 16,
  },
  titleWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  title: {
    flex: 1,
  },
  emptyRecordingsText: {
    marginTop: 24,
    marginHorizontal: 24,
    textAlign: "center",
  },
  footer: {
    padding: 16,
    alignItems: "center",
  },
});
