import type { Caption } from "./types";

/**
 * TODO: PERFORMANCE: Need to run on different thread
 * Calculate the current index. To optimize, we start searching from the previous index.
 */
export const calculateCurrentIndex = (
  {
    currentTime,
    captions,
    previousArrayIndex,
  }: {
    currentTime: number;
    captions: Caption[];
    // previousIndex is the last index, so we can start searching from there
    // to optimize the search
    previousArrayIndex: number | undefined;
  },
  callback: (
    result: { captionIndex: number; arrayIndex: number } | undefined,
  ) => void,
) => {
  let index = 0;
  if (typeof previousArrayIndex === "number") {
    index = previousArrayIndex;
  }

  if (!captions?.length) {
    callback(undefined);
    return;
  }

  if (!captions[index]) {
    // invalid index, reset to 0
    index = 0;
  }

  const searchDirection = captions[index].startTime < currentTime ? 1 : -1;

  for (let i = index; i < captions.length && i >= 0; i += searchDirection) {
    const caption = captions[i];
    if (currentTime >= caption.startTime && currentTime <= caption.endTime) {
      callback({
        captionIndex: caption.index,
        arrayIndex: i,
      });
      return;
    }
  }

  callback(undefined);
};
