import { lightTheme } from "@/styles";
import type { FC } from "react";
import { StyleSheet, View } from "react-native";
import Animated, {
  useAnimatedStyle,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
} from "react-native-reanimated";

const timingOptions = {
  duration: 300,
};

export const FredTyping: FC = () => {
  // TODO: defining inline without useAnimatedStyles once we get to v3
  const dot1Style = useAnimatedStyle(
    () => ({
      opacity: withRepeat(
        withSequence(
          withTiming(1, timingOptions),
          withTiming(0, timingOptions),
          withTiming(1, timingOptions),
        ),
        -1,
      ),
    }),
    [],
  );
  const dot2Style = useAnimatedStyle(
    () => ({
      opacity: withDelay(
        100,
        withRepeat(
          withSequence(
            withTiming(1, timingOptions),
            withTiming(0, timingOptions),
            withTiming(1, timingOptions),
          ),
          -1,
        ),
      ),
    }),
    [],
  );
  const dot3Style = useAnimatedStyle(
    () => ({
      opacity: withDelay(
        200,
        withRepeat(
          withSequence(
            withTiming(1, timingOptions),
            withTiming(0, timingOptions),
            withTiming(1, timingOptions),
          ),
          -1,
        ),
      ),
    }),
    [],
  );

  return (
    <View style={styles.typing}>
      <Animated.View style={[styles.dot, dot1Style]} />
      <Animated.View style={[styles.dot, dot2Style]} />
      <Animated.View style={[styles.dot, dot3Style]} />
    </View>
  );
};

const styles = StyleSheet.create({
  typing: {
    flexDirection: "row",
    gap: 4,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: lightTheme.colors.layerBrand,
    borderRadius: 9999,
  },
});
