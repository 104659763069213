import type { Caption } from "@/features/transcript";
import type { FlashList } from "@shopify/flash-list";
import type { FC, MutableRefObject, PropsWithChildren, RefObject } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { LogBox } from "react-native";
import { TranscriptContext } from "./TranscriptContext";
import type { CaptionIndexToGroupCaptionIndex, GroupedCaption } from "./types";

LogBox.ignoreLogs(["returned an invalid measurement response"]);

const AutoscrollContext = createContext(
  {} as {
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
    scrollToCurrentCaption: (index?: number) => void;
    onScroll: () => void;
  },
);

export const AutoscrollProvider: FC<
  PropsWithChildren<{
    captionIndexToGroupCaptionIndexRef: MutableRefObject<CaptionIndexToGroupCaptionIndex>;
    captions: Caption[];
    listRef: RefObject<FlashList<GroupedCaption>>;
  }>
> = ({ children, listRef, captionIndexToGroupCaptionIndexRef }) => {
  const { currentIndex } = useContext(TranscriptContext);

  const [enabled, setEnabled] = useState(true);
  const lastAutoscrollTime = useRef(0);

  const scrollToCurrentCaption = useCallback(
    (captionIndex?: number) => {
      if (!captionIndex) return;
      const listItemIndex =
        captionIndexToGroupCaptionIndexRef.current.findIndex(
          (groupCaptionIndex) => groupCaptionIndex > captionIndex,
        ) - 1;
      if (listItemIndex >= 0) {
        listRef.current?.scrollToIndex({
          index: listItemIndex || 0,
          animated: true,
        });
      }
      lastAutoscrollTime.current = Date.now();
    },
    [captionIndexToGroupCaptionIndexRef, listRef],
  );

  const currentIndexRef = useRef(currentIndex);
  useEffect(() => {
    currentIndexRef.current = currentIndex;
    if (enabled) {
      scrollToCurrentCaption(currentIndex);
    }
  }, [currentIndex, enabled, scrollToCurrentCaption]);

  // const listMeasuredValue = useSharedValue<MeasuredDimensions | null>(null);

  // TODO: temporarily removing because this implementation has never
  // worked reliably and is causing perf issue
  // The updated experience where it always tries to scroll to the current
  // caption is acceptable
  // const scrollToCaptionIfOutOfView = useCallback(
  //   (sentenceAnimRef: AnimatedRef<RNText>, captionIndex: number) => {
  //     "worklet";
  //     if (!_WORKLET || !enabled) return;
  //     const listMeasured = (listMeasuredValue.value =
  //       listMeasuredValue.value || measure(listContainerRef));
  //     const captionMeasured = measure(sentenceAnimRef);

  //     if (!listMeasured) {
  //       return;
  //     }

  //     if (!captionMeasured) {
  //       // caption is not measured meant that it is not rendered in
  //       runOnJS(scrollToCurrentCaption)(captionIndex);
  //       return;
  //     }

  //     const listTopEnd = listMeasured.pageY;
  //     const captionTopEnd = captionMeasured.pageY;

  //     const listBottomEnd = listMeasured.pageY + listMeasured.height;
  //     const captionBottomEnd = captionMeasured.pageY + captionMeasured.height;

  //     const captionIsBelowList = captionTopEnd > listBottomEnd;
  //     const captionIsAboveList = captionBottomEnd < listTopEnd;

  //     if (
  //       // caption is rendered, but not in view
  //       captionIsBelowList ||
  //       captionIsAboveList
  //     ) {
  //       runOnJS(scrollToCurrentCaption)(captionIndex);
  //     }
  //   },
  //   [enabled, listMeasuredValue, scrollToCurrentCaption, listContainerRef],
  // );

  const onScroll = useCallback(() => {
    const wasAutoscrolling = Date.now() - lastAutoscrollTime.current < 2000;
    if (wasAutoscrolling) return; // ignore scroll events that happen during autoscroll
    setEnabled(false);
  }, []);

  return (
    <AutoscrollContext.Provider
      value={{
        enabled,
        setEnabled,
        scrollToCurrentCaption,
        onScroll,
      }}
    >
      {children}
    </AutoscrollContext.Provider>
  );
};

export const useAutoscroll = () => {
  return useContext(AutoscrollContext);
};
