import {
  IconBarChart2,
  IconBookOpen,
  IconHome,
  IconMediaLibrary,
  IconPhoneCall,
} from "@/assets/svg";
import { RouteNames } from "@/constants";
import type { FC } from "react";
import type { SvgProps } from "react-native-svg";

export const RouteIconMap: Record<string, FC<SvgProps>> = {
  [RouteNames.Home]: IconHome,
  [RouteNames.Notebook]: IconBookOpen,
  [RouteNames.Library]: IconMediaLibrary,
  [RouteNames.Analytics]: IconBarChart2,
  [RouteNames.Phone]: IconPhoneCall,
};
