export enum RecordingState {
  INACTIVE = "inactive",
  PAUSED = "paused",
  RECORDING = "recording",
}

export enum StreamingState {
  INACTIVE = "inactive",
  CONNECTING = "connecting",
  STREAMING = "streaming",
}
