"use client";

import type { FC, PropsWithChildren } from "react";
import { useState } from "react";
import { PlayerContext } from "./PlayerContext";
import type { Options, PlayerApi } from "./types";

export const PlayerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [player, setPlayer] = useState<PlayerApi | null>(null);
  const [options, setOptions] = useState<Options | null>(null);

  return (
    <PlayerContext.Provider value={{ player, setPlayer, options, setOptions }}>
      {children}
    </PlayerContext.Provider>
  );
};
