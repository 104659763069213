import { useGetCurrentUserQuery } from "@/graphql";
import { OnboardingStep } from "./constants";
import { useShouldShowAutoJoinAndRecapOnboarding } from "./useShouldShowAutoJoinAndRecapOnboarding";
import { useShouldShowSelectLanguageOnboarding } from "./useShouldShowSelectLanguageStep";
import { useShouldShowTeamOnboarding } from "./useShouldShowTeamOnboarding";

export const useShouldShowOnboarding = () => {
  const {
    shouldShowAutoJoinAndRecapOnboarding,
    loading: loadingAutoJoinAndRecap,
  } = useShouldShowAutoJoinAndRecapOnboarding();
  const { shouldShowTeamOnboarding, loading: loadingTeamOnboarding } =
    useShouldShowTeamOnboarding();
  const {
    shouldShowSelectLanguageOnboarding,
    loading: loadingSelectLanguageOnboarding,
  } = useShouldShowSelectLanguageOnboarding();

  const { data: dataUser } = useGetCurrentUserQuery();

  const loading =
    !dataUser?.user ||
    loadingAutoJoinAndRecap ||
    loadingTeamOnboarding ||
    loadingSelectLanguageOnboarding;

  const hasCompletedOnboardingStep =
    !!dataUser?.user?.onboardingStepsCompleted?.includes(
      OnboardingStep.COMPLETED,
    );

  const completed =
    hasCompletedOnboardingStep ||
    (!loading &&
      !shouldShowAutoJoinAndRecapOnboarding &&
      !shouldShowTeamOnboarding &&
      !shouldShowSelectLanguageOnboarding);

  return {
    shouldShowOnboardings:
      !loading &&
      !!dataUser?.user &&
      !completed &&
      (shouldShowAutoJoinAndRecapOnboarding ||
        shouldShowTeamOnboarding ||
        shouldShowSelectLanguageOnboarding),
    shouldShowAutoJoinAndRecapOnboarding,
    shouldShowTeamOnboarding,
    shouldShowSelectLanguageOnboarding,
    loading,
    completed,
  };
};
