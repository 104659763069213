import { Platform, type ViewStyle } from "react-native";

/**
 * Android shadow is just a joke
 */
export const ELEVATIONS = {
  1: Platform.select({
    android: {
      elevation: 1,
      shadowColor: "rgb(16, 24, 40)",
    },
    web: {
      boxShadow: "0px 2px 2px 0px rgba(16, 24, 40, 0.04)",
    },
    default: {
      shadowColor: "rgb(16, 24, 40)",
      shadowOpacity: 0.02,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 0,
    },
  }),
  2: Platform.select({
    android: {
      elevation: 4,
      shadowColor: "rgba(16, 24, 40, 0.2)",
    },
    web: {
      boxShadow:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.08)",
    },
    default: {
      shadowColor: "rgb(16, 24, 40)",
      shadowOpacity: 0.06,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 4,
    },
  }),
} satisfies Record<number, ViewStyle>;
