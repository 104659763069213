export const RouteNames = {
  Main: "Main",
  Home: "Home",
  Notebook: "Notebook",
  Library: "Library",
  Analytics: "Analytics",
  Search: "Search",
  You: "You",
  Record: "Record",
  Notepad: "Notepad",
  Login: "Login",
  Bite: "Bite",
  Playlist: "Playlist",
  Upload: "Upload",
  MeetingStatus: "MeetingStatus",
  NotFound: "NotFound",
  Settings: "Settings",
  Integrations: "Integrations",
  Extensions: "Extensions",
  TopicTracker: "TopicTracker",
  Team: "Team",
  Refer: "Refer",
  Onboarding: "Onboarding",
  Phone: "Phone",
  PhoneCall: "PhoneCall",
  Live: "Live",
  YearRecap: "YearRecap",
} as const;
