import { IconLogoText } from "@/assets/svg";
import { Text } from "@/components/Typography";
import { useTheme } from "@/styles";
import type { FC } from "react";
import { StyleSheet } from "react-native";

export const Header: FC = () => {
  const theme = useTheme();

  return (
    <>
      <IconLogoText height={36} color={theme.colors.textPrimary} />
      <Text
        variant="heading4Weight"
        style={styles.heading}
        allowFontScaling={false}
      >
        Automate{`\n`}your meeting notes
      </Text>
      <Text
        variant="body1Regular"
        style={styles.subheading}
        color="textSecondary"
        allowFontScaling={false}
      >
        Record, transcribe, search and analyze all your online and in-person
        meetings.
      </Text>
    </>
  );
};

const styles = StyleSheet.create({
  heading: {
    marginTop: 88,
    textAlign: "center",
    width: "100%",
  },
  subheading: {
    marginTop: 12,
    maxWidth: 324,
    textAlign: "center",
    lineHeight: 24,
  },
});
