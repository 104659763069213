import type { CalendarEvents, MeetingStatusData } from "@/graphql";
import { useGetWelcomeMeetingsQuery } from "@/graphql";
import { useAuth } from "../auth";

export interface CalendarEventsProps {
  liveMeetings: MeetingStatusData[];
  events: CalendarEvents[] | undefined;
  isLoadingEvents?: boolean;
  refetch: () => void;
  error?: Error;
}

export const useUpcomingCalendarEvents = (): CalendarEventsProps => {
  const { user } = useAuth();

  const skip = !user;

  const { data, loading, refetch, error } = useGetWelcomeMeetingsQuery({
    skip,
    notifyOnNetworkStatusChange: true,
  });

  const isLoadingEvents = loading || skip;

  const events = data?.getCalendarEvents?.events as
    | CalendarEvents[]
    | undefined;
  const liveMeetings = [...(data?.liveMeetings?.meetings || [])].sort((a, b) =>
    a?.startTime && b?.startTime ? a.startTime - b.startTime : 0,
  ) as MeetingStatusData[];

  return {
    events,
    liveMeetings,
    isLoadingEvents,
    refetch,
    error,
  };
};
