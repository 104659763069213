import { toast } from "@/components/Toast";
import { copyAsHtml, copyAsText } from "@/utils/clipboard";

export const markdownify = (text: string) => {
  // a text new line is represented by \n
  // while a markdown new line is represented by two \n
  return text.split("\n").join("\n\n");
};

export const copyText = (text: string, isHtml?: boolean) => {
  (isHtml ? copyAsHtml : copyAsText)(text).then(
    () => {
      toast({
        message: "Copied to clipboard",
      });
    },
    (error) => {
      toast({
        title: "Failed to copy",
        message: error.message,
        type: "error",
      });
    },
  );
};
