import { IconLock } from "@/assets/svg";
import { ErrorScreen, NotFoundScreen } from "@/components/Error";
import { isForbiddenError, shouldRetryError } from "@/errors";
import { openLoginPopup, useAuth, useShowDynamicLogin } from "@/features/auth";
import { DEMO_MEETING_IDS } from "@/features/meeting";
import { PlayerProvider } from "@/features/player";
import { getRemoteConstants } from "@/features/remote-constants";
import { useFetchIsGuestAccessEnabledQuery } from "@/graphql";
import { toSecFromHHMMSS } from "@/utils/time";
import { useQuery } from "@apollo/client";
import {
  GetMeetingDocument,
  GetMeetingSummaryQueryDocument,
} from "@firefliesai/mobile-ff.graphql-client";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { type FC } from "react";
import type { ParamList } from "../types";
import {
  NotepadScreenContent,
  NotepadScreenContentLoading,
} from "./NotepadScreenContent";

export const NotepadScreen: FC<
  NativeStackScreenProps<ParamList, "Notepad">
> = ({ route, navigation }) => {
  const { user } = useAuth();

  const meetingId = DEMO_MEETING_IDS.includes(route.params.id)
    ? getRemoteConstants().DEMO_MEETING_ID
    : route.params.id;

  const { data: isGuestAccessEnabledData } = useFetchIsGuestAccessEnabledQuery({
    variables: {
      meetingNoteId: meetingId,
    },
    skip: !!user,
  });
  useShowDynamicLogin(
    "notepad/auto-show",
    isGuestAccessEnabledData?.meetingNote?.isGuestAccessEnabled,
  );

  const { data, error, loading, refetch } = useQuery(GetMeetingDocument, {
    variables: {
      id: meetingId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  // preload meeting summary
  useQuery(GetMeetingSummaryQueryDocument, {
    variables: { meetingId },
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
  });

  if (!data?.meeting && loading) {
    return <NotepadScreenContentLoading />;
  }

  if (!data?.meeting && error) {
    if (isForbiddenError(error?.graphQLErrors?.[0] || error)) {
      if (!user) {
        return (
          <ErrorScreen
            login={() => openLoginPopup("notepad")}
            Icon={IconLock}
            title="This meeting is private"
            description="Login to check your meeting access"
          />
        );
      }

      return (
        <ErrorScreen
          Icon={IconLock}
          title="You do not have permission to view this meeting"
          description="Request meeting host for access"
        />
      );
    }
    return (
      <ErrorScreen
        description={error.message}
        retry={shouldRetryError(error) ? refetch : undefined}
      />
    );
  }

  // TODO
  if (!data?.meeting) {
    return (
      <NotFoundScreen
        title="Meeting not found"
        description="It does not exist or you do not have access"
      />
    );
  }

  const initialTime = route.params.t
    ? toSecFromHHMMSS(route.params.t)
    : undefined;

  return (
    <PlayerProvider>
      <NotepadScreenContent
        meeting={data.meeting}
        initialTime={initialTime}
        navigation={navigation}
      />
    </PlayerProvider>
  );
};
