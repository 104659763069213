import { useGetFeaturesQuery } from "@/graphql";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { useEffect, useMemo, type FC, type PropsWithChildren } from "react";
import { useAuth } from "../auth";
import { storage } from "../storage";
import { FEATURE_FLAG_STORAGE_KEY } from "./featureFlags";
export { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react";

const persistedFeatureFlags = storage.getItem(FEATURE_FLAG_STORAGE_KEY) || "{}";

export const FeatureFlagProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  const { data, refetch } = useGetFeaturesQuery({
    onCompleted: (queryResponse) => {
      if (queryResponse?.getFeatures.features) {
        storage.setItem(
          FEATURE_FLAG_STORAGE_KEY,
          JSON.stringify(queryResponse.getFeatures.features),
        );
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [user?.id, refetch]);

  const gb = useMemo(() => {
    return new GrowthBook({
      user: { ...user },
      features: data?.getFeatures.features || JSON.parse(persistedFeatureFlags),
    });
  }, [data?.getFeatures.features, user]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};
