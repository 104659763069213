import { AnimatedTouchableHighlight } from "@/components/Animated";
import { Text } from "@/components/Typography";
import { useTheme } from "@/styles";
import type { FC, ReactNode } from "react";
import { StyleSheet, View, type AccessibilityProps } from "react-native";
import { ZoomIn, ZoomOut } from "react-native-reanimated";
import type { SvgProps } from "react-native-svg";
import { sharedStyles } from "../styles";

export const FabButton: FC<
  {
    Icon?: FC<SvgProps>;
    iconSize?: number;
    badge?: number;
    children?: ReactNode;
    onPress?: () => void;
  } & AccessibilityProps
> = ({ Icon, children, onPress, badge, iconSize, ...props }) => {
  const theme = useTheme();

  return (
    <AnimatedTouchableHighlight
      underlayColor={theme.colors.layerSubtle}
      style={sharedStyles.fab(theme)}
      onPress={onPress}
      entering={ZoomIn.duration(100)}
      exiting={ZoomOut.duration(100)}
      {...props}
    >
      <View style={styles.fab}>
        {Icon && (
          <Icon
            width={iconSize || 24}
            height={iconSize || 24}
            color={theme.colors.commandNeutralDefault}
          />
        )}
        {children}
        {typeof badge === "number" && (
          <Text style={sharedStyles.fabBadge(theme)}>{badge}</Text>
        )}
      </View>
    </AnimatedTouchableHighlight>
  );
};

const styles = StyleSheet.create({
  fab: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
