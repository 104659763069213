import { IconCheck } from "@/assets/svg";
import { SkeletonLoading } from "@/components/Loading";
import { MessageView } from "@/components/Message";
import { RefreshControl } from "@/components/RefreshControl";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import { type FC } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { SectionHeader } from "../components/SectionHeader";
import { useLiveScreenContext } from "../Context/LiveScreenContext";
import { ActionItemsSection } from "./ActionItemsSection";
import { AiNotesSection } from "./AiNotesSection";
import { EmptyAiNoteSection } from "./EmptyAiNotesSection";

export const LiveScreenNotes: FC<{
  meeting: Meeting;
}> = () => {
  const { refetchLiveInsights, liveInsights } = useLiveScreenContext();

  if (!liveInsights) {
    return <LoadingLiveScreenNotes />;
  }

  return (
    <ScrollView
      refreshControl={<RefreshControl onRefresh={refetchLiveInsights} />}
      contentContainerStyle={styles.container}
    >
      {liveInsights.notes?.length ? (
        <>
          <SectionHeader title="AI Notes" />
          <View style={styles.section}>
            <AiNotesSection />
          </View>
        </>
      ) : (
        <EmptyAiNoteSection />
      )}

      <SectionHeader title="Action Items" tinted />
      <View style={styles.section}>
        {liveInsights.actionItems?.length ? (
          <ActionItemsSection />
        ) : (
          <MessageView
            Icon={IconCheck}
            title="No action items yet"
            description="Detected action items will appear here."
          />
        )}
      </View>
    </ScrollView>
  );
};

export const LoadingLiveScreenNotes: FC = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <SectionHeader title="AI Notes" />
      <View style={styles.section}>
        <SkeletonLoading width={240} height={16} />
        <SkeletonLoading width={360} height={16} />
        <SkeletonLoading width={240} height={16} />
        <SkeletonLoading width={320} height={16} />
        <SkeletonLoading width={360} height={16} />
        <SkeletonLoading width={360} height={16} />
        <SkeletonLoading width={320} height={16} />
        <SkeletonLoading width={240} height={16} />
        <SkeletonLoading width={320} height={16} />
      </View>
      <SectionHeader title="Action Items" tinted />
      <View style={styles.section}>
        <SkeletonLoading width={240} height={16} />
        <SkeletonLoading width={220} height={16} />
        <SkeletonLoading width={260} height={16} />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 12,
  },
  section: {
    paddingHorizontal: 24,
    marginBottom: 16,
    gap: 12,
  },
});
