import { Mp3Encoder } from "lamejs";
import BitStream from "lamejs/src/js/BitStream";
import Lame from "lamejs/src/js/Lame";
import MPEGMode from "lamejs/src/js/MPEGMode";
import type { AudioWriteData } from "./audio-file-buffer";
import { AudioFileBuffer } from "./audio-file-buffer";

declare global {
  interface Window {
    MPEGMode: typeof MPEGMode;
    Lame: typeof Lame;
    BitStream: typeof BitStream;
  }
}

window.MPEGMode = MPEGMode;
window.Lame = Lame;
window.BitStream = BitStream;

export class Mp3FileBuffer extends AudioFileBuffer {
  private mp3Encoder: Mp3Encoder;

  constructor({
    samplingRate,
    channels,
    bitrate,
    readChunkSize,
  }: {
    samplingRate: number;
    channels: number;
    bitrate: number;
    readChunkSize: number;
  }) {
    super({
      samplingRate,
      readChunkSize,
    });
    this.mp3Encoder = new Mp3Encoder(channels, samplingRate, bitrate);
  }

  getMimeType(): string {
    return "audio/mpeg";
  }

  headerSize(): number {
    return 0;
  }

  encode(pcmData: Uint8Array): AudioWriteData | null {
    const int16Array = new Int16Array(pcmData.buffer);

    const mp3Data = this.mp3Encoder.encodeBuffer(int16Array);

    if (mp3Data.length > 0) {
      return { data: new Uint8Array(mp3Data) };
    }

    return null;
  }

  flush(): AudioWriteData | null {
    const finalChunk = this.mp3Encoder.flush();
    if (finalChunk.length > 0) {
      return { data: new Uint8Array(finalChunk) };
    }

    return null;
  }

  shouldEncodeAudioInMediaStorage() {
    return false;
  }

  getBufferType() {
    return "mp3";
  }
}
