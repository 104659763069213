import {
  IconGlobe,
  IconMessageCircle,
  IconMoreVertical,
  IconPlus,
  IconSearch,
  IconShare,
} from "@/assets/svg";
import { Button } from "@/components/Button";
import { HeaderIconButton, HeaderSideContainer } from "@/components/Layout";
import { MEETING_PRIVACY_OPTIONS } from "@/features/meeting";
import { createStyles, useTheme } from "@/styles";
import { TRACKING_EVENTS, tracker } from "@/tracking";
import type { Meeting } from "@firefliesai/mobile-ff.graphql-client";
import type { Dispatch, FC, SetStateAction } from "react";
import { View } from "react-native";
import Animated, { FadeInLeft, FadeOutLeft } from "react-native-reanimated";
import type { SvgProps } from "react-native-svg";

const headerShareWithPrivacyIcon = (PrivacyIcon: FC<SvgProps>) => {
  return function ShareWithPrivacyIcon(props: SvgProps) {
    const theme = useTheme();
    return (
      <>
        <IconShare {...props} style={styles.shareIcon} />
        <View style={styles.privacyIcon(theme)}>
          <PrivacyIcon
            width={10}
            height={10}
            color={theme.colors.textMuted}
            strokeWidth={3}
          />
        </View>
      </>
    );
  };
};

export const HeaderRight: FC<{
  meeting: Meeting;
  setOverlay: Dispatch<
    SetStateAction<"search" | "thread" | "add-soundbite" | null>
  >;
  openShare: () => void;
  openMenu: () => void;
}> = ({ meeting, setOverlay, openShare, openMenu }) => {
  const toggleOverlay = (overlay: "search" | "thread") => {
    setOverlay((prev) => (prev === overlay ? null : overlay));
  };

  const MEETING_PRIVACY_OPTION = MEETING_PRIVACY_OPTIONS.find(
    (option) => option.value === meeting.notesPrivacy,
  );

  return (
    <Animated.View
      exiting={FadeOutLeft.duration(300)}
      entering={FadeInLeft.duration(300)}
    >
      <HeaderSideContainer>
        <HeaderIconButton
          Icon={IconSearch}
          label="Search transcript"
          onPress={() => toggleOverlay("search")}
        />
        <HeaderIconButton
          Icon={IconMessageCircle}
          label="Comments"
          onPress={() => {
            toggleOverlay("thread");
            tracker.track(TRACKING_EVENTS.MEETING_COMMENTS_VIEWED, {
              meetingId: meeting.id,
              from: "screen/header",
            });
          }}
        />
        <HeaderIconButton
          Icon={
            MEETING_PRIVACY_OPTION?.Icon
              ? headerShareWithPrivacyIcon(MEETING_PRIVACY_OPTION.Icon)
              : IconShare
          }
          label="Share"
          onPress={() => {
            openShare();
            tracker.track(TRACKING_EVENTS.SHARE_DIALOG_OPENED, {
              type: "meeting",
              from: "screen/header",
              id: meeting.id,
              privacy: meeting.notesPrivacy,
            });
          }}
        />
        <HeaderIconButton
          Icon={IconMoreVertical}
          label="More"
          onPress={openMenu}
        />
      </HeaderSideContainer>
    </Animated.View>
  );
};

export const HeaderRightDesktop: FC<{
  meeting: Meeting;
  openShare: () => void;
  openMenu: () => void;
  openCreateSoundbite: () => void;
}> = ({ meeting, openShare, openMenu, openCreateSoundbite }) => {
  const theme = useTheme();
  return (
    <HeaderSideContainer>
      {!!meeting.captions?.length && (
        <Button
          variant="transparentNeutral"
          Icon={IconPlus}
          aria-label="Create Soundbite"
          onPress={openCreateSoundbite}
          size="xs"
        >
          Soundbite
        </Button>
      )}
      <View style={styles.seperator(theme)} />
      <Button
        Icon={IconGlobe}
        onPress={() => {
          openShare();
          tracker.track(TRACKING_EVENTS.SHARE_DIALOG_OPENED, {
            type: "meeting",
            from: "screen/header",
            id: meeting.id,
            privacy: meeting.notesPrivacy,
          });
        }}
        size="xs"
      >
        Share
      </Button>
      <Button
        variant="transparentNeutral"
        Icon={IconMoreVertical}
        aria-label="More"
        onPress={openMenu}
        size="xs"
      />
    </HeaderSideContainer>
  );
};

const styles = createStyles({
  shareIcon: {
    marginBottom: 2,
    marginRight: 2,
  },
  privacyIcon: (theme) => ({
    position: "absolute",
    right: 4,
    bottom: 4,
    borderRadius: 9999,
    padding: 2,
    backgroundColor: theme.colors.layerDefault,
  }),
  seperator: (theme) => ({
    width: 1,
    height: 24,
    backgroundColor: theme.colors.borderStaticSubtle,
  }),
});
