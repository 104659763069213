import { RNText } from "@/components/Typography";
import { vars } from "@/styles";
import { isGenericDomain } from "@/utils/email";
import { isTruthy } from "@/utils/object";
import { StyleSheet } from "react-native";
import type { User } from "../graphql/gql.gen";

export enum SendNotesTo {
  All = "all",
  Team = "team",
  Domain = "domain",
  Me = "me",
}

export enum AutoJoin {
  Auto = "auto",
  Autoself = "autoself",
  Autoteam = "autoteam",
  Autoexternal = "autoexternal",
  Manual = "manual",
}

export const SEND_NOTES_TO_OPTIONS = (user?: Pick<User, "domain"> | null) =>
  [
    {
      value: SendNotesTo.All,
      label: "Everyone on the invite",
    },
    {
      value: SendNotesTo.Team,
      label: `Me and participants from my Fireflies team`,
    },
    !(user?.domain && isGenericDomain(user?.domain)) && {
      value: SendNotesTo.Domain,
      label: (
        <>
          Me and participants from{" "}
          <RNText style={styles.highlightText}>
            {user?.domain || "my domain"}
          </RNText>
        </>
      ),
    },
    {
      value: SendNotesTo.Me,
      label: `Only me`,
    },
  ].filter(isTruthy);

export const AUTO_JOIN_OPTIONS = (user?: Pick<User, "domain"> | null) =>
  [
    {
      value: AutoJoin.Auto,
      label: `All calendar events with web-conf link`,
    },
    {
      value: AutoJoin.Autoself,
      label: "Only calendar events that I own",
    },
    !(user?.domain && isGenericDomain(user?.domain)) && {
      value: AutoJoin.Autoteam,
      label: (
        <>
          Only internal events with{" "}
          <RNText style={styles.highlightText}>{user?.domain}</RNText> teammates
        </>
      ),
    },
    !(user?.domain && isGenericDomain(user?.domain)) && {
      value: AutoJoin.Autoexternal,
      label: (
        <>
          Only external events with{" "}
          <RNText style={styles.highlightText}>non-{user?.domain}</RNText>{" "}
          clients
        </>
      ),
    },
  ].filter(isTruthy);

const styles = StyleSheet.create({
  highlightText: {
    color: vars.colors.purple700,
  },
});

export const MANUAL_AUTO_JOIN_OPTION = {
  value: AutoJoin.Manual,
  label: (
    <>
      Only when I invite{" "}
      <RNText style={styles.highlightText}>fred@fireflies.ai</RNText>
    </>
  ),
};
