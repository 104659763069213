import { IconGlobe } from "@/assets/svg";
import { Select } from "@/components/Input";
import { Text } from "@/components/Typography";
import { SUPPORTED_LANGUAGES_OPTIONS } from "@/constants";
import { useRecorder, useRecordPreferencesStore } from "@/features/live-record";
import { createStyles, useTheme } from "@/styles";
import { getLanguageByCode } from "@firefliesai/shared-libs.utils/dist/langs/langs.helpers";
import type { FC } from "react";
import { View } from "react-native";
import { useRecordScreenContext } from "../RecordScreenContext";

export const RecordLanguageSelect: FC = () => {
  const { startInputs, setStartInputs } = useRecordScreenContext();

  const { status } = useRecorder();

  const theme = useTheme();

  if (status.state === "inactive") {
    return (
      <Select
        value={startInputs.language}
        onValueChange={(value) => {
          useRecordPreferencesStore.getState().setLanguage(value);
          setStartInputs((prev) => ({
            ...prev,
            language: value,
          }));
        }}
        options={SUPPORTED_LANGUAGES_OPTIONS}
        aria-label="Select recording language"
        showSearch
        style={styles.languageSelect}
      />
    );
  }

  const languageName = getLanguageByCode(startInputs.language)?.name;

  return (
    <View style={styles.languageRow}>
      <IconGlobe
        aria-label="Language"
        width={16}
        height={16}
        color={theme.colors.textHint}
      />
      <Text variant="body2Regular" color="textHint">
        {languageName}
      </Text>
    </View>
  );
};

export const styles = createStyles({
  languageSelect: {
    width: "100%",
  },
  languageRow: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    marginTop: 12,
  },
});
