import type { FC } from "react";
import { SafeAreaView } from "react-native-safe-area-context";

import {
  OngoingUploadNotificationBar,
  useOngoingUploadNotificationBarVisible,
} from "./OngoingUploadNotificationBar";

export const NotificationBars: FC = () => {
  const isOngoingUploadNotificationVisible =
    useOngoingUploadNotificationBarVisible();
  if (!isOngoingUploadNotificationVisible) {
    return null;
  }
  return (
    <SafeAreaView edges={["top"]}>
      {isOngoingUploadNotificationVisible && <OngoingUploadNotificationBar />}
    </SafeAreaView>
  );
};

export const useIsNotificationBarsVisible = () => {
  const isOngoingUploadNotificationVisible =
    useOngoingUploadNotificationBarVisible();

  return isOngoingUploadNotificationVisible;
};
