import type { FC } from "react";
import { useCallback, useState } from "react";
import { ComplianceDialog } from "./ComplianceDialog/ComplianceDialog";

export const AnnouncementModals: FC = () => {
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  const open = useCallback((modal: string) => {
    // make sure only one modal is shown at a time
    setCurrentModal((prevCurrentModal) => {
      if (prevCurrentModal) return prevCurrentModal;
      return modal;
    });
  }, []);

  const close = useCallback(() => {
    setCurrentModal(null);
  }, []);

  const openComplianceDialog = useCallback(() => {
    open("complianceDialog");
  }, [open]);

  return (
    <>
      <ComplianceDialog
        isOpen={currentModal === "complianceDialog"}
        open={openComplianceDialog}
        close={close}
      />
    </>
  );
};
