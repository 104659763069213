import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { mobileApi } from "../mobile-api";
import { storage } from "../storage";

interface RemoteConstantsState {
  DEMO_MEETING_ID: string;
  MAX_RECORDING_DURATION: number | undefined | null;
}

// we use zustand store for its persistance api which
// allow us to cache the remote constants in the local storage
export const useRemoteConstantsStore = create(
  persist<RemoteConstantsState>(
    () =>
      // default values
      ({
        DEMO_MEETING_ID: "b1H42tdxM8",
        MAX_RECORDING_DURATION: 3.0 * 60 * 60 * 1000,
      }),
    {
      name: "remote-constants",
      storage: createJSONStorage(() => storage),
      onRehydrateStorage: () => {
        mobileApi.getRemoteConstants().then(
          (remoteConstants) => {
            useRemoteConstantsStore.setState(remoteConstants);
          },
          () => {
            // ignore
          },
        );
      },
    },
  ),
);

export const getRemoteConstants = () => {
  return useRemoteConstantsStore.getState();
};
