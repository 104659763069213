import type { FC, PropsWithChildren } from "react";
import { Platform, StyleSheet, View } from "react-native";

/**
 * Contains button on headerRight and apply
 * a unified gaps and fix padding inconsistencies
 */
export const HeaderSideContainer: FC<
  PropsWithChildren<{
    forcePadding?: boolean;
  }>
> = ({ children, forcePadding }) => {
  return (
    <View
      style={[
        styles.headerRight,
        (forcePadding || Platform.OS === "web") && styles.pad,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  headerRight: {
    flexDirection: "row",
    gap: 8,
  },
  // tab navigator header and native stack header on web
  // does not have padding on the right
  pad: {
    paddingHorizontal: 8,
  },
});
